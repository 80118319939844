<template>
  <CTPlannerComponent :defaultCategory="defaultCategory" />
</template>

<script>
// @ is an alias to /src
import CTPlannerComponent from "@/components/CTPlanner.vue";

export default {
  name: "CTPlanner",
  props: {
    defaultCategory: String,
  },
  components: {
    CTPlannerComponent,
  },
};
</script>
