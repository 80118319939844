<template>
  <div v-if="currentModal != 0" class="modal is-active">
    <div class="modal-background" @click="closeModal()"></div>
    <div class="modal-card overflow">
      <!-- Add Protocol -->
      <div v-if="currentModal == ADD_PROTOCOL">
        <header class="modal-card-head">
          <div class="modal-card-title">Add Case Protocol</div>
        </header>
        <section class="modal-card-body is-size-5">
          <div class="columns">
            <div class="column is-4">
              <div class="has-text-weight-bold is-grey">Select Existing</div>
              <div class="protocols mt-2">
                <div v-for="(protocol, index) in allProtocols" :key="index">
                  <input
                    type="checkbox"
                    :id="index"
                    :value="protocol.name"
                    v-model="selectedProtocols"
                    :disabled="protocolIsInList(protocol.name)"
                  />
                  <label
                    :for="protocol.name"
                    class="is-size-6 ml-2"
                    @click="
                      protocolIsInList(protocol.name)
                        ? ''
                        : toggleProtocol(protocol)
                    "
                    >{{ protocol.name }}</label
                  >
                </div>
              </div>
            </div>
            <div class="column is-2">
              <div class="columns is-multiline mt-2 is-full-height">
                <div class="column is-half has-border-right"></div>
                <div class="column is-full has-text-centered p-0 margin-auto">
                  <span>OR</span>
                </div>
                <div class="column is-half has-border-right"></div>
              </div>
            </div>
            <div class="column is-6">
              <div class="has-text-weight-bold is-grey">Upload File</div>
              <div class="dropbox mt-3">
                <form enctype="multipart/form-data" ref="fileform">
                  <input
                    type="file"
                    accept=".yml,.yaml"
                    :name="uploadFieldName"
                    @change="uploadProtocol($event.target.files)"
                    class="input-file hidden"
                  />
                  <p class="has-text-dark-primary is-size-6">
                    Drag file here or click to browse
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div>
            <button @click="addProtocols()" class="button is-success">
              CONFIRM
            </button>
            <button @click="closeModal()" class="button">CANCEL</button>
          </div>
        </footer>
      </div>
      <!-- End Add Protocol -->
      <!-- Edit Protocol -->
      <div v-else-if="currentModal == EDIT_PROTOCOL" class="mb-2">
        <header class="modal-card-head">
          <div v-if="this.selectedProtocol.name" class="modal-card-title">
            Edit Case Protocol
          </div>
          <div v-else class="modal-card-title">Create Case Protocol</div>
        </header>
        <section class="modal-card-body is-size-5">
          <div>
            <input
              :disabled="true"
              class="input mb-2"
              type="text"
              placeholder="Protocol Name (defined in text)"
              v-model="protocolName"
            />
          </div>
          <span
            v-if="this.selectedProtocol.name"
            class="has-text-grey is-size-6 is-block mb-2"
            >Protocol name has to stay the same</span
          >
          <div>
            <textarea
              class="textarea textinput"
              type="text"
              rows="15"
              v-model="protocolContent"
              :placeholder="exampleYaml"
            />
          </div>
        </section>
        <footer class="modal-card-foot mb-2">
          <div>
            <button @click="editProtocolEvent()" class="button is-success">
              CONFIRM
            </button>
            <button @click="closeModal()" class="button">CANCEL</button>
            <span
              v-if="isYamlError"
              class="has-text-weight-bold has-text-danger"
              >YAML Format Error</span
            >
          </div>
        </footer>
      </div>
      <!-- End Edit Protocol -->
      <!-- Confirm Modals -->
      <div v-else>
        <header class="modal-card-head">
          <div v-if="currentModal == ADD_ORG_CONFIRM" class="modal-card-title">
            Add Organization
          </div>
          <div
            v-if="currentModal == DELETE_ORG_CONFIRM"
            class="modal-card-title"
          >
            Delete Organization
          </div>
          <div
            v-if="currentModal == DELETE_USER_CONFIRM"
            class="modal-card-title"
          >
            Delete User
          </div>
          <div
            v-if="currentModal == DELETE_PROTOCOL_CONFIRM"
            class="modal-card-title"
          >
            Delete Protocol
          </div>
        </header>
        <section class="modal-card-body is-size-5">
          <div v-if="currentModal == ADD_ORG_CONFIRM">
            <span>Add Organization:</span>
            <span class="has-text-weight-bold is-block">{{ orgName }}</span>
            <div v-if="userEmail != ''">
              <span>with Admin User:</span>
              <span class="has-text-weight-bold is-block">{{ userEmail }}</span>
            </div>
            <div v-else>
              <span>without admin user</span>
            </div>
          </div>
          <div v-if="currentModal == DELETE_ORG_CONFIRM">
            <span>Delete "{{ selectedOrganization }}"</span>
            <span class="has-text-weight-bold is-block">
              and ALL of its users?</span
            >
          </div>
          <div v-if="currentModal == DELETE_USER_CONFIRM">
            <span>Delete "{{ selectedUser }}"</span>
            <span class="is-size-14 icon-dark-grey is-block"
              >This will delete the user immediately.</span
            ><span class="is-size-14 icon-dark-grey is-block">
              To undo this, the user has to be added again.</span
            >
          </div>
          <div v-if="currentModal == DELETE_PROTOCOL_CONFIRM">
            <span>Delete protocol "{{ selectedProtocol.name }}"?</span>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div v-if="currentModal == ADD_ORG_CONFIRM">
            <button @click="submitOrganization()" class="button is-success">
              CONFIRM
            </button>
          </div>
          <div v-if="currentModal == DELETE_PROTOCOL_CONFIRM">
            <button @click="deleteProtocol()" class="button is-success">
              CONFIRM
            </button>
          </div>
          <div
            v-if="
              currentModal == DELETE_ORG_CONFIRM ||
              currentModal == DELETE_USER_CONFIRM
            "
          >
            <button
              @click="
                currentModal == DELETE_ORG_CONFIRM
                  ? deleteOrganization()
                  : deleteUser()
              "
              class="button is-danger"
            >
              DELETE
            </button>
          </div>
          <button @click="closeModal()" class="button ml-1">CANCEL</button>
        </footer>
      </div>
      <!-- End Confirm Modals -->
    </div>
  </div>
</template>
<script>
import YAML from "yaml";
import * as constants from "@/constants.js";
export default {
  name: "AdminModal",
  props: {
    currentModal: Number,
    orgName: String,
    userEmail: String,
    selectedOrganization: String,
    selectedProtocol: Object,
    selectedUser: String,
    allProtocols: Array,
    protocolList: Array,
  },
  data() {
    return {
      ADD_ORG_CONFIRM: 5,
      DELETE_ORG_CONFIRM: 6,
      DELETE_USER_CONFIRM: 7,
      ADD_PROTOCOL: 9,
      EDIT_PROTOCOL: 11,
      DELETE_PROTOCOL_CONFIRM: 12,
      uploadFieldName: "protocols",
      selectedProtocols: [],
      protocolName: "",
      protocolContent: "",
      exampleYaml: "",
      isYamlError: false,
    };
  },
  watch: {
    currentModal: function () {
      this.isYamlError = false;
      this.protocolName = this.selectedProtocol.name;
      if (
        this.currentModal === this.ADD_PROTOCOL ||
        this.currentModal === this.EDIT_PROTOCOL
      ) {
        if (this.selectedProtocol.name) {
          this.protocolContent = YAML.stringify(
            YAML.parse(this.selectedProtocol.protocol),
            null,
            2
          );
        }
      }
      this.selectedProtocols = [];
      this.allProtocols.forEach((protocol) => {
        this.protocolList.forEach((organizationProtocol) => {
          if (protocol.name === organizationProtocol) {
            this.selectedProtocols.push(protocol.name);
          }
        });
      });
    },
  },
  beforeMount() {
    this.exampleYaml = YAML.stringify(
      YAML.parse(constants.yamlExample),
      null,
      2
    );
  },
  methods: {
    protocolIsInList(protocol) {
      for (const element of this.protocolList) {
        if (element === protocol) {
          return true;
        }
      }
      return false;
    },
    toggleProtocol: function (protocol) {
      if (!this.selectedProtocols.includes(protocol.name)) {
        this.selectedProtocols.push(protocol.name);
      } else {
        const index = this.selectedProtocols.findIndex(
          (element) => element === protocol.name
        );
        this.selectedProtocols.splice(index, 1);
      }
    },
    submitOrganization: function () {
      this.$emit("submitOrganization");
    },
    deleteOrganization: function () {
      this.$emit("deleteOrganization");
    },
    deleteUser: function () {
      this.$emit("deleteUser");
    },
    closeModal: function () {
      this.protocolName = "";
      this.protocolContent = "";
      this.$emit("closeModal");
    },
    addProtocols: function () {
      this.$emit("addProtocols", this.selectedProtocols);
      this.$emit("closeModal");
    },
    uploadProtocol: function (files) {
      this.$emit("sendUploadProtocolEvent", files[0]);
      this.$emit("closeModal");
    },
    editProtocolEvent: function () {
      try {
        this.isYamlError = false;
      } catch {
        this.isYamlError = true;
        setTimeout(() => {
          this.isYamlError = false;
        }, 3000);
      }
      if (!this.isYamlError && this.selectedProtocol.name) {
        this.$emit(
          "editProtocolEvent",
          JSON.stringify(YAML.parse(this.protocolContent))
        );
        this.protocolContent = "";
      } else if (!this.isYamlError && !this.selectedProtocol.name) {
        this.$emit(
          "createProtocol",
          JSON.stringify(YAML.parse(this.protocolContent))
        );
        this.protocolContent = "";
      }
    },
    deleteProtocol: function () {
      this.$emit("deleteProtocol");
    },
  },
};
</script>
<style scoped>
.protocols {
  height: 170px;
  overflow-y: auto !important;
}
.overflow {
  overflow-y: auto !important;
}
.dropbox {
  height: 80%;
  box-sizing: border-box;
  position: relative;
  border: 2px dashed grey;
  border-radius: 10px;
  background: rgb(231, 226, 226);
  cursor: pointer;
}

.dropbox:hover {
  background: lightgrey;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.input-file {
  margin-left: -10px;
  margin-top: -10px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0 !important;
  cursor: pointer;
  color: transparent !important;
}
</style>
