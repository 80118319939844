import Vue from "vue";
import Vuex from "vuex";
import * as constants from "../constants";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    currentMode: constants.MODE_DEVELOPMENT,
    currentAccessMode: constants.ACCESS_MODE_EDITOR,
    currentView: constants.VIEW_BROWSE,
    currentCaseId: "",
    currentCase: {},
    currentUser: undefined,
    certification: constants.CERTIFICATION_ONGOING,
    isUploadInProgress: false,
    isUploadWarningSeen: false,
    // This is needed because editor access on server side and
    // editor access on client is not always the same
    // For example, due to finalized
    hasEditAcessOnServer: false
  },
  mutations: {
    setCurrentMode(state, mode) {
      state.currentMode = mode;
    },
    setCurrentAccessMode(state, accessMode) {
      state.currentAccessMode = accessMode;
    },
    setCurrentView(state, view) {
      state.currentView = view;
    },
    setCurrentCaseId(state, caseId) {
      state.currentCaseId = caseId;
    },
    setCurrentCase(state, selectedCase) {
      state.currentCase = selectedCase;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setCertification(state, certification) {
      state.certification = certification;
    },
    setIsUploadInProgress(state, value) {
      state.isUploadInProgress = value;
    },
    setIsUploadWarningSeen(state, value) {
      state.isUploadWarningSeen = value;
    },
    setHasEditAccessOnServer(state, value) {
      state.hasEditAcessOnServer = value;
    }
  },
  getters: {
    currentMode: state => state.currentMode,
    currentAccessMode: state => state.currentAccessMode,
    currentView: state => state.currentView,
    currentCaseId: state => state.currentCaseId,
    currentCase: state => state.currentCase,
    currentUser: state => state.currentUser,
    certification: state => state.certification,
    isUploadInProgress: state => state.isUploadInProgress,
    isUploadWarningSeen: state => state.isUploadWarningSeen,
    hasEditAcessOnServer: state => state.hasEditAcessOnServer
  }
});
