<template>
  <div
    :id="'fluoroContainer_' + index"
    class="fluoro-container has-width-100-per"
  >
    <div class="fluoro-box tag is-white is-left-10 is-bottom-35 has-width-54">
      <div
        :id="'primaryFluoroText_' + index"
        class="
          has-line-height-20
          is-size-7
          has-text-black
          is-left-6 is-absolute
          non-selectable-text
        "
      ></div>
    </div>
    <div class="fluoro-box tag is-white is-left-10 is-bottom-10 has-width-54">
      <div
        :id="'secondaryFluoroText_' + index"
        class="
          has-line-height-20
          is-size-7
          has-text-black
          is-left-6 is-absolute
          non-selectable-text
        "
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FluoroWidget",
  props: {
    index: String,
  },
};
</script>

<style scoped>
.is-left-10 {
  left: 10px;
}

.is-left-6 {
  left: 6px;
}

.has-width-54 {
  width: 54px;
}

.is-bottom-35 {
  bottom: 35px;
}
</style>