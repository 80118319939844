import * as THREE from "three";

// Line material from examples is used since THREE.js default Line material
// has bugs with line width.
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";

export default class Materials {
  /* Container of available THREE.js materials. */

  constructor() {
    // TODO: update resolution on resize events from views
    this._resolution = new THREE.Vector2(512, 512);
    this.crossRed = this._makeMaterial(0xdb0b0b);
    this.crossGreen = this._makeMaterial(0x1bdd45);
    this.crossBlue = this._makeMaterial(0x258bc4);
    this.line = this._makeMaterial(0xf525ae, 1.4);
    this.splineWithin = this._makeMaterial(0xf525ae, 2.2, 1);
    this.splineBelow = this._makeMaterial(0xf935b2, 2.2, 0.8);
    this.splineAbove = this._makeMaterial(0xb11160, 2.2, 1);
    this.handle = new THREE.MeshBasicMaterial({
      color: 0xe50c7d
    });
    this.connector = new THREE.LineDashedMaterial({
      color: 0x000000,
      linewidth: 0.5,
      scale: 3,
      dashSize: 3,
      gapSize: 5
    });
  }

  _makeMaterial(color, width = 1.2, opacity = 1.0) {
    return new LineMaterial({
      color: color,
      linewidth: width,
      userData: "custom",
      vertexColors: false,
      dashed: false,
      resolution: this._resolution,
      opacity: opacity
    });
  }

  resize(width, height) {
    /* Width of the line materials (line, cross, spline)
    is realtive to the renderer view reolution.
    This function updates it to the actual reolution
    (e.g when the entire browser window is resized)

    Currently we assume that all views (thus all renderers)
    have equal resolution */
    this._resolution.x = width;
    this._resolution.y = height;
    this.crossRed.resolution = this._resolution;
    this.crossGreen.resolution = this._resolution;
    this.crossBlue.resolution = this._resolution;
    this.line.resolution = this._resolution;
    this.splineWithin.resolution = this._resolution;
    this.splineAbove.resolution = this._resolution;
    this.splineBelow.resolution = this._resolution;
  }
}
