import { VIEW_TYPE } from "../constants";

function getSurfaceViews(caseProtocol) {
  /* Extract surface rendering views from case protocol */
  const surfaceViews = [];
  Object.entries(caseProtocol.categories).forEach(([_, category]) => {
    Object.entries(category.boards).forEach(([_, board]) => {
      Object.entries(board.views).forEach(([_, view]) => {
        if (view.type === VIEW_TYPE.SURFACE) {
          surfaceViews.push(view.id_);
        }
      });
    });
  });
  return surfaceViews;
}

export function createSurfaceCache(phases, caseProtocol) {
  /* Construct storage objects for surface models:
  there is a scene for each surface view;
  scene just defines which surfaces are included - surfaces are shared
  between the views. */
  const models = {};
  const surfaceViews = getSurfaceViews(caseProtocol);
  phases.forEach(phase => {
    models[phase] = {
      scenes: {}
    };
    surfaceViews.forEach((view, _) => {
      models[phase].scenes[view] = {
        data: undefined
      };
    });
  });
  return models;
}
