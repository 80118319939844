<template>
  <div class="widgets is-absolute is-full-height has-width-100-per">
    <div v-if="hasMeasurements">
      <div class="is-absolute draggable-limit">
        <vue-draggable-resizable
          class="is-absolute has-zindex-1 cursor-grab"
          w="auto"
          h="auto"
          :x="box.x"
          :y="box.y"
          :id="'box_' + componentName"
          :parent="true"
          :key="boxKey"
          @dragging="onDrag"
        >
          <div class="header has-background-grey">
            <span
              class="chevron has-float-left fas has-text-white mx-2 pointer"
              :class="showMeasurements ? 'fa-chevron-up' : 'fa-chevron-down'"
              @click="toggleMeasurements"
            ></span
            ><span class="mr-2 has-text-white">{{ boxTitle }} </span>
          </div>
          <div
            v-show="showMeasurements"
            class="measurement-box mt-3"
            :id="'measurementBox_' + componentName"
          >
            <div
              v-for="(measurement, index) in scalars"
              :key="index"
              class="padding-tb-0"
            >
              <div class="columns pb-2">
                <div class="column pb-0 pt-0 measurement-name">
                  <span class="ml-1 is-size-7 is-clipped">
                    {{ measurement.name }}:
                  </span>
                </div>
                <div class="column pb-0 pt-0 is-narrow measurement-value">
                  <span class="has-float-right mr-1 is-size-7">
                    {{ formatValue(measurement.value, measurement.decimal) }}
                    {{ measurement.unit }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </vue-draggable-resizable>
      </div>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import _ from "lodash";

export default {
  name: "ToolsDashboard",
  components: {
    VueDraggableResizable,
  },
  props: {
    componentName: String,
    boxTitle: String,
    scalars: Array,
  },
  data() {
    return {
      box: { x: 0, y: 0 },
      boxKey: 0,
      poseIndex: 0,
      showMeasurements: true,
      // TODO: Connect to backend function
    };
  },
  watch: {
    boxTitle: function () {
      this.showMeasurements = true;
      // Wait for measurement box to rerender, otherwise boxKey++ happens before that and has no effect
      setTimeout(() => {
        this.boxKey++;
      }, 800);
      this.checkOverlap();
    },
  },
  computed: {
    hasMeasurements: function () {
      return this.scalars.length;
    },
  },
  methods: {
    formatValue: function (value, decimal) {
      if (typeof value === "string") {
        return value;
      } else if (typeof value === "number") {
        return this.roundOffValue(value, decimal);
      }
      return "--";
    },
    roundOffValue(value, decimal) {
      return parseFloat(value).toFixed(decimal);
    },
    onDrag: function (x, y) {
      this.box.x = x;
      this.box.y = y;
    },
    toggleMeasurements() {
      this.showMeasurements = !this.showMeasurements;
      this.boxKey++;

      this.$nextTick(() => {
        this.checkOverlap();
      });
    },
    checkOverlap() {
      const draggedElement = document.getElementById(
        "box_" + this.componentName
      );
      if (draggedElement !== null) {
        const boundariesElement = draggedElement.getBoundingClientRect();
        const parentElement = draggedElement.closest("div.draggable-limit");
        const boundariesParent = parentElement.getBoundingClientRect();
        if (this.box.x + boundariesElement.width > boundariesParent.width) {
          this.box.x = boundariesParent.width - boundariesElement.width - 5;
        }
        if (this.box.y + boundariesElement.height > boundariesParent.height) {
          this.box.y = boundariesParent.height - boundariesElement.height - 5;
        }

        this.boxKey++;
      }
    },
  },
};
</script>

<style scoped>
.draggable-limit {
  top: 60px;
  left: 5px;
  right: 5px;
  bottom: 64px;
}

.drag-box {
  min-width: 230px;
  height: auto;
}

.header {
  border-radius: 5px 5px 0 0;
}

.measurement-box {
  background: rgba(255, 255, 255);
  opacity: 0.6;
  border-radius: 0 0 5px 5px;
}

.chevron {
  line-height: unset;
}
</style>
