export const TOOL = {
  /* Tools supported by the MPR views */
  DEFAULT: "default", // default interaction is defined on the view interaction type (slicing or rotation)
  CONTRAST: "contrast",
  ZOOM: "zoom",
  MEASURING: "measuring"
};

export const VIEW_TYPE = {
  /* Types of the supported views */
  MPR: "mpr",
  SURFACE: "surface"
};

export const VIEW_INTERACTION = {
  /* Types of default interactions for MPR view */
  SLICING: "slicing",
  ROTATION: "rotation"
};

export const GEOMETRY_TYPE = {
  CLOSED_SPLINE: "spline_closed",
  CLOSED_SPLINE_2D: "spline_closed_2d",
  OPEN_SPLINE: "spline_open",
  LINE: "line",
  DIAMETER: "diameter"
};

export const STATE_CHANGE_EVENT = {
  /* State change event that may require view updates */
  ZOOM: "zoom",
  CONTRAST: "contrast",
  SURFACE_LOADED: "surface loaded",
  CT_SLICE_LOADED: "ct loaded",
  SLICE_CHANGED: "slice changed",
  PLANES_LOADED: "planes loaded",
  BOARD_CHANGED: "board changed",
  TOOL_CHANGED: "tool changed",
  OVL_STATUS_CHANGED: "overlays status changed",
  FULLSCREEN_CHANGED: "fullscreen changed",
  MEASUREMENTS_LOADED: "measurements loaded",
  ANNULUS_UPDATED: "annulus updated",
  ANNULUS_POINT_SELECTED: "annulus point selected"
};

export const FLUORO_ANGLES = {
  LAO: "LAO",
  LPO: "LPO",
  RAO: "RAO",
  RPO: "RPO",
  CAUD: "CAU",
  CRAN: "CRA"
};

export const CT_QUEUE_MESSAGE = {
  INITIAL: "intial",
  UPDATE: "update",
  ONE_SLICE: "one slice",
  UPDATE_COOKIE: "update_cookie"
};
