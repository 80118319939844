<template>
  <div id="app" v-if="isReady" :class="theme == 'default' ? '' : 'dark-theme'">
    <router-view></router-view>

    <ErrorModal
      :isErrorModalSeen="isErrorModalSeen"
      :errorObject="errorObject"
    ></ErrorModal>

    <footer v-if="theme != 'default'" id="footer" class="has-fixed-footer">
      <p class="padding-rl-15 has-text-right">
        <span class="has-text-dark-primary">
          NOT FOR CLINICAL USE - FOR INTERNAL RESEARCH USE ONLY
        </span>
      </p>
    </footer>
  </div>
</template>

<script>
import ErrorModal from "@/components/ErrorModal.vue";

import axios from "axios";
import { io } from "socket.io-client";
import { sendErrors } from "@/js/errorHandler.js";
import * as helper from "@/js/helper.js";
import * as constants from "@/constants";

const socket = io.connect();
const ACTIVITY_TIMEOUT = 30 * 60 * 1000; // 30 minutes

export default {
  name: "App",
  data() {
    return {
      tokenRefreshIntervalId: "",
      tokenRefreshIntervalSec: 30,
      theme: "default",
      isReady: false,
      lastActivity: Date.now(),
      inactiveLogoutMessage: false,
      redirectInterval: "",
      mode: constants.MODE_PRODUCTION,
      isErrorModalSeen: false,
      errorObject: {
        title: "Environment requirements are not met",
        primaryMessage:
          "The application cannot be started because the screen size is too small, or the browser is not suitable.",
        secondaryMessage: `Minimal screen size: 1440 x 709 px
        Required internet browser: Chrome (version 76 or higher)`,
      },
    };
  },
  components: {
    ErrorModal,
  },
  beforeMount() {
    this.getConfig();
    this.getUserTheme();
    if (!this.isEnvironmentValid()) {
      this.openErrorModal();
    }
  },
  beforeDestroy() {
    this.cancelTokenRefresh();
    this.cancelActivityWatch();
    window.removeEventListener("resize", this.verifyEnvironmentValidity);
  },
  created() {
    axios.interceptors.request.use((config) => {
      const cookie = helper.parseCookie(document.cookie);
      if (config.url === "/api/refresh_token" && cookie["csrf_refresh_token"]) {
        config.headers["X-CSRF-TOKEN"] = cookie["csrf_refresh_token"];
      } else if (cookie["csrf_access_token"]) {
        config.headers["X-CSRF-TOKEN"] = cookie["csrf_access_token"];
      }
      return config;
    });

    axios.interceptors.response.use(null, (error) => {
      if (
        error.response.status === 401 &&
        error.response.data.msg === "Missing CSRF token"
      ) {
        axios
          .get("/api/logout", {})
          .then((res) => {
            window.location.href = "/";
          })
          .catch((error) => {
            sendErrors(error, "logout");
          });
      } else {
        return Promise.reject(error);
      }
    });

    this.setSocket();
    this.refreshJwt();
    this.initializeActivityListener();
    window.addEventListener("resize", this.verifyEnvironmentValidity);

    this.tokenRefreshIntervalId = setInterval(
      this.refreshJwt.bind(this),
      this.tokenRefreshIntervalSec * 1000
    );

    console.log(
      "Refreshing auth token every " + this.tokenRefreshIntervalSec + "secs."
    );
  },
  methods: {
    setSocket: function () {
      const self = this;
      socket.on("ConfirmActivity", function () {
        // Confirmation to backend before automatic logout

        axios
          .post("/api/confirm_active", {
            isActive: Date.now() - self.lastActivity < ACTIVITY_TIMEOUT,
          })
          .then(() => {})
          .catch((err) => {
            sendErrors(err, "App.vue:confirmActivity");
          });
      });
      socket.on("Logout", function () {
        if (Date.now() - self.lastActivity > ACTIVITY_TIMEOUT) {
          self.$store.commit("setCurrentUser", undefined);
          self.$router.push({
            name: "Home",
            params: { inactivityLogoutmessage: "Logout due to inactivity" },
          });
        }
      });
    },
    refreshJwt: function () {
      axios.post("/api/refresh_token", {}).catch((error) => {});
    },
    cancelTokenRefresh: function () {
      clearInterval(this.tokenRefreshIntervalId);
    },
    getUserTheme: function () {
      axios.get("/api/get_theme").then((res) => {
        this.theme = res.data.theme;
        this.isReady = true;
      });
    },
    getConfig: function () {
      axios
        .get("/api/config")
        .then((res) => {
          this.$store.commit("setCurrentMode", res.data.mode);
          this.$store.commit("setCertification", res.data.certification);
          this.verifyEnvironmentValidity();
        })
        .catch((error) => {
          sendErrors(error, "App:getConfig()");
        });
    },
    initializeActivityListener: function () {
      document.addEventListener("mousemove", this.markActive);
    },
    markActive: function () {
      this.lastActivity = Date.now();
    },
    cancelActivityWatch: function () {
      clearInterval(this.redirectInterval);
      document.removeEventListener("mousemove", this.markActive);
    },
    openErrorModal: function () {
      this.isErrorModalSeen = true;
    },
    closeErrorModal: function () {
      this.isErrorModalSeen = false;
    },
    verifyEnvironmentValidity: function () {
      if (!this.isEnvironmentValid()) {
        this.openErrorModal();
      } else {
        this.closeErrorModal();
      }
    },
    isBrowserValid: function () {
      let raw = navigator.userAgent.match(/Chrome\/([0-9]+)\./);
      let version;
      if (raw) {
        version = parseInt(raw[1], 10);
      }
      if (version > 76) {
        return true;
      }
      return false;
    },
    isScreenConstraintsValid: function () {
      if (
        this.$store.getters.currentMode !== constants.MODE_DEVELOPMENT &&
        (window.innerWidth < 1440 || window.innerHeight < 709)
      ) {
        return false;
      }
      return true;
    },
    isEnvironmentValid: function () {
      return this.isBrowserValid() && this.isScreenConstraintsValid();
    },
  },
};
</script>

<style lang="scss">
@import "~bulma/bulma";
@import "~bulma-switch";
@import "../public/static/css/custom.css";
@import "../public/static/css/dark-theme.css";
</style>
