<template>
  <div class="">
    <div
      v-if="isInitial"
      class="loader-fullscreen loader-screen-centered"
    ></div>
    <div v-if="!loggedIn && !isInitial">
      <Login
        v-if="!isPasswordResetShown"
        :inactivityLogoutmessage="inactivityLogoutmessage"
        v-on:switchToPasswordReset="togglePasswordResetPage"
      />
      <RequestResetPasswordPage
        v-if="isPasswordResetShown"
        v-on:cancelResetPassword="togglePasswordResetPage"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
import RequestResetPasswordPage from "@/components/RequestResetPassword.vue";
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      loggedIn: false,
      isInitial: true,
      isPasswordResetShown: false,
    };
  },
  props: {
    inactivityLogoutmessage: String,
  },
  components: {
    Login,
    RequestResetPasswordPage,
  },
  beforeMount() {
    if (!this.inactivityLogoutmessage) {
      this.checkLoggedIn();
    }
  },
  methods: {
    checkLoggedIn: function () {
      axios.get("/api/currentuser").then((response) => {
        if (response.data.user) {
          this.loggedIn = true;
          window.location.href = "/overview";
        } else {
          this.isInitial = false;
        }
      });
    },
    togglePasswordResetPage() {
      this.isPasswordResetShown = !this.isPasswordResetShown;
    },
  },
};
</script>
