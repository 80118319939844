<template>
  <div
    v-if="isLoggedIn"
    class="has-background-light-grey hero is-fullheight-with-navbar about"
  >
    <div v-if="isOrgInformationReceived">
      <div v-if="!legalInformationShown">
        <div
          class="
            margin-top-60
            modal-card
            has-background-white has-border-radius-5
          "
        >
          <div class="modal-card-head has-background-white py-0">
            <img
              src="/static/images/laralab_trans_bg.png"
              width="200"
              height="80"
            />
            <div class="has-text-weight-bold heartai">[heart.ai]</div>
            <img
              src="/static/images/logo_medical_device.png"
              alt=""
              class="has-float-right about-icon"
            />
          </div>
          <div class="margin-left-30 modal-card-body">
            <div class="columns">
              <div class="column">
                <img
                  src="/static/images/logo_udi.png"
                  alt=""
                  class="has-float-left about-icon"
                />
                <div class="has-float-left ml-5">
                  <span class="is-block"
                    >UDI DI: (01){{ aboutInformation.udi_di }}</span
                  >
                  <span class="is-block"
                    >UDI PI: (8012){{ aboutInformation.udi_pi }}</span
                  >
                  <span class="is-block"
                    >Version: {{ aboutInformation.app_version }}</span
                  >
                </div>
              </div>
              <div class="column">
                <img
                  v-if="
                    organization.additional_documents &&
                    organization.additional_documents.certification
                  "
                  class="has-float-left mr-2 has-width-100-px"
                  :src="
                    'data:image/png;base64,' +
                    organization.additional_documents.certification
                  "
                  alt="CE-Mark"
                />
              </div>
            </div>

            <div class="clear-float is-relative">
              <hr />
              <img
                src="/static/images/logo_user_manual.png"
                alt=""
                class="about-icon"
              />
              <div class="is-absolute ml-5 is-top-16 is-inline-block">
                <div
                  v-if="
                    organization.additional_documents &&
                    organization.additional_documents[USER_MANUAL + '_en']
                  "
                  class="mr-3"
                  @click="downloadUserManual('en')"
                >
                  <a>User Manual - EN </a>
                  <i class="fas fa-download mr-1 has-icon-primary"></i>
                </div>
                <div
                  v-if="
                    organization.additional_documents &&
                    organization.additional_documents[USER_MANUAL + '_de']
                  "
                  class="mr-3"
                  @click="downloadUserManual('de')"
                >
                  <a>User Manual - DE </a>
                  <i class="fas fa-download mr-1 has-icon-primary"></i>
                </div>
              </div>
            </div>
            <hr />
            <div class="columns">
              <div class="column">
                <img
                  src="/static/images/logo_manufacturer.png"
                  alt=""
                  class="has-float-left my-3 about-icon"
                />
                <div class="has-float-left ml-5 my-3">
                  <span class="is-block">Laralab GmbH</span>
                  <span class="is-block">Herzog-Heinrich-Str. 13</span>
                  <span class="is-block">80336 Munich</span>
                  <span class="is-block">Germany</span>
                  <span class="is-block"
                    ><a href="http://www.laralab.com" target="blank"
                      >www.laralab.com</a
                    ></span
                  >
                </div>
              </div>
              <div class="column">
                <img
                  src="/static/images/logo_manufacture_date.png"
                  alt=""
                  class="has-float-left my-3 about-icon"
                />
                <div class="has-float-left ml-5 my-3">
                  <span class="is-block">2023-03-30</span>
                  <span class="is-block">Made in Germany</span>
                </div>
              </div>
            </div>
            <div class="clear-float">
              <hr />
              <h2 class="is-size-6 has-text-weight-bold mb-3">
                Customer Support
              </h2>
              <div>support@laralab.de</div>
            </div>
            <hr />
            <div class="">We are members of the Alliance for Cybersecurity</div>
            <a
              href="https://www.allianz-fuer-cybersicherheit.de/"
              target="blank"
            >
              <img
                src="/static/images/AllianzCyberSecLogo.svg"
                alt=""
                class="mt-4 width-25-per"
            /></a>
            <div class="has-text-weight-bold mt-2">
              <hr />
              &copy; Laralab GmbH | 2021 | All rights reserved.
            </div>
          </div>
          <div class="modal-card-foot is-block has-text-centered">
            <a
              href="https://www.heart.ai/app-impressum"
              target="blank"
              class="mr-3"
              >Imprints</a
            >
            <a
              href="https://www.heart.ai/app-data-privacy-note"
              target="blank"
              class="mr-3"
              >Privacy Note</a
            >
          </div>
        </div>
        <div
          class="modal-card has-background-white has-border-radius-5 mt-2 mb-4"
        >
          <div class="margin-left-30 modal-card-body">
            <div id="toggleLicenseField" @click="expandLicenseField">
              <i
                class="fas fa-lg has-text-grey pointer"
                :class="
                  isLicenseFieldExpanded
                    ? 'fa-chevron-down'
                    : 'fa-chevron-right'
                "
              ></i>
              <span
                class="
                  is-uppercase
                  has-text-grey has-text-weigh-bold
                  ml-3
                  fa-lg
                  pointer
                "
                >third party licenses</span
              >
            </div>
            <div v-if="isLicenseFieldExpanded" class="mt-3">
              This application uses third party software published under the
              <a @click="showMitLicense">MIT LICENSE</a>, the
              <a @click="showApacheLicense">Apache 2.0 LICENSE</a>, the
              <a @click="showBSDLicense">BSD-clause 2 and 3</a> and the
              <a @click="showISCLicense">ISC License</a>.
              <div
                id="toggleLicenseListButton"
                class="has-width-100-per mt-1 mb-2"
                @click="toggleLicenseList"
              >
                <a><i class="fas fa-list"></i> Third Party Software License</a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isLicenseTextShown" class="my-2">
          <div
            class="
              modal-card
              has-background-white has-border-radius-5 has-max-height-80vh
            "
          >
            <div class="modal-card-head is-block has-text-grey is-flex">
              <span>
                The Laralab Application uses software published under the
                {{ selectedLicense.title }}. The {{ selectedLicense.title }} is
                only applicable to the software parts listed in the third party
                software license list and not to the Laralab Application,
                neither in parts nor as whole.</span
              >

              <i
                class="
                  fas
                  fa-times
                  pointer
                  has-float-right
                  is-size-5
                  has-text-grey
                  is-top
                "
                @click="closeLicenseText"
              ></i>
            </div>
            <div class="margin-left-30 modal-card-body">
              <div class="is-size-4">{{ selectedLicense.title }}</div>
              <pre>{{ selectedLicense.text }}</pre>
            </div>
          </div>
        </div>
        <div v-if="isLicenseListShown" class="my-2">
          <div
            class="
              modal-card
              has-background-white has-border-radius-5 has-max-height-80vh
            "
          >
            <div class="modal-card-head is-block is-size-5 has-text-grey">
              <span>Third party software license</span
              ><i
                class="fas fa-times pointer has-float-right"
                @click="toggleLicenseList"
              ></i>
            </div>
            <div class="modal-card-body">
              <div class="columns">
                <div class="column license-list-head">Module</div>
                <div class="column license-list-head">License</div>
              </div>
              <div
                v-for="(value, name) in licenseList"
                :key="name"
                class="columns license-list has-bottom-line"
              >
                <div class="column pb-0">
                  <a :href="value.url" target="blank">{{ name }}</a>
                </div>
                <div class="column pb-0">
                  <span>{{ value.license }}</span>
                </div>
              </div>
            </div>
            <div class="modal-card-foot">
              <div id="disclaimer" class="px-1">
                {{ disclaimer }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="legalInformationShown"
        class="modal-background"
        @click="toggleLegal"
      >
        <div
          class="
            margin-top-110
            modal-card
            has-background-white has-border-radius-5
          "
        >
          <div class="modal-card-head">
            <span class="is-size-4 has-text-weight-bold">{{
              legalText.title
            }}</span>
            <span class="margin-left-auto"
              ><i
                class="fas fa-times fa-lg has-text-grey"
                @click="toggleLegal"
              ></i
            ></span>
          </div>
          <div class="modal-card-body">
            {{ legalText.content }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="loader-fullscreen loader-screen-centered"></div>
  </div>
</template>

<script>
import axios from "axios";
import * as constants from "../constants.js";
import {
  mitLicense,
  apache20License,
  bsdLicense,
  iscLicense,
  disclaimer,
  licenseList,
} from "../../public/static/js/licenses.js";
import { sendErrors } from "@/js/errorHandler.js";

const USER_MANUAL = "user_manual";
const USER_MANUAL_FILENAME = "user_manual_filename";

export default {
  name: "About",
  data() {
    return {
      certification: null,
      isLoggedIn: false,
      aboutInformation: {},
      isLicenseFieldExpanded: false,
      isLicenseTextShown: false,
      isLicenseListShown: false,
      mitLicense: mitLicense,
      apache20License: apache20License,
      bsdLicense: bsdLicense,
      iscLicense: iscLicense,
      licenseList: licenseList,
      disclaimer: disclaimer,
      selectedLicense: { title: "", text: "" },
      legalInformationShown: false,
      isOrgInformationReceived: false,
      legalText: "",
      organization: { additional_documents: {} },
      CERTIFICATION_CE: constants.CERTIFICATION_CE,
      USER_MANUAL: USER_MANUAL,
    };
  },
  beforeMount() {
    this.checkLoggedIn();
    this.getAboutInformation();
  },
  methods: {
    checkLoggedIn: function () {
      axios
        .get("/api/currentuser")
        .then((response) => {
          if (!response.data.user) {
            window.location.href = "/";
          } else {
            this.isLoggedIn = true;
            this.$store.commit("setCurrentUser", response.data.user);
            this.switchToAboutView();
            this.getOrganization(response.data.user.org);
            this.certification = this.$store.getters.certification;
          }
        })
        .catch((err) => {
          sendErrors(err, "About.vue:checkLogin()");
        });
    },
    switchToAboutView: function () {
      this.$store.commit("setCurrentView", constants.VIEW_ABOUT);
    },
    getAboutInformation: function () {
      axios.get("/api/version").then((res) => {
        this.aboutInformation = res.data;
      });
    },
    getOrganization: function (org) {
      axios
        .get("api/get_organizations", {
          params: {
            organization: org,
          },
        })
        .then((res) => {
          this.organization = res.data.organizationInfo[0];
          this.isOrgInformationReceived = true;
        })
        .catch((err) => {
          sendErrors(err, "Admin.vue");
        });
    },

    showMitLicense: function () {
      this.selectedLicense.title = this.mitLicense.title;
      this.selectedLicense.text = this.mitLicense.text;
      this.openLicenseText();
    },
    showApacheLicense: function () {
      this.selectedLicense.title = this.apache20License.title;
      this.selectedLicense.text = this.apache20License.text;
      this.openLicenseText();
    },
    showBSDLicense: function () {
      this.selectedLicense.title = this.bsdLicense.title;
      this.selectedLicense.text = this.bsdLicense.text;
      this.openLicenseText();
    },
    showISCLicense: function () {
      this.selectedLicense.title = this.iscLicense.title;
      this.selectedLicense.text = this.iscLicense.text;
      this.openLicenseText();
    },
    expandLicenseField: function () {
      this.isLicenseFieldExpanded = !this.isLicenseFieldExpanded;
    },
    openLicenseText: function () {
      this.isLicenseListShown = false;
      this.isLicenseTextShown = true;
    },
    closeLicenseText: function () {
      this.isLicenseTextShown = false;
    },
    toggleLicenseList: function () {
      this.isLicenseTextShown = false;
      this.isLicenseListShown = !this.isLicenseListShown;
    },
    toggleLegal() {
      this.legalInformationShown = !this.legalInformationShown;
    },
    downloadUserManual(lang = "en") {
      let user_manual = `${USER_MANUAL}_${lang}`;
      let user_manual_filename = `${USER_MANUAL_FILENAME}_${lang}`;
      if (
        this.organization.additional_documents &&
        this.organization.additional_documents[user_manual] !== undefined
      ) {
        const linkSource = `data:application/pdf;base64,${this.organization.additional_documents[user_manual]}`;
        const downloadLink = document.createElement("a");

        downloadLink.href = linkSource;
        let fileName =
          this.organization.additional_documents[user_manual_filename];
        if (fileName === undefined) {
          fileName = "HEARTAI_USER_MANUAL.pdf";
        }
        downloadLink.download = fileName;
        downloadLink.click();
      }
    },
  },
};
</script>

<style scoped>
div.modal-card {
  box-shadow: 0px 0px 3px grey;
}

hr {
  margin-bottom: 0.5rem !important;
}

.hero {
  justify-content: flex-start !important;
}

.has-bottom-line {
  border-bottom: 1px solid grey;
}

.has-max-height-80vh {
  max-height: 80vh;
}

.license-list-head {
  padding-bottom: 2px;
  font-weight: bold;
  border-bottom: 2px solid grey;
}

.is-top {
  transform: translate(0, -150%);
}

.about {
  display: block;
  height: calc(100vh - 3.25rem);
  overflow-y: auto !important;
}

.heartai {
  margin: 0 auto;
  font-size: 26px;
  width: fit-content;
  transform: translate(-95px, -2px);
}

.about-icon {
  width: 60px;
}
</style>