<template>
  <div
    id="reportBoard"
    class="
      reportBoard
      has-scrollable-full-height-modal
      has-text-almost-black
      has-text-dark-primary
      has-background-white
      has-background-dark-primary
    "
  >
    <ExpandedImageModal
      :key="expandImageComponentKey"
      :isExpandImageSeen="isExpandImageSeen"
      :image="selectedImage"
      v-on:closeExpandedImage="toggleExpandedImage"
    ></ExpandedImageModal>

    <article v-if="caseNotFound" class="message is-danger mt-3">
      <div class="message-body">
        <span> Case not found. Please make sure the case exists.</span>
      </div>
    </article>
    <div
      v-if="!caseNotFound && !isReady"
      class="loader-fullscreen loader-screen-centered"
    ></div>
    <div
      id="rbContent"
      v-else-if="!caseNotFound && isReady"
      class="margin-all-40"
    >
      <!-- Header -->
      <div :class="!isPrinting ? 'is-print-only mb-0' : 'mt-2'">
        <p
          class="is-absolute"
          :class="!isPrinting ? 'margin-top-neg-5 is-right-20' : 'is-right-0'"
        >
          Created by: {{ getCurrentUser() }} via LARALAB on
          {{ getCurrentTime() }}.
        </p>
      </div>
      <!-- End of Header -->

      <div class="columns">
        <div class="column is-narrow">
          <div v-show="!isPrinting" class="margin-top-6">
            <i
              class="
                fas
                fa-arrow-left
                has-text-almost-black has-text-dark-primary
                is-size-4
                pointer
                no-print
              "
              @click="sendCloseEvent"
            ></i>
          </div>
        </div>
        <div class="column has-ellipsis">
          <span class="is-size-4">
            Report Case {{ studyInfo.caseId }}:
            {{ studyInfo.name }}
          </span>
        </div>
        <div class="column">
          <div class="margin-top-6">
            <p class="has-text-right has-text-darker-grey is-size-14">
              last modified {{ localizeDate(studyInfo.lastModified) }} by
              {{ studyInfo.lastModifier }}
            </p>
          </div>
        </div>
        <div
          v-show="!isPrinting"
          class="column is-narrow pointer no-print"
          @click="exportPDF()"
        >
          <div class="margin-top-6">
            <span class="fa-stack is-size-7">
              <i class="fas fa-circle fa-stack-2x icon-dark-grey"></i>
              <i
                class="
                  fas
                  fa-file-export fa-stack-1x fa-inverse
                  has-text-centered
                  margin-left-2
                "
              ></i>
            </span>
            <span class="is-size-7 has-text-darker-grey has-text-weight-bold">
              EXPORT PDF
            </span>
          </div>
        </div>
      </div>
      <div class="has-width-1440 is-centered">
        <section
          id="studyInfo"
          class="has-background-light-grey margin-bottom-30 is-relative"
        >
          <div
            class="vertical-progressbar"
            :style="
              studyInfo.finalized
                ? 'background: #0fb032'
                : `background: linear-gradient(0deg, #ffe500 ${completePercent}%, #c4c4c4 ${completePercent}% ${
                    100 - completePercent
                  }%)`
            "
          ></div>
          <div
            class="
              card
              margin-left-5
              padding-0
              has-hover has-background-light-grey has-background-dark-card
            "
          >
            <div id="status">
              <span class="has-text-darker-grey padding-rl-15">
                Planning ongoing
              </span>
            </div>
            <div id="header">
              <div
                class="columns has-text-left has-text-darker-grey padding-rl-15"
              >
                <div class="column">
                  <div class="columns is-multiline margin-bottom-0">
                    <div class="column is-4">
                      <span class="subtitle has-text-darker-grey is-7"
                        >PATIENT
                      </span>
                      <div class="has-text-almost-black">
                        {{ studyInfo.name }}
                      </div>
                    </div>
                    <div class="column is-3">
                      <span class="subtitle has-text-darker-grey is-7"
                        >SEX
                      </span>
                      <div class="has-text-almost-black">
                        {{ studyInfo.sex }}
                      </div>
                    </div>
                    <div class="column is-5">
                      <span class="subtitle has-text-darker-grey is-7"
                        >STUDY DATE
                      </span>
                      <div class="has-text-almost-black">
                        {{ formatDate(studyInfo.date) }}
                      </div>
                    </div>
                    <div class="column is-4">
                      <button
                        id="setFinalButton"
                        v-show="!isPrinting"
                        class="
                          button
                          noborder
                          has-width-104
                          has-background-darker-grey
                          has-text-weight-bold
                          is-size-7
                          padding-20
                          no-print
                        "
                        :class="
                          isReadOnly
                            ? 'is-disabled-button tooltip is-tooltip-bottom is-tooltip-shifted-left'
                            : ''
                        "
                        :data-tooltip="isReadOnly ? readOnlyWarning : ''"
                        @click="isReadOnly ? '' : toggleFinalStatus()"
                      >
                        <span
                          v-if="!studyInfo.finalized"
                          class="has-text-white has-text-dark-primary"
                        >
                          SET FINAL
                        </span>
                        <span
                          v-else
                          class="has-text-white has-text-dark-primary"
                        >
                          EDIT
                        </span>
                      </button>
                      <div v-show="!isPrinting" class="no-print">
                        <p
                          v-if="!studyInfo.finalized"
                          class="
                            mt-1
                            subtitle
                            is-size-7
                            has-text-darker-grey has-text-dark-font
                          "
                        >
                          Set plan to final to prevent unwanted changes
                        </p>
                        <p
                          v-else
                          class="
                            mt-1
                            subtitle
                            is-size-7
                            has-text-darker-grey has-text-dark-font
                          "
                        >
                          This plan has been set to final. <br />
                          Click to set the plan back to edit mode.
                        </p>
                      </div>
                    </div>
                    <div class="column is-3">
                      <span class="subtitle has-text-darker-grey is-7"
                        >BIRTH DATE
                      </span>
                      <div class="has-text-almost-black">
                        {{ this.formatDate(studyInfo.dob) }}
                      </div>
                    </div>
                    <div class="column is-5">
                      <span class="subtitle has-text-darker-grey is-7"
                        >DESCRIPTION
                      </span>
                      <div
                        :class="
                          studyInfo.description.length > maxTextLength
                            ? 'tooltip is-tooltip-multiline is-tooltip-bottom'
                            : ''
                        "
                        :data-tooltip="studyInfo.description"
                      >
                        <div class="has-text-almost-black">
                          {{ trimText(studyInfo.description) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="columns margin-bottom-0">
                    <div class="column">
                      <span class="subtitle has-text-darker-grey is-7"
                        >COMMENTS
                        <i
                          v-if="!isEditingComments && !isPrinting"
                          class="
                            fas
                            fa-pen
                            has-text-darker-grey
                            is-pulled-right
                            pointer
                            no-print
                          "
                          @click="editComments"
                        ></i>
                        <i
                          v-else-if="isEditingComments && !isPrinting"
                          class="
                            fas
                            fa-save
                            has-text-darker-grey
                            is-pulled-right
                            pointer
                            no-print
                          "
                          @click="saveComments"
                        ></i>
                      </span>
                      <div
                        v-if="isEditingComments"
                        class="field"
                        v-click-outside="saveComments"
                      >
                        <div class="control">
                          <textarea
                            class="
                              textarea
                              has-text-dark-dark has-background-dark-toned-grey
                              is-small
                              has-fixed-size
                              height-110
                            "
                            v-model="studyInfo.lastComment"
                          ></textarea>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          class="
                            box
                            has-background-dark-toned-grey has-no-box-shadow
                            height-110
                            padding-10
                            has-overflow-auto
                            has-text-almost-black
                            has-text-dark-dark
                            is-size-7
                          "
                          @dblclick="editComments"
                        >
                          {{ studyInfo.lastComment }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          id="caseInfo"
          class="
            has-background-white has-background-dark-primary
            margin-bottom-30
          "
        >
          <h1
            class="
              subtitle
              has-text-almost-black has-text-dark-primary has-text-weight-bold
              is-size-6
            "
          >
            Case Input
          </h1>
          <div
            class="
              card
              margin-top-20
              padding-0
              has-hover has-background-light-grey has-background-dark-card
            "
          >
            <div class="columns padding-rl-15">
              <div class="column is-one-quarter">
                <p class="subtitle has-text-darker-grey is-7 margin-bottom-0">
                  CASE PROTOCOL
                </p>
                <p class="subtitle is-7">{{ studyInfo.protocolName }}</p>
              </div>
              <div class="column">
                <span class="subtitle has-text-darker-grey is-7"
                  >CARDIAC CT SERIES
                </span>
                <i v-if="studyInfo.seriesDescription">
                  <p class="subtitle is-7 margin-bottom-0">
                    <span
                      :class="
                        studyInfo.seriesDescription.length > maxTextLength
                          ? 'tooltip is-tooltip-multiline'
                          : ''
                      "
                      :data-tooltip="studyInfo.seriesDescription"
                    >
                      {{ this.trimText(studyInfo.seriesDescription) }}
                    </span>
                  </p>
                </i>
                <p class="subtitle is-7 margin-bottom-0">
                  # Images: {{ studyInfo.sliceNr }}, # Phases:
                  {{ studyInfo.phases }}, Slice Thickness:
                  {{ formatSliceThickness(studyInfo.sliceThickness) }}, Pixel
                  Spacing:
                  <span
                    class="tooltip is-tooltip-top"
                    :data-tooltip="studyInfo.pixelSpacing"
                  >
                    {{ roundOffAndFormatPixelSpacing(studyInfo.pixelSpacing) }}
                  </span>
                </p>
              </div>
              <div
                v-if="!isPrinting"
                id="checkInputButton"
                class="column is-vcentered is-narrow pointer no-print"
                @click="reviewCaseInput()"
              >
                <span class="fa-stack is-size-7">
                  <i class="fas fa-circle fa-stack-2x icon-dark-grey"></i>
                  <i class="fas fa-cog fa-stack-1x fa-inverse"></i>
                </span>
                <span
                  class="
                    subtitle
                    has-text-darker-grey has-text-weight-bold
                    is-7
                  "
                >
                  CHECK INPUT
                </span>
              </div>
            </div>
          </div>
        </section>

        <section
          id="assessments"
          class="
            has-background-white has-background-dark-primary
            margin-bottom-30
          "
        >
          <h1
            class="
              subtitle
              has-text-almost-black has-text-dark-primary has-text-weight-bold
              is-size-6
            "
          >
            Assessments
          </h1>

          <div
            v-for="(categoryInfo, category) in studyInfo.categories"
            :key="category"
          >
            <div
              v-if="
                !studyInfo.finalized ||
                (studyInfo.finalized && categoryInfo.approved)
              "
              class="
                card
                margin-top-20
                padding-0
                has-hover has-background-light-grey has-background-dark-card
              "
              :class="categoryInfo.approved ? 'pointer' : 'cursor-not-allowed'"
            >
              <div
                :id="'categoryInfo_' + category"
                class="padding-rl-15 margin-top-3"
              >
                <div
                  class="columns is-variable is-2 is-vcentered margin-bottom-0"
                  @click="categoryInfo.approved ? toggleCategory(category) : ''"
                >
                  <div class="column">
                    <h1
                      class="
                        subtitle
                        has-text-almost-black
                        has-text-dark-primary
                        has-text-weight-bold
                        is-size-5
                      "
                    >
                      {{ categoryInfo.name }}
                    </h1>
                  </div>
                  <div v-if="!categoryInfo.approved" class="column is-3">
                    <span class="columns is-variable is-0 is-vcentered">
                      <span class="column is-narrow mr-1">
                        <i
                          class="fas fa-info-circle icon-dark-grey is-size-6"
                        ></i>
                      </span>
                      <span
                        class="column subtitle has-text-darker-grey is-size-14"
                      >
                        <div>
                          Please use the Planner to review and approve the
                          assessments
                        </div>
                      </span>
                    </span>
                  </div>
                  <div
                    v-show="!isPrinting"
                    class="column is-narrow has-text-right no-print"
                  >
                    <a
                      :id="'reviewInPlannerButton_' + category"
                      class="
                        button
                        is-small is-outlined
                        has-text-darker-grey has-background-transparent
                        is-review-button
                      "
                      @click.stop="
                        isUploadInProgress
                          ? openUploadWarningModal()
                          : getImageAnalysis(categoryInfo.id_)
                      "
                      >Review in Planner
                    </a>
                    <span class="margin-left-15 is-protocol-expand-button">
                      <i
                        v-if="categoryInfo.isOpen"
                        class="fas fa-chevron-up has-text-darker-grey"
                      ></i>
                      <i
                        v-else
                        class="fas fa-chevron-down has-text-darker-grey"
                      ></i>
                    </span>
                  </div>
                </div>
                <div
                  v-if="
                    categoryInfo.isOpen &&
                    Object.keys(categoryInfo.details).length !== 0
                  "
                >
                  <div
                    v-for="(board, index) in categoryInfo.details"
                    :key="index"
                    :id="'board_' + index"
                    class="mb-5 no-page-break"
                  >
                    <div
                      class="
                        mb-5
                        has-text-weight-bold
                        has-text-almost-black
                        has-text-dark-primary
                        is-size-6
                      "
                    >
                      {{ board.name }} - {{ board.phase }}%
                    </div>
                    <div
                      v-if="Object.keys(board.views).length > 0"
                      class="columns is-multiline"
                    >
                      <div
                        v-for="(view, index) in board.views"
                        :key="index"
                        class="column is-6 mb-3"
                      >
                        <div
                          class="
                            card
                            has-background-whisper-grey has-text-almost-black
                          "
                        >
                          <div class="columns">
                            <div class="column is-one-third">
                              <div
                                class="
                                  has-background-dark-content
                                  is-size-6
                                  padding-rl-15
                                "
                              >
                                <div
                                  class="has-text-weight-bold mb-3 word-break"
                                >
                                  {{ view.view_name }} - {{ view.pose_name }}
                                </div>
                                <div
                                  v-for="(scalarMeasurement, index) in view
                                    .screenshot.scalars"
                                  :key="index"
                                  class="padding-tb-0 is-size-14"
                                >
                                  <div class="columns">
                                    <div class="column pb-0">
                                      {{ scalarMeasurement.name }}
                                    </div>
                                    <div
                                      class="
                                        column
                                        has-text-right
                                        pb-0
                                        pl-0
                                        pr-0
                                      "
                                    >
                                      {{ formatMeasurement(scalarMeasurement) }}
                                      <span
                                        v-if="scalarMeasurement.value != null"
                                      >
                                        {{ scalarMeasurement.unit }}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="column card-image pt-0 pb-0 has-text-right"
                            >
                              <div
                                class="
                                  is-image is-vcentered is-relative
                                  has-line-height-0
                                  has-overflow-hidden
                                  has-right-border-radius-3
                                "
                              >
                                <div class="is-relative">
                                  <div
                                    class="
                                      is-absolute is-image-expand-button
                                      has-zindex-1
                                      pointer
                                    "
                                    @click.stop="
                                      toggleExpandedImage(view.screenshot.image)
                                    "
                                  >
                                    <span
                                      class="
                                        tag
                                        margin-left-5
                                        has-background-white
                                        has-background-dark-primary
                                        has-opacity-60
                                      "
                                    >
                                      <i class="fas fa-expand-arrows-alt"></i>
                                    </span>
                                  </div>

                                  <div
                                    :id="'fluro-container' + index"
                                    class="
                                      fluoro-container
                                      height-50
                                      is-bottom-5
                                    "
                                  >
                                    <div
                                      v-for="(angleValue, key) in view
                                        .screenshot.fluoro_angles"
                                      :key="key"
                                      :id="'fluro-' + key + '-' + index"
                                      class="is-absolute has-zindex-1"
                                      :class="
                                        key === 'primary'
                                          ? 'is-bottom-25'
                                          : 'is-bottom-0'
                                      "
                                    >
                                      <span
                                        class="
                                          tag
                                          margin-left-5
                                          has-background-white
                                          has-background-dark-primary
                                          has-opacity-60
                                          is-size-7
                                          has-text-black
                                        "
                                      >
                                        {{ angleValue }}
                                      </span>
                                    </div>
                                  </div>

                                  <img
                                    class="is-resized"
                                    :src="view.screenshot.image"
                                  />
                                  <div
                                    v-for="(line, index) in view.screenshot
                                      .text_boxes"
                                    :key="index"
                                    :id="'measurementLabels' + index"
                                    class="
                                      measurements-label
                                      has-text-black
                                      is-absolute
                                      has-background-white
                                      has-opacity-60
                                      has-line-height-15
                                      has-border-radius-5
                                      is-size-8
                                    "
                                    :style="{
                                      top: line.coordinates[0] + '%',
                                      left: line.coordinates[1] + '%',
                                    }"
                                  >
                                    {{ line.text }}
                                  </div>
                                  <div
                                    v-for="(point, index) in view.screenshot
                                      .endpoints"
                                    :key="'startpoint_' + index"
                                    class="measurements-circle is-absolute"
                                    :style="{
                                      top: point.start.y + '%',
                                      left: point.start.x + '%',
                                    }"
                                  ></div>
                                  <div
                                    v-for="(point, index) in view.screenshot
                                      .endpoints"
                                    :key="'endpoint_' + index"
                                    class="measurements-circle is-absolute"
                                    :style="{
                                      top: point.end.y + '%',
                                      left: point.end.x + '%',
                                    }"
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="
                        has-text-weight-bold
                        has-text-almost-black
                        has-text-dark-primary
                        is-size-6
                        mb-1
                      "
                    >
                      No screenshots found
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ExpandedImageModal from "@/components/ExpandImage.vue";

import axios from "axios";
import vClickOutside from "v-click-outside";
import _ from "lodash";
import html2pdf from "html2pdf.js";
import moment from "moment";

import * as constants from "../constants.js";
import * as helper from "@/js/helper.js";
import { sendErrors } from "@/js/errorHandler.js";

export default {
  name: "ReportBoard",
  data() {
    return {
      readOnlyWarning: constants.READ_ONLY_WARNING,
      maxTextLength: 90,
      isEditingComments: false,
      completePercent: 0,
      expandImageComponentKey: 0,
      isExpandImageSeen: false,
      studyInfo: {},
      selectedImage: "",
      // TODO: Remove after implementing events on the server side
      intervalId: "",
      intervalSec: 5,
      isReady: false,
      caseNotFound: false,
      isPrinting: false,
    };
  },
  components: {
    ExpandedImageModal,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    isUploadInProgress() {
      return this.$store.getters.isUploadInProgress;
    },
    isReadOnly() {
      return (
        this.$store.getters.currentAccessMode === constants.ACCESS_MODE_READONLY
      );
    },
  },
  created() {
    // TODO: This is a temporary working solution.
    // For a more robost system, we should use sockets and unique tab identifiers instead

    // Release the Editor mode when refreshing
    let caseId = this.$store.getters.currentCaseId;
    window.onbeforeunload = () => {
      this.releaseEditAccess(caseId);
    };

    // Handle the case when browser is closed
    document.onvisibilitychange = () => {
      if (document.visibilityState === "hidden") {
        // Only trigger release if the tab/browser is closed
        if (!document) {
          this.releaseEditAccess(caseId);
        }
      }
    };
  },
  mounted() {
    // TODO: Check if current case object correct. In unit test SliceNr. difer from SliceNr in PhaseSet
    this.getStudyInformation()
      .then(() => {
        this.getCaseInformation(true);
        this.getCategories();
      })
      .catch(() => {
        this.caseNotFound = true;
      });
  },
  beforeDestroy() {
    // Release the Editor mode
    this.releaseEditAccess(this.studyInfo.caseId);
    this.reset();
  },
  beforeRouteLeave() {
    window.onbeforeunload = null;
    document.onvisibilitychange = null;
  },
  methods: {
    formatDate: helper.formatDate,
    formatSliceThickness: helper.formatSliceThickness,
    roundOffAndFormatPixelSpacing: helper.roundOffAndFormatPixelSpacing,
    startAnalysis: helper.startAnalysis,
    releaseEditAccess: helper.releaseEditAccess,
    reset: function () {
      this.isEditingComments = false;
      this.completePercent = 0;
      this.studyInfo = {};
      this.expandImageComponentKey = 0;
      this.isExpandImageSeen = false;
      this.selectedImage = "";
      this.isReady = false;
      this.caseNotFound = false;
      this.isPrinting = false;
    },
    getStudyInformation: function () {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/get_study", {
            CaseID: this.$store.getters.currentCaseId,
          })
          .then((res) => {
            this.studyInfo = res.data.studyInfo;
            resolve();
          })
          .catch((error) => {
            sendErrors(error, "ReportBoard:getStudyInformation()");
            reject(new Error(error));
          });
      });
    },
    getCaseInformation: function (isInitial = false) {
      // TODO: adjust RB BE
      const path = "/api/get_case_information";
      axios
        .post(path, {
          CaseID: this.studyInfo.caseId,
        })
        .then((res) => {
          const responseData = res.data;
          _.forEach(responseData, (value, key) => {
            this.$set(this.studyInfo, key, value);
          });

          // Only the last comment is relevant right now
          let lastComment = "";
          if (this.studyInfo.comments && this.studyInfo.comments[0]) {
            lastComment = this.studyInfo.comments[0].comment;
          }
          this.$set(this.studyInfo, "lastComment", lastComment);
          this.$forceUpdate();

          if (
            isInitial ||
            this.$store.getters.currentAccessMode !==
              constants.ACCESS_MODE_EDITOR
          ) {
            // Set access level
            this.$store.commit(
              "setHasEditAccessOnServer",
              responseData.assignedRole === "editor"
            );
            if (responseData.assignedRole === "editor") {
              this.setAccessMode(constants.ACCESS_MODE_EDITOR);
            } else {
              this.setAccessMode(constants.ACCESS_MODE_READONLY);
            }
          }
        })
        .catch((error) => {
          if (error && error.response.status === 400) {
            this.caseNotFound = true;
          }
          sendErrors(error, "ReportBoard:getCaseInformation()");
        });
    },
    getCategories: function () {
      const path = "/reporting-board-api/categories";
      axios
        .post(path, {
          protocol_name: this.studyInfo.protocolName,
          git_ref: this.studyInfo.gitRef,
          phase_set_id: this.studyInfo.cardiacPhaseSet,
          lara_id: this.studyInfo.caseId,
        })
        .then((res) => {
          this.studyInfo.categories = res.data;
          _.forEach(this.studyInfo.categories, (category) => {
            this.$set(category, "isOpen", false);
            this.$set(category, "details", {});
          });
          // first approved category is selected by default
          _.forEach(this.studyInfo.categories, (categoryInfo, category) => {
            if (categoryInfo.approved) {
              this.toggleCategory(category);
              return false;
            }
          });
          this.isReady = true;
          this.completePercent = this.calculateReviewPercent();
        })
        .catch((error) => {
          sendErrors(error, "ReportBoard:getCategories()");
        });
    },
    getCategoryDetails: function (category) {
      return new Promise((resolve, reject) => {
        // Only fetch the details if it hasn't been pulled already
        if (
          Object.keys(this.studyInfo.categories[category].details).length === 0
        ) {
          const path = "/reporting-board-api/category_details";
          axios
            .post(path, {
              lara_id: this.studyInfo.caseId,
              git_ref: this.studyInfo.gitRef,
              protocol_name: this.studyInfo.protocolName,
              phase_set_id: this.studyInfo.cardiacPhaseSet,
              category_id: this.studyInfo.categories[category].id_,
            })
            .then((res) => {
              this.studyInfo.categories[category].details = res.data;
              this.$forceUpdate();
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve();
        }
      });
    },
    getImageAnalysis: function (categoryId) {
      // Cancel Upload before starting analysis
      this.$emit("cancelUpload");
      if (this.startAnalysis(categoryId)) {
        this.reset();
      }
    },
    setAccessMode: function (accessMode) {
      this.$store.commit("setCurrentAccessMode", accessMode);
    },
    calculateReviewPercent: function () {
      const approvedCategories = _.filter(
        this.studyInfo.categories,
        function (o) {
          return o.approved;
        }
      );
      return (
        (approvedCategories.length / this.studyInfo.categories.length) * 100
      );
    },
    localizeDate: function (date) {
      return moment(new Date(date)).format("DD-MMM-YYYY HH:mm");
    },
    openUploadWarningModal: function () {
      this.$store.commit("setIsUploadWarningSeen", true);
    },
    getCurrentUser: function () {
      return this.$store.getters.currentUser.email;
    },
    getCurrentTime: function () {
      return moment().format("lll");
    },
    exportPDF: function () {
      let promiseArray = [];

      // Open all approved categories for print
      _.forEach(this.studyInfo.categories, (categoryValue, category) => {
        if (!categoryValue.isOpen && categoryValue.approved) {
          promiseArray.push(this.toggleCategory(category));
        }
      });

      Promise.all(promiseArray).then(() => {
        this.isPrinting = true;
        let element = document.getElementById("rbContent");
        let opt = {
          margin: 0.5,
          filename: "Report_" + this.studyInfo.caseId + ".pdf",
          image: { type: "jpeg", quality: 1 },
          html2canvas: { scale: 2, logging: true },
          jsPDF: { unit: "in", format: "a4", orientation: "l" },
          pagebreak: {
            mode: ["css"],
          },
        };

        html2pdf()
          .set(opt)
          .from(element)
          .save()
          .then(() => {
            this.isPrinting = false;
          });
      });
    },
    formatMeasurement: function (measurement) {
      if (measurement.value != null) {
        return measurement.value.toFixed(measurement.decimal);
      } else {
        return "-";
      }
    },
    trimText: function (value) {
      return value.length > this.maxTextLength
        ? value.substring(0, this.maxTextLength - 3) + "..."
        : value;
    },
    isToBeReviewed: function (status) {
      return status === "to be reviewed";
    },
    editComments: function () {
      this.isEditingComments = true;
    },
    saveComments: function () {
      axios
        .post("/api/cases/" + this.studyInfo.caseId + "/comments", {
          comment: this.studyInfo.lastComment,
        })
        .then(() => {})
        .catch((error) => {
          sendErrors(error, "ReportBoard:saveComments");
        });
      this.isEditingComments = false;
    },
    toggleFinalStatus: function () {
      const status = !this.studyInfo.finalized;
      axios
        .patch(
          "/api/cases/" + this.studyInfo.caseId,
          {
            finalized: status,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.getCaseInformation();
          this.studyInfo.finalized = status;
          this.$forceUpdate();
          this.$emit("changeFinalStatus", this.studyInfo.caseId, status);
        })
        .catch((error) => {
          sendErrors(error, "ReportBoard:toggleFinalStatus");
        });
    },
    toggleCategory: function (category) {
      return new Promise((resolve, reject) => {
        let categoryInfo = this.studyInfo.categories[category];
        if (categoryInfo.approved) {
          if (!categoryInfo.isOpen) {
            this.getCategoryDetails(category)
              .then(() => {
                categoryInfo.isOpen = !categoryInfo.isOpen;
                this.$forceUpdate();
                resolve();
              })
              .catch((error) => {
                sendErrors(error, "ReportBoard:getCategoryDetails");
                reject(error);
              });
          } else {
            categoryInfo.isOpen = !categoryInfo.isOpen;
            this.$forceUpdate();
            resolve();
          }
        } else {
          resolve();
        }
      });
    },
    toggleExpandedImage: function (image = undefined) {
      if (!this.isExpandImageSeen) {
        this.selectedImage = image;
        this.isExpandImageSeen = true;
      } else {
        this.selectedImage = "";
        this.expandImageComponentKey++;
        this.isExpandImageSeen = false;
      }
    },
    reviewCaseInput: function () {
      this.$emit(
        "showGateway",
        this.studyInfo.caseId,
        this.$store.getters.currentAccessMode
      );
    },
    sendCloseEvent: function () {
      this.$emit("closeReportBoard");
    },
  },
};
</script>

<style scoped>
.reportBoard {
  height: calc(100vh - 3.25rem);
  overflow-y: auto;
  padding: 20px;
}

.has-width-104 {
  width: 104px;
}

.has-width-1440 {
  max-width: 1440px;
}

.is-bottom-0 {
  bottom: 0px;
}

.is-bottom-25 {
  bottom: 25px;
}

.is-centered {
  margin: auto;
  width: 100%;
}

.is-review-button {
  padding: 16px 18px;
  border-radius: 5px;
}

.noborder {
  border-color: transparent !important;
  box-shadow: none !important;
}

.is-image {
  margin-left: auto;
  margin-right: 0;
  width: 100%;
  height: 100%;
}

.is-image-expand-button {
  right: 5px;
  top: 5px;
}

.is-protocol-expand-button {
  float: right;
  margin-top: 9px;
}

.is-disabled-button {
  background-color: rgb(117, 117, 117, 50%) !important;
  cursor: not-allowed;
}

.measurements-circle {
  height: 4px;
  width: 4px;
  border-radius: 50%;
}

.no-page-break {
  page-break-inside: avoid;
}

.is-tooltip-shifted-left::before,
.is-tooltip-shifted-left:hover::before {
  transform: translate(-68px, 0.5rem) !important;
  width: 135px;
  white-space: normal;
}
</style>
