<template>
  <OverviewComponent />
</template>

<script>
// @ is an alias to /src
import OverviewComponent from "@/components/Overview.vue";

export default {
  name: "Overview",
  components: {
    OverviewComponent,
  },
};
</script>
