<template>
  <div v-if="isLoggedIn" class="hero is-fullheight-with-navbar padding-top-10">
    <UploadModal
      :key="uploadComponentKey"
      :isUploadModalSeen="isUploadModalSeen"
      :cancelUpload="cancelUpload"
      v-on:minimizeUploadModal="minimizeUploadModal"
      v-on:reloadPage="reload"
      v-on:updateProgressBar="updateProgressBar"
      v-on:uploadComplete="uploadComplete"
    ></UploadModal>

    <MinimizedUploadModal
      :isMinimizedUploadVisible="isMinimizedUploadVisible"
      :isUploadDone="isUploadDone"
      :progressObject="progressObject"
      :successMessage="successMessage"
      :errorMessage="errorMessage"
      v-on:maximizeUploadModal="maximizeUploadModal"
      v-on:closeMinimizedUploadWindow="closeMinimizedUploadWindow"
    ></MinimizedUploadModal>

    <BrowseView
      ref="browseView"
      v-show="isBrowseView"
      :isBrowseView="isBrowseView"
      :user="user"
      :socket="socket"
      v-on:toggleUploadModal="toggleUploadModal"
      v-on:showGateway="switchToGateway"
    ></BrowseView>

    <Gateway
      ref="gatewayView"
      v-if="isGatewayView"
      :key="gatewayComponentKey"
      :isGatewayView="isGatewayView"
      :accessMode="accessMode"
      v-on:closeGateway="closeGateway"
      v-on:cancelUpload="cancelUploadInProgress"
    ></Gateway>

    <ReportBoard
      v-if="isReportBoardView"
      :key="reportBoardComponentKey"
      :isReportBoardView="isReportBoardView"
      v-on:changeFinalStatus="changeFinalStatus"
      v-on:closeReportBoard="closeReportBoard"
      v-on:showGateway="switchToGateway"
      v-on:cancelUpload="cancelUploadInProgress"
    ></ReportBoard>

    <WarningModal
      :key="warningComponentKey"
      :isWarningModalSeen="isWarningModalSeen"
      :warningObject="warningObject"
      v-on:sendFirstEvent="getImageAnalysis()"
      v-on:sendSecondEvent="closeWarningModal()"
      v-on:sendCloseEvent="closeWarningModal()"
    ></WarningModal>
  </div>
</template>

<script>
import UploadModal from "@/components/Upload.vue";
import MinimizedUploadModal from "@/components/MinimizedUpload.vue";
import WarningModal from "@/components/WarningModal.vue";
import BrowseView from "@/components/Browse.vue";
import Gateway from "@/components/Gateway.vue";
import ReportBoard from "@/components/ReportBoard.vue";

import { io } from "socket.io-client";
import _ from "lodash";

import * as constants from "../constants.js";
import * as helper from "@/js/helper.js";
import { sendErrors } from "@/js/errorHandler.js";

import axios from "axios";

export default {
  name: "OverviewComponent",
  data() {
    return {
      constants: constants,
      isLoggedIn: false,
      user: null,
      uploadComponentKey: 0,
      gatewayComponentKey: 1000,
      reportBoardComponentKey: 2000,
      warningComponentKey: 3000,
      isUploadModalSeen: false,
      isWarningModalSeen: false,
      warningObject: {},
      progressObject: {},
      isMinimizedUploadVisible: false,
      isUploadDone: false,
      cancelUpload: false,
      errorMessage: "",
      successMessage: "",
      socket: null,
      accessMode: undefined,
    };
  },
  components: {
    UploadModal,
    MinimizedUploadModal,
    WarningModal,
    BrowseView,
    Gateway,
    ReportBoard,
  },
  computed: {
    isBrowseView() {
      return this.$store.getters.currentView === constants.VIEW_BROWSE;
    },
    isGatewayView() {
      return this.$store.getters.currentView === constants.VIEW_GATEWAY;
    },
    isReportBoardView() {
      return this.$store.getters.currentView === constants.VIEW_REPORT;
    },
    isUploadInProgress() {
      return this.$store.getters.isUploadInProgress;
    },
  },
  watch: {
    $route(to) {
      this.loadComponentBasedOnRoute(to);
    },
    "$store.state.isUploadWarningSeen": function () {
      if (this.$store.state.isUploadWarningSeen) {
        this.openUploadWarningModal();
      } else {
        this.closeAndResetUploadWarningModal();
      }
    },
  },
  beforeMount() {
    this.checkLoggedIn();
    this.setSocketConnection();
  },
  mounted() {
    this.loadComponentBasedOnRoute(this.$route);
  },
  methods: {
    startAnalysis: helper.startAnalysis,
    checkLoggedIn: function () {
      axios
        .get("/api/currentuser")
        .then((response) => {
          if (!response.data.user) {
            window.location.href = "/";
          } else {
            this.isLoggedIn = true;
            this.$store.commit("setCurrentUser", response.data.user);
            this.user = this.$store.getters.currentUser;
          }
        })
        .catch((error) => {
          sendErrors(error, "Overview:checkLoggedIn");
        });
    },
    setSocketConnection: function () {
      this.socket = io.connect();
      // Verify our websocket connection is established
      this.socket.on("connect", function () {
        console.log("Websocket connected!");
      });
    },
    loadComponentBasedOnRoute: function () {
      if (
        this.$route.name === "ReportBoard" &&
        !_.isEmpty(this.$route.params)
      ) {
        this.reportBoardComponentKey++;
        this.$store.commit("setCurrentCaseId", this.$route.params.caseId);
        this.$store.commit("setCurrentView", constants.VIEW_REPORT);
      } else {
        this.$store.commit("setCurrentCaseId", "");
        this.$store.commit("setCurrentView", constants.VIEW_BROWSE);
      }
    },
    switchToGateway: function (caseId = null, accessMode = undefined) {
      this.gatewayComponentKey++;
      if (caseId != null) {
        this.$store.commit("setCurrentCaseId", caseId);
      }
      this.accessMode = accessMode;
      this.$store.commit("setCurrentView", constants.VIEW_GATEWAY);
    },
    switchToReportBoard: function (caseId) {
      this.$router.push("/report/" + caseId);
    },
    reload: function () {
      this.toggleUploadModal();
      this.closeMinimizedUploadWindow();
    },
    maximizeUploadModal: function () {
      this.isUploadModalSeen = true;
      this.isMinimizedUploadVisible = false;
    },
    minimizeUploadModal: function () {
      this.isUploadModalSeen = false;
      this.isMinimizedUploadVisible = true;
    },
    closeMinimizedUploadWindow: function () {
      this.isMinimizedUploadVisible = false;
    },
    closeGateway: function () {
      if (this.$route.name === "ReportBoard") {
        this.reportBoardComponentKey++;
        this.$store.commit("setCurrentView", constants.VIEW_REPORT);
      } else {
        this.$store.commit("setCurrentCaseId", "");
        this.$store.commit("setCurrentView", constants.VIEW_BROWSE);
      }
    },
    closeReportBoard: function () {
      this.$store.commit("setCurrentCaseId", "");
      this.$router.push("/overview");
    },
    cancelUploadInProgress: function () {
      this.cancelUpload = true;
    },
    toggleUploadModal: function () {
      // Increment componentKey to force remount
      this.uploadComponentKey++;
      this.isUploadModalSeen = !this.isUploadModalSeen;
      // Reset minimized upload window
      this.progressObject = {};
      this.isMinimizedUploadVisible = false;
      this.isUploadDone = false;
      this.cancelUpload = false;
      this.errorMessage = "";
      this.successMessage = "";
      this.$store.commit("setIsUploadInProgress", false);
    },
    updateProgressBar: function (progressObject) {
      this.progressObject = progressObject;
    },
    uploadComplete: function (successfulUploads, failedUploads) {
      this.isUploadDone = true;
      this.$nextTick(() => {
        this.$store.commit("setIsUploadInProgress", false);
      });

      if (successfulUploads.length) {
        this.successMessage =
          "Studies successfully uploaded " + successfulUploads.join(", ");
      }
      if (failedUploads.length) {
        this.errorMessage = "Failed to upload " + failedUploads.join(", ");
      }
    },
    getImageAnalysis: function () {
      // Cancel Upload before starting analysis
      this.cancelUpload = true;
      // Starting analysis from within Gateway is different because it needs to start case first
      if (this.$store.getters.currentView === constants.VIEW_GATEWAY) {
        this.$refs.gatewayView.getImageAnalysis();
      } else {
        this.startAnalysis();
      }
      this.$store.commit("setIsUploadWarningSeen", false);
    },
    openUploadWarningModal: function () {
      this.warningObject = {
        title: "Upload in progress",
        primaryMessage: "Starting the CT Planner will cancel the upload.",
        secondaryMessage: "Do you want to continue?",
        firstButton: "YES - CANCEL UPLOAD",
        secondButton: "NO",
      };
      this.warningComponentKey++;
      this.isWarningModalSeen = true;
    },
    closeAndResetUploadWarningModal: function () {
      this.isWarningModalSeen = false;
      this.warningObject = {};
    },
    closeWarningModal: function () {
      this.$store.commit("setIsUploadWarningSeen", false);
    },
    changeFinalStatus: function (caseID, status) {
      this.$refs.browseView.changeFinalStatus(caseID, status);
    },
  },
};
</script>
