<template>
  <div
    id="expandedImage"
    class="modal has-text-almost-black"
    :class="isExpandImageSeen ? 'is-active' : ''"
  >
    <div class="modal-background" @click="sendCloseEvent()"></div>
    <div class="modal-content has-text-centered">
      <div
        class="is-absolute is-image-collapse-button has-zindex-1 pointer"
        @click="sendCloseEvent()"
      >
        <span
          class="
            tag
            margin-left-5
            has-background-white has-background-dark-primary has-opacity-60
          "
        >
          <i class="fas fa-compress-arrows-alt"></i>
        </span>
      </div>
      <div class="margin-all-15">
        <img :src="image" />
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="sendCloseEvent()"
    ></button>
  </div>
</template>

<script>
export default {
  name: "ExpandedImageModal",
  props: {
    isExpandImageSeen: Boolean,
    image: String,
  },
  data() {
    return {};
  },
  methods: {
    sendCloseEvent: function () {
      this.$emit("closeExpandedImage");
    },
  },
};
</script>

<style scoped>
.modal-background {
  background-color: rgba(255, 255, 255, 0.9);
}

.is-image-collapse-button {
  right: 60px;
  top: 20px;
}
</style>
