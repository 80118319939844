<template>
  <AboutComponent />
</template>

<script>
// @ is an alias to /src
import AboutComponent from "@/components/About.vue";

export default {
  name: "About",
  components: {
    AboutComponent,
  },
};
</script>