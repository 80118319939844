import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import * as THREE from "three";

// TODO: tmp attempt, needs to be updated
class CrossLine {
  /* THREE.js crosshair Line: consists of two lines with the gap
  in between */
  constructor(center, axis, material, gap = 5) {
    this.center = center;
    this.axis = axis;
    this._material = material;
    this._gap = gap;
  }

  getLines() {
    /* Return two THREE.js lines */
    const lines = [];
    for (const direction of [-1, 1]) {
      const points = [];
      for (const distance of [1, 10]) {
        const point = [];
        for (let i = 0; i < 3; i++) {
          point.push(
            this.center[i] +
              direction * (this._gap / 2) * this.axis[i] * distance
          );
        }
        points.push(...point);
      }
      lines.push(this._getLine(points));
    }
    return lines;
  }

  _getLine(points) {
    // const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const geometry = new LineGeometry();
    geometry.setPositions(points);
    return new Line2(geometry, this._material);
  }
}

export default class Cross {
  constructor(materials, gap = 50) {
    /* THREE.js 3-dimensional crossHair drawn around the point (0, 0, 0) with
    lines parallel to X, Y, Z coordinates.

    Positioning and orientation of cross at the CT views is achieved by updating
    the cross matrix to allign with the center of the CT Mpr plane (in world coordinate system) and the directions to be alligned with the normals of the views.

    This approach additionally requires to maintain the three js camera
    position according to the normals on every update.

    Additionally gap is passed which defines the size in world units (mm) of the gap at the center of the cross */

    this._gap = gap;
    this._materials = materials;

    // TODO: there should be a better way to solve this problem:
    // one THREE.js object cannot be simply added to multiple scenes
    this.crosses = [
      this._makeCross(
        this._materials.crossBlue,
        this._materials.crossBlue,
        this._materials.crossGreen
      ),
      this._makeCross(
        this._materials.crossBlue,
        this._materials.crossRed,
        this._materials.crossRed
      ),
      this._makeCross(
        this._materials.crossGreen,
        this._materials.crossRed,
        this._materials.crossRed
      ),
      this._makeCross(
        this._materials.crossGreen,
        this._materials.crossRed,
        this._materials.crossRed
      )
    ];
  }

  setMatrix(matrix) {
    for (const cross of this.crosses) {
      cross.matrix.set(...matrix);
    }
  }

  _makeCross(material1, material2, material3) {
    // order of the colors is alligned with the order
    // of the views colorized frames
    const lines = [
      ...new CrossLine([0, 0, 0], [1, 0, 0], material1, this._gap).getLines(),
      ...new CrossLine([0, 0, 0], [0, 1, 0], material2, this._gap).getLines(),
      ...new CrossLine([0, 0, 0], [0, 0, 1], material3, this._gap).getLines()
    ];

    const group = new THREE.Group();
    for (const line of lines) {
      group.add(line);
    }
    // This is required when updating the matrix manually
    group.matrixAutoUpdate = false;

    return group;
  }
}
