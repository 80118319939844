<template>
  <div
    id="errorModal"
    class="modal has-background-dark-primary"
    :class="isErrorModalSeen ? 'is-active' : ''"
  >
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="message">
        <div class="is-modal padding-rl-20 padding-tb-20">
          <h1 id="title" class="subtitle is-3 has-text-almost-black">
            {{ errorObject.title }}
          </h1>
          <p id="primaryMessage" class="has-text-dark-primary">
            {{ errorObject.primaryMessage }}
          </p>
          <p
            id="secondaryMessage"
            class="
              has-text-almost-black has-text-dark-primary
              pt-5
              enable-line-break
            "
          >
            {{ errorObject.secondaryMessage }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorModal",
  props: {
    isErrorModalSeen: Boolean,
    errorObject: Object,
  },
};
</script>

<style scoped>
.modal-card {
  min-width: 790px;
  min-height: 400px;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.modal-background {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.enable-line-break {
  white-space: pre-line;
}
</style>
