<template>
  <div
    id="warningModal"
    class="modal has-background-dark-primary"
    :class="isWarningModalSeen ? 'is-active' : ''"
  >
    <div class="modal-background" @click="sendCloseEvent"></div>
    <div class="modal-content">
      <div class="message">
        <div class="is-modal padding-rl-20 padding-tb-20">
          <h1 id="title" class="subtitle is-3 has-text-almost-black">
            {{ warningObject.title }}
          </h1>
          <p id="primaryMessage" class="has-text-dark-primary">
            {{ warningObject.primaryMessage }}
          </p>
          <p
            id="secondaryMessage"
            class="has-text-almost-black has-text-dark-primary pt-5"
          >
            {{ warningObject.secondaryMessage }}
          </p>
        </div>

        <div
          id="controlButtons"
          class="
            pb-4
            margin-top-110
            padding-right-24
            has-text-right
            is-control-botton
          "
        >
          <a
            id="firstButton"
            class="
              button
              is-control-right-icon is-size-7
              margin-right-10
              has-text-darker-grey
              has-text-weight-bold
              has-background-transparent
              has-border-none
            "
            @click="sendFirstEvent"
          >
            {{ warningObject.firstButton }}
          </a>

          <a
            v-if="warningObject.secondButton"
            id="secondButton"
            class="
              button
              noborder
              has-border-none
              is-size-7 is-control-right-icon
              has-text-white has-text-weight-bold has-background-darker-grey
            "
            @click="sendSecondEvent"
          >
            {{ warningObject.secondButton }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WarningModal",
  props: {
    isWarningModalSeen: Boolean,
    warningObject: Object,
  },
  methods: {
    sendFirstEvent: function () {
      this.$emit("sendFirstEvent");
    },
    sendSecondEvent: function () {
      this.$emit("sendSecondEvent");
    },
    sendCloseEvent: function () {
      this.$emit("sendCloseEvent");
    },
  },
};
</script>

<style scoped>
.modal-card {
  min-width: 790px;
  min-height: 400px;
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.modal-background {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

.vertical-center {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(50%);
}
</style>
