<template>
  <div
    id="uploadModal"
    class="modal has-text-almost-black"
    :class="isUploadModalSeen ? 'is-active' : ''"
  >
    <div
      class="modal-background"
      @click="isSaving ? sendMinimizeModalEvent() : sendReloadEvent()"
    ></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <!-- Additional studies upload -->
        <div
          v-if="
            !isInitial &&
            !isSaving &&
            !isSuccess &&
            !isPartialSuccess &&
            !isUploadFailed
          "
          class="additional-upload"
        >
          <span>
            <label for="additionalUpload">
              <i class="fas fa-folder-plus is-size-3 has-text-dark-primary"></i>
            </label>
            <input
              id="additionalUpload"
              type="file"
              webkitdirectory
              mozdirectory
              msdirectory
              odirectory
              directory
              multiple="multiple"
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="getStudies($event.target.files)"
            />
          </span>
        </div>
        <!-- End of Additional studies upload -->
        <p
          class="modal-card-title has-text-centered"
          v-if="!isSuccess && !isPartialSuccess && !isUploadFailed"
        >
          Upload Dicom CT studies
        </p>
        <p
          class="modal-card-title has-text-centered"
          v-else-if="isSuccess || isPartialSuccess"
        >
          Upload Complete
        </p>
        <p
          class="modal-card-title has-text-centered"
          v-else-if="isUploadFailed"
        >
          Upload Failed
        </p>
        <i
          v-if="!isSaving"
          class="is-size-5 fas fa-times pointer has-text-dark-primary"
          aria-label="close"
          @click="sendReloadEvent()"
        ></i>
        <i
          v-if="isSaving"
          class="is-size-5 far fa-window-minimize pointer has-text-dark-primary"
          aria-label="close"
          @click="sendMinimizeModalEvent()"
        ></i>
      </header>
      <div id="uploadLoader" class="loader-background" v-if="isProcessing">
        <div class="loader loader-screen-centered"></div>
      </div>

      <header class="has-background-light-grey">
        <article
          v-if="additionalMessage != ''"
          class="
            message
            is-dark
            margin-top-20 margin-bottom-0 margin-left-right-20
          "
        >
          <div class="message-body">
            {{ additionalMessage }}
          </div>
        </article>
        <article
          v-if="errorMessage != ''"
          class="
            message
            is-danger
            margin-top-20 margin-bottom-0 margin-left-right-20
          "
        >
          <div class="message-body">{{ errorMessage }}</div>
        </article>
        <article
          v-if="incompatibleFiles > 0 && totalFiles == 0"
          class="
            message
            is-dark
            margin-top-20 margin-bottom-0 margin-left-right-20
          "
        >
          <div class="message-body">
            <b>{{ incompatibleFiles }} </b> DICOM files skipped due to
            incompatibility with Laralab specification.
          </div>
        </article>
        <article
          v-if="successMessage != ''"
          class="
            message
            is-success
            margin-top-20 margin-bottom-0 margin-left-right-20
          "
        >
          <div class="message-body">{{ successMessage }}</div>
        </article>

        <div
          v-if="
            !isInitial && !isSuccess && !isPartialSuccess && !isUploadFailed
          "
          class="padding-trl-20"
        >
          <div v-if="isSaving" class="margin-bottom-30">
            Uploading {{ totalUploadedFiles }} of {{ totalFiles }} files
            <div>
              <progress
                id="progressBar"
                class="progress is-success"
                :value="progressBarValue"
                max="100"
              ></progress>
            </div>
          </div>
          <ConfirmStudyModal
            :key="confirmStudyComponentKey"
            :isConfirmStudyModalSeen="isConfirmStudyModalSeen"
            :existingStudies="existingStudies"
            v-on:cancelConfirmStudy="cancelConfirmStudy"
            v-on:confirmChoice="confirmChoice"
          ></ConfirmStudyModal>

          <WarningModal
            :componentKey="warningComponentKey"
            :isWarningModalSeen="isWarningModalSeen"
            :warningObject="warningObject"
            v-on:sendFirstEvent="prepareForUpload()"
            v-on:sendSecondEvent="closeWarningModal()"
            v-on:sendCloseEvent="closeWarningModal()"
          ></WarningModal>

          <!-- Header -->
          <div class="has-max-height-40">
            <div class="columns has-text-left padding-rl-15 pointer">
              <div class="column field">
                <input
                  type="checkbox"
                  class="is-checkradio is-info has-hover-dark"
                  id="selectAllCheckbox"
                  v-model="selectAll"
                  :disabled="isSaving"
                  @click="isSaving ? '' : toggleAllCheckbox()"
                />
                <label for="selectAllCheckbox">
                  <span class="subtitle has-text-mid-grey is-7"
                    >UPLOAD [ALL]</span
                  >
                </label>
              </div>
              <div class="column is-1" @click="sortStudies(CASEID)">
                <span
                  class="subtitle has-text-mid-grey is-7"
                  :class="sortKey === CASEID ? 'has-text-weight-bold' : ''"
                  >CASE ID
                </span>
                <i
                  :class="[
                    sortKey === CASEID && sortAsc
                      ? 'fa-caret-up icon-grey'
                      : 'fas fa-caret-down icon-grey',
                    'fas',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="column" @click="sortStudies(NAME)">
                <span
                  class="subtitle has-text-mid-grey is-7"
                  :class="sortKey === NAME ? 'has-text-weight-bold' : ''"
                  >NAME
                </span>
                <i
                  :class="[
                    sortKey === NAME && sortAsc
                      ? 'fa-caret-up icon-grey'
                      : 'fas fa-caret-down icon-grey',
                    'fas',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
              <div
                class="column is-2"
                v-if="anonymize"
                @click="sortStudies(ANONYMIZEDNAME)"
              >
                <span
                  class="subtitle has-text-mid-grey is-7"
                  :class="
                    sortKey === ANONYMIZEDNAME ? 'has-text-weight-bold' : ''
                  "
                  >ANONYMIZED NAME
                </span>
                <i
                  :class="[
                    sortKey === ANONYMIZEDNAME && sortAsc
                      ? 'fa-caret-up icon-grey'
                      : 'fas fa-caret-down icon-grey',
                    'fas',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="column" @click="sortStudies(BIRTHDATE)">
                <span
                  class="subtitle has-text-mid-grey is-7"
                  :class="sortKey === BIRTHDATE ? 'has-text-weight-bold' : ''"
                  >BIRTH DATE
                </span>
                <i
                  :class="[
                    sortKey === BIRTHDATE && sortAsc
                      ? 'fa-caret-up icon-grey'
                      : 'fas fa-caret-down icon-grey',
                    'fas',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="column is-1" @click="sortStudies(SEX)">
                <span
                  class="subtitle has-text-mid-grey is-7"
                  :class="sortKey === SEX ? 'has-text-weight-bold' : ''"
                  >SEX
                </span>
                <i
                  :class="[
                    sortKey === SEX && sortAsc
                      ? 'fa-caret-up icon-grey'
                      : 'fas fa-caret-down icon-grey',
                    'fas',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="column is-3" @click="sortStudies(DESCRIPTION)">
                <span
                  class="subtitle has-text-mid-grey is-7"
                  :class="sortKey === DESCRIPTION ? 'has-text-weight-bold' : ''"
                  >DESCRIPTION
                </span>
                <i
                  :class="[
                    sortKey === DESCRIPTION && sortAsc
                      ? 'fa-caret-up icon-grey'
                      : 'fas fa-caret-down icon-grey',
                    'fas',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="column" @click="sortStudies(STUDYDATE)">
                <span
                  class="subtitle has-text-mid-grey is-7"
                  :class="sortKey === STUDYDATE ? 'has-text-weight-bold' : ''"
                  >STUDY DATE
                </span>
                <i
                  :class="[
                    sortKey === STUDYDATE && sortAsc
                      ? 'fa-caret-up icon-grey'
                      : 'fas fa-caret-down icon-grey',
                    'fas',
                  ]"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
          <!-- End of Header -->
        </div>
      </header>
      <section class="modal-card-body has-background-light-grey">
        <!-- Dropzone start -->
        <div class="dropbox" v-if="isInitial">
          <form enctype="multipart/form-data" ref="fileform">
            <input
              type="file"
              webkitdirectory
              mozdirectory
              msdirectory
              odirectory
              directory
              multiple="multiple"
              :name="uploadFieldName"
              :disabled="isSaving"
              @change="getStudies($event.target.files)"
              class="input-file hidden"
            />
            <p class="has-text-dark-primary">
              Drag folder(s) here or click to browse patient files
            </p>
          </form>
        </div>
        <!-- Dropzone end -->

        <div id="patientList" class="padding-0">
          <div
            v-if="
              !isInitial && !isSuccess && !isPartialSuccess && !isUploadFailed
            "
          >
            <div
              v-if="
                !(
                  Object.keys(patientInfo).length === 0 &&
                  patientInfo.constructor === Object
                )
              "
            >
              <div v-for="(studyInfo, studyUID) in patientInfo" :key="studyUID">
                <div class="card margin-top-20 pointer has-hover-background">
                  <!-- Basic Patient Info -->
                  <div class="padding-rl-15">
                    <div class="columns has-text-left is-multiline">
                      <div class="column field">
                        <input
                          type="checkbox"
                          name="studyCheckbox"
                          :id="'study_' + studyUID"
                          class="is-checkradio is-info has-hover-dark"
                          :class="studyInfo.toUpload ? 'markedForUpload' : ''"
                          :checked="studyInfo.toUpload ? true : false"
                          :disabled="isSaving"
                          :value="
                            JSON.stringify({
                              name: studyInfo.name,
                              studyuid: studyUID,
                            })
                          "
                          @click="
                            isSaving ? '' : toggleCheckboxValue(studyInfo)
                          "
                        />
                        <label :for="'study_' + studyUID"></label>
                      </div>
                      <div class="column is-1">
                        <span>{{ studyInfo.caseId }}</span>
                      </div>
                      <div class="column word-break">
                        <span :class="anonymize ? 'has-text-grey' : ''">{{
                          studyInfo.name
                        }}</span>
                      </div>
                      <div class="column is-2 word-break" v-if="anonymize">
                        <div class="field">
                          <div class="control">
                            <input
                              class="input is-grey"
                              type="text"
                              :disabled="isSaving"
                              v-model="studyInfo.anonymizedName"
                              maxlength="80"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="column word-break">
                        <span
                          >{{ formatDate(studyInfo.patientDob) }} ({{
                            studyInfo.patientAge
                          }})</span
                        >
                      </div>
                      <div class="column is-1">
                        <span>{{ studyInfo.patientSex }}</span>
                      </div>
                      <div class="column is-3 word-break">
                        <span>{{ studyInfo.studyDesc }}</span>
                      </div>
                      <div class="column">
                        <span>{{ formatDate(studyInfo.studyDate) }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- End of Basic Patient Info -->
                </div>
              </div>
            </div>
          </div>
          <div
            class="columns"
            v-if="isSuccess || isPartialSuccess || isUploadFailed"
          >
            <div class="column is-one-quarter">
              <div v-if="isSuccess">
                <p class="success-symbol">
                  <i class="far fa-check-circle has-text-darker-success"></i>
                </p>
              </div>
              <div v-else-if="isPartialSuccess">
                <p class="warning-symbol">
                  <i class="fas fa-exclamation"></i>
                </p>
              </div>
              <div v-else-if="isUploadFailed">
                <p class="warning-symbol">
                  <i class="fas fa-times has-text-darker-danger"></i>
                </p>
              </div>
            </div>
            <div class="column">
              <div class="columns padding-rl-15">
                <div class="column">
                  <span class="subtitle has-text-mid-grey is-7">STATUS</span>
                </div>
                <div class="column">
                  <span class="subtitle has-text-mid-grey is-7">LARA-ID</span>
                </div>
                <div class="column">
                  <span class="subtitle has-text-mid-grey is-7">NAME</span>
                </div>
                <div v-if="anonymize" class="column is-2">
                  <span class="subtitle has-text-mid-grey is-7"
                    >ANONYMIZED NAME
                  </span>
                </div>
                <div class="column">
                  <span class="subtitle has-text-mid-grey is-7"
                    >STUDY DATE
                  </span>
                </div>
              </div>
              <div v-for="studyUID in selectedStudies" :key="studyUID">
                <div
                  class="
                    card
                    margin-top-20
                    has-hover-background
                    pointer
                    tooltip
                    is-tooltip-bottom
                  "
                  :class="
                    patientInfo[studyUID].uploadSuccessful
                      ? 'message is-success'
                      : 'message is-danger'
                  "
                  :data-tooltip="
                    patientInfo[studyUID].uploadSuccessful
                      ? 'Upload Status: Upload Successful'
                      : 'Upload Status: Upload Failed'
                  "
                >
                  <div class="padding-rl-15">
                    <div class="columns has-text-left is-multiline">
                      <div class="column">
                        <span
                          :class="
                            patientInfo[studyUID].uploadSuccessful
                              ? 'has-text-darker-success'
                              : 'has-text-darker-danger'
                          "
                        >
                          <i
                            v-if="patientInfo[studyUID].uploadSuccessful"
                            class="fas fa-check"
                          ></i>
                          <i v-else class="fas fa-times"></i>
                        </span>
                      </div>
                      <div class="column">
                        <span
                          :class="
                            patientInfo[studyUID].uploadSuccessful
                              ? 'has-text-darker-success'
                              : 'has-text-darker-danger'
                          "
                          >{{
                            patientInfo[studyUID].uploadSuccessful
                              ? patientInfo[studyUID].caseId
                              : ""
                          }}
                        </span>
                      </div>
                      <div class="column word-break">
                        <span
                          :class="
                            patientInfo[studyUID].uploadSuccessful
                              ? 'has-text-darker-success'
                              : 'has-text-darker-danger'
                          "
                          >{{ patientInfo[studyUID].name }}
                        </span>
                      </div>
                      <div v-if="anonymize" class="column is-2 word-break">
                        <span
                          :class="
                            patientInfo[studyUID].uploadSuccessful
                              ? 'has-text-darker-success'
                              : 'has-text-darker-danger'
                          "
                          >{{ patientInfo[studyUID].anonymizedName }}
                        </span>
                      </div>
                      <div class="column word-break">
                        <span
                          :class="
                            patientInfo[studyUID].uploadSuccessful
                              ? 'has-text-darker-success'
                              : 'has-text-darker-danger'
                          "
                          >{{ formatDate(patientInfo[studyUID].studyDate) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        class="has-background-light-grey has-text-dark-tertiary padding-20"
        v-if="incompatibleFiles > 0 && totalFiles > 0"
      >
        *<b> {{ totalFiles }} </b> DICOM files uploaded successfully.
        <b>{{ incompatibleFiles }} </b> DICOM files skipped due to
        incompatibility to Laralab specification.
      </div>
      <footer class="modal-card-foot">
        <div
          class="field"
          v-if="!isSuccess && !isSaving && !isPartialSuccess && !isUploadFailed"
        >
          <input
            type="checkbox"
            class="is-checkradio has-hover-dark"
            :class="highlightAnonymize ? 'is-danger' : 'is-info'"
            id="anonymizeCheckbox"
            name="anonymizeCheckbox"
            v-model="anonymize"
          />
          <label for="anonymizeCheckbox" class="has-text-dark-primary">
            De-Identify before upload
          </label>
        </div>

        <a
          v-if="!isSuccess && !isPartialSuccess && !isUploadFailed"
          class="
            button
            is-outlined
            has-text-darker-grey
            modal-footer-right-button
            has-background-transparent
          "
          :class="!isReadyForUpload ? 'tooltip is-tooltip-left' : ''"
          :disabled="!isReadyForUpload"
          @click="isReadyForUpload ? checkValidityForUpload() : ''"
          :data-tooltip="
            isSaving
              ? 'Wait until upload is complete'
              : 'Select at least one study'
          "
          >Upload
        </a>
        <div
          class="columns"
          v-if="isSuccess || isPartialSuccess || isUploadFailed"
        >
          <div class="column">
            <a
              class="
                button
                is-outlined
                has-text-darker-grey has-background-transparent
              "
              @click="downloadPDF"
              >Download PDF</a
            >
          </div>
          <div class="column">
            <a
              class="
                button
                is-outlined
                modal-footer-right-button
                has-text-darker-grey has-background-transparent
              "
              @click="sendReloadEvent"
              >Close
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import ConfirmStudyModal from "@/components/ConfirmStudyModal.vue";
import WarningModal from "@/components/WarningModal.vue";
import async from "async";
import axios from "axios";
import axiosRetry from "axios-retry";
import dicomParser from "dicom-parser";
import moment from "moment";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as helper from "@/js/helper.js";
import { sendErrors, sendInfos } from "@/js/errorHandler.js";

axiosRetry(axios, {
  shouldResetTimeout: true,
  retries: 5,
  retryDelay: (retryCount) => {
    console.log(`Retry attempt: ${retryCount}`);
    return retryCount * 5000; // wait for 5 seconds before retrying
  },
  retryCondition: (error) => {
    return (
      error &&
      // Network Error
      (error.toString().match("Network Error") ||
        // 401 CSRF
        // This can happen if the CSRF is refreshed while requests are already in
        // flight. Retrying is OK here because the user would normally be
        // authenticated if she can start an upload.
        // FIXME: should get a proper error code with HAI-536: Consistent API error handling in MAB
        (error.response &&
          error.response.status === 401 &&
          error.response.data.msg === "CSRF double submit tokens do not match") ||
        // Relevant 5xx Error
        (error.response &&
          error.response.status &&
          (error.response.status === 500 ||
            error.response.status === 502 ||
            error.response.status === 503 ||
            error.response.status === 504)))
    );
  },
});

const STATUS_INITIAL = 0;
const STATUS_PROCESSING = 1;
const STATUS_SAVING = 2;
const STATUS_PENDING = 3;
const STATUS_READYFORUPLOAD = 4;
const STATUS_SUCCESS = 5;
const STATUS_PARTIALSUCESS = 6;
const STATUS_UPLOADFAILED = 7;
const STATUS_FAILED = 8;

export default {
  name: "UploadModal",
  props: {
    isUploadModalSeen: Boolean,
    cancelUpload: Boolean,
  },
  data() {
    return {
      CASEID: "caseId",
      NAME: "name",
      ANONYMIZEDNAME: "anonymizedName",
      DESCRIPTION: "studyDesc",
      BIRTHDATE: "patientDob",
      SEX: "patientSex",
      STUDYDATE: "studyDate",
      user: "",
      confirmStudyComponentKey: 0,
      dragAndDropCapable: false,
      droppedFiles: [],
      uploadError: null,
      currentStatus: STATUS_INITIAL,
      uploadFieldName: "patients",
      patientInfo: {},
      allFolders: {},
      allFiles: {},
      errorsAndInfos: {},
      uploadedCaseIds: [],
      successfulUploads: [],
      failedUploads: [],
      existingStudies: {},
      caseIds: {},
      selectedStudies: [],
      incompatibleFiles: 0,
      totalFiles: 0,
      totalUploadedFiles: 0,
      allChunksUploaded: false,
      progressBarValue: 0,
      anonymize: false,
      additionalMessage: "",
      errorMessage: "",
      successMessage: "",
      highlightAnonymize: false,
      enableUpload: true,
      selectAll: true,
      sortKey: "",
      sortAsc: true,
      isConfirmStudyModalSeen: false,
      isWarningModalSeen: false,
      warningObject: {},
      warningComponentKey: 0,
      selectedChoice: 0, // Replace by default
    };
  },
  watch: {
    anonymize: function () {
      if (this.highlightAnonymize) {
        this.highlightAnonymize = !this.highlightAnonymize;
      }
    },
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isProcessing() {
      return this.currentStatus === STATUS_PROCESSING;
    },
    isPending() {
      return this.currentStatus === STATUS_PENDING;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isPartialSuccess() {
      return this.currentStatus === STATUS_PARTIALSUCESS;
    },
    isUploadFailed() {
      return this.currentStatus === STATUS_UPLOADFAILED;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    isReadyForUpload() {
      return this.currentStatus === STATUS_READYFORUPLOAD && this.enableUpload;
    },
  },
  components: {
    ConfirmStudyModal,
    WarningModal,
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.confirmLeave);
    this.checkLoggedIn();
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.confirmLeave);
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    const self = this;

    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach(
        // Prevents the default action for each drag event and stops the propagation of the event
        function (evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            },
            false
          );
        }.bind(this)
      );

      // Add event listener for drop, capture the files and add them to the droppedFiles array
      this.$refs.fileform.addEventListener(
        "drop",
        function (e) {
          const items = e.dataTransfer.items;
          for (let i = 0; i < items.length; ++i) {
            const entry = items[i].webkitGetAsEntry();
            if (entry.isDirectory) {
              traverseDirectoryTree(entry).then((e) => {
                this.getStudies(this.droppedFiles);
              });
            } else {
              this.errorMessage = "Only dropping of folders allowed.";
              this.currentStatus = STATUS_INITIAL;
            }
          }
        }.bind(this)
      );
    }

    const traverseDirectoryTree = function (item, newPath) {
      return new Promise(function (resolve, reject) {
        const path = newPath || "";
        if (item.isFile) {
          item.file((file) => {
            // TODO: Handle dicomdir
            if (file.name !== "DICOMDIR") {
              if (path !== "") {
                file.relativePath = path;
              }
              self.droppedFiles.push(file);
            }
            return resolve("Done");
          });
        } else if (item.isDirectory) {
          // Get folder contents
          const dirReader = item.createReader();
          const entries = [];

          const getEntries = function () {
            dirReader.readEntries((results) => {
              // readEntries should be called recursively until it returns an empty array
              // to ensure that the end of the directory has been reached
              if (results.length) {
                results.map((entry) => entries.push(entry));
                getEntries();
              } else {
                Promise.all(
                  entries.map((entry) =>
                    traverseDirectoryTree(entry, path + item.name + "/")
                  )
                ).then((values) => {
                  return resolve("Done");
                });
              }
            });
          };
          getEntries();
        }
      });
    };
  },
  methods: {
    formatDate: helper.formatDate,
    checkLoggedIn: function () {
      axios.get("/api/currentuser").then((response) => {
        if (!response.data.user) {
          window.location.href = "/";
        } else {
          this.user = response.data.user.email;
        }
      });
    },
    reset: function () {
      this.user = "";
      this.confirmStudyComponentKey = 0;
      this.currentStatus = STATUS_INITIAL;
      this.droppedFiles = [];
      this.uploadError = null;
      this.patientInfo = {};
      this.allFolders = {};
      this.allFiles = {};
      this.errorsAndInfos = {};
      this.uploadedCaseIds = [];
      this.successfulUploads = [];
      this.failedUploads = [];
      this.existingStudies = {};
      this.selectedStudies = [];
      this.caseIds = {};
      this.additionalMessage = "";
      this.errorMessage = "";
      this.successMessage = "";
      this.highlightAnonymize = false;
      this.isConfirmStudyModalSeen = false;
      this.incompatibleFiles = 0;
      this.selectedChoice = 0;
      this.totalFiles = 0;
      this.totalUploadedFiles = 0;
    },
    // determines if the drag and drop functionality is available
    determineDragAndDropCapable: function () {
      const div = document.createElement("div");
      return (
        ("draggable" in div || "ondragstart" in div) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    getStudies: async function (files) {
      this.currentStatus = STATUS_PROCESSING;
      this.successMessage = "";
      this.errorMessage = "";
      this.additionalMessage = "";

      for (const file of files) {
        const filePath = file.relativePath
          ? file.relativePath
          : file.webkitRelativePath;
        const folderPath = filePath.substring(0, filePath.lastIndexOf("/"));

        // Parse only 1 valid(!) file from each folder to collect the list of studies
        if (!this.allFolders[folderPath]) {
          try {
            await this.parse(file, true);
            this.allFolders[folderPath].files.push(file);
          } catch (error) {
            this.collectErrors(error);
          }
        } else {
          if (
            !this.allFolders[folderPath].files.find(
              (existingFile) => existingFile.name === file.name
            )
          ) {
            this.allFolders[folderPath].files.push(file);
          }
        }
      }

      this.$nextTick(() => {
        this.checkEnableUpload();
      });
      this.currentStatus = STATUS_READYFORUPLOAD;
      // console.log("Basic info", this.patientInfo);
    },
    collectAllFiles: function (name, studyUID) {
      return new Promise((resolve) => {
        this.currentStatus = STATUS_PROCESSING;
        let patientFiles = [];

        _.forEach(this.allFolders, (folderPath) => {
          if (
            folderPath.patientName === name &&
            folderPath.studyUID === studyUID
          ) {
            patientFiles = [...patientFiles, ...folderPath.files];
          }
        });
        this.allFiles[studyUID] = patientFiles;
        this.totalFiles = this.totalFiles + patientFiles.length;
        resolve();
      });
    },
    parse: function (file, basic, chunkIndex = 0) {
      const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        const filePath = file.relativePath
          ? file.relativePath
          : file.webkitRelativePath;
        const folderPath = filePath.substring(0, filePath.lastIndexOf("/"));

        reader.onload = (e) => {
          const arrayBuffer = reader.result;
          const byteArray = new Uint8Array(arrayBuffer);

          try {
            const dataSet = dicomParser.parseDicom(byteArray);
            if (dataSet.warnings.length > 0) {
              const message = `Warnings encountered while parsing: ${dataSet.warnings}`;
              sendInfos(message);
              dataSet.warnings.forEach(function (warning) {
                console.log("warning: ", warning);
              });
            } else {
              const pixelData = dataSet.elements.x7fe00010;
              if (pixelData) {
                if (basic) {
                  const basicInfo = this.getBasicPatientInfo(dataSet);
                  if (!this.allFolders[folderPath]) {
                    this.allFolders[folderPath] = {
                      patientName: "",
                      studyUID: "",
                      files: [],
                    };
                  }
                  this.allFolders[folderPath].patientName = basicInfo[0];
                  this.allFolders[folderPath].studyUID = basicInfo[1];
                } else {
                  this.getCompletePatientInfo(dataSet, file, chunkIndex);
                }
                // console.log("Status: Success");
              } else {
                // console.log("Status: Ready - no pixel data found");
              }
            }
          } catch (err) {
            // Deduct number of non-DICOM files not caught during study selection from the total number of files
            if (
              !basic &&
              typeof err === "string" &&
              err.includes("readPart10Header")
            ) {
              this.totalFiles--;
            }
            reject(new Error(err));
          }
          resolve();
        };
        reader.readAsArrayBuffer(file);
      });
      return promise;
    },
    confirmPhaseValue: function (
      phaseTagValue,
      scanOptions,
      seriesDescription
    ) {
      // conside the phase tag first
      if (phaseTagValue.trim() && phaseTagValue.trim().length !== 0) {
        return this.convertToInt(phaseTagValue);
      }

      // look in scanOptions if phase tag is empty
      const scanOptionsPhase = scanOptions.match(/^TP(\d{1,3}).*/);
      if (scanOptionsPhase != null) {
        return this.convertToInt(scanOptionsPhase[1]);
      }

      // look in seriesDescription if both phase tag and scanOptions are empty
      // default to -100 if nothing is available
      let phaseValue = -100;
      if (seriesDescription) {
        let foundMatches = [];
        const pattern = RegExp(/(\d+(\.{1}\d+)*( ){0,1})\%/g);
        while ((foundMatches = pattern.exec(seriesDescription)) != null) {
          // ignore if range is present
          if (foundMatches.index > 2) {
            const stringToSearch = seriesDescription.slice(
              Math.max(0, foundMatches.index - 5),
              pattern.lastIndex
            );
            const rangeMatch = stringToSearch.match(
              /(\d|\.)( ){0,1}-( ){0,1}/g
            );
            if (rangeMatch != null) {
              continue;
            }
          }
          phaseValue = seriesDescription
            .slice(foundMatches.index, pattern.lastIndex)
            .replace(" ", "");
        }
      }
      return this.convertToInt(phaseValue);
    },
    convertToInt: function (phaseValue) {
      if (typeof phaseValue === "string") {
        // Strip "%"
        phaseValue = phaseValue.split("%")[0];
      }
      let phase = Math.floor(Number(phaseValue));
      if (Number.isNaN(phase)) {
        phase = -100;
      }
      return phase;
    },
    getBasicPatientInfo: function (dataSet) {
      const requiredTags = {
        ImageTypeTag: "x00080008",
        NumberOfFramesTag: "x00280008",
        SOPClassUIDTag: "x00080016",
        StudyInstanceUIDTag: "x0020000d",
        PatientNameTag: "x00100010",
        PatientAgeTag: "x00101010",
        PatientSexTag: "x00100040",
        PatientBirthDateTag: "x00100030",
        StudyDescriptionTag: "x00081030",
        StudyDateTag: "x00080020",
      };

      let patientName = "MISSING";
      let studyUID = "";

      // Check SamplesPerPixel
      const SamplesPerPixelTag = "x00280002";
      const tagValue = dataSet.uint16(SamplesPerPixelTag);
      if (tagValue !== 1) {
        this.incompatibleFiles++;
        throw new Error("WrongSamplesPerPixel: " + tagValue);
      }

      Object.keys(requiredTags).forEach((key) => {
        const value = requiredTags[key];
        const element = dataSet.elements[value];
        let tagValue = "";
        if (element !== undefined) {
          const str = dataSet.string(value);
          if (str !== undefined) {
            tagValue = str;
          }
        }

        // Check ImageType
        if (key === "ImageTypeTag") {
          if (
            !tagValue.trim().startsWith("ORIGINAL") ||
            tagValue.trim().split("\\")[2] !== "AXIAL"
          ) {
            this.incompatibleFiles++;
            throw new Error("WrongImageType: " + tagValue);
          }
        }

        // Check NumberOfFrames
        if (key === "NumberOfFramesTag") {
          if (tagValue !== "" && tagValue !== 1) {
            this.incompatibleFiles++;
            throw new Error("WrongNumberOfFrames: " + tagValue);
          }
        }

        // Check SOPClassUID
        if (key === "SOPClassUIDTag") {
          if (!tagValue.trim().startsWith("1.2.840.10008.5.1.4.1.1.2")) {
            this.incompatibleFiles++;
            throw new Error("WrongSOPClassUID: " + tagValue);
          }
        }

        if (key !== "PatientNameTag" && tagValue === "") {
          return;
        }

        if (key === "StudyInstanceUIDTag") {
          studyUID = tagValue;
          if (!this.patientInfo[studyUID]) {
            this.$set(this.patientInfo, studyUID, {
              caseId: "",
              name: "",
              anonymizedName: "",
              patientAge: "",
              patientDob: "",
              patientSex: "",
              studyDesc: "",
              studyDate: "",
              admittingDiagnosesDescription: "",
              imageInfos: {},
              toUpload: true,
              uploadSuccessful: false,
              hasFailedChunk: false,
              totalChunks: 0,
              successfulChunks: new Set(),
            });
          }
        } else if (key === "PatientNameTag") {
          // Use default value if Patient name is empty
          if (tagValue.trim() !== "") {
            patientName = tagValue;
          }
          this.patientInfo[studyUID].name = patientName;
        } else if (key === "PatientAgeTag") {
          this.patientInfo[studyUID].patientAge = tagValue;
        } else if (key === "PatientBirthDateTag") {
          this.patientInfo[studyUID].patientDob = tagValue;
        } else if (key === "PatientSexTag") {
          this.patientInfo[studyUID].patientSex = tagValue;
        } else if (key === "StudyDescriptionTag") {
          this.patientInfo[studyUID].studyDesc = tagValue;
        } else if (key === "StudyDateTag") {
          this.patientInfo[studyUID].studyDate = tagValue;
        }
      });

      this.calculatePatientAge(this.patientInfo[studyUID]);
      return [patientName, studyUID];
    },
    getCompletePatientInfo: function (dataSet, file, chunkIndex) {
      const specialTags = {
        StudyInstanceUID: "x0020000d",
        Phase: "x01f11041",
        AlternatePhase: "x00209241",
        ToshibaPhase: "x70051004",
        ScanOptions: "x00180022",
        AdmittingDiagnosesDescription: "x00081080",
      };

      const requiredTags = {
        // Filter TAGS
        SamplesPerPixel: "x00280002",
        ImageType: "x00080008",
        SOPClassUID: "x00080016",
        // Series TAGS
        SeriesInstanceUID: "x0020000e",
        SeriesDescription: "x0008103e",
        // Meta TAGS
        TransferSyntaxUID: "x00020010",
        // Image TAGS
        Rows: "x00280010",
        PixelSpacing: "x00280030",
        Columns: "x00280011",
        FrameOfReferenceUID: "x00200052",
        RescaleIntercept: "x00281052",
        BitsAllocated: "x00280100",
        BitsStored: "x00280101",
        HighBit: "x00280102",
        ImagePositionPatient: "x00200032",
        ImageOrientationPatient: "x00200037",
        SliceThickness: "x00180050",
        PixelRepresentation: "x00280103",
        RescaleSlope: "x00281053",
        WindowWidth: "x00281051",
        WindowCenter: "x00281050",
        ContentTime: "x00080033",
      };

      let studyUID = "";
      let scanOptions = "";
      let phase = "";
      let admittingDiagnosesDescription = "";
      const requiredTagValues = {};

      Object.keys(specialTags).forEach((key) => {
        const value = specialTags[key];
        const element = dataSet.elements[value];
        let tagValue = "";
        let rawValue = "";
        if (element !== undefined) {
          if (key === "AlternatePhase") {
            rawValue = dataSet.float(value).toString();
          } else {
            rawValue = this.extractTagValue(dataSet, value);
          }

          if (rawValue !== undefined) {
            tagValue = rawValue;
          }
        }

        if (key === "StudyInstanceUID") {
          studyUID = tagValue;
        } else if (key === "ScanOptions") {
          scanOptions = tagValue;
        } else if (key === "Phase") {
          phase = tagValue;
        } else if (
          key === "AlternatePhase" &&
          phase === "" &&
          tagValue !== ""
        ) {
          phase = tagValue;
        } else if (key === "ToshibaPhase" && phase === "") {
          phase = tagValue;
        } else if (key === "AdmittingDiagnosesDescription") {
          admittingDiagnosesDescription = tagValue;
        }
      });

      Object.keys(requiredTags).forEach((key) => {
        const value = requiredTags[key];
        const element = dataSet.elements[value];
        let tagValue;

        if (element !== undefined) {
          tagValue = this.extractTagValue(dataSet, value);
        }
        requiredTagValues[key] = tagValue;
      });

      requiredTagValues.Phase = this.confirmPhaseValue(
        phase,
        scanOptions,
        requiredTagValues.SeriesDescription
      );

      if (requiredTagValues.SamplesPerPixel !== 1) {
        this.incompatibleFiles++;
        this.totalFiles--;
        throw new Error(
          "WrongSamplesPerPixel: " + requiredTagValues.SamplesPerPixel
        );
      }

      if (
        !requiredTagValues.ImageType.trim().startsWith("ORIGINAL") ||
        requiredTagValues.ImageType.trim().split("\\")[2] !== "AXIAL"
      ) {
        this.incompatibleFiles++;
        this.totalFiles--;
        throw new Error("WrongImageType: " + requiredTagValues.ImageType);
      }

      if (
        requiredTagValues.NumberOfFrames &&
        requiredTagValues.NumberOfFrames !== "" &&
        requiredTagValues.NumberOfFrames !== 1
      ) {
        this.incompatibleFiles++;
        this.totalFiles--;
        throw new Error(
          "WrongNumberOfFrames: " + requiredTagValues.NumberOfFrames
        );
      }

      if (
        !requiredTagValues.SOPClassUID.trim().startsWith(
          "1.2.840.10008.5.1.4.1.1.2"
        )
      ) {
        this.incompatibleFiles++;
        this.totalFiles--;
        throw new Error("WrongSOPClassUID: " + requiredTagValues.SOPClassUID);
      }

      const pixelDataElement = dataSet.elements.x7fe00010;
      // let web client just extract bytes and server take care of the rest
      const pixelArray = new Uint8Array(
        dataSet.byteArray.buffer,
        pixelDataElement.dataOffset,
        pixelDataElement.length
      );

      const imageInfo = {
        PixelData: btoa(this.Uint8ToString(pixelArray)),
      };

      _.forEach(requiredTagValues, (value, key) => {
        if (Number.isInteger(value) || value) {
          imageInfo[key] = value;
        }
      });

      const studyObj = this.patientInfo[studyUID];
      studyObj.admittingDiagnosesDescription = admittingDiagnosesDescription;

      if (!studyObj.imageInfos[chunkIndex]) {
        studyObj.imageInfos[chunkIndex] = [];
      }
      studyObj.imageInfos[chunkIndex].push(imageInfo);
    },
    extractTagValue: function (dataSetValue, tagValue) {
      const self = this;
      return getTagValue(dataSetValue, tagValue);

      function getTagValue(dataSet, tag) {
        const sqItems = [];
        let vr;

        const element = dataSet.elements[tag];
        if (element.vr !== undefined) {
          vr = element.vr;
        } else {
          vr = self.getDefaultVR(tag);
        }

        if (vr !== "SQ") {
          return getCastedValue(dataSet, tag, vr);
        }

        // Elements with vr === 'SQ' contain sequence
        if (vr === "SQ") {
          // SQ element should always have items
          if (element.items) {
            _.forEach(element.items, (item) => {
              const sqValue = {};
              _.forEach(item.dataSet.elements, (value, key) => {
                // Recursively get tag values for each tag inside SQ
                sqValue[key] = getTagValue(item.dataSet, value.tag);
              });
              sqItems.push(sqValue);
            });
            return sqItems;
          }
        }

        function getCastedValue(dataSetIn, tagIn, vrIn) {
          function isASCII(str) {
            return /^[\x00-\x7F]*$/.test(str);
          }

          let castedValue;
          function isStringVr(vr) {
            if (
              vr === "US" ||
              vr === "SQ" ||
              vr === "FD" ||
              vr === "FL" ||
              vr === "UL" ||
              vr === "OB"
            ) {
              return false;
            }
            return true;
          }

          if (isStringVr(vrIn)) {
            let str = "";
            str += dataSetIn.string(tagIn);

            if (isASCII(str)) {
              castedValue = str.trim();
            }
          } else if (vrIn === "US") {
            castedValue = dataSetIn.uint16(tagIn);
          } else if (vrIn === "FD") {
            castedValue = dataSetIn.double(tagIn);
          } else if (vrIn === "FL") {
            castedValue = dataSetIn.float(tagIn);
          } else if (vrIn === "UL") {
            castedValue = dataSetIn.uint32(tagIn);
          } else if (vrIn === "OB") {
            if (element.length === 2) {
              castedValue = dataSetIn.uint16(tagIn);
            } else if (element.length === 4) {
              castedValue = dataSetIn.uint32(tagIn);
            }
          }

          return castedValue;
        }
      }
    },
    postFiles: function (studyUID, chunkIndex) {
      return new Promise((resolve, reject) => {
        const studyObj = this.patientInfo[studyUID];
        let uploadName = studyObj.name;

        // Anonymize name if "anonymize" has been selected
        if (this.anonymize) {
          uploadName = studyObj.anonymizedName;
        }

        const currentPatientInfo = {};
        this.currentStatus = STATUS_SAVING;
        currentPatientInfo.CaseID = studyObj.caseId;
        currentPatientInfo.PatientName = uploadName;
        currentPatientInfo.StudyInstanceUID = studyUID;
        currentPatientInfo.StudyDescription = studyObj.studyDesc;
        currentPatientInfo.AdmittingDiagnosesDescription =
          studyObj.admittingDiagnosesDescription;

        // Send patient related information
        this.sendPatientInfo(currentPatientInfo)
          .then(() => {
            // Send image related information
            this.sendImageInfo(studyObj, chunkIndex)
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(new Error(error));
              });
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    sendPatientInfo: function (currentPatientInfo) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("PatientInfo", JSON.stringify(currentPatientInfo));
        axios
          .post("/api/web_patient_info", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 300000, // 5 minutes
          })
          .then((res) => {
            if (res && (res.data === "Error" || res.status !== 200)) {
              reject(new Error(res.data));
            }
            if (
              res &&
              res.data.includes("Please log in to access this page.")
            ) {
              reject(new Error("Login Required"));
            }
            resolve();
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    sendImageInfo: function (studyObj, chunkIndex) {
      return new Promise((resolve, reject) => {
        let uploadDob = studyObj.patientDob;
        let uploadAge = studyObj.patientAge;
        let uploadStudyDate = studyObj.studyDate;
        const imageInfo = studyObj.imageInfos[chunkIndex] || [];

        if (this.anonymize) {
          uploadDob = "";
          if (studyObj.patientAge.replace(/[^0-9]/g, "") >= 89) {
            uploadAge = ">88Y";
          }

          if (
            uploadStudyDate &&
            moment().year() - moment(uploadStudyDate).year() >= 89
          ) {
            uploadStudyDate = "";
          }
        }
        const formData = new FormData();

        formData.append("CaseID", studyObj.caseId);
        formData.append("PatientDob", uploadDob);
        formData.append("PatientAge", uploadAge);
        formData.append("PatientSex", studyObj.patientSex);
        formData.append("StudyDate", uploadStudyDate);
        formData.append("ImageInfo", JSON.stringify(imageInfo));
        formData.append("ChunkIndex", chunkIndex);
        axios
          .post("/api/web_upload_files", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            timeout: 1500000, // 25 minutes
          })
          .then((response) => {
            studyObj.imageInfos[chunkIndex] = [];
            if (
              response &&
              (response.data === "Error" ||
                response.data.includes("Please log in to access this page.") ||
                response.status !== 200)
            ) {
              studyObj.hasFailedChunk = true;
              reject();
            }
            studyObj.successfulChunks.add(chunkIndex);
            resolve();
          })
          .catch((error) => {
            studyObj.hasFailedChunk = true;
            reject(error);
          });
      });
    },
    sendEndRequest: function (studyUID) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/api/web_end_upload",
            {
              studyUID: studyUID,
              case_id: this.patientInfo[studyUID].caseId,
              TotalChunks: this.patientInfo[studyUID].totalChunks,
            },
            { timeout: 1500000 }
          )
          .then((response) => {
            if (
              response &&
              (response.data === "Error" || response.status !== 200)
            ) {
              reject(new Error(response.data));
            }
            if (
              response &&
              response.data.includes("Please log in to access this page.")
            ) {
              reject(new Error("Login Required"));
            }
            resolve();
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    sendParseErrorsAndInfos: function (studyUID, chunkIndex) {
      // Send errors caught during basic parsing
      if (chunkIndex === 0) {
        this.sendParseErrorsAndInfos(studyUID, -1);
      }
      if (this.errorsAndInfos[chunkIndex]) {
        if (this.errorsAndInfos[chunkIndex].errors) {
          const error = `StudyUID=${studyUID}, ChunkIndex=${chunkIndex}: ${this.errorsAndInfos[chunkIndex].errors}`;
          sendErrors(error, "Upload:startUpload()").then(() => {
            this.errorsAndInfos[chunkIndex] = {};
          });
        }
        if (this.errorsAndInfos[chunkIndex].infos) {
          const infos = `studyUID=${studyUID}, ChunkIndex=${chunkIndex}: ${this.errorsAndInfos[chunkIndex].infos}`;
          sendInfos(infos).then(() => {
            this.errorsAndInfos[chunkIndex] = {};
          });
        }
      }
    },
    toggleCheckboxValue: function (studyInfo) {
      studyInfo.toUpload = !studyInfo.toUpload;
      const self = this;
      _.forEach(this.patientInfo, function (studyInfo, studyUid) {
        if (!studyInfo.toUpload) {
          self.selectAll = false;
          return false;
        }
        self.selectAll = true;
      });

      this.$nextTick(() => {
        this.checkEnableUpload();
      });
      this.currentStatus = STATUS_READYFORUPLOAD;
    },
    toggleAllCheckbox: function () {
      this.selectAll = !this.selectAll;
      const self = this;
      _.forEach(this.patientInfo, function (studyInfo, studyUID) {
        studyInfo.toUpload = self.selectAll;
      });

      this.$nextTick(() => {
        this.checkEnableUpload();
      });
      this.currentStatus = STATUS_READYFORUPLOAD;
    },
    openWarningModal: function () {
      this.warningObject = {
        title: "Confirm De-Identification",
        primaryMessage:
          "Dicom files will be de-identified before upload. A PDF enabling re-identification only for you will be created on your browser and automatically downloaded.",
        secondaryMessage: "Are you sure you want to continue?",
        firstButton: "YES - CONTINUE UPLOAD",
        secondButton: "NO",
      };
      this.isWarningModalSeen = true;
    },
    closeWarningModal: function () {
      this.isWarningModalSeen = false;
      this.highlightAnonymize = true;
      this.warningObject = {};
    },
    checkEnableUpload: function () {
      const uploadArray = document.getElementsByClassName("markedForUpload");
      this.enableUpload = uploadArray.length > 0;
      if (uploadArray.length > 0) {
        this.additionalMessage = "";
        this.errorMessage = "";
      }
    },
    calculatePatientAge: function (studyObj) {
      if (studyObj.patientAge === "") {
        if (!studyObj.patientDob) {
          studyObj.patientAge = "000Y";
        } else {
          let studyDate = studyObj.studyDate;
          studyDate = studyDate === "" ? moment() : studyDate;

          let patientAge =
            moment(studyDate).year() - moment(studyObj.patientDob).year();
          patientAge = String(patientAge).padStart(3, "0") + "Y";
          studyObj.patientAge = patientAge;
        }
      }
    },
    sortStudies: function (key, order) {
      if (order === undefined) {
        // Sort by ascending order by default
        this.sortAsc = this.sortKey === key ? !this.sortAsc : true;
        if (this.sortAsc) {
          order = "asc";
        } else {
          order = "desc";
        }
      } else {
        if (order === "asc") {
          this.sortAsc = true;
        } else {
          this.sortAsc = false;
        }
      }
      this.sortKey = key;
      this.patientInfo = _.orderBy(this.patientInfo, [key], [order]);
    },
    checkValidityForUpload: function () {
      this.highlightAnonymize = false;
      const uploadArray = document.getElementsByClassName("markedForUpload");
      if (uploadArray.length < 1) {
        this.currentStatus = STATUS_FAILED;
        this.additionalMessage = "Select at least one study to upload";
        return;
      }

      // Warn about no anonymization
      if (this.anonymize) {
        this.openWarningModal();
      } else {
        this.prepareForUpload();
      }
    },
    prepareForUpload: function () {
      this.closeWarningModal();
      this.selectedStudies = [];
      const uploadArray = document.getElementsByClassName("markedForUpload");

      _.forEach(uploadArray, (element) => {
        const elementValue = JSON.parse(element.value).studyuid;
        this.selectedStudies.push(elementValue);
      });

      this.checkIfStudyExists()
        .then(() => {
          if (Object.keys(this.existingStudies).length > 0) {
            this.openConfirmStudyModal();
          } else {
            this.getLaralabId().then(() => {
              this.startUpload();
            });
          }
        })
        .catch((error) => {
          sendErrors(error, "Upload:checkIfStudyExists()");
          this.currentStatus = STATUS_FAILED;
          this.errorMessage = "Something went wrong. Please try again.";
        });
    },
    checkIfStudyExists() {
      this.existingStudies = {};
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/api/web_study_exists",
            {
              studyUIDs: this.selectedStudies,
            },
            { timeout: 120000 } // 2 minutes
          )
          .then((res) => {
            const response = res.data;
            if (response.includes("Please log in to access this page.")) {
              reject(new Error("Login Required"));
            }
            if (response.length > 0) {
              _.forEach(response, (studyuid) => {
                this.existingStudies[studyuid] = _.cloneDeep(
                  this.patientInfo[studyuid]
                );
              });
            }
            resolve("Success");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLaralabId: function () {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/api/web_get_caseid",
            {
              studyUIDs: this.selectedStudies,
              ifExists: this.selectedChoice,
            },
            { timeout: 120000 } // 2 minutes
          )
          .then((res) => {
            if (res.data.includes("Please log in to access this page.")) {
              reject(new Error("Login Required"));
              return;
            }
            this.caseIds = res.data;
            const self = this;
            _.forEach(this.caseIds, (value) => {
              _.forEach(value, function (caseId, studyuid) {
                self.patientInfo[studyuid].caseId = caseId;
              });
            });
            resolve("Success");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    startUpload: function () {
      const promiseArray = [];
      // ConcurrentRequests is set to 5 to leave one spot open for other potential requests
      const concurrentRequests = 5;

      if (this.selectedStudies.length === 0) {
        this.additionalMessage = "Select at least one study to upload";
        return;
      }

      // Collect all files that belong to the selected studies
      _.forEach(this.selectedStudies, (studyuid) => {
        const studyInfo = this.patientInfo[studyuid];
        promiseArray.push(this.collectAllFiles(studyInfo.name, studyuid));
      });

      Promise.all(promiseArray).then(() => {
        this.$store.commit("setIsUploadInProgress", true);
        this.sendMinimizeModalEvent();
        // Upload concurrentRequests (currently 5 because Chrome allows max of 6 requests and we want to leave one request free for other potential request) chunks in parallel
        const q = async.queue((task, callback) => {
          // studyuid and chunkIndex is available as the task variable
          this.updateProgressBar(task.chunkLength);
          // Stop further upload if an error has occured or if cancel upload has been called
          if (
            this.patientInfo[task.studyuid].hasFailedChunk ||
            this.cancelUpload
          ) {
            if (this.cancelUpload) {
              if (!_.includes(this.failedUploads, task.studyuid)) {
                this.failedUploads.push(task.studyuid);
              }
              this.$store.commit("setIsUploadInProgress", false);
            }
            callback();
          } else {
            this.postFiles(task.studyuid, task.chunkIndex)
              .then(() => {
                // Send end request to mark the end of the upload request after response has been received for all chunks
                if (
                  this.patientInfo[task.studyuid].successfulChunks.size ===
                  this.patientInfo[task.studyuid].totalChunks
                ) {
                  this.sendEndRequest(task.studyuid)
                    .then(() => {
                      this.uploadedCaseIds.push(
                        this.patientInfo[task.studyuid].caseId
                      );
                      this.patientInfo[task.studyuid].uploadSuccessful = true;
                      this.successfulUploads.push(task.studyuid);
                    })
                    .catch((error) => {
                      // If error occurs, mark the upload as failed so that we can move on to the next study
                      if (!_.includes(this.failedUploads, task.studyuid)) {
                        this.failedUploads.push(task.studyuid);
                      }
                      sendErrors(error, "Upload:sendEndRequest()");
                    })
                    .finally(() => {
                      callback();
                    });
                } else {
                  callback();
                }
              })
              .catch((error) => {
                if (!_.includes(this.failedUploads, task.studyuid)) {
                  this.failedUploads.push(task.studyuid);
                }
                sendErrors(error, "Upload:postFiles()");
                callback();
              });
          }
        }, concurrentRequests);

        // Parse and upload files for each study in chunks
        _.forEach(this.selectedStudies, (studyuid) => {
          const patientFiles = this.allFiles[studyuid];
          let chunkIndex = 0;
          const maxChunkSize = 25;
          const chunkedPatientFiles = _.chunk(patientFiles, maxChunkSize);
          this.patientInfo[studyuid].totalChunks = chunkedPatientFiles.length;

          _.forEach(chunkedPatientFiles, (chunk) => {
            const localChunkIndex = chunkIndex;
            const promiseArray = [];
            let invalidFiles = 0;
            _.forEach(chunk, (file) => {
              if (!this.patientInfo[studyuid].hasFailedChunk) {
                promiseArray.push(this.parse(file, false, localChunkIndex));
              }
            });

            Promise.all(
              promiseArray.map((promise) =>
                promise.catch((error) => {
                  invalidFiles++;
                  this.collectErrors(error, localChunkIndex);
                })
              )
            ).then(() => {
              // Send errors and infos caught during parsing
              this.sendParseErrorsAndInfos(studyuid, localChunkIndex);
              // Send the chunk to queue for parallel upload
              q.push({
                studyuid,
                chunkIndex: localChunkIndex,
                chunkLength: chunk.length - invalidFiles,
              });
            });
            chunkIndex++;
          });
        });

        const self = this;
        q.drain(function () {
          // Show the summary of upload after all studies are uploaded
          if (self.failedUploads.length) {
            const error = `Failed Uploads: ${self.failedUploads}`;
            sendErrors(error, "Upload:startUpload()");
            if (self.successfulUploads.length) {
              self.currentStatus = STATUS_PARTIALSUCESS;
            } else {
              self.currentStatus = STATUS_UPLOADFAILED;
            }
          } else {
            console.log("Upload Complete");
            self.currentStatus = STATUS_SUCCESS;
          }
          if (!self.isUploadModalSeen) {
            self.sendUploadCompleteEvent();
          }
          if (self.anonymize) {
            self.downloadPDF();
          }
        });
      });
    },
    openConfirmStudyModal: function () {
      this.confirmStudyComponentKey++;
      this.isConfirmStudyModalSeen = true;
    },
    cancelConfirmStudy: function () {
      this.isConfirmStudyModalSeen = false;
      this.existingStudies = {};
      this.selectedStudies = [];
    },
    confirmChoice: function (value) {
      this.selectedChoice = value;
      this.isConfirmStudyModalSeen = false;

      if (value === 1) {
        _.remove(this.selectedStudies, (n) => {
          return n in this.existingStudies;
        });

        _.forEach(this.existingStudies, (value, key) => {
          this.patientInfo[key].toUpload = false;
        });

        this.$nextTick(() => {
          const uploadArray =
            document.getElementsByClassName("markedForUpload");
          if (uploadArray.length < 1) {
            this.selectAll = false;
          }
          this.enableUpload = uploadArray.length > 0;
        });
      }
      this.getLaralabId()
        .then(() => {
          this.startUpload();
        })
        .catch((error) => {
          sendErrors(error, "Upload:getLaralabID()");
          this.currentStatus = STATUS_FAILED;
          this.errorMessage = "Something went wrong. Please try again.";
        });
    },
    sendReloadEvent: function () {
      this.$emit("reloadPage", this.uploadedCaseIds);
      // this.reset();
    },
    sendMinimizeModalEvent: function () {
      this.$emit("minimizeUploadModal");
    },
    sendUploadCompleteEvent: function () {
      this.$emit("uploadComplete", this.uploadedCaseIds, this.failedUploads);
    },
    sendUploadProgressEvent: function () {
      this.$emit("updateProgressBar", {
        progressBarValue: this.progressBarValue,
        totalUploadedFiles: this.totalUploadedFiles,
        totalFiles: this.totalFiles,
      });
    },
    // Bytes to base64 encoding. Previous method didn't work well with compressed data. Also TextDecoder is not supported by Edge.
    Uint8ToString: function (u8a) {
      const CHUNK_SZ = 0x8000;
      const c = [];
      for (let i = 0; i < u8a.length; i += CHUNK_SZ) {
        c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)));
      }
      return c.join("");
    },
    updateProgressBar: function (chunkSize) {
      this.totalUploadedFiles = this.totalUploadedFiles + chunkSize;
      this.progressBarValue = Math.floor(
        (this.totalUploadedFiles / this.totalFiles) * 100
      );
      this.sendUploadProgressEvent();
    },
    downloadPDF: function () {
      const doc = new jsPDF({ orientation: "l" });
      const head = [
        "Uploaded",
        "Case ID",
        "Name",
        "Anonymized Name",
        "Birth Date",
        "Study Description",
        "Study Date",
        "StudyInstanceUID",
      ];
      const body = [];

      _.forEach(this.selectedStudies, (studyuid) => {
        const studyObj = this.patientInfo[studyuid];
        let anonymizedName = studyObj.anonymizedName;
        let caseId = studyObj.caseId;
        const uploadSuccessful = studyObj.uploadSuccessful ? "Yes" : "No";
        const data = [
          uploadSuccessful,
          caseId,
          studyObj.name,
          anonymizedName,
          this.formatDate(studyObj.patientDob),
          studyObj.studyDesc,
          this.formatDate(studyObj.studyDate),
          studyuid,
        ];
        if (!this.anonymize) {
          anonymizedName = "";
          // Remove "Anonymized Name"
          head.splice(3, 1);
          data.splice(3, 1);
        }
        if (!studyObj.uploadSuccessful) {
          caseId = "";
        }

        body.push(data);
      });
      doc.setFontSize(10);
      doc.text(
        "Created by: " +
          this.user +
          " via LARALAB on " +
          moment().format("lll") +
          ".",
        15,
        15
      );

      let text = "This file was created on the client side.";
      if (this.anonymize) {
        text += " Personalized information was not transferred.";
      }

      // Cells width are different based on whether or not de-identify is selected
      let columnStyles = {
        0: { cellWidth: 20 },
        1: { cellWidth: 20 },
        2: { cellWidth: 30 },
        3: { cellWidth: 40 },
        4: { cellWidth: 25 },
        6: { cellWidth: 25 },
        7: { cellWidth: 45 },
      };
      if (!this.anonymize) {
        columnStyles = {
          0: { cellWidth: 20 },
          1: { cellWidth: 20 },
          2: { cellWidth: 35 },
          3: { cellWidth: 30 },
          5: { cellWidth: 25 },
          6: { cellWidth: 55 },
        };
      }

      doc.text(text, 15, 20);
      doc.autoTable({
        head: [head],
        body: body,
        startY: 25,
        styles: { overflow: "linebreak", cellWidth: "auto" },
        columnStyles: columnStyles,
        didParseCell: function (data) {
          data.cell.styles.textColor = "#000000";
          data.cell.styles.fillColor = "#FAFAFA";
          if (data.row.section === "head") {
            data.cell.styles.fillColor = "#BEBEBE";
          } else if (data.row.raw[0] === "No") {
            data.cell.styles.fillColor = "#FFDCE3";
          }
        },
      });
      doc.save(
        "uploaded_dicom_files_" + moment().format("DD-MM-YY_hh-mm-ss") + ".pdf"
      );
      this.successMessage = "PDF with patient information downloaded";
    },
    confirmLeave: function () {
      if (
        this.currentStatus === STATUS_PROCESSING ||
        this.currentStatus === STATUS_SAVING ||
        this.currentStatus === STATUS_READYFORUPLOAD
      ) {
        event.preventDefault();
        event.returnValue = "";
      }
    },
    collectErrors: function (error, chunkIndex = -1) {
      if (!this.errorsAndInfos[chunkIndex]) {
        this.errorsAndInfos[chunkIndex] = {
          errors: {},
          infos: {},
        };
      }
      const errorString = error.toString().trim();
      if (
        errorString.includes("dicomParser.readPart10Header") ||
        errorString.includes("WrongSamplePerPixel") ||
        errorString.includes("WrongImageType")
      ) {
        if (!this.errorsAndInfos[chunkIndex].infos[errorString]) {
          this.errorsAndInfos[chunkIndex].infos[errorString] = 0;
        }
        this.errorsAndInfos[chunkIndex].infos[errorString] += 1;
      } else {
        if (!this.errorsAndInfos[chunkIndex].errors[errorString]) {
          this.errorsAndInfos[chunkIndex].errors[errorString] = 0;
        }
        this.errorsAndInfos[chunkIndex].errors[errorString] += 1;
      }
    },
    getDefaultVR(tag) {
      const VRDict = {
        x00020010: "UI",
        x00100010: "PN",
        x0020000d: "UI",
        x00081030: "LO",
        x00101010: "AS",
        x00100040: "CS",
        x00081080: "LO",
        x00080020: "DA",
        x00080016: "UI",
        x0020000e: "UI",
        x0008103e: "LO",
        x00280010: "US",
        x00280011: "US",
        x00180022: "CS",
        x00200052: "UI",
        x00281052: "DS",
        x00280030: "DS",
        x00280100: "US",
        x00280101: "US",
        x00280102: "US",
        x00280002: "US",
        x00080008: "CS",
        x00200032: "DS",
        x00200037: "DS",
        x00180050: "DS",
        x00280103: "US",
        x00281053: "DS",
        x00281051: "DS",
        x00281050: "DS",
        x00100030: "DA",
        x00080033: "TM",
      };
      return VRDict[tag] ? VRDict[tag] : "SH";
    },
  },
};
</script>

<style scoped>
.dropbox {
  box-sizing: border-box;
  position: relative;
  outline: 2px dashed grey;
  border-radius: 5px;
  background: rgb(231, 226, 226);
  height: 100%;
  cursor: pointer;
}

.input-file {
  margin-left: -10px;
  margin-top: -10px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0 !important;
  cursor: pointer;
  color: transparent !important;
}

.additional-upload > span > input {
  display: none;
}

.additional-upload i {
  width: 80px;
  cursor: pointer;
}

.dropbox:hover {
  background: lightgrey;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.modal {
  overflow-y: initial !important;
}

.modal-card {
  width: 90%;
  height: 75%;
  overflow-y: auto;
}

.background-tinted {
  background: rgb(248, 242, 242);
}

.no-border {
  border: 0 !important;
}

.success-symbol {
  display: inline-block;
  clear: both;
  font-size: 208px;
  white-space: nowrap;
  text-align: center;
  position: relative;
  padding: 0px;
  top: 0px;
}

.warning-symbol {
  display: inline-block;
  clear: both;
  font-size: 150px;
  white-space: nowrap;
  text-align: center;
  position: relative;
  padding: 0px;
  left: 45%;
  top: 0px;
}
</style>
