<template>
  <div
    id="BrowseView"
    v-if="isBrowseView"
    class="margin-left-right-15 margin-top-20 has-text-almost-black"
  >
    <ConfirmDeleteModal
      :componentKey="confirmDeleteComponentKey"
      :isConfirmDeleteModalSeen="isConfirmDeleteModalSeen"
      :studyInfo="toDeletePatientInfo"
      v-on:cancelDelete="cancelDeletePatient"
    ></ConfirmDeleteModal>

    <ConfirmRerunDpModal
      :key="confirmRerunDpComponentKey"
      :isConfirmRerunDpSeen="isConfirmRerunDpSeen"
      :toRerunDp="toRerunDp"
      v-on:cancelRerunDp="cancelRerunDp"
    ></ConfirmRerunDpModal>

    <div class="has-fixed-header">
      <div id="fixedHeaderContent" class="fixed-header-content">
        <div class="columns is-mobile">
          <div class="column is-narrow">
            <h5 class="padding-tb-5 subtitle is-2 has-text-almost-black">
              <span class="margin-all-15 has-text-dark-primary">
                Case Overview
              </span>
            </h5>
          </div>
          <div class="column is-two-quarters">
            <a
              :disabled="isUploadInProgress"
              @click="isUploadInProgress ? '' : toggleUploadModal()"
              class="
                button
                margin-top-10
                has-border-none has-background-darker-grey
              "
              :class="isUploadInProgress ? 'tooltip is-tooltip-bottom' : ''"
              :data-tooltip="isUploadInProgress ? 'Upload in progress' : ''"
            >
              <span
                class="
                  has-text-white has-text-dark-primary has-text-weight-bold
                  is-size-7
                "
                id="uploadButton"
              >
                UPLOAD CT DATA
              </span>
            </a>
          </div>
          <div class="column">
            <div class="field margin-top-10">
              <div class="control has-icons-right">
                <input
                  class="input is-size-14 noborder has-background-light-grey"
                  type="text"
                  v-model="searchString"
                  placeholder="Search for Names, Dates, Notes..."
                />
                <span
                  class="
                    icon
                    has-border-radius-5
                    is-right
                    has-background-darker-grey
                  "
                >
                  <i class="fas fa-search has-text-white"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- Header -->
        <div id="header">
          <div class="columns pointer has-text-left padding-rl-15 is-multiline">
            <div id="uploadSort" class="column" @click="sortStudies(UPLOAD)">
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === UPLOAD
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >UPLOAD DATE
              </span>
              <i
                :class="[
                  sortKey === UPLOAD
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === UPLOAD && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
            <div
              id="caseIdSort"
              class="column is-1"
              @click="sortStudies(CASEID)"
            >
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === CASEID
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >CASE ID
              </span>
              <i
                :class="[
                  sortKey === CASEID
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === CASEID && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
            <div id="nameSort" class="column" @click="sortStudies(NAME)">
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === NAME
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >NAME
              </span>
              <i
                :class="[
                  sortKey === NAME
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === NAME && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
            <div
              id="birthdateSort"
              class="column"
              @click="sortStudies(BIRTHDATE)"
            >
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === BIRTHDATE
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >BIRTH DATE
              </span>
              <i
                :class="[
                  sortKey === BIRTHDATE
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === BIRTHDATE && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
            <div id="sexSort" class="column is-1" @click="sortStudies(SEX)">
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === SEX
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >SEX
              </span>
              <i
                :class="[
                  sortKey === SEX
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === SEX && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
            <div
              id="descriptionSort"
              class="column is-3"
              @click="sortStudies(DESCRIPTION)"
            >
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === DESCRIPTION
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >DESCRIPTION
              </span>
              <i
                :class="[
                  sortKey === DESCRIPTION
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === DESCRIPTION && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
            <div
              id="studyDateSort"
              class="column"
              @click="sortStudies(STUDYDATE)"
            >
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === STUDYDATE
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >STUDY DATE
              </span>
              <i
                :class="[
                  sortKey === STUDYDATE
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === STUDYDATE && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
            <div
              id="caseProtocolSort"
              class="column"
              @click="sortStudies(CASEPROTOCOL)"
            >
              <span
                class="subtitle is-size-14 has-text-weight-bold is-column-name"
                :class="
                  sortKey === CASEPROTOCOL
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font'
                "
                >CASE PROTOCOL
              </span>
              <i
                :class="[
                  sortKey === CASEPROTOCOL
                    ? 'has-text-almost-black has-text-dark-primary'
                    : 'has-text-mid-grey has-text-dark-font',
                  sortKey === CASEPROTOCOL && sortAsc
                    ? 'fa-caret-up'
                    : 'fas fa-caret-down',
                  'fas',
                ]"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
        <!-- End of Header -->
      </div>
    </div>

    <div class="margin-top-110 has-fixed-header-content">
      <div id="patientList" class="padding-rl-15">
        <div v-for="(studyInfo, index) in filteredList" :key="index">
          <div
            class="
              card
              margin-top-20
              has-hover-background-light
              has-background-light-grey
              has-background-dark-card
            "
            :class="studyInfo.canStartCase ? 'pointer' : 'cursor-not-allowed'"
            @click="studyInfo.canStartCase ? startCase(index) : ''"
            @mouseover="hoverOn(index)"
            @mouseleave="hoverOff(index)"
          >
            <div
              class="vertical-progressbar"
              :style="
                studyInfo.canStartCase
                  ? ``
                  : `background: linear-gradient(0deg, #c4c4c4 ${
                      studyInfo.overallDPProgress
                    }%, #efefef ${studyInfo.overallDPProgress}% ${
                      100 - studyInfo.overallDPProgress
                    }%)`
              "
              :class="
                studyInfo.canStartCase
                  ? !studyInfo.hasValidPhaseSets
                    ? 'has-error'
                    : studyInfo.finalized
                    ? 'final'
                    : studyInfo.caseProtocol
                    ? 'planning-ongoing'
                    : 'case-ready'
                  : ''
              "
            ></div>
            <span
              v-if="studyInfo.isNew"
              id="NewBadge"
              class="
                has-badge-rounded has-badge-outlined has-badge-darker-grey
                margin-left-5
              "
              data-badge="New"
            ></span>
            <!-- Basic Patient Info -->
            <div class="padding-rl-15">
              <div class="studyHeader">
                <span>
                  <span
                    :id="'caseStatus_' + studyInfo.caseId"
                    class="has-text-grey is-size-14"
                    v-if="!studyInfo.canStartCase"
                    >Deep analysis ongoing
                  </span>
                  <span
                    :id="'caseStatus_' + studyInfo.caseId"
                    class="has-text-grey is-size-14 has-text-right"
                    v-if="
                      studyInfo.canStartCase &&
                      studyInfo.hasValidPhaseSets &&
                      !studyInfo.caseProtocol
                    "
                    >Ready for planning
                  </span>
                  <span
                    :id="'caseStatus_' + studyInfo.caseId"
                    class="has-text-grey is-size-14"
                    v-if="
                      studyInfo.canStartCase &&
                      studyInfo.caseProtocol &&
                      !studyInfo.finalized
                    "
                    >Planning ongoing
                  </span>
                  <span
                    :id="'caseStatus_' + studyInfo.caseId"
                    class="has-text-grey is-size-14"
                    v-if="
                      studyInfo.canStartCase &&
                      studyInfo.caseProtocol &&
                      studyInfo.finalized
                    "
                    >Finalized
                  </span>
                  <span
                    :id="'caseStatus_' + studyInfo.caseId"
                    class="has-text-grey is-size-14"
                    v-if="
                      studyInfo.canStartCase && !studyInfo.hasValidPhaseSets
                    "
                    >No valid CT series available
                  </span>
                </span>

                <span
                  :id="'controlButtons_' + studyInfo.caseId"
                  class="is-pulled-right pt-1 pointer"
                >
                  <div
                    :id="'showControls_' + studyInfo.caseId"
                    v-if="!studyInfo.showControls"
                    @click.stop="toggleControls(index)"
                  >
                    <span class="fa-stack is-size-7">
                      <i class="fas fa-circle fa-stack-2x icon-mid-grey" />
                      <i class="fas fa-ellipsis-h fa-stack-1x fa-inverse" />
                    </span>
                  </div>
                  <div
                    v-else
                    :id="'extendedControls_' + studyInfo.caseId"
                    class="is-relative"
                  >
                    <div
                      class="
                        is-absolute
                        controls-container
                        has-background-darker-grey has-text-weight-bold
                        is-size-14
                      "
                    >
                      <span
                        :id="'showDetails_' + studyInfo.caseId"
                        class="has-text-white ml-5 mr-5"
                        @click.stop="
                          !studyInfo.isOpen
                            ? getPhasesFromServer(studyInfo.caseId)
                            : togglePatientIsOpen(index)
                        "
                      >
                        <i class="fas fa-info-circle has-text-white" />
                        DETAILS
                      </span>
                      <span
                        :id="'deleteButton_' + studyInfo.caseId"
                        class="has-text-white margin-right-10"
                        @click.stop="confirmDeletePatient(index)"
                      >
                        <i class="far fa-trash-alt has-text-white" />
                        DELETE
                      </span>
                    </div>
                    <span
                      :id="'hideControls_' + studyInfo.caseId"
                      class="fa-stack is-size-7"
                      @click.stop="toggleControls(index)"
                    >
                      <i class="fas fa-circle fa-stack-2x icon-mid-grey" />
                      <i class="fas fa-times fa-stack-1x fa-inverse" />
                    </span>
                  </div>
                </span>
              </div>
              <div
                class="columns has-text-left is-multiline is-size-6 clear-both"
                :id="'patientInfo_' + studyInfo.caseId"
              >
                <div
                  class="column pt-0"
                  :id="'patientInfo_uploadDate_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.formattedUploadDate }}
                  </span>
                </div>
                <div
                  class="column is-1 pt-0"
                  :id="'patientInfo_caseId_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.caseId }}
                  </span>
                </div>
                <div
                  class="column word-break pt-0"
                  :id="'patientInfo_name_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.name }}
                  </span>
                </div>
                <div
                  class="column pt-0"
                  :id="'patientInfo_birthDate_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.formattedBirthDate }} ({{
                      studyInfo.patientAge
                    }})
                  </span>
                </div>
                <div
                  class="column is-1 pt-0"
                  :id="'patientInfo_patientSex_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.patientSex }}
                  </span>
                </div>
                <div
                  class="column is-3 word-break pt-0"
                  :id="'patientInfo_studyDesc_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.studyDesc }}
                  </span>
                </div>
                <div
                  class="column pt-0"
                  :id="'patientInfo_studyDate_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.formattedStudyDate }}
                  </span>
                </div>
                <div
                  class="column pt-0"
                  :id="'patientInfo_caseProtocol_' + studyInfo.caseId"
                >
                  <span :class="studyInfo.canStartCase ? '' : 'has-text-grey'">
                    {{ studyInfo.caseProtocol }}
                  </span>
                </div>
              </div>
            </div>
            <!-- End of Basic Patient Info -->

            <!-- Phase Info -->
            <div
              :id="'phaseInfo_' + studyInfo.caseId"
              v-if="studyInfo.isOpen"
              class="has-background-light-mid-grey has-border-radius-5"
            >
              <hr />
              <div class="columns has-text-left padding-rl-30 is-multiline">
                <div v-if="!production" class="column is-1"></div>
                <div class="column">
                  <span
                    class="
                      subtitle
                      is-7
                      has-text-weight-bold has-text-mid-grey has-text-dark-font
                      is-column-name
                    "
                    >ID</span
                  >
                </div>
                <div class="column">
                  <span
                    class="
                      subtitle
                      is-7
                      has-text-weight-bold has-text-mid-grey has-text-dark-font
                      is-column-name
                    "
                    >AUTOMATIC ANALYSIS
                  </span>
                </div>
                <div
                  class="column is-two-fifth"
                  @click.stop="sortPhases(index, SERIESDESCRIPTION)"
                >
                  <span
                    class="subtitle is-7 has-text-weight-bold is-column-name"
                    :class="
                      secondarySortKey === SERIESDESCRIPTION
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font'
                    "
                    >DESCRIPTION
                  </span>
                  <i
                    :class="[
                      secondarySortKey === SERIESDESCRIPTION
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font',
                      secondarySortKey === SERIESDESCRIPTION && sortSecondaryAsc
                        ? 'fa-caret-up'
                        : 'fas fa-caret-down',
                      'fas',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
                <div class="column is-1" @click.stop="sortPhases(index, PHASE)">
                  <span
                    class="subtitle is-7 has-text-weight-bold is-column-name"
                    :class="
                      secondarySortKey === PHASE
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font'
                    "
                    >PHASE
                  </span>
                  <i
                    :class="[
                      secondarySortKey === PHASE
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font',
                      secondarySortKey === PHASE && sortSecondaryAsc
                        ? 'fa-caret-up'
                        : 'fas fa-caret-down',
                      'fas',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
                <div class="column" @click.stop="sortPhases(index, PIXEL)">
                  <span
                    class="subtitle is-7 has-text-weight-bold is-column-name"
                    :class="
                      secondarySortKey === PIXEL
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font'
                    "
                    >PIXEL
                  </span>
                  <i
                    :class="[
                      secondarySortKey === PIXEL
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font',
                      secondarySortKey === PIXEL && sortSecondaryAsc
                        ? 'fa-caret-up'
                        : 'fas fa-caret-down',
                      'fas',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
                <div class="column" @click.stop="sortPhases(index, IMAGES)">
                  <span
                    class="subtitle is-7 has-text-weight-bold is-column-name"
                    :class="
                      secondarySortKey === IMAGES
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font'
                    "
                    >IMAGES
                  </span>
                  <i
                    :class="[
                      secondarySortKey === IMAGES
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font',
                      secondarySortKey === IMAGES && sortSecondaryAsc
                        ? 'fa-caret-up'
                        : 'fas fa-caret-down',
                      'fas',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  class="column"
                  @click.stop="sortPhases(index, FPIXELSPACING)"
                >
                  <span
                    class="subtitle is-7 has-text-weight-bold is-column-name"
                    :class="
                      secondarySortKey === FPIXELSPACING
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font'
                    "
                    >PIXEL SPACING
                  </span>
                  <i
                    :class="[
                      secondarySortKey === FPIXELSPACING
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font',
                      secondarySortKey === FPIXELSPACING && sortSecondaryAsc
                        ? 'fa-caret-up'
                        : 'fas fa-caret-down',
                      'fas',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
                <div
                  class="column"
                  @click.stop="sortPhases(index, SLICETHICKNESS)"
                >
                  <span
                    class="subtitle is-7 has-text-weight-bold is-column-name"
                    :class="
                      secondarySortKey === SLICETHICKNESS
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font'
                    "
                    >SLICE THICKNESS
                  </span>
                  <i
                    :class="[
                      secondarySortKey === SLICETHICKNESS
                        ? 'has-text-almost-black has-text-dark-primary'
                        : 'has-text-mid-grey has-text-dark-font',
                      secondarySortKey === SLICETHICKNESS && sortSecondaryAsc
                        ? 'fa-caret-up'
                        : 'fas fa-caret-down',
                      'fas',
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div
                v-for="(phaseSetValue, phaseSet) in studyInfo.phaseSet"
                :key="phaseSet"
              >
                <div
                  v-for="(phaseInfo, index) in phaseSetValue.phases"
                  :key="index"
                >
                  <div
                    class="
                      columns
                      cursor-default
                      has-text-left
                      padding-rl-30
                      is-multiline
                    "
                  >
                    <div v-if="!production" class="column is-1">
                      <i
                        v-if="
                          index == 0 && isValid(phaseSetValue.phaseSetStatus)
                        "
                        class="fas fa-redo pointer"
                        @click.stop="confirmRerunDp(studyInfo.caseId, phaseSet)"
                      >
                      </i>
                    </div>
                    <div class="column">
                      <span
                        v-if="index == 0"
                        class="
                          tooltip
                          is-tooltip-multiline
                          is-tooltip-top
                          is-tooltip-shifted-right
                        "
                        :data-tooltip="
                          isInvalid(phaseSetValue.phaseSetStatus)
                            ? 'Phases do not satisfy Laralab constraints for CT processing'
                            : isIncomplete(phaseSetValue.phaseSetStatus)
                            ? 'Phases have missing slices but satisfy Laralab constraints for CT processing'
                            : 'Phases satisfy Laralab constraints for CT processing'
                        "
                        >{{ phaseSet }}
                        <i
                          v-if="isInvalid(phaseSetValue.phaseSetStatus)"
                          class="fas fa-times-circle"
                        ></i>
                        <i
                          v-else-if="isIncomplete(phaseSetValue.phaseSetStatus)"
                          class="fas fa-exclamation-circle"
                        >
                        </i>
                        <i
                          v-else-if="isComplete(phaseSetValue.phaseSetStatus)"
                          class="fas fa-check-circle"
                        >
                        </i>
                      </span>
                    </div>
                    <div class="column has-text-centered">
                      <span
                        v-if="index == 0"
                        class="tooltip is-tooltip-top"
                        :data-tooltip="
                          isInProgress(phaseSetValue.irStatus)
                            ? 'IN PROGRESS'
                            : isReady(phaseSetValue.irStatus)
                            ? 'READY'
                            : 'FAILED'
                        "
                      >
                        <i
                          v-if="isFailed(phaseSetValue.irStatus)"
                          class="fas fa-times-circle has-text-centered"
                        ></i>
                        <span v-else-if="isInProgress(phaseSetValue.irStatus)">
                          <div class="columns">
                            <div class="column">
                              <progress
                                id="progressBar"
                                class="progress is-radiusless"
                                :value="phaseSetValue.progress"
                                max="100"
                              ></progress>
                            </div>
                            <div class="column is-narrow">
                              <span
                                id="progressValue"
                                class="has-text-darker-grey"
                                >{{ phaseSetValue.progress }}%</span
                              >
                            </div>
                          </div>
                        </span>
                        <i
                          v-else-if="isReady(phaseSetValue.irStatus)"
                          class="fas fa-check-circle"
                        >
                        </i>
                      </span>
                    </div>
                    <div class="column is-two-fifth word-break">
                      <span>{{ phaseInfo.seriesDescription }}</span>
                    </div>
                    <div class="column is-1">
                      <span>
                        {{ phaseInfo.phase }}%
                        <br />
                      </span>
                    </div>
                    <div class="column">
                      <span>{{ phaseInfo.pixel }}</span>
                    </div>
                    <div class="column">
                      <span>{{ phaseInfo.sliceNr }}</span>
                    </div>
                    <div class="column">
                      <span
                        class="tooltip is-tooltip-top"
                        :data-tooltip="
                          formatPixelSpacing(phaseInfo.pixelSpacing)
                        "
                      >
                        {{ phaseInfo.fPixelSpacing }}
                        <br />
                      </span>
                    </div>
                    <div class="column">
                      <span>
                        {{ formatSliceThickness(phaseInfo.sliceThickness) }}
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of Phase Info -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmDeleteModal from "@/components/ConfirmDelete.vue";
import ConfirmRerunDpModal from "@/components/ConfirmRerun.vue";

import moment from "moment";
import _ from "lodash";
import axios from "axios";
import * as constants from "@/constants";
import * as helper from "@/js/helper.js";
import { sendErrors } from "@/js/errorHandler.js";

const cases = require("../resources/usergroups.json");

const IN_PROGRESS = 0;
const READY = 1;

export default {
  name: "BrowseView",
  props: {
    isBrowseView: Boolean,
    user: Object,
    socket: Object,
  },
  data() {
    return {
      UPLOAD: "uploadDateTimeStamp",
      CASEID: "caseId",
      NAME: "name",
      BIRTHDATE: "patientDob",
      SEX: "patientSex",
      DESCRIPTION: "studyDesc",
      STUDYDATE: "studyDate",
      CASEPROTOCOL: "caseProtocol",
      SERIESDESCRIPTION: "seriesDescription",
      PHASE: "phase",
      PIXEL: "pixel",
      IMAGES: "sliceNr",
      FPIXELSPACING: "fPixelSpacing",
      SLICETHICKNESS: "sliceThickness",
      confirmDeleteComponentKey: 50,
      confirmRerunDpComponentKey: 100,
      isConfirmDeleteModalSeen: false,
      isConfirmRerunDpSeen: false,
      selectedCase: "Heart Segmentation",
      selectedPatientInfo: [],
      toDeletePatientInfo: {},
      toRerunDp: [],
      allPatientInfo: [],
      studyUIDs: [],
      uploadedStudyUIDs: [],
      cases: cases,
      selectedPatient: "",
      searchString: "",
      sortKey: "",
      secondarySortKey: "",
      sortAsc: true,
      sortSecondaryAsc: true,
      isRerun: false,
      isRefetch: false,
      production: this.$store.getters.currentMode === constants.MODE_PRODUCTION,
    };
  },
  computed: {
    filteredList: function () {
      const searchString = this.searchString.toLowerCase();
      return _.pickBy(this.allPatientInfo, function (value) {
        return (
          value.formattedUploadDate.toLowerCase().includes(searchString) ||
          value.caseId.toLowerCase().includes(searchString) ||
          value.name.toLowerCase().includes(searchString) ||
          value.formattedBirthDate.toLowerCase().includes(searchString) ||
          value.patientAge.toLowerCase().includes(searchString) ||
          value.patientSex.toLowerCase().includes(searchString) ||
          value.studyDesc.toLowerCase().includes(searchString) ||
          value.formattedStudyDate.toLowerCase().includes(searchString) ||
          (value.caseProtocol &&
            value.caseProtocol.toLowerCase().includes(searchString))
        );
      });
    },
    isUploadInProgress() {
      return this.$store.getters.isUploadInProgress;
    },
  },
  components: {
    ConfirmDeleteModal,
    ConfirmRerunDpModal,
  },
  beforeMount() {
    this.getPatientListFromServer().catch((error) => {
      sendErrors(error, "Browse:getPatientListFromServer()");
    });
    this.setSocketConnection();
  },

  beforeDestroy() {
    // window.removeEventListener("click", this.close);
  },
  methods: {
    formatDate: helper.formatDate,
    formatPixelSpacing: helper.formatPixelSpacing,
    formatSliceThickness: helper.formatSliceThickness,
    roundOffAndFormatPixelSpacing: helper.roundOffAndFormatPixelSpacing,
    reset: function () {
      this.allPatientInfo = [];
      this.searchString = "";
      this.sortKey = "";
      this.secondarySortKey = "";
      this.sortAsc = true;
      this.sortSecondaryAsc = true;
    },
    toggleUploadModal: function () {
      this.$emit("toggleUploadModal");
    },
    confirmDeletePatient: function (studyIndex) {
      // Increment componentKey to force remount
      this.confirmDeleteComponentKey++;
      this.toDeletePatientInfo = _.cloneDeep(this.allPatientInfo[studyIndex]);
      this.isConfirmDeleteModalSeen = true;
    },
    cancelDeletePatient: function () {
      this.isConfirmDeleteModalSeen = false;
      this.toDeletePatientInfo = {};
    },
    confirmRerunDp: function (caseId, phaseSetId) {
      // Increment componentKey to force remount
      this.confirmRerunDpComponentKey++;
      this.toRerunDp.push([caseId, phaseSetId]);
      this.isConfirmRerunDpSeen = true;
      this.isRerun = true;
    },
    cancelRerunDp: function () {
      this.isConfirmRerunDpSeen = false;
      this.isRerun = false;
      this.toRerunDp = [];
    },
    startCase: function (index) {
      if (this.allPatientInfo[index].caseProtocol) {
        this.openReportBoard(index);
      } else {
        this.openGateway(index);
      }
    },
    openGateway: function (index) {
      this.$emit("showGateway", this.allPatientInfo[index].caseId);
    },
    openReportBoard: function (index) {
      const studyInfo = this.allPatientInfo[index];
      this.$router.push("/report/" + studyInfo.caseId);
    },
    setSocketConnection: function () {
      const self = this;

      this.socket.on("ChunkUploaded", function (data) {
        // console.log("Upload Chunk Complete", data);
      });

      this.socket.on("StudyUploaded", async function (data) {
        if (data.status === 200) {
          self.isRefetch = true;
          self
            .getPatientListFromServer()
            .then(() => {
              const study = _.find(self.allPatientInfo, function (o) {
                return o.caseId === data.case_id;
              });
              self.$nextTick(() => {
                study.overallDPProgress = 0;
              });
            })
            .catch((error) => {
              sendErrors(error, "Browse:getPatientListFromServer()");
            });
        }
      });

      this.socket.on("DpStarted", function (data) {
        const study = _.find(self.allPatientInfo, function (o) {
          return o.caseId === data.case_id;
        });
        if (study && !study.phaseSet[data.phase_set_id]) {
          self.$set(study.phaseSet, data.phase_set_id, {
            irStatus: undefined,
            progress: 0,
            phases: [],
          });
        }
        if (study && study.phaseSet[data.phase_set_id]) {
          study.phaseSet[data.phase_set_id].irStatus = data.status;
          study.phaseSet[data.phase_set_id].progress = "0";
          study.canStartCase = data.can_start_case;
          self.updateProgressBar(study);
        }
        self.$forceUpdate();
      });

      this.socket.on("DpProgressUpdated", function (data) {
        const study = _.find(self.allPatientInfo, function (o) {
          return o.caseId === data.case_id;
        });
        if (study && !study.phaseSet[data.phase_set_id]) {
          self.$set(study.phaseSet, data.phase_set_id, {
            irStatus: undefined,
            progress: 0,
            phases: [],
          });
        }
        if (
          self.isRerun ||
          !self.isReady(study.phaseSet[data.phase_set_id].irStatus)
        ) {
          study.phaseSet[data.phase_set_id].irStatus = data.status;
          study.phaseSet[data.phase_set_id].progress = data.progress_percent;
          study.canStartCase = data.can_start_case;
          self.updateProgressBar(study);
          self.$forceUpdate();
        }
      });

      this.socket.on("DpCompleted", function (data) {
        const study = _.find(self.allPatientInfo, function (o) {
          return o.caseId === data.case_id;
        });
        if (study && !study.phaseSet[data.phase_set_id]) {
          self.$set(study.phaseSet, data.phase_set_id, {
            irStatus: undefined,
            progress: 0,
            phases: [],
          });
        }
        if (study.phaseSet[data.phase_set_id]) {
          study.phaseSet[data.phase_set_id].irStatus = data.status;
          study.phaseSet[data.phase_set_id].progress = "100";
          study.canStartCase = data.can_start_case;
        }
        self.$forceUpdate();
        self.getPatientListFromServer().catch((error) => {
          sendErrors(error, "Browse:getPatientListFromServer()");
        });
      });

      this.socket.on("DpFailed", function (data) {
        const study = _.find(self.allPatientInfo, function (o) {
          return o.caseId === data.case_id;
        });
        if (study.phaseSet[data.phase_set_id]) {
          self.$nextTick(() => {
            study.phaseSet[data.phase_set_id].irStatus = data.status;
            study.phaseSet[data.phase_set_id].progress = "";
            study.canStartCase = data.can_start_case;
          });
        }
      });

      this.socket.on("CaseStarted", function (data) {
        const study = _.find(self.allPatientInfo, function (o) {
          return o.caseId === data.case_id;
        });
        if (study !== undefined) {
          study.caseProtocol = data.protocol_name;
          study.cardiacPhaseSet = data.cardiac_phase_set_id;
          study.gitRef = data.git_ref;
        }
      });

      this.socket.on("CaseDeleted", function (data) {
        const study = _.find(self.allPatientInfo, function (o) {
          return o.caseId === data.case_id;
        });
        if (study !== undefined) {
          study.caseProtocol = undefined;
          study.cardiacPhaseSet = undefined;
          study.gitRef = undefined;
        }
        // TODO: we can also think of kicking of all the users
        // from Rep Board in this case.
      });
    },
    updateProgressBar(study) {
      let countPhases = 0;
      let addProgress = 0;
      _.forOwn(study.phaseSet, (value) => {
        if ([IN_PROGRESS, READY].includes(value.irStatus)) {
          countPhases++;
          addProgress += parseInt(value.progress);
        }
      });
      study.overallDPProgress = addProgress / countPhases;
    },
    getPatientListFromServer: function () {
      return new Promise((resolve, reject) => {
        const path = "/api/browse";
        axios
          .get(path)
          .then((response) => {
            _.forEach(response.data.studyInfo, (studyInfo) => {
              const studyUID = studyInfo.studyUID;
              const existingStudy = _.find(this.allPatientInfo, function (o) {
                return o.caseId === studyInfo.caseId;
              });
              if (existingStudy === undefined) {
                const study = {
                  studyUID: studyUID,
                  uploadDate: new Date(studyInfo.uploadDate),
                  formattedUploadDate: moment(
                    new Date(studyInfo.uploadDate)
                  ).format("DD-MMM-YYYY HH:mm"),
                  uploadDateTimeStamp: new Date(studyInfo.uploadDate).getTime(),
                  caseId: studyInfo.caseId,
                  name: studyInfo.name,
                  patientDob: studyInfo.dob,
                  formattedBirthDate: this.formatDate(studyInfo.dob),
                  patientAge: studyInfo.age,
                  patientSex: studyInfo.sex,
                  studyDesc: studyInfo.description,
                  notes: studyInfo.notes,
                  studyDate: studyInfo.date,
                  formattedStudyDate: this.formatDate(studyInfo.date),
                  ownerEmail: studyInfo.ownerEmail,
                  canStartCase: studyInfo.canStartCase,
                  hasValidPhaseSets: studyInfo.hasValidPhaseSets,
                  finalized: studyInfo.finalized,
                  phasesPulledFromServer: false,
                  phaseSet: {},
                  showControls: false,
                  isOpen: false,
                  isNew: false,
                  hover: false,
                };
                if (studyInfo.protocolName !== undefined) {
                  study.caseProtocol = studyInfo.protocolName;
                  study.cardiacPhaseSet = studyInfo.cardiacPhaseSet;
                  study.accessRoutesPhaseSet = studyInfo.accessRoutesPhaseSet;
                  study.gitRef = studyInfo.gitRef;
                }

                if (this.isRefetch) {
                  study.isNew = true;
                }
                this.allPatientInfo.push(study);
              } else {
                this.$nextTick(() => {
                  // If the caseId already exists, pick the latest info
                  existingStudy.name = studyInfo.name;
                  existingStudy.patientDob = studyInfo.dob;
                  existingStudy.formattedBirthDate = this.formatDate(
                    studyInfo.dob
                  );
                  existingStudy.patientAge = studyInfo.age;
                  existingStudy.studyDesc = studyInfo.description;
                  existingStudy.notes = studyInfo.notes;
                  existingStudy.studyDate = studyInfo.date;
                  existingStudy.formattedStudyDate = this.formatDate(
                    studyInfo.date
                  );
                  existingStudy.ownerEmail = studyInfo.ownerEmail;
                  existingStudy.uploadDate = new Date(studyInfo.uploadDate);
                  existingStudy.formattedUploadDate = moment(
                    studyInfo.uploadDate
                  ).format("DD-MMM-YYYY HH:mm");
                  existingStudy.uploadDateTimeStamp = new Date(
                    studyInfo.uploadDate
                  ).getTime();
                  existingStudy.caseProtocol = studyInfo.protocolName;
                  existingStudy.gitRef = studyInfo.gitRef;
                  existingStudy.canStartCase = studyInfo.canStartCase;
                  existingStudy.hasValidPhaseSets = studyInfo.hasValidPhaseSets;
                  existingStudy.finalized = studyInfo.finalized;
                  // Sort the list
                  this.sortStudies(this.UPLOAD, "desc");
                });
              }
            });
            // The list is sorted by the newest first by default
            this.sortStudies(this.UPLOAD, "desc");
            this.isRefetch = false;
            // console.log("AllPatientInfo", this.allPatientInfo);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getPhasesFromServer(caseID, toggle = true) {
      return new Promise((resolve, reject) => {
        let studyIndex = _.findIndex(this.allPatientInfo, function (o) {
          return o.caseId === caseID;
        });
        if (studyIndex === -1) {
          return;
        }

        if (toggle) {
          this.togglePatientIsOpen(studyIndex);
        }
        // Only call the api if the phases have not been pulled already
        if (!this.allPatientInfo[studyIndex].phasesPulledFromServer) {
          const path = "/api/phases";
          axios
            .post(path, {
              CaseID: caseID,
            })
            .then((response) => {
              _.forEach(
                response.data.allPhaseInfo,
                (phaseSetValue, phaseSet) => {
                  if (!this.allPatientInfo[studyIndex].phaseSet[phaseSet]) {
                    this.$set(
                      this.allPatientInfo[studyIndex].phaseSet,
                      phaseSet,
                      {
                        phaseSetStatus: undefined,
                        irStatus: undefined,
                        progress: 0,
                        phases: [],
                      }
                    );
                  }

                  const phaseSetInformation =
                    this.allPatientInfo[studyIndex].phaseSet[phaseSet];
                  if (phaseSetInformation) {
                    phaseSetInformation.phaseSetStatus =
                      phaseSetValue.phase_set_status;
                    phaseSetInformation.irStatus =
                      phaseSetValue.automatic_analysis_status;
                    phaseSetInformation.progress =
                      phaseSetValue.automatic_analysis_progress;
                  }

                  _.forEach(phaseSetValue.phases, (phaseSetInfo) => {
                    const seriesUID = phaseSetInfo.seriesUID;
                    let pvalue = phaseSetInfo.pvalue;
                    if (typeof phaseSetInfo.pvalue !== "number") {
                      pvalue = parseInt(phaseSetInfo.pvalue.replace("%", ""));
                    }
                    // To make sure we have the right study
                    studyIndex = _.findIndex(this.allPatientInfo, function (o) {
                      return o.caseId === caseID;
                    });

                    const existingPhase = _.find(
                      this.allPatientInfo[studyIndex].phaseSet[phaseSet].phases,
                      function (o) {
                        return o.seriesUID === seriesUID && o.phase === pvalue;
                      }
                    );

                    if (existingPhase === undefined) {
                      const phase = {
                        seriesUID: seriesUID,
                        seriesDescription: phaseSetInfo.seriesDescription,
                        pid: phaseSetInfo.pid,
                        phase: pvalue,
                        pixel: phaseSetInfo.rows + " x " + phaseSetInfo.columns,
                        sliceNr: phaseSetInfo.sliceNr,
                        pixelSpacing: phaseSetInfo.pixelSpacing,
                        fPixelSpacing: this.roundOffAndFormatPixelSpacing(
                          phaseSetInfo.pixelSpacing
                        ),
                        sliceThickness: phaseSetInfo.sliceThickness,
                        isSelected: true,
                      };
                      this.allPatientInfo[studyIndex].phaseSet[
                        phaseSet
                      ].phases.push(phase);
                    }
                  });
                }
              );
              // The list is sorted by the ascending order of phases by default
              this.sortPhases(studyIndex, this.PHASE, "asc");
              this.allPatientInfo[studyIndex].phasesPulledFromServer = true;
              resolve();
              // console.log("All Phase Info", this.allPatientInfo);
            })
            .catch((error) => {
              sendErrors(error, "Browse:getPhasesFromServer()");
              reject();
            });
        } else {
          resolve();
        }
      });
    },
    changeFinalStatus: function (caseID, status) {
      let studyIndex = _.findIndex(this.allPatientInfo, function (o) {
        return o.caseId === caseID;
      });
      if (studyIndex !== -1) {
        this.allPatientInfo[studyIndex].finalized = status;
      }
    },
    togglePatientIsOpen: function (studyIndex) {
      const clickedPatientInfo = this.allPatientInfo[studyIndex];
      if (
        this.selectedPatient !== "" &&
        this.selectedPatient !== clickedPatientInfo
      ) {
        this.selectedPatient.isOpen = false;
      }
      if (clickedPatientInfo.isOpen) {
        clickedPatientInfo.isOpen = false;
        this.selectedPatient = "";
      } else {
        clickedPatientInfo.isOpen = true;
        this.selectedPatient = clickedPatientInfo;
      }
    },
    toggleControls: function (studyIndex) {
      const studyInfo = this.allPatientInfo[studyIndex];
      if (studyInfo.isOpen) {
        this.togglePatientIsOpen(studyIndex);
      }
      studyInfo.showControls = !studyInfo.showControls;
    },
    sortStudies: function (key, order) {
      if (order === undefined) {
        // Sort by ascending order by default
        this.sortAsc = this.sortKey === key ? !this.sortAsc : true;
        if (this.sortAsc) {
          order = "asc";
        } else {
          order = "desc";
        }
      } else {
        if (order === "asc") {
          this.sortAsc = true;
        } else {
          this.sortAsc = false;
        }
      }
      this.sortKey = key;
      this.allPatientInfo = _.orderBy(this.allPatientInfo, [key], [order]);
    },
    sortPhases: function (studyIndex, key, order) {
      if (order === undefined) {
        // Sort by ascending order by default
        this.sortSecondaryAsc =
          this.secondarySortKey === key ? !this.sortSecondaryAsc : true;
        if (this.sortSecondaryAsc) {
          order = "asc";
        } else {
          order = "desc";
        }
      } else {
        if (order === "asc") {
          this.sortSecondaryAsc = true;
        } else {
          this.sortSecondaryAsc = false;
        }
      }
      this.secondarySortKey = key;
      _.forEach(this.allPatientInfo[studyIndex].phaseSet, (phaseSetValue) => {
        phaseSetValue.phases = _.orderBy(phaseSetValue.phases, [key], [order]);
      });
    },
    hoverOn: function (studyIndex) {
      const hoveredPatientInfo = this.allPatientInfo[studyIndex];
      hoveredPatientInfo.hover = true;
    },
    hoverOff: function (studyIndex) {
      const hoveredPatientInfo = this.allPatientInfo[studyIndex];
      hoveredPatientInfo.hover = false;
    },
    isInvalid: function (phaseSetStatus) {
      return phaseSetStatus <= 2;
    },
    isValid: function (phaseSetStatus) {
      return phaseSetStatus > 2;
    },
    isIncomplete: function (phaseSetStatus) {
      return phaseSetStatus === 3;
    },
    isComplete: function (phaseSetStatus) {
      return phaseSetStatus === 4;
    },
    isFailed: function (irStatus) {
      return irStatus === 2;
    },
    isInProgress: function (irStatus) {
      return irStatus === 0;
    },
    isReady: function (irStatus) {
      return irStatus === 1;
    },
  },
};
</script>

<style scoped>
.clear-both {
  clear: both;
}

.controls-container:not(body) {
  align-items: center;
  border-radius: 90px;
  display: inline-flex;
  width: 230px;
  margin-left: -204px;
  line-height: 24px;
}

.is-tooltip-shifted-right::before,
.is-tooltip-shifted-right:hover::before {
  transform: translate(-25px, -0.5rem) !important;
}

.vertical-progressbar {
  background-color: #efefef;
}
.vertical-progressbar.case-ready {
  background-color: #258bc4;
}

.vertical-progressbar.has-error {
  background-color: #bebebe;
}

.vertical-progressbar.planning-ongoing {
  background-color: #ffe500;
}

.vertical-progressbar.final {
  background-color: #0fb032;
}
</style>

