// @ts-ignore
import ActivityWorker from "worker-loader!./activity.worker.js";
import * as helper from "../helper.js";

export default function createActivityMonitor(apiURL) {
  const worker = new ActivityWorker();
  worker.postMessage({
    aTopic: "sendApiUrl",
    apiURL: apiURL,
    cookie: helper.parseCookie(document.cookie)
  });
  worker.addEventListener("message", message => {
    if (message.data.error) {
      document.dispatchEvent(new CustomEvent("connectionError"));
    }
  });

  return worker;
}
