<template>
  <AdminComponent />
</template>

<script>
// @ is an alias to /src
import AdminComponent from "@/components/Admin.vue";

export default {
  name: "Admin",
  components: {
    AdminComponent,
  },
};
</script>