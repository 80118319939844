<template>
  <div
    :id="'labelContainer_' + index"
    class="label-container has-width-100-per"
  >
    <div class="label-box tag is-white">
      <div
        :id="'labelText_' + index"
        class="has-line-height-20 is-size-7 has-text-black"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LabelWidget",
  props: {
    index: String,
  },
  methods: {},
};
</script>

<style scoped>
.label-container {
  position: absolute;
  bottom: 0;
  height: 75px;
  right: 0px;
  width: 50%;
  display: none;
}

.label-box {
  position: absolute;
  z-index: 50;
  opacity: 0.8;
  bottom: 0;
  transform: translate(-50%, -10px);
}
</style>

