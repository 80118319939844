/* eslint-disable */

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Overview from "../views/Overview.vue";
import CTPlanner from "../views/CTPlanner.vue";
import NotFound from "../views/NotFound.vue";
import Admin from "../views/Admin.vue";
import Account from "../views/Account.vue";
import About from "../views/About.vue";
import ResetPassword from "../views/ResetPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    props: true
  },
  {
    path: "/overview",
    name: "Overview",
    // route level code-splitting
    // this generates a separate chunk (upload.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Overview
  },
  {
    path: "/report/:caseId",
    name: "ReportBoard",
    component: Overview
  },
  {
    path: "/planner/:analysisId",
    name: "CTPlanner",
    component: CTPlanner,
    props: true
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin
  },
  {
    path: "/account",
    name: "Account",
    component: Account
  },
  {
    path: "/reset",
    name: "Reset",
    component: ResetPassword
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
