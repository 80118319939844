<template>
  <div class="columns is-centered">
    <div v-if="waitingForResponse" class="modal-background has-zindex-15">
      <div class="loader loader-screen-centered"></div>
    </div>
    <div class="column is-one-third"></div>
    <div class="column margin-top-110">
      <div>
        <h2>Please enter a password to reset:</h2>
        <div>
          <div class="ml-1 mt-2 mb-1">
            <span class="has-text-grey is-size-7"
              >At least 12 characters. Subsequent spaces will be
              contracted.</span
            >
          </div>
          <div class="is-relative mb-1">
            <input
              id="newPassword"
              class="input password-input"
              :type="isNewPasswordVisible ? 'text' : 'password'"
              autocomplete="new-password"
              v-model="newPassword"
              placeholder="New password"
            />
            <i
              class="eye-button fas"
              :class="isNewPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
              @click="toggleNewPasswordVisibility()"
            ></i>
            <i
              v-show="newPassword"
              class="fas is-size-4 password-check"
              :class="
                passwordMeetsRequirements
                  ? 'fa-check has-text-success'
                  : 'fa-times has-text-danger'
              "
            ></i>
          </div>
          <div class="is-relative">
            <input
              id="repeatNewPassword"
              class="input password-input"
              :type="isRepeatPasswordVisible ? 'text' : 'password'"
              autocomplete="new-password"
              v-model="repeatNewPassword"
              placeholder="Repeat new password"
            />
            <i
              class="eye-button fas"
              :class="isRepeatPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
              @click="toggleRepeatPasswordVisibility()"
            ></i>
            <i
              v-show="repeatNewPassword"
              class="fas is-size-4 password-check"
              :class="
                repeatPasswordMeetsRequirements
                  ? 'fa-check has-text-success'
                  : 'fa-times has-text-danger'
              "
            ></i>
            <div
              class="message is-danger is-small is-inline ml-2 mt-4 warn-box"
            >
              <span v-show="repeatNewPassword && !passwordsMatch" class="mx-2"
                >Passwords don't match</span
              >
            </div>
          </div>
        </div>
        <div v-if="errorMessage" class="message is-danger is-small">
          <div class="message-body has-text-weight-bold mt-2">
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div v-if="message" class="message is-success is-small">
          <div class="message-body has-text-weight-bold mt-2">
            <span>{{ message }}</span>
          </div>
        </div>
        <div class="mt-2">
          <a
            id="submitButton"
            @click="submitPassword()"
            class="
              button
              has-background-transparent
              is-outlined
              has-text-weight-bold has-text-darker-grey
              mr-4
            "
            >SUBMIT
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "ResetPasswordComponent",
  data() {
    return {
      isNewPasswordVisible: false,
      isRepeatPasswordVisible: false,
      message: "",
      errorMessage: "",
      newPassword: "",
      repeatNewPassword: "",
      token: "",
      waitingForResponse: false,
    };
  },
  mounted() {
    const urlQueryString = window.location.search;
    const urlParams = new URLSearchParams(urlQueryString);
    this.token = urlParams.get("t");
  },
  computed: {
    passwordMeetsRequirements() {
      // Replace subsequent spaces with one space
      const password = this.newPassword.replace(/  +/g, " ");
      return password.length > 11;
    },
    passwordsMatch() {
      // Replace subsequent spaces with one space
      const password = this.newPassword.replace(/  +/g, " ");
      const repeatPassword = this.repeatNewPassword.replace(/  +/g, " ");
      return password === repeatPassword;
    },
    repeatPasswordMeetsRequirements() {
      return this.passwordMeetsRequirements && this.passwordsMatch;
    },
  },
  methods: {
    toggleNewPasswordVisibility: function () {
      this.isNewPasswordVisible = !this.isNewPasswordVisible;
    },
    toggleRepeatPasswordVisibility: function () {
      this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
    },
    submitPassword: function () {
      this.waitingForResponse = true;
      this.isPasswordValid()
        .then(() => {
          axios
            .post("api/reset_password", {
              token: this.token,
              new_password: this.newPassword,
            })
            .then((res) => {
              this.$emit("closeResetPassword", res.data.message);
            })
            .catch((err) => {
              this.setErrorMessage(err.response.data.message);
              console.log(err);
            })
            .finally(() => {
              this.waitingForResponse = false;
            });
        })
        .catch(() => {
          this.waitingForResponse = false;
          console.log("Something went wrong");
        });
    },
    isPasswordValid: function () {
      return new Promise((resolve, reject) => {
        if (this.newPassword !== this.repeatNewPassword) {
          this.setErrorMessage("Passwords do not match");
          reject();
          return;
        }
        axios
          .post("/api/check_password", {
            password: this.newPassword,
          })
          .then((res) => {
            resolve();
          })
          .catch((err) => {
            this.setErrorMessage(err.response.data.message);
            reject();
          });
      });
    },
    setErrorMessage: function (msg) {
      this.errorMessage = msg;
    },
    setMessage: function (msg) {
      this.message = msg;
      setTimeout(() => {
        this.message = "";
      }, 4000);
    },
  },
};
</script>

<style scoped>
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #555;
  animation: spin 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>