import axios from "axios";
import moment from "moment";
import * as constants from "../constants.js";
import { sendErrors } from "@/js/errorHandler.js";

export function formatDate(date) {
  const formattedDate = moment(date, "YYYYMMDD").format("DD-MMM-YYYY");
  return formattedDate === "Invalid date" ? "" : formattedDate;
}

export function formatPixelSpacing(pixelSpacing) {
  if (pixelSpacing != null) {
    return pixelSpacing.replace("\\", " x ");
  }
}

export function formatSliceThickness(sliceThickness) {
  return (Math.round(sliceThickness * 10) / 10).toFixed(1);
}

export function roundOffAndFormatPixelSpacing(pixelSpacing) {
  if (pixelSpacing != null) {
    const value = pixelSpacing.replace(",", "x");
    const match = value.match(/([0-9]*\.[0-9]+)x([0-9]*\.[0-9]+)/);
    return (
      (countDecimal(match[1]) > 3
        ? Math.round(match[1] * 100) / 100 + ".."
        : Math.round(match[1] * 100) / 100) +
      " x " +
      (countDecimal(match[2]) > 3
        ? Math.round(match[2] * 100) / 100 + ".."
        : Math.round(match[2] * 100) / 100)
    );
  }
}

function countDecimal(value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1].length || 0;
}

export function startAnalysis(category = undefined) {
  axios
    .post("/api/planner", {
      CaseID: this.$store.getters.currentCaseId
    })
    .then(res => {
      this.$store.commit("setCurrentView", constants.VIEW_PLANNER);
      this.$router.push({
        name: "CTPlanner",
        params: { analysisId: res.data.id, defaultCategory: category }
      });
      return true;
    })
    .catch(function(error) {
      sendErrors(error, "StartAnalysis");
      return false;
    });
}

export function parseCookie(cookie = "") {
  return cookie.split("; ").reduce((obj, item) => {
    const array = item.split("=");
    obj[array[0]] = array[1];
    return obj;
  }, {});
}

export function releaseEditAccess(caseId) {
  if (this.$store.getters.hasEditAcessOnServer) {
    const path = "/api/cases/" + caseId + "/release_edit_access";
    axios
      .post(path, {})
      .then(() => {})
      .catch(error => {
        sendErrors(error, "CTPlanner:releaseEditAccess");
      });
  }
}
