<template>
  <!-- Confirm Rerun Datapipe Modal -->
  <div
    id="ConfirmRerunDpModal has-text-almost-black"
    class="modal"
    :class="isConfirmRerunDpSeen ? 'is-active' : ''"
  >
    <div class="modal-background" @click="sendCloseEvent"></div>
    <div class="modal-content">
      <article class="message">
        <div class="message-header">
          <p class="has-text-mid-grey">Confirm Rerun</p>
        </div>
        <div v-if="!hasError">
          <div class="message-body is-modal">
            <p
              id="rerunMessage"
              class="has-text-centered has-text-dark-primary"
            >
              Are you sure you want to re-run Datapipe for {{ caseId }}:{{
                phaseSetId
              }}?
              <strong>This will override existing intermediate results!</strong>
            </p>
          </div>
          <div
            class="has-text-centered has-background-dark-primary padding-tb-5"
          >
            <button
              id="confirmButton"
              class="button is-danger margin-right-5"
              @click="rerunDp"
            >
              Confirm
            </button>
            <button
              id="cancelButton"
              class="button margin-right-5"
              @click="sendCloseEvent"
            >
              Cancel
            </button>
          </div>
        </div>
        <div v-else>
          <div class="message-body has-background-dark-message">
            <p id="errorMessage" class="has-text-centered">
              Something went wrong. Please try again later.
            </p>
          </div>
          <div class="has-text-centered padding-tb-5">
            <button id="okButton" class="button" @click="sendCloseEvent">
              OK
            </button>
          </div>
        </div>
      </article>
    </div>
  </div>
  <!-- Confirm Rerun Datapipe Modal end -->
</template>

<script>
import { sendErrors } from "@/js/errorHandler.js";
import axios from "axios";

export default {
  name: "ConfirmRerunDpModal",
  props: {
    isConfirmRerunDpSeen: Boolean,
    toRerunDp: Array,
  },
  data() {
    return {
      caseId: "",
      phaseSetId: "",
      hasError: false,
    };
  },
  beforeMount() {
    this.checkLoggedIn();
    if (this.toRerunDp.length > 0) {
      this.caseId = this.toRerunDp[0][0];
      this.phaseSetId = this.toRerunDp[0][1];
    }
  },
  methods: {
    reset: function () {
      this.caseId = "";
      this.phaseSetId = "";
    },
    sendCloseEvent: function (event) {
      this.reset();
      this.$emit("cancelRerunDp");
    },
    checkLoggedIn: function () {
      axios.get("/api/currentuser").then((response) => {
        if (!response.data.user) {
          window.location.href = "/";
        }
      });
    },
    rerunDp: function () {
      axios
        .post("/api/rerun_datapipe", {
          CaseID: this.caseId,
          PhaseSetId: this.phaseSetId,
        })
        .then((res) => {
          if (!res.data.error) {
            this.sendCloseEvent();
          }
        })
        .catch((error) => {
          sendErrors(error, "ConfirmRerun:rerunDp()");
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: initial !important;
}

.modal-card {
  width: 90%;
  height: 75%;
  overflow-y: auto;
}
</style>
