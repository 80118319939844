const _CIRCLE_RADIUS = 2;
export const HANDLE_RADIUS = 11;

export class Point {
  /* Point of a line or spline.
  It has:
    - 3d coordinates in world coordinate system
    - 2d corrdinates in pixel coordinate system
    - span circle element
    - span handle element which is hidden unless
      the mouse position is close to the point */
  constructor(
    pixelCoordinates,
    worldCoordinates,
    viewIndex,
    circleRadius = _CIRCLE_RADIUS
  ) {
    this.worldCoordinates = worldCoordinates;
    this.viewIndex = viewIndex;
    this._circleRadius = circleRadius;
    if (pixelCoordinates !== undefined) {
      this._setPixelCoordinates(pixelCoordinates);
    } else {
      this.pixelCoordinates = undefined;
    }
  }

  getRelativeCoordinates(width, height) {
    return {
      x: (parseFloat(this.circle.style.left) / width) * 100,
      y: (parseFloat(this.circle.style.top) / height) * 100
    };
  }

  _setPixelCoordinates(pixelCoordinates) {
    this.pixelCoordinates = pixelCoordinates;
    this.circle = _createCircle(this.pixelCoordinates, this._circleRadius);
    this.handle = _createHandle(this.pixelCoordinates);
    addToDom(this.circle, this.viewIndex);
    addToDom(this.handle, this.viewIndex);
  }

  setWorldToPixel(worldToPixel) {
    const pixelCoordinates = worldToPixel(this.worldCoordinates).slice(0, 2);
    this._setPixelCoordinates(pixelCoordinates);
    this.showHandle(false);
  }

  isInitialized() {
    return this.pixelCoordinates !== undefined;
  }

  updatePosition(pixelCoordinates, worldCoordinates) {
    if (worldCoordinates !== undefined) {
      this.worldCoordinates = worldCoordinates;
    }
    if (pixelCoordinates !== undefined && this.isInitialized()) {
      this.pixelCoordinates = pixelCoordinates;
      this.circle.style.top = pixelCoordinates[1] - this._circleRadius + "px";
      this.circle.style.left = pixelCoordinates[0] - this._circleRadius + "px";
      this.handle.style.top = pixelCoordinates[1] - HANDLE_RADIUS + "px";
      this.handle.style.left = pixelCoordinates[0] - HANDLE_RADIUS + "px";
    }
  }

  showHandle(show = true) {
    if (this.isInitialized()) {
      if (show) {
        this.handle.style.display = "block";
      } else {
        this.handle.style.display = "none";
      }
    }
  }

  showCircle(show = true) {
    if (this.isInitialized()) {
      if (show) {
        this.circle.style.display = "block";
      } else {
        this.circle.style.display = "none";
      }
    }
  }

  showPoint(show = true) {
    this.showCircle(show);
    this.showHandle(show);
  }
}

export function zoomCoordinates(coordinates, newZoom, oldZoom, rect) {
  /* Update coordinates in a pixel coordinate system in case of
  zoom (rect is a bounding rectangle of the viewer). */
  const factor = oldZoom / newZoom;
  const x = (coordinates[0] - rect.width / 2) * factor + rect.width / 2;
  const y = (coordinates[1] - rect.height / 2) * factor + rect.height / 2;
  return [x, y];
}

export function resizeCoordinates(coordinates, newRect, oldRect) {
  /* Update coordinates in a pixel coordinate system in case of
  resize event (rect is a bounding rectangle of the viewer) */
  const factor = newRect.height / oldRect.height;
  const x = (coordinates[0] - oldRect.width / 2) * factor + newRect.width / 2;
  const y = (coordinates[1] - oldRect.height / 2) * factor + newRect.height / 2;
  return [x, y];
}

export function addToDom(element, viewIndex) {
  const viewer = document.getElementById(`viewer-${viewIndex}`);
  viewer.appendChild(element);
}

function _createCircle(position, radius = _CIRCLE_RADIUS) {
  const div = document.createElement("span");
  div.className = "measurements-circle has-border-round";
  div.style.position = "absolute";
  div.style.width = radius * 2 + "px";
  div.style.height = radius * 2 + "px";
  div.style.top = position[1] - radius + "px";
  div.style.left = position[0] - radius + "px";
  return div;
}

function _createHandle(position) {
  const div = document.createElement("span");
  div.className = "measurements-handle has-border-round";
  div.style.position = "absolute";
  div.style.width = HANDLE_RADIUS * 2 + "px";
  div.style.height = HANDLE_RADIUS * 2 + "px";
  div.style.top = position[1] - HANDLE_RADIUS + "px";
  div.style.left = position[0] - HANDLE_RADIUS + "px";
  return div;
}
