import { LoadingManager } from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

export default function DracoGLTFLoader() {
  /* glTFLoader with draco decoder.
  Handles both regular and draco compressed .gltf files.
  Note: Draco library is hosted statically at /static/js/draco */
  const loadingManager = new LoadingManager();
  const dracoLoader = new DRACOLoader();

  dracoLoader.setDecoderPath("/static/js/draco/");
  dracoLoader.preload();

  const gltfLoader = new GLTFLoader(loadingManager);

  gltfLoader.setDRACOLoader(dracoLoader);

  return gltfLoader;
}
