import { distanceBetweenPoints3d, distanceBetweenPoints2d } from "./math.js";
import { store } from "@/store/store.js";
import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import * as THREE from "three";
import {
  Point,
  zoomCoordinates,
  resizeCoordinates,
  addToDom,
  HANDLE_RADIUS
} from "./common.js";
import { ACCESS_MODE_READONLY } from "@/constants.js";

export class LineController {
  /* Class responsible for line measurmenets tool (interaction and appearance)
  inside the view */
  constructor(
    caseProtocol,
    cache,
    viewIndex,
    viewController,
    scene,
    rect,
    pixelToWorld,
    worldToPixel
  ) {
    // container of lines (each sliceIndex of each view of each board can have it's own list of lines)
    this._lines = {};
    this._linesCounter = {}; // counter used to name poses
    for (const category of caseProtocol.categories) {
      for (const board of category.boards) {
        this._lines[board.id_] = {};
        this._linesCounter[board.id_] = 0;
      }
    }
    this.cache = cache;
    this._currentSliceIndex = undefined;
    this._viewIndex = viewIndex;
    this._viewController = viewController;
    this._currentBoard = this._viewController.currentBoard.id_;
    this._pixelToWorld = pixelToWorld; // function to transform from pixel to world CS
    this._worldToPixel = worldToPixel; // function to transform from pixel to world CS
    this._scene = scene; // pointer to the scene of the view
    this._rect = rect; // bounding rectangle of the view
    this._activeLine = undefined; // line being currently in edit
    this._closeLine = {
      lineIdx: undefined, // index of the line at the this._lines[this._currentBoard][this._currentSliceIndex]
      start: false // true if start point is being edit, false if end (default)
    }; // line which point is close to the mouse position (can become active on mouse down)
    this.getViewNormal = this.getViewNormal.bind(this);
  }

  loadLines(caseProtocol) {
    /* Load saved lines for all boards on starting the CT Planner */
    for (const category of caseProtocol.categories) {
      for (const board of category.boards) {
        const view = board.views[this._viewIndex];
        for (const pose of view.poses) {
          if (pose.lines !== null) {
            for (const line of pose.lines) {
              // manual lines do not have names
              // and their subIds stored as list
              if (line.sub_ids instanceof Array) {
                for (const subId of line.sub_ids) {
                  this._loadLine(
                    line.master_id,
                    subId,
                    pose.transformation,
                    board.id_
                  );
                }
              } else if (line.sub_ids instanceof Object) {
                // automatic lines have names
                // and their subIds stored as Dict[subId, name]
                for (const [id_, name] of Object.entries(line.sub_ids)) {
                  this._loadLine(
                    line.master_id,
                    id_,
                    pose.transformation,
                    board.id_,
                    name
                  );
                }
              }
            }
          }
        }
      }
    }
    this._addLines();
  }

  _loadLine(masterId, subId, sliceIndex, boardId, name = undefined) {
    const phase = this._viewController.currentBoard.phase;
    const geometry = this.cache._getGeometry(masterId, subId, phase);
    let [startWorld, endWorld] = geometry.points;
    if (startWorld.length === 3) {
      startWorld = [[startWorld[0]], [startWorld[1]], [startWorld[2]], [1]];
    }
    if (endWorld.length === 3) {
      endWorld = [[endWorld[0]], [endWorld[1]], [endWorld[2]], [1]];
    }

    const line = new Line(
      this._viewController.materials,
      this._viewIndex,
      undefined,
      undefined,
      ++this._linesCounter[boardId],
      this._pixelToWorld,
      name,
      false,
      this.getViewNormal
    );

    line.setStart(undefined, startWorld);
    line.setEnd(undefined, endWorld);
    line.masterId = masterId;

    if (this._lines[boardId][sliceIndex] === undefined) {
      this._lines[boardId][sliceIndex] = [];
    }
    this._lines[boardId][sliceIndex].push(line);
  }

  mouseUp(pixelCoordinates, worldCoordinates) {
    /* Function taking care of mouseUp event inside the view.
    Such event may imply setting (or updating) one of the points
    of one of the lines.
    Returns true if a new line was created */

    if (!this.isActive()) {
      // starting a new line
      this._activeLine = new Line(
        this._viewController.materials,
        this._viewIndex,
        this._viewController.mprZoom,
        this._rect(),
        ++this._linesCounter[this._currentBoard],
        this._pixelToWorld,
        undefined,
        false,
        this.getViewNormal
      );

      this._activeLine.setStart(pixelCoordinates, worldCoordinates);
      this._activeLine.setEnd(pixelCoordinates, worldCoordinates);
      this._scene.add(this._activeLine.group);
    } else {
      // adjusting existing line
      this._activeLine.finishMeasurement();
      if (this._closeLine.lineIdx === undefined) {
        if (!(this._currentSliceIndex in this._lines[this._currentBoard])) {
          this._lines[this._currentBoard][this._currentSliceIndex] = [];
        }
        this._lines[this._currentBoard][this._currentSliceIndex].push(
          this._activeLine
        );
      }
      this._viewController.addLine(this._viewIndex, this._activeLine);
      this._activeLine = undefined;
      document.dispatchEvent(new CustomEvent("updateMeasurement"));
    }
  }

  isActive() {
    /* Return true if there is currently a line being adjusted.
    In such case other interactions (e.g slicing) are blocked */
    return this._activeLine !== undefined;
  }

  mouseMove(pixelCoordinates, worldCoordinates) {
    /* Function taking care of mouseMove event inside the view.
    Such event may imply either setting the 2nd point of active line
    or highlighting a point of one of the previously drawn lines.
    Both of these features will be disabled in Read Only mode */
    if (store.getters.currentAccessMode !== ACCESS_MODE_READONLY) {
      if (this.isActive()) {
        if (this._closeLine.start) {
          this._activeLine.setStart(pixelCoordinates, worldCoordinates);
        } else {
          this._activeLine.setEnd(pixelCoordinates, worldCoordinates);
        }
      } else {
        this._findClosestLine(pixelCoordinates);
      }
    }
  }

  mouseDown() {
    /* Start adjusting existing line if the mouse pointer is close to it */
    if (this._closeLine.lineIdx !== undefined) {
      this._activeLine = this._lines[this._currentBoard][
        this._currentSliceIndex
      ][this._closeLine.lineIdx];
    }
  }

  zoom() {
    /* When zooming, all shown dom elements (e.g line points, measurements boxes) need to update position. */
    if (this._currentSliceIndex in this._lines[this._currentBoard]) {
      for (const line of this._lines[this._currentBoard][
        this._currentSliceIndex
      ]) {
        if (line.zoom !== this._viewController.mprZoom) {
          line.zoom(this._viewController.mprZoom);
        }
      }
    }
  }

  resize(newRect) {
    /* When the viewer is resized, all shown elements (e.g line points, measurements boxes) need to update position. */
    if (this._currentSliceIndex in this._lines[this._currentBoard]) {
      for (const line of this._lines[this._currentBoard][
        this._currentSliceIndex
      ]) {
        line.resize(newRect);
      }
    }
  }

  updateSliceIndex(newSliceIndex) {
    /* All lines belong to a specific slice index.
    This function hides the lines from the previous slice index
    and shows the lines for the new one. */
    if (newSliceIndex !== this._currentSliceIndex) {
      this.clearViewer();
      this._currentSliceIndex = newSliceIndex;
      this._addLines();
    }
  }

  updateBoard(newBoard) {
    /* All lines belong to a specific board.
    This function hides the lines from the previous board (& slice index)
    and shows the lines for the new one. */
    if (newBoard !== this._currentBoard) {
      this.clearViewer();
      this._currentBoard = newBoard;
    }
  }

  _addLines() {
    /* Add lines that belong to the current board and slice to the viewer */
    if (this._currentSliceIndex in this._lines[this._currentBoard]) {
      for (const line of this._lines[this._currentBoard][
        this._currentSliceIndex
      ]) {
        if (!line.start.isInitialized() || !line.end.isInitialized()) {
          line.initialize(
            this._worldToPixel,
            this._viewController.mprZoom,
            this._rect()
          );
        } else {
          line.resize(this._rect());
        }
        line.showCss();
        // zoom & resize in case setting were updated since the last time user visited this slice
        line.zoom(this._viewController.mprZoom);
        this._scene.add(line.group);
      }
    }
  }

  clearViewer() {
    /* remove three js objects from scene; hide dom elements */
    if (this._currentSliceIndex in this._lines[this._currentBoard]) {
      for (const line of this._lines[this._currentBoard][
        this._currentSliceIndex
      ]) {
        line.hideCss();
        this._scene.remove(line.group);
      }
    }
  }

  onDelete() {
    /* Handle `delete` button pressed event - delete line if the user's mouse
    is close to any of the lines */
    if (this._closeLine.lineIdx !== undefined) {
      const line = this._lines[this._currentBoard][this._currentSliceIndex][
        this._closeLine.lineIdx
      ];
      line.removeCss();
      this._scene.remove(line.group);

      this._viewController.deleteLine(this._viewIndex, line);
      this._lines[this._currentBoard][this._currentSliceIndex].splice(
        this._closeLine.lineIdx,
        1
      );
      this._closeLine = {
        lineIdx: undefined,
        start: false
      };
      this._linesCounter[this._currentBoard] -= 1;
      document.dispatchEvent(new CustomEvent("updateMeasurement"));
    }
  }

  isPointHighlighted() {
    return this._closeLine.lineIdx !== undefined;
  }

  _findClosestLine(pixelCoordinates) {
    /* Iterate through lines at the view to see if the mouse position is
    close to one of them */

    if (this._currentSliceIndex in this._lines[this._currentBoard]) {
      let i = 0;
      for (const line of this._lines[this._currentBoard][
        this._currentSliceIndex
      ]) {
        if (
          distanceBetweenPoints2d(
            pixelCoordinates,
            line.start.pixelCoordinates
          ) <= HANDLE_RADIUS
        ) {
          line.start.showHandle();
          this._closeLine = {
            lineIdx: i,
            start: true
          };
          return;
        } else {
          line.start.showHandle(false);
        }
        if (
          distanceBetweenPoints2d(
            pixelCoordinates,
            line.end.pixelCoordinates
          ) <= HANDLE_RADIUS
        ) {
          line.end.showHandle();
          this._closeLine = {
            lineIdx: i,
            start: false
          };
          return;
        } else {
          line.end.showHandle(false);
        }
        i++;
      }
    }
    this._closeLine = {
      lineIdx: undefined,
      start: false
    };
  }

  getLinesForScreenshot() {
    const textBoxes = [];
    const endpoints = [];
    const { width, height } = this._rect();

    if (this._currentSliceIndex in this._lines[this._currentBoard]) {
      for (const line of this._lines[this._currentBoard][
        this._currentSliceIndex
      ]) {
        textBoxes.push({
          coordinates: line.measurementsBox.getRelativeCoordinates(
            width,
            height
          ),
          text: line.measurementsBox.getText()
        });
        endpoints.push({
          start: line.start.getRelativeCoordinates(width, height),
          end: line.end.getRelativeCoordinates(width, height)
        });
      }
    }
    return { textBoxes: textBoxes, endpoints: endpoints };
  }

  getViewNormal() {
    return this._viewController.normalByView[this._viewIndex];
  }
}

export class Line {
  constructor(
    materials,
    viewIndex,
    zoom,
    rect,
    counter,
    pixelToWorld,
    measurementName = undefined,
    isDiameter = false,
    viewNormal = undefined
  ) {
    this._materials = materials;
    this._viewIndex = viewIndex;
    this._zoom = zoom;
    this._rect = rect;
    this._counter = counter;
    this._pixelToWorld = pixelToWorld;
    this.start = undefined; // instance of Point
    this.end = undefined; // instance of Point
    // three js group (line, dashed line - connector to measurements box)
    this.group = undefined;
    this.measurementsBox = undefined;
    this.masterId = undefined; // master id of the line at the measurements
    this.distance = undefined;
    this._measurementsName = measurementName;
    this._isDiameter = isDiameter;
    this._viewNormal = viewNormal;
  }

  initialize(worldToPixel, zoom, rect) {
    this._zoom = zoom;
    this._rect = rect;
    this.start.setWorldToPixel(worldToPixel);
    this.end.setWorldToPixel(worldToPixel);
    this.finishMeasurement();
  }

  getPoseName() {
    /* Return the name of the pose (if needs to be creatde) for this line. */
    return "line " + this._counter;
  }

  setStart(pixelCoordinates, worldCoordinates, hide = false) {
    /* Set the first point of the line.
    pixelCoordinates are used to position the mesurement box
    worldCoordinates for the three js line */
    if (this.measurementsBox !== undefined) {
      this.measurementsBox.remove();
      this.measurementsBox = undefined;
    }
    if (this.start === undefined) {
      this.start = new Point(
        pixelCoordinates,
        worldCoordinates,
        this._viewIndex
      );
      this.start.showHandle(false);
    } else {
      this.start.updatePosition(pixelCoordinates, worldCoordinates);
      this._update();
    }
    if (this.end === undefined) {
      this.end = new Point(pixelCoordinates, worldCoordinates, this._viewIndex);
    }
    if (hide) {
      this.start.showPoint(false);
      this.end.showPoint(false);
    }
  }

  setEnd(pixelCoordinates, worldCoordinates) {
    /* Set the second point of the line */
    if (this.measurementsBox !== undefined) {
      this.measurementsBox.remove();
      this.measurementsBox = undefined;
    }
    this.end.updatePosition(pixelCoordinates, worldCoordinates);
    this._update();
  }

  finishMeasurement() {
    /* Finish following the mouse pointer. Create measurement box and connect it to the line. */

    const distance = distanceBetweenPoints3d(
      this.start.worldCoordinates,
      this.end.worldCoordinates
    );
    this.distance = (Math.round(distance * 10) / 10).toFixed(1); // round up to 1 decimals

    const mbCoordinates = this._calculateMeasurementsBoxCoordinates();

    if (this.measurementsBox === undefined) {
      let text;
      if (this._measurementsName === undefined) {
        text = " " + this.distance + " mm ";
      } else {
        if (this._isDiameter) {
          text = " " + this._measurementsName + " ";
        } else {
          text = " " + this._measurementsName + ": " + this.distance + " mm ";
        }
      }
      this.measurementsBox = new MeasurementsBox(
        text,
        mbCoordinates,
        this.group,
        this._pixelToWorld,
        this._materials.connector
      );
      addToDom(this.measurementsBox.div, this._viewIndex);
      dragElement(this.measurementsBox);
    }
  }

  _getMeasurementsBoxCoordinates() {
    /* return the current coordinates of the measurement box
    top left corner */
    const x = parseFloat(this.measurementsBox.div.style.left);
    const y = parseFloat(this.measurementsBox.div.style.top);
    return [x, y];
  }

  _updateMeasurementsBoxPosition(coordinates) {
    /* update the positin of the measurement box
    top left corner (e.g in case of zoom or resize) */
    if (this.measurementsBox !== undefined) {
      this.measurementsBox.div.style.left = coordinates[0] + "px";
      this.measurementsBox.div.style.top = coordinates[1] + "px";
    }
  }

  _calculateMeasurementsBoxCoordinates() {
    /* Calculate the intial position of the measurements box (MB)
    trying to avoid overlap with view borders.
    Following cases are conidered:
      1. There is enough between the right point and view border
      -> MB is to the right
      2. [1] doesn't hold but there is enough space between the left
      point and view border -> MB to left
      3. [1] and [2] do not hold -> goes to top left (by diagonal) from the right point

    - For diameters the calculation is based on selecting the right most point
    and extending its coordinates along the radius.

    - For automatic measurements the calculation is also different (currently optimized for RA//RV measures only);
    */
    let pLeft, pRight;

    if (!this._isDiameter && this._measurementsName !== undefined) {
      // Automatic measurement - select point which is further from view center.
      if (
        Math.abs(this.start.pixelCoordinates[0] - this._rect.width / 2) >
        Math.abs(this.end.pixelCoordinates[0] - this._rect.width / 2)
      ) {
        pLeft = this.end.pixelCoordinates;
        pRight = this.start.pixelCoordinates;
      } else {
        pLeft = this.end.pixelCoordinates;
        pRight = this.start.pixelCoordinates;
      }
      return [pRight[0] - 20, pRight[1] + 30, pRight];
    }

    if (this.start.pixelCoordinates[0] > this.end.pixelCoordinates[0]) {
      pLeft = this.end.pixelCoordinates;
      pRight = this.start.pixelCoordinates;
    } else {
      pLeft = this.start.pixelCoordinates;
      pRight = this.end.pixelCoordinates;
    }

    if (this._isDiameter) {
      let x = pRight[0] - pLeft[0];
      let y = pRight[1] - pLeft[1];
      const norm = Math.sqrt(x * x + y * y);
      x = (x / norm) * 30;
      y = (y / norm) * 30;
      return [pRight[0] + x, pRight[1] + y, pRight];
    } else {
      const connectorLength = 10;
      const textWidth = 55;
      const minGap = connectorLength + textWidth;

      if (pRight[0] + minGap < this._rect.width) {
        const y1 = Math.min(pRight[1] - 8, this._rect.height - 30);
        const y2 = Math.max(pRight[1] - 8, 60); // 60 is for camera button
        return [pRight[0] + connectorLength, Math.max(y1, y2), pRight];
      } else if (pLeft[0] > minGap) {
        const y1 = Math.min(pLeft[1] - 8, this._rect.height - 30);
        const y2 = Math.max(pLeft[1] - 8, 60);
        return [pLeft[0] - minGap, Math.max(y1, y2), pLeft];
      } else if (pRight[1] > minGap + 60) {
        return [pRight[0] - minGap, pRight[1] - minGap, pRight];
      } else {
        return [pRight[0] - minGap, pRight[1] - minGap, pRight];
      }
    }
  }

  zoom(newZoom) {
    const startCoordinates = zoomCoordinates(
      this.start.pixelCoordinates,
      newZoom,
      this._zoom,
      this._rect
    );

    const endCoordinates = zoomCoordinates(
      this.end.pixelCoordinates,
      newZoom,
      this._zoom,
      this._rect
    );

    const mbCoordinates = zoomCoordinates(
      this._getMeasurementsBoxCoordinates(),
      newZoom,
      this._zoom,
      this._rect
    );
    this.start.updatePosition(startCoordinates);
    this.end.updatePosition(endCoordinates);
    this._updateMeasurementsBoxPosition(mbCoordinates);
    this._zoom = newZoom;
  }

  resize(newRect) {
    const startCoordinates = resizeCoordinates(
      this.start.pixelCoordinates,
      newRect,
      this._rect
    );
    const endCoordinates = resizeCoordinates(
      this.end.pixelCoordinates,
      newRect,
      this._rect
    );
    const mbCoordinates = resizeCoordinates(
      this._getMeasurementsBoxCoordinates(),
      newRect,
      this._rect
    );

    this.start.updatePosition(startCoordinates);
    this.end.updatePosition(endCoordinates);
    this._updateMeasurementsBoxPosition(mbCoordinates);
    this._rect = newRect;
  }

  hideCss() {
    /* hide all css elements */
    if (this.start.isInitialized()) {
      for (const elem of [
        this.measurementsBox.div,
        this.start.circle,
        this.start.handle,
        this.end.circle,
        this.end.handle
      ]) {
        elem.style.display = "none";
      }
    }
  }

  removeCss() {
    /* remove all css elements */
    if (this.start.isInitialized()) {
      for (const elem of [
        this.measurementsBox.div,
        this.start.circle,
        this.start.handle,
        this.end.circle,
        this.end.handle
      ]) {
        elem.remove();
      }
    }
  }

  showCss() {
    /* show required css elements */
    if (this.start.isInitialized()) {
      for (const elem of [
        this.measurementsBox.div,
        this.start.circle,
        this.end.circle
      ]) {
        elem.style.display = "block";
      }
    }
  }

  _update() {
    /* Update the THREE.js line geometry */

    // TODO: hack to make sure that line is always above the cross

    let positions;
    if (this._viewNormal === undefined) {
      positions = [
        this.start.worldCoordinates[0][0],
        this.start.worldCoordinates[1][0],
        this.start.worldCoordinates[2][0],
        this.end.worldCoordinates[0][0],
        this.end.worldCoordinates[1][0],
        this.end.worldCoordinates[2][0]
      ];
    } else {
      positions = [
        this.start.worldCoordinates[0][0] - this._viewNormal().x,
        this.start.worldCoordinates[1][0] - this._viewNormal().y,
        this.start.worldCoordinates[2][0] - this._viewNormal().z,
        this.end.worldCoordinates[0][0] - this._viewNormal().x,
        this.end.worldCoordinates[1][0] - this._viewNormal().y,
        this.end.worldCoordinates[2][0] - this._viewNormal().z
      ];
    }

    if (this.group === undefined) {
      const geometry = new LineGeometry();
      geometry.setPositions(positions);
      const line = new Line2(geometry, this._materials.line);
      this.group = new THREE.Group();
      this.group.add(line);
    } else {
      this.group.children[0].geometry.setPositions(positions);
    }
  }
}

class MeasurementsBox {
  constructor(
    text,
    mbCoordinates,
    threeGroup,
    pixelToWorld,
    connectorMaterial
  ) {
    this.div = this._drawDiv(text, mbCoordinates);
    this.group = threeGroup;
    this._pixelToWorld = pixelToWorld;
    this._drawConnector(mbCoordinates, connectorMaterial);
  }

  getText() {
    return this.div.innerHTML;
  }

  getRelativeCoordinates(width, height) {
    return [
      (parseFloat(this.div.style.top) / height) * 100,
      (parseFloat(this.div.style.left) / width) * 100
    ];
  }

  _drawDiv(text, positionPixel) {
    const div = document.createElement("div");
    div.className =
      "measurements-label has-text-black is-absolute has-background-white has-opacity-60 has-border-radius-5 is-size-8";
    div.style.position = "absolute";
    div.innerHTML = " " + text + " ";
    div.style.top = positionPixel[1] + "px";
    div.style.left = positionPixel[0] + "px";
    return div;
  }

  _drawConnector(mbCoordinates, connectorMaterial) {
    /* draw the connector from measurements box to line */
    const mbWorld = this._pixelToWorld([
      mbCoordinates[0],
      mbCoordinates[1] + 8
    ]);
    this.lineWorld = this._pixelToWorld(mbCoordinates[2]);

    const points = [];
    points.push(
      new THREE.Vector3(
        this.lineWorld[0][0],
        this.lineWorld[1][0],
        this.lineWorld[2][0]
      )
    );
    points.push(new THREE.Vector3(mbWorld[0][0], mbWorld[1][0], mbWorld[2][0]));

    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const line = new THREE.Line(geometry, connectorMaterial);
    line.computeLineDistances();
    this.group.add(line);
  }

  updateConnector() {
    /* update the connector */
    const x = parseFloat(this.div.style.left);
    const y = parseFloat(this.div.style.top) + 8;
    const mbWorld = this._pixelToWorld([x, y]);
    const points = [];
    points.push(
      new THREE.Vector3(
        this.lineWorld[0][0],
        this.lineWorld[1][0],
        this.lineWorld[2][0]
      )
    );
    points.push(new THREE.Vector3(mbWorld[0][0], mbWorld[1][0], mbWorld[2][0]));

    this.group.children[1].geometry.setFromPoints(points);
    this.group.children[1].computeLineDistances();
  }

  remove() {
    /* remove text label from document, connector from group */
    this.div.remove();
    this.group.children.splice(1, 1);
  }
}

function dragElement(measurementsBox) {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  measurementsBox.div.onmousedown = dragMouseDown;

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    measurementsBox.div.style.top = measurementsBox.div.offsetTop - pos2 + "px";
    measurementsBox.div.style.left =
      measurementsBox.div.offsetLeft - pos1 + "px";
    measurementsBox.updateConnector();
  }

  function closeDragElement() {
    /* stop moving when mouse button is released: */
    document.onmouseup = null;
    document.onmousemove = null;
  }
}
