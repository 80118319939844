<template>
  <NotFoundComponent />
</template>

<script>
// @ is an alias to /src
import NotFoundComponent from "@/components/NotFound.vue";

export default {
  name: "NotFound",
  components: {
    NotFoundComponent,
  },
};
</script>
