import { Line2 } from "three/examples/jsm/lines/Line2.js";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import * as THREE from "three";

import { distanceBetweenPoints2d } from "./math.js";

import {
  Point,
  zoomCoordinates,
  resizeCoordinates,
  HANDLE_RADIUS
} from "./common.js";

import { Line } from "./line.js";

import { VIEW_INTERACTION, VIEW_TYPE, GEOMETRY_TYPE } from "../constants.js";

const MAX_DISTANCE_TO_PLANE = 3;
const _BELOW = "below";
const _ABOVE = "above";
const _WITHIN = "within";

export function preSelectPoints(points) {
  /* TMP function to have only 12 points instead of 72 (later this will either
  be output of DP or specific points aligned with the specific planes will be
  selected) */
  const reducedPoints = [];
  const length = 12;
  const delta = Math.floor(points.length / length);

  for (let i = 0; i < points.length; i = i + delta) {
    reducedPoints.push(points[i]);
  }
  return reducedPoints;
}

export function pointsToMeasurementsGroup(points) {
  return {
    name: "tricuspid_annulus",
    type: "custom",
    scalars: {},
    geometries: {
      51: { points: points, name: "annulus", type: GEOMETRY_TYPE.CLOSED_SPLINE }
    }
  };
}

export class AnnulusController {
  /* Class responsbile for synchronization of annulus across connected
  views. */
  constructor(
    viewIndex,
    viewController,
    scene,
    rect,
    pixelToWorld,
    worldToPixel
  ) {
    this._annulusByBoard = undefined;
    this._viewIndex = viewIndex;
    this._viewController = viewController;
    this._currentBoard = this._viewController.currentBoard.id_;
    this._pixelToWorld = pixelToWorld; // function to transform from pixel to world CS
    this._worldToPixel = worldToPixel; // function to transform from world to pixel CS
    this._scene = scene; // pointer to the scene of the view
    this._rect = rect; // bounding rectangle of the view
    this.isActive = false; // annulus being currently in edit
    this._activePointIdx = undefined; // index of the currently editable point
  }

  isInitialized() {
    /* Return true if the annuluses are initialized (this happens once the measurements and at least one CT slice are loaded. */
    return this._annulusByBoard !== undefined;
  }

  init() {
    /* Init elements of the annulus for each board. */
    this._annulusByBoard = {};

    for (const category of this._viewController.caseProtocol.categories) {
      for (const board of category.boards) {
        const points = this._viewController.annulusPointsByBoard(
          board.id_,
          this._viewIndex
        );
        if (points !== undefined) {
          const viewType = this._viewController.getViewTypeByViewIndex(
            this._viewIndex,
            board.id_
          );
          if (viewType === VIEW_TYPE.MPR) {
            const interaction = this._viewController.getInteractionByViewIndex(
              this._viewIndex,
              board.id_
            );
            this._annulusByBoard[board.id_] = new Annulus(
              this._worldToPixel,
              this._pixelToWorld,
              this._viewIndex,
              this._viewController.materials,
              interaction,
              points.modifiable,
              points.diameters
            );
          } else if (viewType === VIEW_TYPE.SURFACE) {
            this._annulusByBoard[board.id_] = new SurfaceAnnulus(
              points.points,
              this._viewIndex,
              this._viewController.materials
            );
          }
        }
      }
    }
    this._tryAddAnnulus();
  }

  mouseUp() {
    /* Function taking care of mouseUp event inside the view.
    Such event may imply setting (or updating) one of the points
    of one of the lines.
    Returns true if a new line was created */
    const annulus = this._currentAnnulus();
    if (annulus !== undefined) {
      const worldCoordinates = annulus.mouseUp();
      if (worldCoordinates !== undefined) {
        if (this._viewController.currentBoard.bookmarks.length > 0) {
          document.dispatchEvent(new CustomEvent("openWarningModal"));
          this._viewController.indexOfUpdatedAnnulus = this._viewIndex;
          this._viewController.annulusWorldCoordinantes = worldCoordinates;
        } else {
          if (this._viewController.currentCategory.approved) {
            document.dispatchEvent(new CustomEvent("updateMeasurement"));
          }
          this.updateAnnulusForViews(worldCoordinates);
        }
        this.isActive = false;
      }
    }
  }

  updateAnnulusForViews(worldCoordinates = undefined) {
    const annulus = this._currentAnnulus();
    if (annulus !== undefined) {
      this._viewController.updateAnnulusPoints(
        annulus.getControlPoints(),
        this._viewIndex
      );
      if (worldCoordinates !== undefined) {
        this._viewController.updateViewsFromAnnulusPoint(
          this._viewIndex,
          worldCoordinates
        );
      }
    }
  }

  mouseMove(pixelCoordinates, worldCoordinates) {
    /* Function taking care of mouseMove event inside the view.
    Such event may imply either setting the 2nd point of active line
    or highlighting  a point of one of the previously drawn lines */
    const annulus = this._currentAnnulus();
    if (annulus !== undefined) {
      annulus.mouseMove(pixelCoordinates, worldCoordinates);
      return true;
    } else {
      return false;
    }
  }

  mouseDown() {
    /* Start adjusting existing line if the mouse pointer is close to it */
    const annulus = this._currentAnnulus();
    if (annulus !== undefined) {
      const worldCoordinates = annulus.mouseDown();
      if (worldCoordinates !== undefined) {
        this.isActive = true;
        this._viewController.updateViewsFromAnnulusPoint(
          this._viewIndex,
          worldCoordinates
        );
      }
    }
  }

  isPointHighlighted() {
    const annulus = this._currentAnnulus();
    if (annulus !== undefined && annulus instanceof Annulus) {
      return annulus.isPointHighlighted();
    } else {
      return false;
    }
  }

  _currentAnnulus() {
    if (
      this._annulusByBoard !== undefined &&
      this._currentBoard in this._annulusByBoard
    ) {
      return this._annulusByBoard[this._currentBoard];
    } else {
      return undefined;
    }
  }

  zoom() {
    /* When zooming, all annulus points handles need to update position. */
    const annulus = this._currentAnnulus();
    if (annulus !== undefined && annulus instanceof Annulus) {
      if (annulus.zoom !== this._viewController.mprZoom) {
        annulus.zoom(this._viewController.mprZoom);
      }
    }
  }

  resize(newRect) {
    /* When the viewer is resized, all annulus points handles need to update position. */
    const annulus = this._currentAnnulus();
    if (annulus !== undefined && annulus instanceof Annulus) {
      annulus.resize(newRect);
    }
  }

  updateSliceIndex() {
    /* Slice index defines the distance of the annulus from the current
    plane and thus the color of the specific parts of the annulus.
    It also defines the position of the handles for the LAX views.
    The update is controlled by view._worldToPixel function which is
    using sliceIndex inside. */
    this.updatePoints(false);
  }

  updateBoard(newBoard) {
    /* Annulus belong to a specific board.
    This function hides the lines from the previous board (& slice index)
    and shows the lines for the new one. */
    if (newBoard !== this._currentBoard) {
      this._currentBoard = newBoard;
      this._tryAddAnnulus();
    }
  }

  _tryAddAnnulus() {
    /* Init annulus if not yet initialized and add it to the views if there is /
    one attached to the current board. */
    const annulus = this._currentAnnulus();
    if (annulus !== undefined) {
      const points = this._viewController.annulusPointsByBoard(
        this._currentBoard,
        this._viewIndex
      ).points;
      if (annulus instanceof Annulus && !annulus.isInitialized()) {
        annulus.initialize(points, this._viewController.mprZoom, this._rect());
      } else {
        annulus.updatePoints(points);
        annulus.updateSpline();
      }
      if (annulus instanceof Annulus) {
        annulus.showCircles(false, true);
      }
      if (!this._scene.children.includes(annulus.line)) {
        this._scene.add(annulus.line);

        if (annulus instanceof Annulus) {
          this._scene.add(annulus.diametersGroup);
        }
      }
    }
  }

  hideCircles() {
    const annulus = this._currentAnnulus();
    if (
      annulus !== undefined &&
      annulus instanceof Annulus &&
      annulus.isInitialized()
    ) {
      annulus.showCircles(false, true);
      annulus.hideDiameters();
    }
  }

  updatePoints(updateDiameters = true) {
    const annulus = this._currentAnnulus();
    if (annulus !== undefined) {
      const points = this._viewController.annulusPointsByBoard(
        this._viewController.currentBoard.id_,
        this._viewIndex
      );
      if (annulus instanceof Annulus && updateDiameters) {
        annulus.diametersGroup.visible = true;
        annulus.toggleMeasurementsBoxVisibility();
        annulus.setDiametersCoordinates(points.diameters);
      }
      annulus.updatePoints(points.points);
      annulus.updateSpline();
    }
  }

  getDiametersTextBoxes() {
    /* Return text boxes of the annulus diameters (if present)
    for screenshots. */
    const textBoxes = [];
    const { width, height } = this._rect();
    const annulus = this._currentAnnulus();
    const viewType = this._viewController.getViewTypeByViewIndex(
      this._viewIndex,
      this._currentBoard
    );
    // Only MPR can have diameterLines
    if (viewType === VIEW_TYPE.MPR && annulus !== undefined) {
      for (const line of annulus.diameterLines) {
        textBoxes.push({
          coordinates: line.measurementsBox.getRelativeCoordinates(
            width,
            height
          ),
          text: line.measurementsBox.getText()
        });
      }
    }
    return textBoxes;
  }
}

class Annulus {
  /* Class responsible for the visualization of the annulus inside the view.
  Includes three.js spline geometry and CSS handles for control points. */

  constructor(
    worldToPixel,
    pixelToWorld,
    viewIndex,
    materials,
    viewInteraction,
    modifiable,
    diameters
  ) {
    this._materials = materials;
    this._zoom = undefined;
    this._rect = undefined;
    this._viewIndex = viewIndex;
    this._worldToPixel = worldToPixel;
    this._pixelToWorld = pixelToWorld;
    this._viewInteraction = viewInteraction;
    this._controlPoints = [];
    this.line = new THREE.Group(); // three js slines (group is used cause
    // spline segments have different colors dependent on distance to the
    // current plane)
    this.diametersGroup = new THREE.Group();
    this._allPointsPixel = []; // list of all points (including interpolated
    // used to highligh control points when the mouse is close to the annulus
    this._highlightedControlIdx = undefined; // index of the highlighted
    // control point (if any)
    this._isEdit = false;
    this._modifiable = modifiable;
    this._diameters = diameters;
    this.diameterLines = [];
    this._initDiameters();
    this._wasModified = false;
  }

  initialize(points, zoom, rect) {
    this._zoom = zoom;
    this._rect = rect;
    this.updatePoints(points);
    this.updateSpline();
    this.updateDiameters(this._diameters);
  }

  setDiametersCoordinates(diameters) {
    this._diameters = diameters;
  }

  _initDiameters() {
    for (const [points, name] of this._diameters) {
      const line = new Line(
        this._materials,
        this._viewIndex,
        undefined,
        undefined,
        0,
        this._pixelToWorld,
        name,
        true
      );
      const startWorld = [[points[0][0]], [points[0][1]], [points[0][2]], [1]];
      const endWorld = [[points[1][0]], [points[1][1]], [points[1][2]], [1]];
      line.setStart(undefined, startWorld, true);
      line.setEnd(undefined, endWorld);
      this.diameterLines.push(line);
      this.diametersGroup.add(line.group);
    }
  }

  updateDiameters(diameters) {
    for (let i = 0; i < this.diameterLines.length; i++) {
      const diameter = this.diameterLines[i];
      const points = diameters[i][0];
      if (!diameter.start.isInitialized() || !diameter.end.isInitialized()) {
        diameter.initialize(this._worldToPixel, this._zoom, this._rect);
      } else {
        const startWorld = [
          [points[0][0]],
          [points[0][1]],
          [points[0][2]],
          [1]
        ];
        const endWorld = [[points[1][0]], [points[1][1]], [points[1][2]], [1]];
        const startPixel = this._worldToPixel(startWorld);
        const endPixel = this._worldToPixel(endWorld);
        diameter.setStart(startPixel, startWorld, true);
        diameter.setEnd(endPixel, endWorld);
        if (this.diametersGroup.visible) {
          diameter.finishMeasurement();
        }
      }
    }
  }

  hideDiameters() {
    for (const diameter of this.diameterLines) {
      diameter.hideCss();
    }
  }

  isInitialized() {
    return this._zoom !== undefined;
  }

  isPointHighlighted() {
    return this._highlightedControlIdx !== undefined;
  }

  updatePoints(points) {
    /* create handles and circles for control points */
    for (const point of this._controlPoints) {
      point.circle.remove();
      point.handle.remove();
    }
    this._controlPoints = [];
    for (let i = 0; i < points.length; i++) {
      const worldCoordinates = [
        [points[i][0]],
        [points[i][1]],
        [points[i][2]],
        [1]
      ];
      const pixelCoordinates = this._worldToPixel(worldCoordinates);
      const point = new Point(
        pixelCoordinates,
        worldCoordinates,
        this._viewIndex,
        3
      );
      if (this._highlightedControlIdx !== i) {
        point.showPoint(false);
      }
      this._controlPoints.push(point);
    }
    if (
      (this._highlightedControlIdx !== undefined &&
        this._viewInteraction === VIEW_INTERACTION.SLICING) ||
      this._viewInteraction === VIEW_INTERACTION.ROTATION
    ) {
      this.showCircles(true);
    }
    this.updateDiameters(this._diameters);
  }

  getControlPoints() {
    /* return 3d coordinates of control points */
    const points = [];
    for (const p of this._controlPoints) {
      const wc = p.worldCoordinates;
      points.push([wc[0][0], wc[1][0], wc[2][0]]);
    }
    return points;
  }

  mouseDown() {
    if (this._highlightedControlIdx !== undefined) {
      this._isEdit = true;
      return this._controlPoints[this._highlightedControlIdx].worldCoordinates;
    } else {
      return undefined;
    }
  }

  mouseUp() {
    if (this._highlightedControlIdx !== undefined) {
      this._isEdit = false;
      if (this._wasModified) {
        this.updateSpline();
        if (this._viewInteraction === VIEW_INTERACTION.ROTATION) {
          this._toogleLineVisibility();
        }
        this._controlPoints[this._highlightedControlIdx].showPoint(false);
        this._wasModified = false;
        return this._controlPoints[this._highlightedControlIdx]
          .worldCoordinates;
      }

      return undefined;
    }
  }

  toggleMeasurementsBoxVisibility(show = true) {
    /* Show // hide Measurements box. */
    const measurementsBox = document.getElementById(
      `box_scalarMeasurements${this._viewIndex}`
    );
    if (show && measurementsBox !== null) {
      measurementsBox.style.display = "";
    } else if (
      measurementsBox !== null &&
      measurementsBox.style.display !== "none"
    ) {
      measurementsBox.style.display = "none";
    }
  }

  _toogleLineVisibility(show = true) {
    if (show) {
      this.line.visible = true;
    } else if (this.line.visible) {
      this.line.visible = false;
    }
  }

  mouseMove(pixelCoordinates, worldCoordinates) {
    // 1st case - adjusting selected point
    if (this._modifiable) {
      if (this._highlightedControlIdx !== undefined && this._isEdit) {
        this._wasModified = true;
        const point = this._controlPoints[this._highlightedControlIdx];
        point.updatePosition(pixelCoordinates, worldCoordinates);
        this.toggleMeasurementsBoxVisibility(false);
        if (this.diametersGroup.visible) {
          this.diametersGroup.visible = false;
          this.hideDiameters();
        }

        if (this._viewInteraction === VIEW_INTERACTION.ROTATION) {
          this._toogleLineVisibility(false);
        } else {
          this.updateSpline();
        }
        return;
      }

      // 2nd case - highlighting specific control points
      let i = 0;
      for (const point of this._controlPoints) {
        if (
          distanceBetweenPoints2d(point.pixelCoordinates, pixelCoordinates) <=
            HANDLE_RADIUS &&
          (this._viewInteraction === VIEW_INTERACTION.SLICING ||
            Math.abs(point.pixelCoordinates[2]) <= MAX_DISTANCE_TO_PLANE)
        ) {
          this.showCircles(true);
          this._hideHandles();
          point.showPoint(true);
          this._highlightedControlIdx = i;
          return;
        } else {
          if (this._viewInteraction === VIEW_INTERACTION.SLICING) {
            this.showCircles(false);
          }
          point.showHandle(false);
          this._highlightedControlIdx = undefined;
          i++;
        }
      }
    }

    // 3rd case - highlighting all control points if close to annulus
    if (this._viewInteraction === VIEW_INTERACTION.SLICING) {
      for (const point of this._allPointsPixel) {
        if (distanceBetweenPoints2d(point, pixelCoordinates) <= HANDLE_RADIUS) {
          this.showCircles(true);
          return;
        }
      }
      this.showCircles(false);
    }
  }

  showCircles(show = true, force = false) {
    for (const point of this._controlPoints) {
      if (
        (this._viewInteraction === VIEW_INTERACTION.SLICING ||
          Math.abs(point.pixelCoordinates[2]) <= MAX_DISTANCE_TO_PLANE ||
          force) &&
        this._modifiable
      )
        point.showCircle(show);
    }
  }

  _hideHandles() {
    for (const point of this._controlPoints) {
      point.showHandle(false);
    }
  }

  updateSpline(nPoints = 100) {
    /* Create Three.js spline out of control points. */
    this.line.children = [];
    this._allPointsPixel = [];
    const threePoints = this._controlPoints.map(
      point =>
        new THREE.Vector3(
          point.worldCoordinates[0][0],
          point.worldCoordinates[1][0],
          point.worldCoordinates[2][0]
        )
    );
    const sampleClosedSpline = new THREE.CatmullRomCurve3(threePoints);
    sampleClosedSpline.curveType = "centripetal";
    sampleClosedSpline.closed = true;
    sampleClosedSpline.tension = 0.65;

    let positions = [];
    const point = new THREE.Vector3();

    let segmentPosition;
    for (let i = 0; i <= nPoints; i++) {
      const t = i / nPoints;
      sampleClosedSpline.getPoint(t, point);

      const pixelCoordinates = this._worldToPixel([
        [point.x],
        [point.y],
        [point.z],
        [1]
      ]);
      this._allPointsPixel.push(pixelCoordinates);

      const distance = pixelCoordinates[2];
      let pointPosition;
      if (distance < -MAX_DISTANCE_TO_PLANE) {
        pointPosition = _BELOW;
      } else if (distance > MAX_DISTANCE_TO_PLANE) {
        pointPosition = _ABOVE;
      } else {
        pointPosition = _WITHIN;
      }
      positions.push(point.x, point.y, point.z);
      if (segmentPosition === undefined) {
        // 1st point
        segmentPosition = pointPosition;
      } else if (pointPosition !== segmentPosition) {
        // start of new segment - point needs to be added to both old and new
        // segments; old segment can be added to group
        this.line.add(this._lineFromPostions(positions, segmentPosition));
        // starting new segement
        positions = [];
        positions.push(point.x, point.y, point.z);
        segmentPosition = pointPosition;
      }
    }
    // last segement
    this.line.add(this._lineFromPostions(positions, segmentPosition));
  }

  _lineFromPostions(positions, segmentPosition) {
    const geometry = new LineGeometry();
    geometry.setPositions(positions);
    let material;
    switch (segmentPosition) {
      case _BELOW:
        material = this._materials.splineBelow;
        break;
      case _ABOVE:
        material = this._materials.splineAbove;
        break;
      case _WITHIN:
        material = this._materials.splineWithin;
        break;
    }
    return new Line2(geometry, material);
  }

  zoom(newZoom) {
    let coordinates;
    for (const point of this._controlPoints) {
      coordinates = zoomCoordinates(
        point.pixelCoordinates,
        newZoom,
        this._zoom,
        this._rect
      );
      point.updatePosition(coordinates);
    }
    for (const diameter of this.diameterLines) {
      diameter.zoom(newZoom);
    }
    this._zoom = newZoom;
  }

  resize(newRect) {
    let coordinates;
    for (const point of this._controlPoints) {
      coordinates = resizeCoordinates(
        point.pixelCoordinates,
        newRect,
        this._rect
      );
      point.updatePosition(coordinates);
    }
    for (const diameter of this.diameterLines) {
      diameter.resize(newRect);
    }
    this._rect = newRect;
  }
}

class SurfaceAnnulus {
  /* Simplified version of annulus used in 3 views */
  constructor(points, viewIndex, materials) {
    this._controlPoints = points;
    this._viewIndex = viewIndex;
    this._materials = materials;
    this.line = undefined;
    this.updateSpline();
  }

  updatePoints(points) {
    this._controlPoints = points;
  }

  updateSpline(nPoints = 100) {
    /* Create Three.js spline out of control points. */
    const positions = [];

    const threePoints = this._controlPoints.map(
      point => new THREE.Vector3(point[0], point[1], point[2])
    );
    const sampleClosedSpline = new THREE.CatmullRomCurve3(threePoints);
    sampleClosedSpline.curveType = "centripetal";
    sampleClosedSpline.closed = true;

    const point = new THREE.Vector3();
    for (let i = 0; i <= nPoints; i++) {
      const t = i / nPoints;
      sampleClosedSpline.getPoint(t, point);
      positions.push(point.x, point.y, point.z);
    }

    if (this.line === undefined) {
      const geometry = new LineGeometry();
      geometry.setPositions(positions);
      this.line = new Line2(geometry, this._materials.splineWithin);
    } else {
      this.line.geometry.setPositions(positions);
    }
  }
}
