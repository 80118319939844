import _ from "lodash";
import * as THREE from "three";
import { FLUORO_ANGLES } from "../constants.js";

export function degreesToRadians(degrees) {
  const pi = Math.PI;
  return degrees * (pi / 180);
}

export function radiansToDegrees(radians) {
  const pi = Math.PI;
  return radians * (180 / pi);
}

export function clampFloat(val, lowerBound, upperBound) {
  if (val < lowerBound) {
    return lowerBound;
  } else if (val > upperBound) {
    return upperBound;
  } else {
    return val;
  }
}

export function clampInteger(value, lowerBound, upperBound) {
  if (upperBound < lowerBound) {
    throw new Error("Boundaries are swapped");
  }
  const val = Math.round(value);
  const lower = Math.round(lowerBound);
  const upper = Math.round(upperBound);
  if (val < lower) {
    return lower;
  } else if (val > upper) {
    return upper;
  } else {
    return val;
  }
}

export function cartesianToFluoro(x, y, z) {
  const length = Math.sqrt(x * x + y * y + z * z);
  x = x / length;
  y = y / length;
  z = z / length;
  const pi = Math.PI;
  let alpha, beta;

  if (y === 0) {
    if (x > 0) {
      alpha = pi / 2;
    } else if (x < 0) {
      alpha = -pi / 2;
    } else {
      alpha = 0;
    }
  } else {
    alpha = Math.atan(x / (-1 * y));
    if (y > 0) {
      if (alpha >= 0) {
        alpha -= pi;
      } else {
        alpha += pi;
      }
    }
  }
  beta = Math.asin(z);
  alpha = radiansToDegrees(alpha);
  beta = radiansToDegrees(beta);
  return {
    alpha: alpha,
    beta: beta
  };
}

export function fluoroToLabelsAngles(alpha, beta) {
  alpha = Math.round(alpha);
  beta = Math.round(beta);
  let alphaLabel, betaLabel, finalAlpha, finalBeta;

  if (alpha === 180) {
    alphaLabel = FLUORO_ANGLES.RPO;
    finalAlpha = 0;
  } else if (alpha < 180 && alpha > 90) {
    alphaLabel = FLUORO_ANGLES.LPO;
    finalAlpha = 180 - alpha;
  } else if (alpha <= 90 && alpha > 0) {
    alphaLabel = FLUORO_ANGLES.LAO;
    finalAlpha = alpha;
  } else if (alpha <= 0 && alpha >= -90) {
    alphaLabel = FLUORO_ANGLES.RAO;
    finalAlpha = Math.abs(alpha);
  } else if (alpha < -90 && alpha >= -180) {
    alphaLabel = FLUORO_ANGLES.RPO;
    finalAlpha = 180 - Math.abs(alpha);
  } else {
    throw new Error("Fluoro alpha angle out of range");
  }

  if (beta <= 90 && beta > 0) {
    betaLabel = FLUORO_ANGLES.CRAN;
    finalBeta = beta;
  } else if (beta <= 0 && beta >= -90) {
    betaLabel = FLUORO_ANGLES.CAUD;
    finalBeta = Math.abs(beta);
  } else {
    throw new Error("Fluoro beta angle out of range");
  }

  return {
    alphaLabel: alphaLabel,
    betaLabel: betaLabel,
    finalAlpha: finalAlpha,
    finalBeta: finalBeta
  };
}

export function matrixDot(A, B) {
  /* Matrix dot product */
  const result = new Array(A.length)
    .fill(0)
    .map(row => new Array(B[0].length).fill(0));

  return result.map((row, i) => {
    return row.map((val, j) => {
      return A[i].reduce((sum, elm, k) => sum + elm * B[k][j], 0);
    });
  });
}

export function matrix4Inv(A) {
  /* Calculate inverse of 4x4 matrix */
  const result = new Array(4).fill(0).map(row => new Array(4).fill(0));
  const flatResult = [];
  const inv = new THREE.Matrix4();
  inv.fromArray(A.flat());
  inv.invert();
  inv.toArray(flatResult);
  return result.map((row, idx) => {
    return flatResult.slice(4 * idx, 4 * idx + 4);
  });
}

export function distanceBetweenPoints3d(A, B) {
  /* Calculate Euclidean distance between two 3d points */
  const x = A[0][0] - B[0][0];
  const y = A[1][0] - B[1][0];
  const z = A[2][0] - B[2][0];
  return Math.sqrt(x * x + y * y + z * z);
}

export function distanceBetweenPoints2d(A, B) {
  /* Calculate Euclidean distance between two 2d points */
  const x = A[0] - B[0];
  const y = A[1] - B[1];
  return Math.sqrt(x * x + y * y);
}

export function angleWithY(pointCoordinates, centerCoordinates) {
  /* Return angle between vector (point - center) and X axis */
  let x = pointCoordinates[0] - centerCoordinates[0];
  let y = pointCoordinates[1] - centerCoordinates[1];
  const length = Math.sqrt(x * x + y * y);
  x = x / length;
  y = y / length;

  const alpha = radiansToDegrees(Math.acos(x));

  if (y >= 0 && x >= 0) {
    return 90 + alpha;
  } else if (y >= 0 && x < 0) {
    return alpha - 270;
  } else {
    return 90 - alpha;
  }
}
