<template>
  <div id="loginForm">
    <section class="hero is-fullheight is-medium is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <article class="card is-rounded has-max-width-50">
              <div class="card-content" @keyup.enter="login">
                <div class="field">
                  <article
                    id="errorMessage"
                    v-if="errorMessage"
                    class="message is-danger"
                  >
                    <div class="message-body">{{ errorMessage }}</div>
                  </article>
                  <p
                    class="
                      control
                      has-icons-left has-icons-right
                      margin-bottom-15
                    "
                  >
                    <input
                      id="emailField"
                      class="input"
                      type="email"
                      name="email"
                      v-model="email"
                      placeholder="Email"
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope has-text-dark-secondary"></i>
                    </span>
                  </p>
                </div>
                <div class="field">
                  <p class="control has-icons-left">
                    <input
                      id="passwordField"
                      class="input"
                      type="password"
                      v-model="password"
                      placeholder="Password"
                    />
                    <span class="icon is-small is-left">
                      <i class="fas fa-lock has-text-dark-secondary"></i>
                    </span>
                  </p>
                  <p
                    v-if="isReady && theme != 'default'"
                    class="has-text-dark-primary margin-all-5"
                  >
                    Log-in and Use is subject to the Agreement between Cardiac
                    Implants LLC (CI) and LARALAB GmbH. You acknowledge that
                    this application is a Work-in-Progress Prototype that shall
                    only be used internally by CI for evaluation and research
                    purposes (in particular not for Clinical Use) and that the
                    prototype may not be free of deficiencies.
                  </p>
                </div>
                <div
                  class="field"
                  :class="
                    isReady && theme == 'default' ? '' : 'has-text-centered'
                  "
                >
                  <p class="control">
                    <button
                      id="loginButton"
                      class="button is-success has-background-darker-grey"
                      @click="login"
                    >
                      Login
                    </button>
                  </p>
                </div>
                <div>
                  <a @click="switchToPasswordReset()" id="forgotPassword"
                    >Forgot Password?</a
                  >
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      theme: "default",
      isReady: false,
    };
  },
  props: {
    inactivityLogoutmessage: String,
  },
  beforeMount() {
    this.getUserTheme();
  },
  mounted() {
    if (this.inactivityLogoutmessage) {
      this.errorMessage = this.inactivityLogoutmessage;
    }
  },
  methods: {
    login: function () {
      if (this.email !== "" && this.password !== "") {
        axios
          .post("/api/login", {
            email: this.email,
            password: this.password,
          })
          .then((res) => {
            if (res.status === 200) {
              window.location.assign("/overview");
            } else {
              this.errorMessage = "Incorrect email or password";
            }
          })
          .catch((error) => {
            console.error("Login failed", error);
            this.showError = true;
            if (error.response.status === 401) {
              this.errorMessage = "Incorrect email or password";
            } else if (error.response.status === 403) {
              this.errorMessage =
                "Login attempts too frequent. Please wait before retrying.";
            } else {
              this.errorMessage = "Something went wrong. Please try again.";
            }
          });
      } else {
        this.errorMessage = "Enter username and password";
      }
    },
    getUserTheme: function () {
      axios
        .get("/api/get_theme")
        .then((res) => {
          this.theme = res.data.theme;
          this.isReady = true;
        })
        .catch(() => {
          console.log("Something went wrong");
        });
    },
    switchToPasswordReset() {
      this.$emit("switchToPasswordReset");
    },
  },
};
</script>
