<template>
  <div>
    <ResetPasswordComponent
      v-if="isResetPasswordComponentSeen"
      v-on:closeResetPassword="closeResetPassword"
    />
    <div v-else class="mt-5 columns is-centered">
      <div class="column is-one-fifth">
        <div
          class="message is-small"
          :class="message ? 'is-success' : 'is-danger'"
        >
          <div class="message-body has-text-weight-bold mt-2">
            <span v-if="message">{{ message }}</span>
            <span v-else>ACCESS DENIED</span>
          </div>
        </div>
        <div v-if="message" class="mt-3" @click="switchToLogin">
          <a><i class="fas fa-chevron-left"></i> BACK TO LOGIN</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ResetPasswordComponent from "@/components/ResetPassword.vue";
import axios from "axios";

export default {
  name: "ResetPassword",
  components: {
    ResetPasswordComponent,
  },
  data() {
    return {
      isResetPasswordComponentSeen: false,
      message: "",
    };
  },
  mounted() {
    const urlQueryString = window.location.search;
    const urlParams = new URLSearchParams(urlQueryString);
    const token = urlParams.get("t");

    axios
      .post("api/allow_reset_password", { token: token })
      .then((res) => {
        if (res.status === 200) {
          this.isResetPasswordComponentSeen = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    closeResetPassword(msg) {
      this.isResetPasswordComponentSeen = false;
      this.setMessage(msg);
    },
    setMessage: function (msg) {
      this.message = msg;
    },
    switchToLogin(msg = false) {
      window.location.href = "/";
    },
  },
};
</script>
