import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store/store";
import Navbar from "@/components/Navbar.vue";
import { sendErrors, sendWarnings } from "./js/errorHandler";
import * as helper from "@/js/helper.js";

Vue.config.productionTip = false;
window.Vue = Vue;
window.parseCookie = helper.parseCookie;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: function(h) {
    return h(Navbar);
  }
}).$mount("#navbar");

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");

// Global error handler for all synchronous uncaught errors within the Vue application
Vue.config.errorHandler = (error, vuemodule) => {
  sendErrors(error, vuemodule.$options.name);
};

// warn handler for developement
Vue.config.warnHandler = (warning, vuemodule, trace) => {
  sendWarnings(warning, trace);
};
