<template>
  <div class="widgets is-absolute is-full-height has-width-100-per">
    <div
      id="upperToolbar"
      class="upper-toolbar has-width-100-per is-absolute padding-10"
    >
      <div class="media">
        <div id="posesPanel" class="media-content has-text-right">
          <span
            v-for="(pose, index) in poses"
            :key="index"
            class="
              tag
              pose-border
              has-background-white
              pointer
              ml-1
              pl-2
              pr-2
              margin-bottom-1
            "
            :class="
              isPoseActive(pose) ? 'has-text-weight-bold' : 'has-opacity-80'
            "
            @click="activatePose(pose)"
          >
            <i
              v-if="pose.added_to_report"
              class="fas fa-camera camera-icon mr-1"
            >
            </i>
            <span class="pose-text non-selectable-text">{{
              pose.name
            }}</span></span
          >
        </div>
        <div id="screenshotPanel" class="media-right margin-top-1 ml-2">
          <div
            id="screenshotButton"
            class="screenshot-button pointer camera-tag"
            :class="
              isReadOnly
                ? 'tooltip is-tooltip-bottom is-tooltip-shifted-right'
                : ''
            "
            :data-tooltip="isReadOnly ? readOnlyWarning : ''"
            @click="isReadOnly ? '' : takeScreenshot()"
          >
            <img
              v-if="!currentPoseBookmarked"
              src="/static/images/camera_not_selected.png"
              class="img img-back"
            />
            <img
              v-if="!currentPoseBookmarked"
              src="/static/images/camera_hover.png"
              class="img img-top"
            />
            <img
              v-if="currentPoseBookmarked"
              src="/static/images/camera_selected.png"
              class="img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import * as constants from "../constants.js";

export default {
  name: "ToolsDashboard",
  props: {
    view: String,
    componentName: String,
    boxTitle: String,
    scalars: Array,
    poses: Array,
    viewIndex: Number,
    currentPoseBookmarked: Boolean,
    activeSlices: Number,
  },
  data() {
    return {
      readOnlyWarning: constants.READ_ONLY_WARNING,
      poseIndex: 0,
      // TODO: Connect to backend function
    };
  },
  watch: {
    poses: function () {
      this.poseIndex++;
    },
  },
  computed: {
    isReadOnly() {
      return (
        this.$store.getters.currentAccessMode === constants.ACCESS_MODE_READONLY
      );
    },
  },
  methods: {
    isPoseActive: function (pose) {
      return this.activeSlices === pose.transformation;
    },
    takeScreenshot() {
      this.$emit("takeScreenshot", this.viewIndex);
    },
    activatePose(pose) {
      this.$emit("setPose", this.viewIndex, pose);
    },
  },
};
</script>

<style scoped>
.upper-toolbar {
  z-index: 11;
  width: 100%;
}

.camera-tag {
  min-width: 43px;
  height: 43px;
}

.camera-tag:hover {
  color: #c4c4c4;
  opacity: unset;
}

.img {
  width: 43px;
  height: 43px;
}

.camera-tag .img-top {
  display: none;
}

.camera-tag:hover .img-top {
  display: block;
}

.camera-tag:hover .img-back {
  display: none;
}

.pose-border {
  min-width: 55px;
  height: 19px;
  border-radius: 5px;
  text-align: center;
}

.pose-border:hover {
  opacity: unset;
}

.pose-text {
  font-size: 12px;
  text-transform: uppercase;
}

.chevron {
  line-height: unset;
}

.camera-icon {
  color: grey;
  opacity: 0.4;
}

.is-tooltip-shifted-right::before,
.is-tooltip-shifted-right:hover::before {
  transform: translate(-103px, 0.5rem) !important;
  width: 125px;
  white-space: normal;
}
</style>
