import {
  TOOL,
  VIEW_INTERACTION,
  VIEW_TYPE,
  STATE_CHANGE_EVENT
} from "../constants.js";

import { WebGLRenderer, OrthographicCamera } from "three";
import * as THREE from "three";
import _ from "lodash";

import {
  clampFloat,
  matrixDot,
  degreesToRadians,
  matrix4Inv,
  angleWithY
} from "./math.js";
import { LineController } from "./line.js";
import { AnnulusController } from "./annulus.js";

export default class View {
  /* Widget rendering data at one of the four views of the CT Planner.
  Also responsible for interactions inside this view (e.g zoom, slice, contrast, etc). */
  constructor(index, cache, viewController) {
    this.index = index;
    this.cache = cache;
    this.viewController = viewController;
    this.viewProperties;
    this._pixelToWorld = this._pixelToWorld.bind(this);
    this._worldToPixel = this._worldToPixel.bind(this);
    this._getBoundingRectangle = this._getBoundingRectangle.bind(this);
    this._updateViewProperties();
    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
      precision: "lowp",
      preserveDrawingBuffer: true
    });

    this.canvases = this._createCanvases();
    this._setBorder();
    const resizeObserver = new ResizeObserver(entry => {
      this._resizeRendererToDisplaySize(this.renderer, this.camera);
    });
    resizeObserver.observe(document.getElementById(`viewer-${this.index}`));
    this.camera = new OrthographicCamera(
      1 / -2,
      1 / 2,
      1 / 2,
      1 / -2,
      0.1,
      10000
    );
    this.scene = new THREE.Scene();
    this.light = new THREE.DirectionalLight(0xffffff, 1);

    this.waitingCt = true;
    this.waitingGeometries = true;

    this._mouseLeftDown = false;
    this._mousePositionPixel = undefined;
    this._mousePositionWorld = undefined;

    this._lineController = new LineController(
      this.cache.caseProtocol,
      this.cache,
      this.index,
      this.viewController,
      this.scene,
      this._getBoundingRectangle,
      this._pixelToWorld,
      this._worldToPixel
    );

    this._annulusController = new AnnulusController(
      this.index,
      this.viewController,
      this.scene,
      this._getBoundingRectangle,
      this._pixelToWorld,
      this._worldToPixel
    );
    this._setInteractions();

    // start THREE.js rendering loop
    this._animate();
  }

  update(change, forceUpdate = false, kwargs = {}) {
    /* Update the view */
    if (change === STATE_CHANGE_EVENT.BOARD_CHANGED) {
      this._updateViewProperties();
      this._clear();
      this._lineController.updateBoard(this.viewController.currentBoard.id_);
      this._annulusController.hideCircles();
      this._annulusController.updateBoard(this.viewController.currentBoard.id_);
      if (this.viewProperties.type === VIEW_TYPE.SURFACE) {
        this._renderSurface();
      } else {
        this._resetCameraControlVectors();
        this._resizeRendererToDisplaySize(this.renderer, this.camera);
        this._zoomSurface();
        this._renderCT();
      }
      this._setCross();

      this.viewController.updateFluoroAngles(this.index);
    } else if (change === STATE_CHANGE_EVENT.SURFACE_LOADED) {
      if (this.viewProperties.type === VIEW_TYPE.SURFACE) {
        this._renderSurface();
        this.viewController.updateFluoroAngles(this.index);
      }
      this._setCross();
    } else if (
      change === STATE_CHANGE_EVENT.ZOOM &&
      this.viewProperties.type === VIEW_TYPE.MPR
    ) {
      this._zoomSurface();
      this._zoomAndCenterCt();
      this._lineController.zoom();
      this._annulusController.zoom();
    } else if (change === STATE_CHANGE_EVENT.PLANES_LOADED) {
      this._resetCameraControlVectors();
      this._resizeRendererToDisplaySize(this.renderer, this.camera);
      this._zoomSurface();
      if (this.viewProperties.type === VIEW_TYPE.MPR) {
        this._setCross();
      }
      this.viewController.updateFluoroAngles(this.index);
    } else if (change === STATE_CHANGE_EVENT.MEASUREMENTS_LOADED) {
      this._addViewGeometries();
    } else if (change === STATE_CHANGE_EVENT.SLICE_CHANGED) {
      if (
        this.viewProperties.type === VIEW_TYPE.MPR &&
        (this.waitingCt || forceUpdate)
      ) {
        this._renderCT();
      }
    } else if (change === STATE_CHANGE_EVENT.OVL_FLAG_CHANGED) {
      if (this.viewProperties.type === VIEW_TYPE.MPR) {
        this._renderCT();
      }
    } else if (change === STATE_CHANGE_EVENT.FULLSCREEN_CHANGED) {
      if (this.viewProperties.type === VIEW_TYPE.MPR) {
        this._resizeRendererToDisplaySize(this.renderer, this.camera);
        this._renderCT();
      }
    } else if (change === STATE_CHANGE_EVENT.CT_SLICE_LOADED) {
      if (this.waitingGeometries) {
        this._addViewGeometries();
      }
      if (
        this.viewProperties.type === VIEW_TYPE.MPR &&
        (this.waitingCt || forceUpdate)
      ) {
        this._renderCT();
        this.viewController.updateFluoroAngles(this.index);
      }
    } else if (change === STATE_CHANGE_EVENT.ANNULUS_UPDATED) {
      this._annulusController.updatePoints();
    } else if (
      change === STATE_CHANGE_EVENT.ANNULUS_POINT_SELECTED &&
      this.viewProperties.type === VIEW_TYPE.MPR
    ) {
      this._setSliceIndexFromPoint(kwargs.worldCoordinates, kwargs.viewIndex);
    } else if (
      (change === STATE_CHANGE_EVENT.CONTRAST ||
        change === STATE_CHANGE_EVENT.OVL_STATUS_CHANGED) &&
      this.viewProperties.type === VIEW_TYPE.MPR
    ) {
      this._renderCT(false);
    }
    this._updateCameraPosition();
  }

  _setSliceIndexFromPoint(worldCoordinates, triggeringViewIndex) {
    /* Update slice index from point in world coordinates system.
    Only one SAX view is responsible for triggereing updates for all MPR
    views. */

    if (!this._isRotationView()) {
      const volumeCoordinates = matrixDot(
        this._worldToVolume(),
        worldCoordinates
      );
      // 1. update slice index of SAX view
      const minSlice = this._getHeartPlane().ct_planner_info.min_slice;
      const z = Math.round(volumeCoordinates[2][0]);
      const increment = z + minSlice - this._sliceIndex();
      if (increment !== 0) {
        this.viewController.updateSliceIndex(this.index, increment);
      }
      // 2. Update slice indeces LAX views
      // First LAX view is a "control" view; its slice is updated
      // and the slice of the dependent view is updated automatically
      if (
        this.viewController.getInteractionByViewIndex(triggeringViewIndex) ===
        VIEW_INTERACTION.SLICING
      ) {
        const laxViewIndex = this.viewController.findFirstRotationView();
        if (laxViewIndex !== undefined) {
          const pixelCoordinates = this._worldToPixel(worldCoordinates);
          const {
            voxelToPixel,
            boundingRectangle
          } = this._getVoxelToPixelConversion();
          const centerCoordinates = [
            boundingRectangle.width / 2,
            boundingRectangle.height / 2
          ];
          const laxSlice = Math.round(
            angleWithY(pixelCoordinates, centerCoordinates) /
              this._getHeartPlane().rotation_step
          );

          const laxIncrement =
            laxSlice - this.viewController.sliceIndexByView[laxViewIndex];
          if (laxIncrement !== 0) {
            this.viewController.updateSliceIndex(laxViewIndex, laxIncrement);
          }
        }
      }
    }
  }

  _addViewGeometries() {
    /* Add geometries attached to a specific view (e.g tricuspid annulus)
    Note that custom line measurements are attached to poses of the views */
    if (this.viewProperties.type === VIEW_TYPE.MPR) {
      if (
        this._volumeToWorld !== undefined &&
        this.waitingGeometries &&
        this.cache.measurementsIntitalized &&
        !this._annulusController.isInitialized()
      ) {
        this._annulusController.init();
        this._lineController.loadLines(this.cache.caseProtocol);
        this.waitingGeometries = false;
      }
    } else if (this.viewProperties.type === VIEW_TYPE.SURFACE) {
      if (
        this.waitingGeometries &&
        this.cache.measurementsIntitalized &&
        !this._annulusController.isInitialized()
      ) {
        this._annulusController.init();
        this.waitingGeometries = false;
      }
    }
  }

  _sliceIndex() {
    if (this._isRotationView()) {
      /* clip the sliceIndex to [0, 180) range. Rotations from range [180, 360)
      are achieved via mirroring. */
      const maxSlice = this._getHeartPlane().ct_planner_info.max_slice;
      return this.viewController.sliceIndexByView[this.index] % (maxSlice + 1);
    } else {
      return this.viewController.sliceIndexByView[this.index];
    }
  }

  _animate() {
    /* start THREE.js rendering loop */
    this.renderer.setAnimationLoop(() => {
      this.renderer.render(this.scene, this.camera);
    });
  }

  _resizeRendererToDisplaySize() {
    /* Renderers size is tied to the elements size.

    We need to update whenever the size changes, e.g.
     - on window resize
     - on change of different views to be shown
    */
    const canvas = this.renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    /* Resize only when there's a change in width or height. But when
    fullscreen is toggled, the disabled views' width and height become 0. In
    that case, these views should not be resized because otherwise the material
    of the fullscreen view is affected and there is no need to resize these
    views anyway. */
    const needResize =
      (canvas.width !== width || canvas.height !== height) &&
      width > 0 &&
      height > 0;
    if (needResize) {
      this.renderer.setSize(width, height, false);
      this.viewController.materials.resize(width, height);
      const rect = this._getBoundingRectangle();
      this._lineController.resize(rect);
      this._annulusController.resize(rect);
      // Zoom is tied to the height, so we need the aspect ratio to pass the proper width
      this.camera.userData = { aspectRatio: width / height };

      this.camera.left = this.camera.bottom * this.camera.userData.aspectRatio;
      this.camera.right = this.camera.top * this.camera.userData.aspectRatio;

      // Update projection matrix for the changes to take effect
      this.camera.updateProjectionMatrix();
      if (this.cache.initialized && this._sliceCenter !== undefined) {
        this._zoomAndCenterCt();
      }
    }
  }

  onDelete() {
    /* Handle `delete` button pressed event */
    this._lineController.onDelete();
  }

  _setInteractions() {
    /* Register possible interaction inside the view */
    // Overwrite the default browser behavior of dragging the images
    this.canvases.threejs.draggable = false;
    this.canvases.overlay.draggable = false;

    this.canvases.overlay.addEventListener("mousedown", e => {
      if (e.button === 0) {
        this._mouseLeftDown = true;
        if (this.viewProperties.type === VIEW_TYPE.MPR) {
          if (
            !this._lineController.isActive() &&
            this.viewController.currentTool !== TOOL.MEASURING
          ) {
            this._annulusController.mouseDown();
          }
          this._lineController.mouseDown();
        }
      }
    });
    this.canvases.overlay.addEventListener("mousemove", e => {
      if (this.viewController.activityMonitor) {
        this.viewController.activityMonitor.postMessage({
          "mouse move": this.currentMode
        });
      }
      this._updateMousePosition(e);
      if (this.viewController.currentTool === TOOL.MEASURING) {
        if (this.viewProperties.type === VIEW_TYPE.MPR) {
          document.getElementById(`viewer-${this.index}`).style.cursor =
            "url(/static/cursors/line.cur), auto";
        }
      } else if (this.viewController.currentTool === TOOL.CONTRAST) {
        if (this.viewProperties.type === VIEW_TYPE.MPR) {
          document.getElementById(`viewer-${this.index}`).style.cursor =
            "url(/static/cursors/window.cur), auto";
        }
      } else if (this.viewController.currentTool === TOOL.ZOOM) {
        if (this.viewProperties.type === VIEW_TYPE.MPR) {
          document.getElementById(`viewer-${this.index}`).style.cursor =
            "url(/static/cursors/zoom.cur), auto";
        }
      } else if (this.viewController.currentTool === TOOL.DEFAULT) {
        if (
          this.viewProperties.type === VIEW_TYPE.MPR &&
          !this._isRotationView()
        ) {
          document.getElementById(`viewer-${this.index}`).style.cursor =
            "url(/static/cursors/slice.cur), auto";
        } else if (
          this.viewProperties.type === VIEW_TYPE.MPR &&
          this._isRotationView()
        ) {
          document.getElementById(`viewer-${this.index}`).style.cursor =
            "url(/static/cursors/rotate.cur), auto";
        }
      } else if (this.viewController.currentTool === TOOL.SPLINE) {
        if (this.viewProperties.type === VIEW_TYPE.MPR) {
          document.getElementById(`viewer-${this.index}`).style.cursor =
            "url(/static/cursors/spline.cur), auto";
        }
      } else {
        document.getElementById(`viewer-${this.index}`).style.cursor =
          "default";
      }

      // Handle lines highlighting or adjustment.
      if (
        this.viewProperties.type === VIEW_TYPE.MPR &&
        [TOOL.DEFAULT, TOOL.MEASURING, TOOL.CONTRAST, TOOL.ZOOM].includes(
          this.viewController.currentTool
        )
      ) {
        if (
          !this._annulusController.isActive &&
          !this._annulusController.isPointHighlighted()
        ) {
          this._lineController.mouseMove(
            this._mousePositionPixel,
            this._mousePositionWorld
          );
        }
        if (
          !this._lineController.isActive() &&
          !this._lineController.isPointHighlighted() &&
          this.viewController.currentTool !== TOOL.MEASURING
        ) {
          this._annulusController.mouseMove(
            this._mousePositionPixel,
            this._mousePositionWorld
          );
        }
      }

      if (this._mouseLeftDown) {
        const deltaX = e.movementX / this.canvases.threejs.clientWidth;
        const deltaY = (-1 * e.movementY) / this.canvases.threejs.clientHeight;
        if (this.viewProperties.type === VIEW_TYPE.MPR) {
          if (
            !this._lineController.isActive() &&
            !this._annulusController.isActive
          ) {
            switch (this.viewController.currentTool) {
              case TOOL.DEFAULT:
                this.viewController.updateSliceIndex(this.index, e.movementY);
                this.viewController.updateFluoroAngles(this.index);

                break;
              case TOOL.CONTRAST:
                const deltaWindow = 3000 * deltaX;
                const deltaLevel = 3000 * deltaY;
                this.viewController.updateContrast(deltaWindow, deltaLevel);
                break;
              case TOOL.ZOOM:
                const delta = 2 * (50 - 200) * deltaY;
                this.viewController.updateMPRZoom(delta);
                break;
            }
          }
        } else if (this.viewProperties.type === VIEW_TYPE.SURFACE) {
          this._rotateCamera(deltaX * 5, -deltaY * 5);
          this.viewController.updateFluoroAngles(this.index);
        }
      }
    });
    this.canvases.overlay.addEventListener("mouseup", e => {
      if (e.button === 0) {
        this._mouseLeftDown = false;

        if (this.viewProperties.type === VIEW_TYPE.MPR) {
          if (this.viewController.currentTool === TOOL.MEASURING) {
            this._lineController.mouseUp(
              this._mousePositionPixel,
              this._mousePositionWorld
            );
            // Switch the tool back to DEFAULT
            if (!this._lineController.isActive()) {
              this.viewController.currentTool = TOOL.DEFAULT;
              document.dispatchEvent(new CustomEvent("finishMeasurement"));
            }
          } else {
            this._annulusController.mouseUp(
              this._mousePositionPixel,
              this._mousePositionWorld
            );
          }
          if (
            [TOOL.DEFAULT, TOOL.CONTRAST, TOOL.ZOOM].includes(
              this.viewController.currentTool
            ) &&
            this._lineController.isActive()
          ) {
            this._lineController.mouseUp(
              this._mousePositionPixel,
              this._mousePositionWorld
            );
          }
        }
      }
    });
    this.canvases.overlay.addEventListener("mouseleave", () => {
      // finish annulus // line adjustment or creation
      if (this._annulusController.isActive) {
        this._annulusController.mouseUp(
          this._mousePositionPixel,
          this._mousePositionWorld
        );
      }
      if (this._lineController.isActive()) {
        this._lineController.mouseUp(
          this._mousePositionPixel,
          this._mousePositionWorld
        );
      }
      if (this._mouseLeftDown) {
        this._mouseLeftDown = false;
      }
    });
    this.canvases.overlay.addEventListener("wheel", e => {
      if (this.viewProperties.type === VIEW_TYPE.SURFACE) {
        const delta = -0.005 * 500 * e.deltaY;
        this.viewController.updateSurfaceZoom(delta);
        this._zoomSurface();
      }
    });
  }

  _updateMousePosition(event) {
    /* Update the mouse position in the Pixel and 3d world coordinate system.
    Composes of:
    1. Getting mouse position in pixel coordinate system
    2. Move the origin to be at the center of the view
    3. Transform to ct-planner-slice coordinate system (zoom and move the origin back to the upper left corner)
    4. Transform to world coordinate system */
    const {
      voxelToPixel,
      boundingRectangle
    } = this._getVoxelToPixelConversion();

    this._mousePositionPixel = [
      event.clientX - boundingRectangle.left, // TODO: dependent on pointer
      event.clientY - boundingRectangle.top
    ];

    if (
      this.viewProperties.type === VIEW_TYPE.MPR &&
      this._sliceCenter !== undefined
    ) {
      this._mousePositionWorld = this._pixelToWorld(this._mousePositionPixel);
    }
  }

  _worldToVolume() {
    return matrix4Inv(this._volumeToWorld);
  }

  _worldToPixel(worldCoordinates) {
    /* Transformation from world to pixel.
    Returns pixel coordinates. */
    const volumeCoordinates = matrixDot(
      this._worldToVolume(),
      worldCoordinates
    );
    let x = volumeCoordinates[1][0];
    let y = volumeCoordinates[0][0];
    let z = volumeCoordinates[2][0];

    const {
      voxelToPixel,
      boundingRectangle
    } = this._getVoxelToPixelConversion();
    const { translateX, translateY, scale } = this._transformation();
    x = (x - this._sliceCenter[1][0]) * scale * voxelToPixel;
    y = (y - this._sliceCenter[0][0]) * scale * voxelToPixel;

    if (this._isMirrored()) {
      y = -y;
    }
    x = x + boundingRectangle.width / 2;
    y = y + boundingRectangle.height / 2;

    // distance to current plane (e.g used to assign different colors for annulus points)
    z = z - this._sliceCenter[2][0];

    return [x, y, z];
  }

  _pixelToWorld(pixelCoordinates) {
    /* Transformation from pixel to world */
    const {
      voxelToPixel,
      boundingRectangle
    } = this._getVoxelToPixelConversion();
    let x = pixelCoordinates[0] - boundingRectangle.width / 2;
    let y = pixelCoordinates[1] - boundingRectangle.height / 2;

    // we mirror the slices for rotational views for angles > 180 degrees -
    // thus we need to mirror the coordinates here as well. This is simply an
    // inverse cause y is already in a coordinate system with origin at the
    // center of the image
    if (this._isMirrored()) {
      y = -y;
    }

    // coordinates in ct volume cs with origin at the upper left corner of the slice
    const { translateX, translateY, scale } = this._transformation();
    x = x / scale / voxelToPixel + this._sliceCenter[1][0];
    y = y / scale / voxelToPixel + this._sliceCenter[0][0];

    let z = 0;
    if (!this._isRotationView()) {
      const minSlice = this._getHeartPlane().ct_planner_info.min_slice;
      z = this._sliceIndex() - minSlice;
    } else {
      z = this._sliceCenter[2][0];
    }

    return matrixDot(this._volumeToWorld, [[y], [x], [z], [1]]);
  }

  _updateViewProperties() {
    /* ViewProperties are taken from the case protocol view item.
    This function updates them, e.g when the board is changed */
    this.viewProperties = this.viewController.currentBoard.views[this.index];
    this._setBorder();

    this.viewController.surfaceZoom = 200; // default value in case there is no at the caseProtocol for this view
  }

  _createCanvases() {
    /* Three canvases are used:
      - ct to display the ct slices
      - overlaysCanvas to display the overlays as svg images
      - threejs to display the three.js objects: geometries
        (like line, annulus, crosshairs) and 3d surfaces in case
        the view is a surface view. */

    const ct = document.createElement("canvas");
    ct.id = `ct-${this.index}`;
    ct.classList.add("canvas");
    ct.width = 512; // Initial value, will be scaled by css
    ct.height = 512; // Initial value, will be scaled by css

    const overlay = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg"
    );
    overlay.classList.add("overlay");
    overlay.setAttribute("width", "512");
    overlay.setAttribute("height", "512");

    const threejs = this.renderer.domElement;
    threejs.id = `measure-${this.index}`;

    // @ts-ignore
    threejs.style = "";
    threejs.classList.add("canvas");
    threejs.width = 512;
    threejs.height = 512;

    // Context loss restoration
    // handles context loss gracefully https://discourse.threejs.org/t/context-lose-webgl-losecontext-context-lose-in-chrome-after-some-time/5227

    threejs.addEventListener(
      "webglcontextlost",
      e => {
        console.log("lost context");
        e.preventDefault();
      },
      false
    );
    // threejs.addEventListener(
    //   "webglcontextrestored",
    //   e => {
    //     this.viewerState.dispatchStateChangeEvent({});
    //     e.preventDefault();
    //   },
    //   false
    // );

    // Attach canvases and overlays to DOM
    const viewer = document.getElementById(`viewer-${this.index}`);
    viewer.appendChild(ct);
    viewer.appendChild(threejs);
    viewer.appendChild(overlay);

    return {
      ct: ct,
      overlay: overlay,
      threejs: threejs,
      context: ct.getContext("2d", {
        alpha: true
      })
    };
  }

  _clear() {
    /* Clear the canvases and scenes; reset parameters derived
    from user interaction */
    // clear three js scene
    this.scene.children = [];
    // clear ct image canvas
    this.canvases.context.clearRect(
      0,
      0,
      this.canvases.ct.width,
      this.canvases.ct.height
    );
    // clear overlays
    this.canvases.overlay.innerHTML = "";
  }

  _getHeartPlane() {
    /* Get the Heart Plane - sepcific plane of the heart which may have
    multiple poses (e.g standard.axial, tricuspid_annulus.sax, etc)
    used by this view. */
    return this.cache.getHeartPlaneForView(
      this.viewController.currentBoard.phase,
      this.viewController.currentBoard.heart_plane_group,
      this.viewProperties.heart_plane
    );
  }

  _setBorder() {
    const viewer = document.getElementById(`viewer-${this.index}`);
    const mprColors = ["#DB0B0B", "#1BDD45", "#258BC4"];

    let j = 0;
    _.range(0, 4).forEach(i => {
      if (this.viewController.currentBoard.views[i].type === VIEW_TYPE.MPR) {
        if (i === this.index) {
          viewer.style.border = `2px solid ${mprColors[j]}`;
        }
        j++;
      } else {
        if (i === this.index) {
          viewer.style.border = `2px solid grey`;
        }
      }
    });
  }

  _renderCT(updateAnnulus = true) {
    /* Render CT data */
    const sliceObject = this.cache.ctCache.slicesByPhase[
      this.viewController.currentBoard.phase
    ][this.viewController.currentBoard.heart_plane_group][
      this.viewProperties.heart_plane
    ][this._sliceIndex()];

    if (typeof sliceObject.ct !== "undefined") {
      const xextent = sliceObject.shape[0];
      const yextent = sliceObject.shape[1];
      this.canvases.ct.width = yextent;
      this.canvases.ct.height = xextent;

      const imgData = this.canvases.context.createImageData(yextent, xextent);
      for (let ix = 0; ix < sliceObject.ct.length; ix++) {
        // Place CT data in canvas.
        const grayValue = this.viewController.contrastLookUpTable[
          sliceObject.ct[ix]
        ];
        imgData.data[ix * 4 + 0] = grayValue;
        imgData.data[ix * 4 + 1] = grayValue;
        imgData.data[ix * 4 + 2] = grayValue;
        imgData.data[ix * 4 + 3] = 255;
      }
      this.canvases.ct.style.setProperty("display", "block");
      this.canvases.ct.style.removeProperty("filter");
      this.canvases.context.putImageData(imgData, 0, 0);
      this._sliceCenter = sliceObject.center;
      this._volumeToWorld = sliceObject.volumeToWorld;
      this._zoomAndCenterCt(sliceObject);
      this.canvases.overlay.innerHTML = "";

      if (this.viewController.isOverlaysActive) {
        this.canvases.overlay.style.removeProperty("filter");
        this.canvases.overlay.innerHTML = sliceObject.overlay;
        const labelContainer = document.getElementById(
          `labelContainer_${this.index}`
        );
        const labelText = document.getElementById(`labelText_${this.index}`);
        const paths = this.canvases.overlay.getElementsByTagName("path");

        for (const path of paths) {
          path.addEventListener(
            "mouseover",
            e => {
              path.style.strokeWidth = 2;
              const currentStructure = path.getAttribute("class");
              labelContainer.style.display = "block";
              labelText.innerHTML = currentStructure;
            },
            { capture: true }
          );
          path.addEventListener("mouseout", e => {
            path.style.strokeWidth = 0;
            labelContainer.style.display = "none";
            labelText.innerHTML = "";
          });
        }
      }
      this.waitingCt = false;
      if (updateAnnulus) {
        this._annulusController.updateSliceIndex();
      }
      this._lineController.updateSliceIndex(
        this.viewController.sliceIndexByView[this.index]
      );
    } else {
      this.canvases.overlay.style.setProperty("filter", `blur(5px)`);
      this.canvases.ct.style.setProperty("filter", `blur(5px)`);
      this.waitingCt = true;
      this._annulusController.hideCircles();
      this._lineController.clearViewer();
      // Request ctFetchQueue.worker to fetch this slice directly
      if (!this._mouseLeftDown || this._annulusController.isActive) {
        this.cache.ctCache.requestOneSlice([
          this.viewController.currentBoard.heart_plane_group,
          this.viewProperties.heart_plane,
          this.viewController.currentBoard.phase,
          this._sliceIndex()
        ]);
      }
    }
  }

  _zoomAndCenterCt() {
    const transformInfo = this._ctCanvasTransformation();
    this.canvases.ct.style.setProperty("transform", transformInfo);
    this.canvases.overlay.style.setProperty("transform", transformInfo);
  }

  _transformation() {
    const {
      voxelToPixel,
      boundingRectangle
    } = this._getVoxelToPixelConversion();

    // Height
    const heightInWorldDistance =
      (boundingRectangle.height / voxelToPixel) *
      this._getHeartPlane().ct_planner_info.spacing[0];
    const translateY =
      (this.canvases.ct.height / 2 - this._sliceCenter[0]) * voxelToPixel;
    const translateX =
      (this.canvases.ct.width / 2 - this._sliceCenter[1]) * voxelToPixel;

    const scale = heightInWorldDistance / this.viewController.mprZoom;

    return { translateX, translateY, scale };
  }

  _ctCanvasTransformation() {
    /* Calculates properties needed for zooming and centering of the ct
      image and overlay */
    const { translateX, translateY, scale } = this._transformation();

    if (this._isMirrored()) {
      return `scale(${scale}, ${-scale}) translate(${translateX}px, ${translateY}px)`;
    }

    return `scale(${scale}) translate(${translateX}px, ${translateY}px)`;
  }

  _isMirrored() {
    /* Return true in case slice needs to be mirrored: rotation goes beyound 180 degrees */
    if (this._isRotationView()) {
      const maxSlice = this._getHeartPlane().ct_planner_info.max_slice;
      if (
        Math.floor(
          this.viewController.sliceIndexByView[this.index] / (maxSlice + 1)
        ) >= 1
      ) {
        return true;
      }
    }
    return false;
  }

  _isRotationView() {
    /* Return turn if this view has default rotation interaction */
    return this._getHeartPlane().interaction === VIEW_INTERACTION.ROTATION;
  }

  _getBoundingRectangle() {
    /* Return the bounding rectangle of the canvases. This is equal to
    the rectangle of the viewer but without 2px borders */
    let boundingRectangle = this.canvases.ct.parentElement.getBoundingClientRect();
    boundingRectangle = {
      left: boundingRectangle.left + 2,
      top: boundingRectangle.top + 2,
      height: boundingRectangle.height - 4,
      width: boundingRectangle.width - 4
    };
    return boundingRectangle;
  }

  _getVoxelToPixelConversion() {
    /* Gets a voxel to pixel ratio.

    For every plane our CT images have a different extent which is stored in the canvas width and height and the aspect ratio is not always equal.

    Also the aspect ratio of the container we show the CT image in changes in size.

    Mainly this serves the purpose of accounting for letterboxing due to object-fit: contain
    */
    const boundingRectangle = this._getBoundingRectangle();

    const canvasRatio = this.canvases.ct.height / this.canvases.ct.width;
    const rectangleRatio = boundingRectangle.height / boundingRectangle.width;

    let voxelToPixel;
    if (canvasRatio > rectangleRatio) {
      // Letterbox left and right -> So canvas covers full height of bounding rectangle and we can get the ratio from it.
      voxelToPixel = boundingRectangle.height / this.canvases.ct.height;
    } else {
      // Letterbox top and bottom -> So canvas covers full width of bounding rectangle and we can get the ratio from it.
      voxelToPixel = boundingRectangle.width / this.canvases.ct.width;
    }

    return { voxelToPixel, boundingRectangle };
  }

  _setCross() {
    if (this.viewProperties.type === VIEW_TYPE.MPR) {
      this.scene.add(this.viewController.cross.crosses[this.index]);
    }
  }

  _resetCameraControlVectors() {
    /* Reset THREE.js camera control vectors according to heart plane information.

    Camera position is defined by:
    1. sceneCenter: 3d point in world coordinate system
         where the camera is looking at
    2. normal: 3d vector defining the camera view direction
    3. upVector: 3d vector defining the camera up direction

    Note that this is a helper function which doesn't update the camera!
    */
    const heartPlane = this._getHeartPlane();
    const wtv = heartPlane.ct_planner_info.world_to_volume;

    this.sceneCente = new THREE.Vector3(
      ...heartPlane.ct_planner_info.center_world
    );

    const upVector = new THREE.Vector3(
      wtv[0][0],
      wtv[0][1],
      wtv[0][2]
    ).normalize();

    const normal = new THREE.Vector3(
      wtv[2][0],
      wtv[2][1],
      wtv[2][2]
    ).normalize();
    this.viewController.upVectorByView[this.index] = upVector;
    this.viewController.normalByView[this.index] = normal;
  }

  _updateCameraPosition() {
    /* Set the camera position for surface rendering view
    using current north and viewUp vectors. */
    const position = new THREE.Vector3();
    const dir = new THREE.Vector3()
      .copy(this.viewController.normalByView[this.index])
      .multiplyScalar(-500.0);
    position.copy(this.sceneCente).add(dir);

    this.light.position.set(position.x, position.y, position.z);
    this.camera.position.copy(position);

    // Important: Make sure up is set before lookAt.
    this.camera.up = new THREE.Vector3()
      .copy(this.viewController.upVectorByView[this.index])
      .multiplyScalar(-1);
    this.camera.lookAt(this.sceneCente);
  }

  // methods of surface view
  _renderSurface() {
    /* Surface rendering view is controlled via:
    Zooming is controlled by the size of the plane on which the models are projected (see `_zoomSurface()`) */

    this._resetCameraControlVectors();
    this._updateCameraPosition();
    this.canvases.threejs.style.setProperty("display", "block");

    this.scene.add(this.light);
    this.scene.add(
      this.cache.modelsByPhase[this.viewController.currentBoard.phase].scenes[
        this.viewProperties.id_
      ].data
    );

    this._resizeRendererToDisplaySize(this.renderer, this.camera);
    this._zoomSurface();
  }

  setSurfacePose(pose) {
    /* Set pose for the surface view. */
    this.viewController.normalByView[
      this.index
    ] = new THREE.Vector3().fromArray(pose.normal_vector);
    this.viewController.upVectorByView[
      this.index
    ] = new THREE.Vector3().fromArray(pose.up_vector);
    this._updateCameraPosition();
    this.viewController.updateFluoroAngles(this.index);
    if (pose.zoom.value !== undefined) {
      this.viewController.surfaceZoom = pose.zoom.value;
    } else {
      this.viewController.surfaceZoom = 200;
    }
    this._zoomSurface();
  }

  _zoomSurface() {
    let zoom;
    if (this.viewProperties.type === VIEW_TYPE.SURFACE) {
      zoom = this.viewController.surfaceZoom;
    } else {
      zoom = this.viewController.mprZoom;
    }
    this.camera.top = zoom / 2;
    this.camera.bottom = -zoom / 2;
    this.camera.left = this.camera.bottom * this.camera.userData.aspectRatio;
    this.camera.right = this.camera.top * this.camera.userData.aspectRatio;
    this.camera.updateProjectionMatrix();
  }

  _rotateCamera(offsetX, offsetY) {
    /* Trackball interactor that mimics VTK interactor behaviour
    `https://github.com/Kitware/VTK/blob/3cba47a1a28a77a6f0b94d8e6a2290b2f3c59f5e/Interaction/Style/vtkInteractorStyleTrackballCamera.cxx#L248`

    This function updates the north and viewUp vectors (and thus the camera
    position) based on the user's mouse movement.
    */

    const normal = this.viewController.normalByView[this.index];
    const upVector = this.viewController.upVectorByView[this.index];
    // Find elevation axis
    const elevationAxis = new THREE.Vector3()
      .crossVectors(upVector, normal)
      .normalize();

    // Construct the rotation matrices using the offsets and the rotation axes
    const elevationRotationMatrix = new THREE.Matrix4();
    elevationRotationMatrix.makeRotationAxis(elevationAxis, -offsetY);

    const azimuthRotationMatrix = new THREE.Matrix4();
    azimuthRotationMatrix.makeRotationAxis(upVector, offsetX);
    // Apply the rotation matrices to find the new camera position (direction)

    normal
      .applyMatrix4(elevationRotationMatrix)
      .applyMatrix4(azimuthRotationMatrix);
    // Find new viewUp vector
    this.viewController.upVectorByView[
      this.index
    ] = new THREE.Vector3().crossVectors(normal, elevationAxis).normalize();

    this._updateCameraPosition();
  }

  updateAnnulusForViews() {
    this._annulusController.updateAnnulusForViews();
  }

  // Screenshoting fuctions

  getLinesForScreenshot() {
    /* Return coordiates (and text) of the lines text boxes and endpoints shown
    at the view currently. */

    // manual and automatic lines
    const {
      textBoxes,
      endpoints
    } = this._lineController.getLinesForScreenshot();
    // annulus diameters
    for (const diameterTextBox of this._annulusController.getDiametersTextBoxes()) {
      textBoxes.push(diameterTextBox);
    }
    return { textBoxes: textBoxes, endpoints: endpoints };
  }

  screenshot() {
    /* Combine data (ct, measurements) from canvases into one image. */
    const width = this.canvases.threejs.width;
    const height = this.canvases.threejs.height;

    const screenshotCanvas = document.createElement("canvas");
    const screenshotContext = screenshotCanvas.getContext("2d", {
      alpha: true
    });
    screenshotCanvas.width = width;
    screenshotCanvas.height = height;
    screenshotContext.fillStyle = "black";
    screenshotContext.fillRect(0, 0, width, height);
    if (this.viewProperties.type === VIEW_TYPE.MPR) {
      // add transformated (center, zoom, crop) ct image data
      const ctWidth = this.canvases.ct.width;
      const ctHeight = this.canvases.ct.height;
      const { translateX, translateY, scale } = this._transformation();

      const {
        voxelToPixel,
        boundingRectangle
      } = this._getVoxelToPixelConversion();

      const zoom = scale * voxelToPixel;

      if (this._isMirrored()) {
        screenshotContext.setTransform(
          1,
          0,
          0,
          -1,
          (width - ctWidth * zoom) / 2 + translateX * scale,
          (height + ctHeight * zoom) / 2 - translateY * scale
        );
        screenshotContext.drawImage(
          this.canvases.ct,
          0,
          0,
          ctWidth * zoom,
          ctHeight * zoom
        );
        screenshotContext.setTransform(1, 0, 0, 1, 0, 0);
      } else {
        screenshotContext.drawImage(
          this.canvases.ct,
          (width - ctWidth * zoom) / 2 + translateX * scale,
          (height - ctHeight * zoom) / 2 + translateY * scale,
          ctWidth * zoom,
          ctHeight * zoom
        );
      }
    }
    // add THREE.js measurements
    screenshotContext.drawImage(this.canvases.threejs, 0, 0);

    return screenshotCanvas.toDataURL();
  }
}
