<template>
  <AccountComponent />
</template>

<script>
// @ is an alias to /src
import AccountComponent from "@/components/Account.vue";

export default {
  name: "Account",
  components: {
    AccountComponent,
  },
};
</script>