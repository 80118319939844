<template>
  <div
    v-show="isReady"
    class="no-print"
    :class="isReady && theme === 'default' ? '' : 'dark-theme'"
  >
    <nav
      id="navbar"
      class="navbar has-shadow-bold is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <a class="navbar-item margin-left-10" href="/">
          <img
            v-if="isReady && theme != 'default'"
            class="margin-right-8 is-ci-logo"
            src="/static/images/cardiac_logo_trans.png"
          />
          <span v-if="isReady && theme != 'default'" class="has-text-dark-font">
            powered by
          </span>
          <img
            v-if="isReady && theme != 'default'"
            class="padding-top-2"
            src="/static/images/laralab_white.png"
            width="70"
            height="28"
          />
          <img
            v-else
            src="/static/images/laralab_trans_bg.png"
            width="100"
            height="40"
          />
        </a>
        <span
          v-if="
            $store.getters.currentUser &&
            !isAdminView &&
            !isAccountView &&
            !isAboutView
          "
          class="navbar-item margin-left-10"
        >
          <span
            id="navbarCaseOverviewButton"
            class="
              is-size-6
              has-text-weight-bold has-text-dark-primary
              is-uppercase
              pointer
            "
            :class="isBrowseView ? 'has-border-bottom' : ''"
            @click="switchToOverview"
            >Case Overview
          </span>
        </span>

        <span
          v-if="
            $store.getters.currentUser &&
            !isBrowseView &&
            !isAdminView &&
            !isAccountView &&
            !isAboutView
          "
          class="navbar-item"
        >
          <span
            class="
              is-size-6
              padding-10
              has-border-radius-3 has-background-light-grey
            "
          >
            <span v-if="!isCurrentCaseEmpty" class="has-text-dark-primary">
              CASE {{ $store.getters.currentCaseId }}</span
            >
            <span
              id="navbarPlannerButton"
              class="ml-2 has-text-dark-primary has-text-weight-bold pointer"
              :class="isPlannerView ? 'has-border-bottom' : ''"
              @click="switchToAnalysis"
            >
              PLANNER
            </span>
            <span
              id="navbarReportButton"
              class="ml-2 has-text-dark-primary has-text-weight-bold pointer"
              :class="isReportBoardView ? 'has-border-bottom' : ''"
              @click="switchToReportingBoard"
            >
              REPORT
            </span>
          </span>
        </span>

        <div
          v-if="isNotForClinicalUse"
          class="
            navbar-item
            is-clinical-warning is-size-5
            has-text-weight-bold has-text-danger
            is-uppercase
            has-text-right
          "
        >
          NOT FOR CLINICAL USE
        </div>

        <a
          id="navbar-burger"
          v-show="$store.getters.currentUser"
          class="navbar-burger burger margin-top-3"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasic"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarBasic" class="navbar-menu padding-0">
        <div class="navbar-end">
          <div
            id="userIcon"
            v-if="$store.getters.currentUser"
            class="navbar-item has-dropdown is-hoverable"
          >
            <div class="margin-top-3 margin-right-10 padding-10">
              <figure class="image is-32x32">
                <img
                  class="is-rounded"
                  id="userimage"
                  :src="
                    $store.getters.currentUser.org === 'CI'
                      ? '/static/images/cardiac_implants.jpeg'
                      : '/static/images/default-logo.png'
                  "
                />
              </figure>
            </div>
            <div id="dropdown-menu" class="navbar-dropdown is-right padding-0">
              <a href="#" class="dropdown-item padding-tb-10">
                <p
                  id="email"
                  class="has-text-weight-bold has-text-dark-primary"
                >
                  {{ this.$store.getters.currentUser.email }}
                </p>
              </a>
              <hr class="dropdown-divider margin-0" />
              <a
                id="accountSettingsButton"
                href="/account"
                class="dropdown-item padding-tb-10"
              >
                <p class="has-text-dark-primary">Account Settings</p>
              </a>
              <hr class="dropdown-divider margin-0" />
              <a
                id="adminToolButton"
                v-if="
                  this.$store.getters.currentUser.roles.includes(LARA_SUPPORT)
                "
                href="/admin"
                class="dropdown-item padding-tb-10"
              >
                <p id="support" class="has-text-dark-primary">Admin Tools</p>
              </a>
              <hr class="dropdown-divider margin-0" />
              <a
                id="aboutButton"
                class="dropdown-item has-text-dark-primary padding-tb-10"
                href="/about"
              >
                About
              </a>
              <hr class="dropdown-divider margin-0" />
              <a
                id="logoutButton"
                class="dropdown-item has-text-dark-primary padding-tb-10"
                @click="logout()"
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <!-- end nav bar -->
</template>

<script>
import axios from "axios";
import { sendErrors } from "@/js/errorHandler.js";
import * as constants from "../constants.js";
import * as helper from "@/js/helper.js";

export default {
  data() {
    return {
      LARA_SUPPORT: 3,
      theme: "default",
      isReady: false,
      constants: constants,
    };
  },
  computed: {
    isBrowseView: function () {
      return this.$store.getters.currentView === constants.VIEW_BROWSE;
    },
    isReportBoardView() {
      return this.$store.getters.currentView === constants.VIEW_REPORT;
    },
    isPlannerView() {
      return this.$store.getters.currentView === constants.VIEW_PLANNER;
    },
    isCurrentCaseEmpty() {
      return this.$store.getters.currentCaseId === "";
    },
    isAdminView: function () {
      return this.$store.getters.currentView === constants.VIEW_ADMIN;
    },
    isAccountView: function () {
      return this.$store.getters.currentView === constants.VIEW_ACCOUNT;
    },
    isAboutView: function () {
      return this.$store.getters.currentView === constants.VIEW_ABOUT;
    },
    isNotForClinicalUse: function () {
      return (
        this.$store.getters.certification === constants.CERTIFICATION_ONGOING
      );
    },
  },
  beforeMount() {
    this.getUserTheme();
  },
  methods: {
    startAnalysis: helper.startAnalysis,
    logout: function () {
      axios
        .get("/api/logout", {})
        .then((res) => {
          window.location.href = "/";
        })
        .catch((error) => {
          sendErrors(error, "Navbar:logout()");
        });
    },
    switchToOverview: function () {
      this.$router.push("/overview");
    },
    // TODO: Check if memory leak still happens
    switchToReportingBoard: function () {
      if (this.$store.getters.currentView === constants.VIEW_PLANNER) {
        this.$router.push("/report/" + this.$store.getters.currentCaseId);
      }
    },
    switchToAnalysis: function () {
      if (this.$store.getters.isUploadInProgress) {
        this.$store.commit("setIsUploadWarningSeen", true);
      } else {
        if (this.$store.getters.currentView === constants.VIEW_REPORT) {
          this.startAnalysis();
        }
      }
    },
    getUserTheme: function () {
      axios.get("/api/get_theme").then((res) => {
        this.theme = res.data.theme;
        this.isReady = true;
      });
    },
  },
};
</script>

<style scoped>
.is-clinical-warning {
  position: absolute !important;
  top: 8px;
  right: 150px;
}
</style>