<template>
  <div
    v-if="isLoggedIn"
    class="hero is-fullheight-with-navbar has-background-white"
  >
    <div v-if="hasFailed" class="hero-body padding-0">
      <div class="fullscreen">
        <div class="screen-centered">
          <section class="hero is-light">
            <div class="hero-body">
              <div class="container">
                <h1 class="title">Error occurred</h1>
                <h2 class="subtitle">{{ errorMessage }}</h2>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div v-else class="hero-body padding-0">
      <div v-if="!analysisReady" class="fullscreen has-background-white">
        <div class="loader-fullscreen loader-screen-centered"></div>
        <!-- <div class="padding-15 screen-centered">
          <p class="has-text-mid-grey">Please Wait...</p>
          <div class="columns">
            <div class="column is-four-fifths">
              <progress
                id="progressBar"
                class="progress is-success"
                :value="progressBarValue"
                max="100"
              ></progress>
            </div>
            <div class="column">
              <span id="progressValue" class="has-text-mid-grey">{{
                progressValue
              }}</span>
            </div>
          </div>
        </div> -->
      </div>
      <div v-else-if="analysisReady" class="has-width-100-per">
        <WarningModal
          :isWarningModalSeen="isWarningModalSeen"
          :warningObject="warningObject"
          v-on:sendFirstEvent="
            isDiscardWarning ? closeDiscardWarningModal() : closeWarningModal()
          "
          v-on:sendSecondEvent="deleteScreenshotAndRevokeApproval()"
          v-on:sendCloseEvent="
            isDiscardWarning ? closeDiscardWarningModal() : closeWarningModal()
          "
        ></WarningModal>

        <div class="columns is-gapless">
          <div class="column is-one-quarter">
            <div class="has-height-100-with-navbar">
              <div
                id="caseInfo"
                class="
                  margin-rl-8 margin-top-20
                  padding-5
                  pt-5
                  pb-5
                  has-background-light-grey has-border-radius-5
                "
              >
                <h1 class="is-size-6 has-text-almost-black">
                  {{ studyInfo.name }} - {{ formatDate(studyInfo.dob) }} (
                  {{ studyInfo.age }}) - {{ studyInfo.sex }}
                </h1>
                <h1 class="is-size-6 has-text-almost-black">
                  {{ studyInfo.description }}
                </h1>
                <h1 class="is-size-6 has-text-almost-black">
                  {{ studyInfo.sliceNr }} images - {{ studyInfo.phases }} -
                  {{ formatSliceThickness(studyInfo.sliceThickness) }} mm
                </h1>
              </div>
              <ToolsDashboard
                :key="toolDashboardKey"
                :interaction="currentTool"
                :isOverlaysActive="isOverlaysActive"
                v-on:enableMeasurements="enableMeasurements"
                v-on:enableContrast="enableContrast"
                v-on:enableZoom="enableZoom"
                v-on:enableSlicing="enableSlicing"
                v-on:toggleOverlays="toggleOverlays"
              ></ToolsDashboard>
              <h1
                class="
                  margin-rl-8 margin-top-20
                  subtitle
                  has-text-almost-black
                  has-text-dark-primary
                  has-text-weight-bold
                  is-size-5
                "
              >
                Assessments - {{ protocolName }}
              </h1>

              <div class="margin-rl-8 has-scrollable-column">
                <div
                  v-for="(category, categoryIndex) in categoryInfo"
                  :key="categoryIndex"
                  class="
                    card
                    margin-bottom-10
                    has-hover has-background-light-grey has-background-dark-card
                    pointer
                    position-relative
                  "
                >
                  <div
                    class="vertical-progressbar"
                    :class="
                      activeCategory !== categoryIndex
                        ? category.approved
                          ? 'has-background-approved'
                          : category.reviewProgress > 0
                          ? 'has-background-partially-approved'
                          : 'has-background-whisper-grey'
                        : ''
                    "
                    :style="
                      activeCategory === categoryIndex
                        ? `background: linear-gradient(0deg, #e5e5e5 ${
                            100 - category.reviewProgress
                          }%, #${
                            category.approved ? '0fb032' : 'ffe500'
                          } 0% 100%)`
                        : ''
                    "
                  ></div>
                  <div
                    class="padding-tb-10 padding-left-15 padding-right-10"
                    @click="toggleCategory(categoryIndex)"
                  >
                    <span
                      class="
                        subtitle
                        is-size-5
                        has-text-almost-black
                        has-text-dark-primary
                        has-text-weight-bold
                        is-pulled-right
                        pointer
                      "
                    >
                      <i
                        v-if="activeCategory === categoryIndex"
                        class="fas fa-chevron-up has-text-darker-grey"
                      ></i>
                      <i
                        v-else
                        class="fas fa-chevron-down has-text-darker-grey"
                      ></i>
                    </span>

                    <h2
                      class="
                        subtitle
                        is-size-5
                        has-text-almost-black has-text-weight-bold
                      "
                    >
                      {{ category.name }}
                    </h2>
                  </div>

                  <div v-if="activeCategory === categoryIndex">
                    <div
                      v-for="(board, boardIndex) in category.boards"
                      :key="boardIndex"
                      class="
                        has-text-almost-black
                        padding-tb-10 padding-left-15 padding-right-10
                      "
                      :class="
                        activeBoard === board.id_ && category.approved
                          ? 'has-background-light-green'
                          : activeBoard === board.id_ &&
                            category.reviewProgress > 0
                          ? 'has-background-light-yellow'
                          : ''
                      "
                    >
                      <div
                        class="is-size-6"
                        :class="
                          activeBoard === board.id_
                            ? 'has-text-weight-bold'
                            : ''
                        "
                        @click="switchBoard(categoryIndex, boardIndex)"
                      >
                        {{ board.name }} - {{ board.phase }}%
                      </div>
                      <div
                        v-if="activeBoard === board.id_"
                        class="is-size-14 pb-1 has-text-darker-grey"
                      >
                        <div
                          v-if="
                            !category.approved && board.action_note !== null
                          "
                          class="
                            columns
                            is-vcentered is-variable is-0
                            margin-top-1 margin-bottom-1
                          "
                        >
                          <div class="column is-narrow mr-1">
                            <i
                              class="
                                fas
                                fa-info-circle
                                icon-partially-approved
                                is-size-6
                              "
                            ></i>
                          </div>
                          <div class="column is-size-14 has-text-darker-grey">
                            {{ board.action_note }}
                          </div>
                        </div>
                        Saved for report:
                        <div
                          v-for="(bookmark, bookmarkIndex) in board.bookmarks"
                          :key="bookmarkIndex"
                        >
                          <span
                            class="pb-1 is-size-6 has-text-almost-black pointer"
                            @click="
                              bookmark.editing
                                ? ''
                                : openPose(bookmark, categoryIndex, boardIndex)
                            "
                          >
                            <i
                              class="
                                fas
                                fa-camera
                                is-size-7
                                has-text-almost-black
                                margin-right-5
                              "
                            ></i
                            ><span
                              >{{ getViewName(bookmark.target_view, board) }} -
                              <span
                                v-show="!bookmark.editing"
                                @dblclick="
                                  isReadOnly
                                    ? ''
                                    : !bookmark.pose_predefined
                                    ? openEditBookmarkName(
                                        bookmark,
                                        bookmarkIndex
                                      )
                                    : ''
                                "
                                >{{ bookmark.name }}</span
                              >
                              <input
                                v-show="bookmark.editing"
                                @keydown.enter="
                                  bookmark.editing
                                    ? renameBookmark(
                                        categoryIndex,
                                        boardIndex,
                                        bookmarkIndex
                                      )
                                    : ''
                                "
                                v-click-outside="
                                  () => {
                                    renameBookmark(
                                      categoryIndex,
                                      boardIndex,
                                      bookmarkIndex
                                    );
                                  }
                                "
                                class="
                                  input
                                  is-small
                                  has-max-width-120
                                  is-narrow
                                "
                                type="text"
                                :placeholder="bookmark.name"
                                v-model="newBookmarkName"
                                :ref="'renameBookmark' + bookmarkIndex"
                                tabindex="-1"
                              />
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="has-text-right mr-1 margin-top-5 pb-1">
                      <button
                        class="
                          button
                          approve-button
                          has-background-transparent
                          noborder
                          has-border-none
                          is-size-7
                        "
                        :disabled="
                          category.reviewProgress >= 100 ? false : true
                        "
                      >
                        <span
                          @mouseover="hoverOn(categoryIndex)"
                          @mouseleave="hoverOff(categoryIndex)"
                        >
                          <b
                            v-if="!category.approved"
                            class="margin-right-5 is-size-14"
                            :class="
                              isReadOnly || category.reviewProgress < 100
                                ? 'has-text-darker-grey-30'
                                : 'has-text-darker-grey'
                            "
                            @click="
                              isReadOnly
                                ? ''
                                : category.approved
                                ? unapproveCategory(categoryIndex)
                                : category.reviewProgress >= 100
                                ? approveCategory(categoryIndex)
                                : ''
                            "
                          >
                            <span
                              v-if="categoryIndex === categoryInfo.length - 1"
                              :class="
                                isReadOnly || category.reviewProgress < 100
                                  ? 'has-text-darker-grey-30'
                                  : 'has-text-darker-grey'
                              "
                            >
                              APPROVE
                            </span>
                            <span
                              v-else
                              :class="
                                isReadOnly || category.reviewProgress < 100
                                  ? 'has-text-darker-grey-30'
                                  : 'has-text-darker-grey'
                              "
                            >
                              APPROVE & NEXT
                            </span>
                          </b>
                          <i
                            v-if="!category.approved"
                            class="far fa-circle is-size-5 is-pulled-right"
                            :class="
                              isReadOnly || category.reviewProgress < 100
                                ? 'has-text-darker-grey-30'
                                : 'has-text-darker-grey is-ready-for-approve-button'
                            "
                            @click="
                              isReadOnly
                                ? ''
                                : category.reviewProgress >= 100
                                ? approveCategory(categoryIndex)
                                : ''
                            "
                          ></i>
                          <i
                            v-else
                            class="
                              far
                              fa-check-circle
                              is-size-5 is-approved-button
                            "
                            :disabled="isReadOnly"
                            @click="
                              isReadOnly ? '' : revertApprove(categoryIndex)
                            "
                          ></i>
                        </span>
                      </button>
                      <div id="unlockWarning">
                        <span
                          v-if="isReadOnly || category.reviewProgress < 100"
                          class="
                            has-background-whisper-grey has-border-radius-5
                            padding-5
                            is-right
                          "
                          :class="category.hover ? '' : 'is-invisible'"
                        >
                          <span v-if="isReadOnly">
                            {{ readOnlyWarning }}
                          </span>
                          <span v-else-if="category.reviewProgress < 100">
                            Complete all workflow steps to unlock approval
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column has-flex">
            <div
              v-show="showUpperRow"
              class="columns is-gapless is-relative"
              :class="
                isMultipleViewLayout ? 'col-is-split-4' : 'col-is-split-2'
              "
            >
              <div
                v-show="isMultipleViewLayout || viewIndex === 0"
                class="column is-relative"
              >
                <PoseWidgets
                  componentName="poseWidget_0"
                  :key="widgetKey"
                  :poses="currentViews[0].poses"
                  :viewIndex="0"
                  :activeSlices="activeSlices[0]"
                  :currentPoseBookmarked="currentPoseBookmarked[0]"
                  v-on:setPose="setPose"
                  v-on:takeScreenshot="takeScreenshot"
                ></PoseWidgets>
                <ScalarMeasurementWidget
                  componentName="scalarMeasurements0"
                  :boxTitle="boxTitles[0]"
                  :scalars="scalars[0]"
                ></ScalarMeasurementWidget>
                <LabelWidget index="0"></LabelWidget>
                <FluoroWidget index="0"></FluoroWidget>
                <div
                  @dblclick="toggleFullscreen(0)"
                  @mousemove="isSliceActive"
                  id="viewer-0"
                  class="viewer"
                ></div>
              </div>
              <div
                v-show="isMultipleViewLayout || viewIndex === 1"
                class="column is-relative"
              >
                <PoseWidgets
                  componentName="poseWidget_1"
                  :key="widgetKey"
                  :poses="currentViews[1].poses"
                  :viewIndex="1"
                  :currentPoseBookmarked="currentPoseBookmarked[1]"
                  :activeSlices="activeSlices[1]"
                  v-on:setPose="setPose"
                  v-on:takeScreenshot="takeScreenshot"
                ></PoseWidgets>
                <ScalarMeasurementWidget
                  componentName="scalarMeasurements1"
                  :boxTitle="boxTitles[1]"
                  :scalars="scalars[1]"
                ></ScalarMeasurementWidget>
                <LabelWidget index="1"></LabelWidget>
                <FluoroWidget index="1"></FluoroWidget>
                <div
                  @dblclick="toggleFullscreen(1)"
                  @mousemove="isSliceActive"
                  id="viewer-1"
                  class="viewer"
                ></div>
              </div>
            </div>
            <div
              v-show="showLowerRow"
              class="columns is-gapless"
              :class="
                isMultipleViewLayout ? 'col-is-split-4' : 'col-is-split-2'
              "
            >
              <div
                v-show="isMultipleViewLayout || viewIndex === 2"
                class="column is-relative"
              >
                <PoseWidgets
                  componentName="poseWidget_2"
                  :key="widgetKey"
                  :poses="currentViews[2].poses"
                  :viewIndex="2"
                  :currentPoseBookmarked="currentPoseBookmarked[2]"
                  :activeSlices="activeSlices[2]"
                  v-on:setPose="setPose"
                  v-on:takeScreenshot="takeScreenshot"
                ></PoseWidgets>
                <ScalarMeasurementWidget
                  componentName="scalarMeasurements2"
                  :boxTitle="boxTitles[2]"
                  :scalars="scalars[2]"
                ></ScalarMeasurementWidget>
                <LabelWidget index="2"></LabelWidget>
                <FluoroWidget index="2"></FluoroWidget>
                <div
                  @dblclick="toggleFullscreen(2)"
                  @mousemove="isSliceActive"
                  id="viewer-2"
                  class="viewer"
                ></div>
              </div>
              <div
                v-show="isMultipleViewLayout || viewIndex === 3"
                class="column is-relative"
              >
                <PoseWidgets
                  componentName="poseWidget_3"
                  :key="widgetKey"
                  :poses="currentViews[3].poses"
                  :viewIndex="3"
                  :activeSlices="activeSlices[3]"
                  :currentPoseBookmarked="currentPoseBookmarked[3]"
                  v-on:setPose="setPose"
                  v-on:takeScreenshot="takeScreenshot"
                ></PoseWidgets>
                <ScalarMeasurementWidget
                  componentName="scalarMeasurements3"
                  :boxTitle="boxTitles[3]"
                  :scalars="scalars[3]"
                ></ScalarMeasurementWidget>
                <LabelWidget index="3"></LabelWidget>
                <FluoroWidget index="3"></FluoroWidget>
                <div
                  @dblclick="toggleFullscreen(3)"
                  @mousemove="isSliceActive"
                  id="viewer-3"
                  class="viewer"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolsDashboard from "@/components/ToolsDashboard.vue";
import PoseWidgets from "@/components/PoseWidgets.vue";
import LabelWidget from "@/components/LabelWidget.vue";
import FluoroWidget from "@/components/FluoroWidget.vue";
import ScalarMeasurementWidget from "@/components/ScalarMeasurementWidget.vue";
import WarningModal from "@/components/WarningModal.vue";

import _ from "lodash";
import axios from "axios";
import { io } from "socket.io-client";
import vClickOutside from "v-click-outside";

import * as constants from "../constants.js";
import * as helper from "@/js/helper.js";
import Controller from "@/js/controller.js";
import { TOOL } from "@/js/constants.js";
import { sendErrors, sendInfos } from "@/js/errorHandler.js";

// import createActivityMonitor from "@/js/clientActivity.js";

const SINGLE_VIEW = 1;
const MULTIPLE_VIEW = 4;

let CONTROLLER = null;

export default {
  name: "CTPlannerComponent",
  props: {
    defaultCategory: String,
  },
  data() {
    return {
      readOnlyWarning: constants.READ_ONLY_WARNING,
      analysisId: this.$route.params.analysisId,
      analysisRoom: "",
      analysisReady: false,
      crReadyReceived: false,
      isLoggedIn: false,
      user: null,
      studyInfo: {},
      categoryInfo: [],
      protocolName: "", // It may be beneficial to store entire caseProtocol instead of categoryInfo and protocolName separately.
      isOverlaysActive: false, // Overlays deactivated by default
      showViewLayout: MULTIPLE_VIEW, // todo: @Lachana: status.detail.numberOfViews has the correct amount of views to show.
      progressBarValue: 0,
      progressValue: "0/100",
      hasFailed: false,
      errorMessage: "",
      socket: null,
      status: null,
      isViewLayoutUpdated: false,
      theme: "default",
      crURL: "",
      timer: null,
      activeCategory: null,
      activeBoard: null,
      boxTitles: { 0: "", 1: "", 2: "", 3: "" },
      scalars: { 0: [], 1: [], 2: [], 3: [] },
      currentViews: [
        { poses: [] },
        { poses: [] },
        { poses: [] },
        { poses: [] },
      ], // List of the current views
      viewIndex: 0,
      widgetKey: 0,
      newBookmarkName: "",
      currentTool: TOOL.DEFAULT,
      activeSlices: { 0: 0, 1: 0, 2: 0, 3: 0 },
      currentPoseBookmarked: { 0: false, 1: false, 2: false, 3: false },
      toolDashboardKey: 0,
      isWarningModalSeen: false,
      warningObject: {},
      isDiscardWarning: false,
    };
  },
  computed: {
    showUpperRow() {
      return (
        this.isMultipleViewLayout ||
        (this.isSingleViewLayout &&
          (this.viewIndex === 0 || this.viewIndex === 1))
      );
    },
    showLowerRow() {
      return (
        this.isMultipleViewLayout ||
        (this.isSingleViewLayout &&
          (this.viewIndex === 2 || this.viewIndex === 3))
      );
    },
    isSingleViewLayout() {
      return this.showViewLayout === SINGLE_VIEW;
    },
    isMultipleViewLayout() {
      return this.showViewLayout === MULTIPLE_VIEW;
    },
    isReadOnly() {
      return (
        this.$store.getters.currentAccessMode === constants.ACCESS_MODE_READONLY
      );
    },
  },
  components: {
    ToolsDashboard,
    PoseWidgets,
    LabelWidget,
    FluoroWidget,
    ScalarMeasurementWidget,
    WarningModal,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  beforeMount() {
    this.checkLoggedIn();
    this.getUserTheme();
    // the port is different if we use webpack development server so we decide based on env var set on image build
    this.socket = io.connect();
    this.setSocketConnection();
    this.$store.commit("setCurrentView", constants.VIEW_PLANNER);
    document.addEventListener("connectionError", this.raiseConnectionError);
    document.addEventListener("measurementsLoaded", this.updateMeasurements);
    document.addEventListener("finishMeasurement", this.enableSlicing);
    document.addEventListener("overlayStateChanged", this.changeOverlayState);
    document.addEventListener("openWarningModal", this.openDiscardWarningModal);
    document.addEventListener("updateMeasurement", this.revokeApproval);
    document.addEventListener("updateScreenshot", this.revokeApproval);
    document.addEventListener("updatePose", this.revokeApproval);
    this.cookie = helper.parseCookie(document.cookie);
  },
  created() {
    this.isPoseBookmarked();
    // TODO: This is a temporary working solution.
    // For a more robost system, we should use sockets and unique tab identifiers instead

    // Release the Editor mode when refreshing
    window.onbeforeunload = () => {
      this.releaseEditAccess(this.studyInfo.caseId);
    };

    // Handle the case when browser is closed
    document.onvisibilitychange = () => {
      if (document.visibilityState === "hidden") {
        // Only trigger release if the tab/browser is closed
        if (!document) {
          this.releaseEditAccess(this.studyInfo.caseId);
        }
      }
    };

    // check CR Connection every 10 seconds
    // this.timer = setInterval(() => this.checkCRConnection(), 10000);
  },
  updated() {
    if (this.isViewLayoutUpdated) {
      CONTROLLER.toggleFullscreen();
      this.isViewLayoutUpdated = false;
    }
  },
  beforeDestroy() {
    // Temporary hack to clear memory
    // TODO: Find memory leak so this is not necessary
    this.$router.go();
    this.releaseEditAccess(this.studyInfo.caseId);
    if (CONTROLLER) {
      CONTROLLER.destroy();
      CONTROLLER = null;
    }
    document.removeEventListener("connectionError", this.raiseConnectionError);
    document.removeEventListener("measurementsLoaded", this.updateMeasurements);
    document.removeEventListener("finishMeasurement", this.enableSlicing);
    document.removeEventListener(
      "overlayStateChanged",
      this.changeOverlayState
    );
    document.removeEventListener(
      "openWarningModal",
      this.openDiscardWarningModal
    );
    document.removeEventListener("updateMeasurement", this.revokeApproval);
    document.removeEventListener("updateScreenshot", this.revokeApproval);
    document.removeEventListener("updatePose", this.revokeApproval);
  },
  beforeRouteLeave() {
    window.onbeforeunload = null;
    document.onvisibilitychange = null;
  },
  methods: {
    formatDate: helper.formatDate,
    formatSliceThickness: helper.formatSliceThickness,
    releaseEditAccess: helper.releaseEditAccess,
    reset: function () {
      this.analysisReady = false;
      this.studyInfo = {};
      this.categoryInfo = [];
      this.protocolName = "";
      this.isOverlaysActive = false;
      this.showViewLayout = MULTIPLE_VIEW;
      this.isViewLayoutUpdated = false;
      this.crURL = "";
      this.activeCategory = null;
      this.activeBoard = null;
      this.boxTitles = { 0: "", 1: "", 2: "", 3: "" };
      this.scalars = { 0: [], 1: [], 2: [], 3: [] };
      this.currentViews = [
        { poses: [] },
        { poses: [] },
        { poses: [] },
        { poses: [] },
      ];
      this.viewIndex = 0;
      this.newBookmarkName = "";
      this.currentTool = TOOL.DEFAULT;
    },
    getViewName: function (viewId, board) {
      const view = _.find(board.views, function (o) {
        return o.id_ === viewId;
      });
      if (view) {
        return view.name;
      } else {
        return "";
      }
    },
    takeScreenshot: function (viewIndex) {
      CONTROLLER.takeScreenshot(
        this.categoryInfo[this.activeCategory].id_,
        viewIndex
      );
      this.isPoseBookmarked();
      this.widgetKey += 10;
    },
    isPoseBookmarked: function () {
      const screenshottedPoses = { 0: [], 1: [], 2: [], 3: [] };
      _.forEach(this.currentViews, (view, index) => {
        _.forEach(view.poses, (pose) => {
          if (pose.added_to_report) {
            screenshottedPoses[index].push(pose.transformation);
          }
        });
      });
      _.forEach(this.currentViews, (view, index) => {
        if (CONTROLLER) {
          if (
            screenshottedPoses[index].includes(
              CONTROLLER.views[index].viewController.sliceIndexByView[index]
            )
          ) {
            this.currentPoseBookmarked[index] = true;
          } else {
            this.currentPoseBookmarked[index] = false;
          }
        }
      });
    },
    isSliceActive: function () {
      if (CONTROLLER !== null) {
        _.forEach(this.currentViews, (view, index) => {
          this.activeSlices[index] =
            CONTROLLER.views[index].viewController.sliceIndexByView[index];
        });
        this.isPoseBookmarked();
        this.widgetKey++;
      }
    },
    openEditBookmarkName(bookmark, ref) {
      this.newBookmarkName = bookmark.name;

      bookmark.editing = true;
      this.$forceUpdate();

      this.$nextTick(() => {
        this.$refs["renameBookmark" + ref][0].focus();
        this.$refs["renameBookmark" + ref][0].select();
      });
    },
    renameBookmark(categoryIndex, boardIndex, bookmarkIndex) {
      const bookmark =
        this.categoryInfo[categoryIndex].boards[boardIndex].bookmarks[
          bookmarkIndex
        ];
      if (bookmark.editing) {
        if (!this.newBookmarkName) {
          bookmark.editing = false;
          this.$forceUpdate();
          return;
        }
        CONTROLLER.viewController.renamePose(
          bookmark,
          categoryIndex,
          boardIndex,
          this.newBookmarkName
        );
        bookmark.name = this.newBookmarkName;
        bookmark.editing = false;
        this.$forceUpdate();
      }
    },
    checkLoggedIn: function () {
      axios.get("/api/currentuser").then((response) => {
        if (!response.data.user) {
          window.location.href = "/";
        } else {
          this.isLoggedIn = true;
          this.$store.commit("setCurrentUser", response.data.user);
          this.user = this.$store.getters.currentUser;
        }
      });
    },
    setSocketConnection: function () {
      const self = this;
      const path = "/api/planner/" + this.analysisId;

      this.socket.on("connect", function () {
        console.log("Websocket connected!");
      });

      // Join room
      this.analysisRoom = "a::" + this.analysisId;
      this.socket.emit("join", this.analysisRoom, function (data) {
        axios.get(path).then((response) => {
          return Promise.all([]); // TODO: check if good response
        });
      });

      this.socket.on("DpFailed", function (data) {
        if (data.analysis_id.includes(parseInt(self.analysisId))) {
          self.errorMessage = data.error;
          self.$nextTick(() => {
            self.hasFailed = true;
            console.log("Error in analysis: " + this.errorMessage);
          });
        }
      });

      this.socket.on("CrFailed", function (data) {
        self.errorMessage = data.error;

        self.$nextTick(() => {
          self.hasFailed = true;
          console.log("Error in analysis: " + this.errorMessage);
        });
      });

      this.socket.on("CrProgressUpdated", function (data) {
        // Assuming data contains progress_percent
        self.progressBarValue = data.progress_percent;
        self.progressValue = data.progress_percent + "%";
      });

      this.socket.on("CrReady", function (data) {
        // TODO: Find out why this event is being sent multiple times
        if (!self.crReadyReceived) {
          console.log("Images ready");
          self.crReadyReceived = true;
          // reset the states
          self.reset();

          const resUrl =
            window.location.protocol +
            "//" +
            window.location.hostname +
            data.view_url_4d;
          console.log(resUrl + ", " + data.view_url_4d);
          self.crURL = resUrl;
          self.getCase();
          // end of renderer
          if (data !== "Error") {
            self.analysisReady = true;
            self.studyInfo = data.study;
            self.studyInfo.date = self.formatDate(self.studyInfo.date);
            self.$store.commit("setCurrentCaseId", self.studyInfo.caseId);
            if (self.studyInfo.finalized) {
              self.setAccessMode(constants.ACCESS_MODE_READONLY, true);
            }
            self.getCaseInformation();
          } else {
            self.hasFailed = true;
          }
        }
      });
    },
    setAccessMode: function (accessMode, isFinalized = false) {
      this.$store.commit("setCurrentAccessMode", accessMode);
      if (accessMode === constants.ACCESS_MODE_READONLY) {
        this.openReadOnlyWarningModal(isFinalized);
      }
    },
    checkCRConnection: function () {
      if (this.analysisReady && !this.hasFailed) {
        const resUrl = "/api/planner/" + this.analysisId + "/status";
        axios
          .get(
            resUrl,
            { timeout: 10000 } // 10 seconds
          )
          .then((response) => {
            if (
              !response ||
              response.status !== 200 ||
              !response.data ||
              !response.data.all_alive
            ) {
              this.raiseConnectionError();
            }
          })
          .catch((error) => {
            this.raiseConnectionError();
          });
      }
    },
    raiseConnectionError: function () {
      this.hasFailed = true;
      this.analysisReady = false;
      this.errorMessage =
        "Session has been terminated. Please restart the CT planner.";
      sendInfos(
        "Connection Error raised in Analysis. Session has been terminated"
      );
    },
    updateMeasurements: function () {
      this.scalars = CONTROLLER.getScalars();
    },
    getCaseInformation: function () {
      return new Promise((resolve, reject) => {
        // Get information about the SliceNr, SliceThickness etc.
        const path = "/api/get_case_information";
        axios
          .post(path, {
            CaseID: this.studyInfo.caseId,
          })
          .then((res) => {
            const responseData = res.data;
            _.forEach(responseData, (value, key) => {
              this.$set(this.studyInfo, key, value);
            });
            // Set access level
            this.$store.commit(
              "setHasEditAccessOnServer",
              responseData.assignedRole === "editor"
            );
            if (!this.studyInfo.finalized) {
              if (responseData.assignedRole === "editor") {
                this.setAccessMode(constants.ACCESS_MODE_EDITOR);
              } else {
                this.setAccessMode(constants.ACCESS_MODE_READONLY);
              }
            }

            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCase: function () {
      const path = "/api/cases/" + this.analysisId;
      axios
        .get(path)
        .then((res) => {
          const caseProtocol = res.data;
          this.protocolName = caseProtocol.name;
          this.categoryInfo = caseProtocol.categories;
          _.forEach(this.categoryInfo, (category) => {
            this.$set(
              category,
              "reviewProgress",
              this.getReviewPercent(category)
            );
            this.$set(category, "hover", false);
          });
          if (CONTROLLER === null) {
            // For some reasons we sometimes receive this event twice

            let startingCategory = caseProtocol.starting_category;
            if (this.defaultCategory !== undefined) {
              const index = _.findIndex(this.categoryInfo, {
                id_: this.defaultCategory,
              });
              startingCategory = index;
            }
            CONTROLLER = new Controller(caseProtocol, startingCategory);
            const maURL = "/api/planner/" + this.analysisId;
            CONTROLLER.onCrReady(this.crURL, maURL);

            this.toggleCategory(startingCategory, caseProtocol.starting_board);
          }
          this.markBookmarksForPredefinedPoses();
        })
        .catch((error) => {
          sendErrors(error, "CTPlanner:getCase");
        });
    },
    markBookmarksForPredefinedPoses() {
      const predefinedPoses = [];
      _.forEach(this.currentViews, (view) => {
        _.forEach(view.poses, (pose) => {
          if (pose.predefined) {
            predefinedPoses.push(pose.id_);
          }
        });
      });
      _.forEach(this.categoryInfo, (category) => {
        _.forEach(category.boards, (board) => {
          _.forEach(board.bookmarks, (bookmark) => {
            if (predefinedPoses.includes(bookmark.pose)) {
              bookmark.pose_predefined = true;
            }
          });
        });
      });
    },
    toggleCategory: function (index, boardIndex = 0) {
      if (this.activeCategory === index) {
        this.activeCategory = null;
      } else {
        this.activeCategory = index;
      }
      this.switchBoard(index, boardIndex);
    },
    switchBoard: function (index, boardIndex) {
      const category = this.categoryInfo[index];
      const board = category.boards[boardIndex];
      // Only one board can be opened at a time
      if (this.activeBoard !== board.id_) {
        this.activeBoard = board.id_;
        if (!this.isReadOnly && !board.reviewed) {
          this.patchBoardReviewed(this.categoryInfo[index].id_, board.id_);
          board.reviewed = true;
          category.reviewProgress = this.getReviewPercent(category);
        }
        this.currentViews = board.views;
        this.markBookmarksForPredefinedPoses();
        CONTROLLER.changeCurrentBoard(index, boardIndex);
        this.scalars = CONTROLLER.getScalars();
        this.boxTitles = CONTROLLER.getBoxTitles();
        this.isOverlaysActive =
          CONTROLLER.viewController.caseProtocol.categories[index].boards[
            boardIndex
          ].overlay;
        this.toolDashboardKey++;
      }
    },
    patchBoardReviewed: function (categoryId, boardId) {
      axios
        .patch(
          this.patchCategoryUrl(categoryId) + "/boards/" + boardId,
          {
            reviewed: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {})
        .catch((error) => {
          sendErrors(error, "CTPlanner:patchBoardReviewed");
        });
    },
    getReviewPercent: function (category) {
      const totalReviewsRemaining = _.filter(category.boards, [
        "reviewed",
        false,
      ]).length;

      return (
        100 - Math.ceil((totalReviewsRemaining / category.boards.length) * 100)
      );
    },
    approveCategory: function (index) {
      axios
        .patch(
          this.patchCategoryUrl(this.categoryInfo[index].id_),
          {
            approved: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.categoryInfo[index].approved = true;
          // Expand the next category if it exists
          if (index < this.categoryInfo.length - 1) {
            this.toggleCategory(index + 1);
          } else {
            // Else collapse the current Category
            this.toggleCategory(index);
          }
          this.$forceUpdate();
        })
        .catch((error) => {
          sendErrors(error, "CTPlanner:approveCategory");
        });
    },
    unapproveCategory: function (index) {
      axios
        .patch(
          this.patchCategoryUrl(this.categoryInfo[index].id_),
          {
            approved: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.categoryInfo[index].approved = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          sendErrors(error, "CTPlanner:approveCategory");
        });
    },
    revertApprove: function (index) {
      axios
        .patch(
          this.patchCategoryUrl(this.categoryInfo[index].id_),
          {
            approved: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          this.categoryInfo[index].approved = false;
          this.$forceUpdate();
        })
        .catch((error) => {
          sendErrors(error, "CTPlanner:revertApprove");
        });
    },
    patchCategoryUrl: function (categoryId) {
      return (
        this.crURL +
        "/" +
        CONTROLLER.cache.phases[0] +
        "/categories/" +
        categoryId
      );
    },
    toggleFullscreen: function (view) {
      this.viewIndex = view;
      if (this.showViewLayout === SINGLE_VIEW) {
        this.showViewLayout = MULTIPLE_VIEW;
      } else {
        this.showViewLayout = SINGLE_VIEW;
      }
      this.isViewLayoutUpdated = true;
    },
    getUserTheme: function () {
      axios.get("/api/get_theme").then((res) => {
        this.theme = res.data.theme;
      });
    },
    openPose(bookmark, categoryIndex, boardIndex) {
      const [pose, viewIndex] = CONTROLLER.viewController.getPose(
        bookmark,
        categoryIndex,
        boardIndex
      );
      this.setPose(viewIndex, pose);
    },
    setPose: function (viewIndex, pose) {
      CONTROLLER.setPose(viewIndex, pose);
      this.isSliceActive();
    },
    enableContrast: function () {
      this.currentTool = TOOL.CONTRAST;
      CONTROLLER.setTool(TOOL.CONTRAST);
      console.log("Contrast enabled");
    },
    enableMeasurements: function () {
      this.currentTool = TOOL.MEASURING;
      CONTROLLER.setTool(TOOL.MEASURING);
      console.log("Measurements enabled");
    },
    enableZoom: function () {
      this.currentTool = TOOL.ZOOM;
      CONTROLLER.setTool(TOOL.ZOOM);
      console.log("Zoom enabled");
    },
    enableSlicing: function () {
      this.currentTool = TOOL.DEFAULT;
      CONTROLLER.setTool(TOOL.DEFAULT);
      console.log("Slicing enabled");
    },
    toggleOverlays: function () {
      CONTROLLER.toggleOverlays();
    },
    changeOverlayState: function () {
      this.isOverlaysActive = CONTROLLER.viewController.isOverlaysActive;
    },
    openReadOnlyWarningModal: function (isFinalized = false) {
      // Content of the warning modal shown when opening in READ ONLY mode
      let primaryMessage =
        "Case is being modified by another user. Some of the features will not be available.";
      if (isFinalized) {
        primaryMessage =
          "Case is set to Final. Some of the features will not be available.";
      }
      const readOnlyObject = {
        title: "Planner is opened in read-only mode",
        primaryMessage: primaryMessage,
        firstButton: "OK - CONTINUE",
        secondButton: undefined,
      };
      this.openWarningModal(readOnlyObject);
    },
    openDiscardWarningModal: function () {
      // Content of the warning modal shown when editing measurements
      const discardWarningObject = {
        title: "Screenshots will be discarded",
        primaryMessage:
          "Changing the annulus shape will discard all screenshots for the chosen board.",
        secondaryMessage: "Do you want to continue?",
        firstButton: "NO - CANCEL EDITING",
        secondButton: "YES",
      };
      this.isDiscardWarning = true;
      this.openWarningModal(discardWarningObject);
    },
    closeDiscardWarningModal: function () {
      CONTROLLER.rejectAnnulusUpdate();
      this.isDiscardWarning = false;
      this.closeWarningModal();
    },
    openWarningModal: function (warningObject) {
      this.warningObject = warningObject;
      this.isWarningModalSeen = true;
    },
    closeWarningModal: function () {
      this.isWarningModalSeen = false;
      this.warningObject = {};
    },
    deleteScreenshotAndRevokeApproval: function () {
      CONTROLLER.deleteAllBoardScreenshots();
    },
    revokeApproval: function () {
      if (this.categoryInfo[CONTROLLER.categoryIndex].approved) {
        this.unapproveCategory(CONTROLLER.categoryIndex);
      }
      this.isWarningModalSeen = false;
    },
    hoverOn: function (categoryIndex) {
      this.categoryInfo[categoryIndex].hover = true;
    },
    hoverOff: function (categoryIndex) {
      this.categoryInfo[categoryIndex].hover = false;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 7px;
  height: 3px;
}

.modal {
  z-index: 55;
}

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 2.6rem);
}

.has-background-approved {
  background-color: #0fb032;
}

.has-background-partially-approved {
  background-color: #ffe500;
}

.is-approved-button[disabled="disabled"],
.is-approved-button:disabled {
  background: #cfffda;
  color: rgba(15, 176, 50, 0.3) !important;
  border-radius: 50%;
}

.is-approved-button {
  background: #cfffda;
  color: #0fb032;
  border-radius: 50%;
}

.approve-button:hover .is-ready-for-approve-button {
  background-color: #bebebe;
  border-radius: 50%;
}

.approve-button:hover .is-approved-button {
  background-color: #bfebc8;
  border-radius: 50%;
}

.is-narrow {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 25px;
}

.has-max-width-120 {
  max-width: 120px;
}
</style>
