<template>
  <div id="gateway" class="modal" :class="isGatewayView ? 'is-active' : ''">
    <div
      class="modal-background"
      @click="isAnalysisInProgress ? '' : sendCloseEvent()"
    ></div>
    <div
      v-if="isInitial"
      class="loader-fullscreen loader-screen-centered"
    ></div>
    <div v-else class="modal-card gateway">
      <section
        class="
          modal-card-body
          has-overflow-hidden
          padding-tb-0
          has-border-top-5
        "
      >
        <WarningModal
          :key="warningComponentKey"
          :isWarningModalSeen="isWarningModalSeen"
          :warningObject="warningObject"
          v-on:sendFirstEvent="
            isDeleteCase ? deleteThenStartCase() : openPlannerInViewMode()
          "
          v-on:sendSecondEvent="
            isDeleteCase ? cancelDelete() : sendCloseEvent()
          "
        ></WarningModal>

        <div v-if="isAnalysisInProgress">
          <div class="loader-fullscreen loader-screen-centered"></div>
        </div>

        <article v-if="hasError" class="message is-danger mt-3">
          <div class="message-body">
            <span> Something went wrong. Please try again later.</span>
          </div>
        </article>

        <h1
          class="
            subtitle
            is-5
            has-text-almost-black
            margin-top-10 margin-bottom-5
            padding-rl-20 padding-tb-0
          "
        >
          Case {{ selectedCase.caseId }} - {{ selectedCase.name }} -
          {{ formatDate(selectedCase.dob) }}
          ({{ selectedCase.age }}) - {{ selectedCase.sex }} -
          {{ selectedCase.description }}
        </h1>

        <h1
          class="
            subtitle
            is-size-2
            has-text-almost-black has-text-dark-primary
            margin-bottom-5
            padding-rl-20 padding-tb-10
          "
        >
          Select Input for Planning
        </h1>

        <div id="cardiacPhaseInformation">
          <h1
            class="
              is-size-5
              has-text-almost-black has-text-dark-primary has-text-weight-bold
              margin-bottom-10
              padding-rl-20 padding-tb-0
            "
          >
            Select one Cardiac CT Series for Planning
          </h1>
          <article
            v-if="isInvalid"
            class="message is-danger margin-top-5 margin-left-right-20"
          >
            <div class="message-body">
              <span> No valid CT Series </span>
            </div>
          </article>
          <div v-else class="columns is-multiline has-scrollable-content">
            <div
              id="phaseSets"
              v-for="(phaseSetValue, phaseSet) in selectedCase.phaseSet"
              :key="phaseSet"
              class="column is-one-third has-max-width-418"
            >
              <div
                class="
                  padding-5 padding-top-0
                  has-border-radius-5
                  phase-set-card-container
                "
                :class="
                  selectedPhaseSet == phaseSet
                    ? 'has-background-pressed'
                    : 'has-background-white'
                "
              >
                <div
                  class="
                    card
                    margin-top-5
                    pointer
                    has-border-radius-5 has-overflow-hidden
                  "
                  @click="isAnalysisInProgress ? '' : selectPhaseSet(phaseSet)"
                >
                  <div
                    class="
                      phase-set-card-title
                      padding-tb-5
                      is-absolute
                      has-zindex-1 has-width-100-per
                    "
                  >
                    <div class="padding-rl-15">
                      <div
                        class="has-text-weight-bold is-size-6"
                        :class="
                          selectedPhaseSet == phaseSet
                            ? 'has-text-light-grey'
                            : 'has-text-almost-black'
                        "
                      >
                        {{ phaseSetValue.seriesDescription }}
                      </div>
                      <span
                        class="is-size-6"
                        :class="
                          selectedPhaseSet == phaseSet
                            ? 'has-text-light-grey'
                            : 'has-text-almost-black'
                        "
                      >
                        # Images: {{ phaseSetValue.sliceNr }}, # Phases:
                        {{ phaseSetValue.phases.length }}
                      </span>
                      <span
                        v-if="phaseSetValue.phases.length > 1"
                        class="is-size-6"
                        :class="
                          selectedPhaseSet == phaseSet
                            ? 'has-text-light-grey'
                            : 'has-text-almost-black'
                        "
                      >
                        ({{ phaseSetValue.phases[0] }}-{{
                          phaseSetValue.phases[phaseSetValue.phases.length - 1]
                        }}%),
                      </span>
                      <span
                        v-else
                        class="is-size-6"
                        :class="
                          selectedPhaseSet == phaseSet
                            ? 'has-text-light-grey'
                            : 'has-text-almost-black'
                        "
                      >
                        ({{ phaseSetValue.phases[0] }}%)
                      </span>
                      <div
                        class="is-size-6"
                        :class="
                          selectedPhaseSet == phaseSet
                            ? 'has-text-light-grey'
                            : 'has-text-almost-black'
                        "
                      >
                        Slice Thickness:
                        {{
                          formatSliceThickness(phaseSetValue.sliceThickness)
                        }}, Pixel Spacing:
                        {{
                          roundOffAndFormatPixelSpacing(
                            phaseSetValue.pixelSpacing
                          )
                        }}
                      </div>
                    </div>
                    <div
                      v-if="
                        isInvalidPhaseSet(phaseSetValue.phaseSetStatus) ||
                        isIncompletePhaseSet(phaseSetValue.phaseSetStatus)
                      "
                      class="
                        is-absolute
                        has-zindex-1
                        is-size-6 is-comment-box
                        has-text-dark-dark has-background-white has-opacity-80
                      "
                    >
                      <i
                        class="margin-top-3 margin-right-5"
                        :class="
                          isInvalidPhaseSet(phaseSetValue.phaseSetStatus)
                            ? 'fas fa-times-circle'
                            : 'fas fa-exclamation-circle'
                        "
                      ></i>
                      {{ getPhaseSetComment(phaseSetValue.phaseSetStatus) }}
                    </div>
                  </div>

                  <figure class="image is-relative is-square">
                    <img
                      v-if="allImages[phaseSet]"
                      :src="
                        'data:image/png;base64,' +
                        allImages[phaseSet][phaseSetValue.selectedPhase]
                      "
                    />
                    <div
                      class="
                        is-bottom-5 is-absolute
                        has-zindex-1 has-width-100-per
                      "
                    >
                      <div
                        v-if="phaseSetValue && phaseSetValue.phases.length > 1"
                        class="padding-rl-5"
                      >
                        <div class="is-phase-slider-background">
                          <vue-slider
                            v-if="phaseSetValue"
                            @change="switchPhase($event, phaseSet)"
                            :tooltip="'always'"
                            :adsorb="true"
                            :data="phaseSetValue.phases"
                            :min="phaseSetValue.phases[0]"
                            :max="
                              phaseSetValue.phases[
                                phaseSetValue.phases.length - 1
                              ]
                            "
                            :marks="true"
                          >
                            <template v-slot:tooltip="{}">
                              <div class="has-text-dark-dark">
                                {{ phaseSetValue.selectedPhase }}%
                              </div>
                            </template>
                            <template v-slot:step="{}">
                              <div class="custom-step"></div>
                            </template>
                            <template v-slot:label="{ active, value }">
                              <div
                                class="has-text-dark-dark"
                                :class="['vue-slider-mark-label', { active }]"
                              >
                                <span
                                  v-if="
                                    phaseSetValue.phases.length < 3 ||
                                    value === phaseSetValue.phases[0] ||
                                    value ===
                                      phaseSetValue.phases[
                                        phaseSetValue.phases.length - 1
                                      ]
                                  "
                                >
                                  {{ value }}%
                                </span>
                              </div>
                            </template>
                          </vue-slider>
                        </div>
                      </div>
                      <div v-else class="has-text-right">
                        <span
                          class="
                            tag
                            has-background-white
                            has-text-dark-dark
                            has-opacity-80
                            is-size-6
                            margin-right-5
                          "
                        >
                          {{ phaseSetValue.selectedPhase }}%
                        </span>
                      </div>
                    </div>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer
        class="
          modal-card-foot
          pl-0
          pr-0
          pb-0
          padding-top-10
          has-background-white
        "
      >
        <div class="columns mb-0 ml-0 has-width-100-per padding-left-10">
          <div class="column">
            <div id="caseProtocol" class="columns padding-rl-10">
              <div class="column is-narrow">
                <h1
                  class="
                    is-size-5
                    margin-top-10 margin-bottom-10
                    has-text-almost-black
                    has-text-dark-primary
                    has-text-weight-bold
                  "
                >
                  Assign Case Protocol
                </h1>
              </div>

              <div class="column">
                <div ref="caseSelect">
                  <div
                    class="dropdown is-up"
                    :class="[
                      isReadOnly ? 'has-opacity-60' : '',
                      caseProtocolsOpen ? 'is-active' : '',
                    ]"
                    v-click-outside="
                      () => (caseProtocolsOpen ? toggleCases() : '')
                    "
                  >
                    <div class="dropdown-trigger">
                      <div
                        class="
                          has-background-whisper-grey
                          min-width-324
                          has-line-height-50
                          padding-left-10 padding-right-45
                          has-border-radius-3
                        "
                        :class="
                          caseProtocolsOpen ? 'has-box-shadow-darker-grey' : ''
                        "
                        id="selectedCaseProtocol"
                      >
                        <span
                          v-if="selectedCaseProtocol == null"
                          class="is-size-5 has-text-weight-normal"
                        >
                          Select Case Protocol...</span
                        >
                        <span v-else class="is-size-5">
                          {{ selectedCaseProtocol }}
                        </span>
                        <span
                          class="
                            icon
                            is-right-0 is-absolute
                            margin-left-15
                            has-background-darker-grey
                            height-50
                            width-50
                            has-border-radius-5
                            pointer
                          "
                          id="caseProtocolDropdownButton"
                          :class="isReadOnly ? 'tooltip is-tooltip-top' : ''"
                          :data-tooltip="isReadOnly ? readOnlyWarning : ''"
                          @click="
                            isReadOnly || isAnalysisInProgress
                              ? ''
                              : toggleCases()
                          "
                        >
                          <i
                            class="fas has-text-white"
                            :class="
                              caseProtocolsOpen
                                ? 'fa-angle-up'
                                : 'fa-angle-down'
                            "
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>

                      <div
                        class="dropdown-menu min-width-324"
                        id="dropdown-cases"
                        role="menu"
                      >
                        <div
                          class="dropdown-content has-background-light-grey"
                          :class="
                            caseProtocolsOpen ? 'has-border-darker-grey' : ''
                          "
                        >
                          <div
                            v-for="(caseStudy, caseIndex) in caseStudies"
                            :key="caseIndex"
                            @click="
                              isAnalysisInProgress
                                ? ''
                                : selectCaseProtocol(caseStudy)
                            "
                          >
                            <div
                              class="dropdown-item has-hover-background-light"
                            >
                              <p class="pointer has-text-black is-size-5">
                                {{ caseStudy }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="controlButtons" class="column is-narrow">
            <a
              id="cancelCaseButton"
              class="
                button
                is-control-right-icon is-size-7
                margin-right-10
                has-text-darker-grey
                has-text-weight-bold
                has-background-transparent
                has-border-none
              "
              @click="isAnalysisInProgress ? '' : sendCloseEvent()"
              >CANCEL
            </a>

            <a
              id="startCaseButton"
              class="
                button
                noborder
                has-border-none
                is-size-7 is-control-right-icon
                has-text-white has-text-weight-bold has-background-darker-grey
              "
              :class="
                isReadOnly ||
                isCaseIncomplete ||
                (selectedCase.protocolName === selectedCaseProtocol &&
                  selectedCase.cardiacPhaseSet === selectedPhaseSet)
                  ? 'tooltip is-tooltip-multiline is-tooltip-top'
                  : ''
              "
              :data-tooltip="
                isReadOnly
                  ? readOnlyWarning
                  : isCaseIncomplete
                  ? 'Please select case protocol and series'
                  : selectedCase.protocolName === selectedCaseProtocol &&
                    selectedCase.cardiacPhaseSet === selectedPhaseSet
                  ? 'No changes to apply'
                  : ''
              "
              :disabled="
                isReadOnly ||
                isCaseIncomplete ||
                isAnalysisInProgress ||
                (selectedCase.protocolName === selectedCaseProtocol &&
                  selectedCase.cardiacPhaseSet === selectedPhaseSet)
                  ? true
                  : false
              "
              @click="
                isUploadInProgress
                  ? openUploadWarningModal()
                  : isReadOnly ||
                    isCaseIncomplete ||
                    isAnalysisInProgress ||
                    (selectedCase.protocolName === selectedCaseProtocol &&
                      selectedCase.cardiacPhaseSet === selectedPhaseSet)
                  ? ''
                  : applyChanges()
              "
              >APPLY
            </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueSlider from "vue-slider-component";
import vClickOutside from "v-click-outside";
import { sendErrors } from "@/js/errorHandler.js";
import _ from "lodash";
import * as constants from "../constants.js";
import * as helper from "@/js/helper.js";
import WarningModal from "@/components/WarningModal.vue";

const cases = require("../resources/usergroups.json");
const STATUS_INITIAL = 0;
const STATUS_INVALID = 1;
const STATUS_READY = 2;
const STATUS_ANALYSISINPROGRESS = 3;

export default {
  name: "gateway",
  props: {
    isGatewayView: Boolean,
    accessMode: Number,
  },
  data() {
    return {
      readOnlyWarning: constants.READ_ONLY_WARNING,
      cases: cases,
      caseStudies: [],
      caseProtocolsOpen: false,
      selectedCase: {},
      selectedCaseProtocol: null,
      selectedPhaseSet: null,
      currentStatus: STATUS_INITIAL,
      allImages: {},
      hasError: false,
      warningObject: {},
      warningComponentKey: 0,
      isWarningModalSeen: false,
      analysisIdCache: null,
      isDeleteCase: false,
    };
  },
  components: {
    VueSlider,
    WarningModal,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isReady() {
      return this.currentStatus === STATUS_READY;
    },
    isInvalid() {
      return this.currentStatus === STATUS_INVALID;
    },
    isAnalysisInProgress() {
      return this.currentStatus === STATUS_ANALYSISINPROGRESS;
    },
    isCaseIncomplete() {
      return (
        this.selectedCaseProtocol === null || this.selectedPhaseSet === null
      );
    },
    isUploadInProgress() {
      return this.$store.getters.isUploadInProgress;
    },
    isReadOnly() {
      return this.accessMode === constants.ACCESS_MODE_READONLY;
    },
  },
  mounted() {
    if (this.isGatewayView) {
      this.getStudyInformation()
        .then(() => {
          this.getCaseProtocols().catch((error) => {
            sendErrors(error, "Gateway:watcher:getCaseProtocols()");
          });

          this.getPhasesFromServer()
            .then(() => {
              this.getImages();
            })
            .catch((error) => {
              sendErrors(error, "Gateway:watcher:getPhasesFromServer()");
            });
        })
        .catch((error) => {
          sendErrors(error, "Gateway:watcher:getStudyInformation()");
        });
    }
  },
  methods: {
    formatDate: helper.formatDate,
    formatSliceThickness: helper.formatSliceThickness,
    roundOffAndFormatPixelSpacing: helper.roundOffAndFormatPixelSpacing,
    reset: function () {
      this.caseStudies = [];
      this.caseProtocolsOpen = false;
      this.selectedCaseProtocol = null;
      this.selectedPhaseSet = null;
      this.currentStatus = STATUS_INITIAL;
      this.allImages = {};
      this.hasError = false;
      this.warningObject = {};
      this.warningComponentKey = 0;
      this.isWarningModalSeen = false;
    },
    getStudyInformation: function () {
      return new Promise((resolve, reject) => {
        axios
          .post("/api/get_study", {
            CaseID: this.$store.getters.currentCaseId,
          })
          .then((res) => {
            this.selectedCase = res.data.studyInfo;
            // Pre select the defined case protocol and phase set if they have been defined already
            if (this.selectedCase.protocolName !== undefined) {
              this.selectedCaseProtocol = this.selectedCase.protocolName;
              this.selectedPhaseSet = this.selectedCase.cardiacPhaseSet;
            }
            resolve();
          })
          .catch((error) => {
            sendErrors(error, "Gateway:getStudyInformation()");
            reject(new Error(error));
          });
      });
    },
    getCaseProtocols: function () {
      return new Promise((resolve, reject) => {
        axios
          .get("/api/case_protocols")
          .then((res) => {
            this.caseStudies = res.data;
            resolve();
          })
          .catch((error) => {
            this.hasError = true;
            sendErrors(error, "Gateway:getCaseProtocols()");
            reject();
          });
      });
    },
    getPhasesFromServer: function () {
      return new Promise((resolve, reject) => {
        this.selectedCase.phaseSet = {};
        const path = "/api/phase_sets";
        axios
          .post(path, {
            CaseID: this.selectedCase.caseId,
          })
          .then((res) => {
            _.forEach(res.data.allCardiacPhaseSets, (value, phaseSet) => {
              const seriesDescription = value.seriesDescription;
              const length = 40;
              value.seriesDescription =
                seriesDescription.length > length
                  ? seriesDescription.substring(0, length - 3) + "..."
                  : seriesDescription;

              // Only show valid phaseSet, i.e. phaseSets with intermediate results
              if (value.gitRef && value.gitRef != null) {
                this.$set(this.selectedCase.phaseSet, phaseSet, value);
                value.phases.sort((a, b) => a - b);
                // Select first phase by default
                value.selectedPhase = value.phases[0];
              }
            });

            if (Object.keys(this.selectedCase.phaseSet).length === 0) {
              this.currentStatus = STATUS_INVALID;
              reject("No valid phase set");
              return;
            }

            resolve();
          })
          .catch((error) => {
            sendErrors(error, "Gateway:getPhasesFromServer()");
            reject();
          });
      });
    },
    getImages: function () {
      const path = "/reporting-board-api/gateway_images";
      const allPhaseSets = {};
      _.forEach(this.selectedCase.phaseSet, (value, phaseSet) => {
        allPhaseSets[phaseSet] = value.phases;
      });
      axios
        .post(path, {
          lara_id: this.selectedCase.caseId,
          git_ref:
            this.selectedCase.phaseSet[
              Object.keys(this.selectedCase.phaseSet)[0]
            ].gitRef,
          phase_sets: allPhaseSets,
        })
        .then((res) => {
          this.allImages = res.data;
          this.currentStatus = STATUS_READY;
        })
        .catch((error) => {
          this.hasError = true;
          sendErrors(error, "Gateway:getImages()");
        });
    },
    applyChanges: function () {
      if (
        this.selectedCase.protocolName !== undefined &&
        (this.selectedCase.protocolName !== this.selectedCaseProtocol ||
          this.selectedCase.cardiacPhaseSet !== this.selectedPhaseSet)
      ) {
        this.openAdjustCaseConfirmation();
      } else {
        this.startCase();
      }
    },
    openAdjustCaseConfirmation: function () {
      this.isDeleteCase = true;
      this.warningObject = {
        title: "Confirm Delete",
        primaryMessage:
          "Are you sure you want to delete the case for " +
          this.selectedCase.caseId +
          "?",
        secondaryMessage: "This action cannot be undone.",
        firstButton: "CONFIRM",
        secondButton: "CANCEL",
      };
      this.openWarningModal();
    },
    startCase: function () {
      this.$emit("cancelUpload");
      this.closeWarningModal();
      this.currentStatus = STATUS_ANALYSISINPROGRESS;
      axios
        .post("/api/start_case", {
          protocol_name: this.selectedCaseProtocol,
          cardiac_phase_set_id: this.selectedPhaseSet,
        })
        .then((res) => {
          if (res.status === 200) {
            this.$router.push({ path: "/planner/" + res.data.id });
            this.$store.commit("setCurrentView", constants.VIEW_PLANNER);
            this.currentStatus = STATUS_READY;
          } else {
            // TODO: we need to show some warning here and probably
            //  redirect to Analysis or Report
            console.log("Something went wrong");
            this.sendCloseEvent();
          }
        })
        .catch((error) => {
          this.currentStatus = STATUS_READY;
          if (error.response && error.response.status === 409) {
            this.analysisIdCache = error.response.data.analysis_id;
            this.isDeleteCase = false;
            this.warningObject = {
              title: "Case has already been started",
              primaryMessage:
                "Do you want to start the CT planner in View-Only-Mode or go back to the overview page?",
              secondaryMessage: "",
              firstButton: "GO TO CT PLANNER",
              secondButton: "BACK TO OVERVIEW",
            };
            this.openWarningModal();
          } else {
            this.hasError = true;
            sendErrors(error, "Gateway:getImageAnalysis()");
          }
        });
    },
    deleteThenStartCase: function () {
      axios
        .post("/api/delete_case", {
          CaseID: this.$store.getters.currentCaseId,
        })
        .then(() => {
          this.startCase();
        })
        .catch((error) => {
          sendErrors(error, "Gateway:deleteCase()");
          this.hasError = true;
        });
    },
    openWarningModal: function () {
      this.warningComponentKey++;
      this.isWarningModalSeen = true;
    },
    closeWarningModal: function () {
      this.isWarningModalSeen = false;
      this.warningObject = {};
    },
    openPlannerInViewMode: function () {
      this.$router.push({
        path: "/planner/" + this.analysisIdCache,
      });
    },
    cancelDelete: function () {
      this.isDeleteCase = false;
      this.closeWarningModal();
    },
    isInvalidPhaseSet: function (phaseSetStatus) {
      return phaseSetStatus <= 2;
    },
    isIncompletePhaseSet: function (phaseSetStatus) {
      return phaseSetStatus === 3;
    },
    getPhaseSetComment: function (phaseSetStatus) {
      if (this.isInvalidPhaseSet(phaseSetStatus)) {
        return "Phases do not satisfy Laralab constraints for CT processing";
      } else if (this.isIncompletePhaseSet(phaseSetStatus)) {
        return "Some phases have missing slices, but CT processing is still possible. Review automatic assessments carefully.";
      } else {
        return "Phases do not have missing slices and satisfy Laralab constraints for CT processing";
      }
    },
    selectCaseProtocol: function (caseProtocol) {
      this.selectedCaseProtocol = caseProtocol;
      this.toggleCases();
    },
    selectPhaseSet: function (phaseSet) {
      if (phaseSet !== this.selectedPhaseSet) {
        this.selectedPhaseSet = parseInt(phaseSet);
      }
    },
    switchPhase: function (phaseValue, phaseSet) {
      this.selectedCase.phaseSet[phaseSet].selectedPhase = phaseValue;
      this.$forceUpdate();
    },
    toggleCases: function () {
      this.caseProtocolsOpen = !this.caseProtocolsOpen;
    },
    openUploadWarningModal: function () {
      this.$store.commit("setIsUploadWarningSeen", true);
    },
    sendCloseEvent: function () {
      this.reset();
      this.$emit("closeGateway");
    },
  },
};
</script>

<style scoped>
.gateway {
  width: 80%;
  max-width: 1150px;
  height: 97%;
  max-height: 742px;
  padding: 10px;
}

.custom-step {
  height: 100%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #272727;
  margin-left: -3px;
  margin-top: 5px;
}

#startCaseButton::before,
#startCaseButton:hover::before {
  transform: translate(-173px, -0.5rem) !important;
}

.has-scrollable-content {
  overflow-y: auto;
  padding-right: 20px;
  padding-left: 20px;
  height: calc(100vh - 260px);
  max-height: 500px;
}

.is-control-right-icon {
  min-height: 48px;
  min-width: 110px;
}

.is-control-botton {
  position: fixed;
  right: 20px;
}

.is-phase-slider-background {
  background: rgba(255, 255, 255, 0.6);
  padding: 20px;
  border-radius: 5px;
}

.is-phase-slider-background >>> .vue-slider-dot-tooltip-top {
  top: -3px;
  color: #272727;
  font-size: 12px;
  font-weight: bold;
}

.is-phase-slider-background >>> .vue-slider-mark-label {
  font-size: 12px;
  color: #272727;
}

.phase-set-card-container.has-background-pressed .phase-set-card-title {
  background-color: #258bc4 !important;
}

.phase-set-card-container.has-background-pressed:hover .phase-set-card-title {
  background-color: #1f719e !important;
}

.phase-set-card-container.has-background-white .phase-set-card-title {
  background-color: #bebebe !important;
}

.phase-set-card-container.has-background-white:hover .phase-set-card-title {
  background-color: #757575 !important;
}
</style>
