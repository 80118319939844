<template>
  <div
    id="minimizedUploadWindow"
    v-if="isMinimizedUploadVisible"
    class="is-minimizable-card margin-bottom-10"
  >
    <div class="card">
      <header class="card-header">
        <p
          v-if="!isUploadDone"
          class="card-header-title pt-2 pb-1 has-text-dark-primary"
        >
          Upload in progress
        </p>
        <p v-else class="card-header-title pt-2 pb-1 has-text-dark-primary">
          Upload Complete
        </p>
        <a class="card-header-icon" aria-label="more options">
          <span class="icon">
            <i
              class="far fa-window-maximize has-text-dark-primary"
              @click="maximizeUploadModal()"
            ></i>
          </span>
          <span v-if="isUploadDone" class="icon">
            <i
              class="fas fa-times has-text-dark-primary"
              @click="closeMinimizedUploadWindow()"
            ></i>
          </span>
        </a>
      </header>
      <div class="card-content pt-2 pb-1 pl-4">
        <div class="content">
          <div v-if="!isUploadDone">
            Uploading {{ progressObject.totalUploadedFiles }} of
            {{ progressObject.totalFiles }} files
            <div>
              <progress
                id="progressBar"
                class="progress is-success"
                :value="progressObject.progressBarValue"
                max="100"
              ></progress>
            </div>
          </div>
          <div v-else>
            <p
              v-if="successMessage != ''"
              class="margin-bottom-15 has-text-success"
            >
              <i class="fas fa-check margin-right-5"></i>
              {{ successMessage }}
            </p>
            <p
              v-if="errorMessage != ''"
              class="margin-bottom-15 has-text-danger"
            >
              <i class="fas fa-times margin-right-5"></i>
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MinimizedUploadModal",
  props: {
    isMinimizedUploadVisible: Boolean,
    isUploadDone: Boolean,
    progressObject: Object,
    successMessage: String,
    errorMessage: String,
  },
  methods: {
    maximizeUploadModal: function () {
      this.$emit("maximizeUploadModal");
    },
    closeMinimizedUploadWindow: function () {
      this.$emit("closeMinimizedUploadWindow");
    },
  },
};
</script>
