<template>
  <div
    id="confirmStudyModal"
    class="modal has-background-dark-primary"
    :class="isConfirmStudyModalSeen ? 'is-active' : ''"
  >
    <div class="modal-background" @click="sendCloseEvent"></div>
    <div class="modal-content">
      <div
        class="message"
        v-if="
          !(
            Object.keys(existingStudies).length === 0 &&
            existingStudies.constructor === Object
          )
        "
      >
        <div class="is-modal padding-rl-20 padding-tb-20">
          <h1 id="modalTitle" class="subtitle is-3 has-text-almost-black">
            Study already exists
          </h1>

          <p id="modalDescription" class="has-text-dark-primary">
            Uploading the following studies will create duplicates:
          </p>

          <div
            id="tableHeader"
            class="columns margin-top-20 margin-left-15 padding-rl-15"
          >
            <div class="column">
              <span class="has-text-darker-grey is-size-14"> NAME </span>
            </div>
            <div class="column">
              <span class="has-text-darker-grey is-size-14"> BIRTH DATE </span>
            </div>
            <div class="column">
              <span class="has-text-darker-grey is-size-14"> STUDY UID </span>
            </div>
          </div>
          <div id="patientList">
            <div
              v-for="(studyInfo, studyUID) in existingStudies"
              :key="studyUID"
            >
              <div :id="'studyInfo_' + studyUID" class="card margin-bottom-20">
                <div class="padding-rl-15">
                  <div class="columns has-text-left is-multiline">
                    <div class="column word-break">{{ studyInfo.name }}</div>
                    <div class="column word-break">
                      {{ formatDate(studyInfo.patientDob) }} ({{
                        studyInfo.patientAge
                      }})
                    </div>
                    <div class="column word-break">{{ studyUID }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p class="has-text-almost-black has-text-dark-primary pt-2">
            Do you want to continue the upload?
          </p>
        </div>

        <div
          id="controlButtons"
          class="
            pb-4
            margin-top-10
            padding-right-24
            has-text-right
            is-control-botton
          "
        >
          <a
            id="yesButton"
            class="
              button
              is-control-right-icon is-size-7
              margin-right-10
              has-text-darker-grey
              has-text-weight-bold
              has-background-transparent
              has-border-none
            "
            @click="sendUploadChoiceEvent"
            >YES - CREATE DUPLICATE
          </a>

          <a
            id="noButton"
            class="
              button
              noborder
              has-border-none
              is-size-7 is-control-right-icon
              has-text-white has-text-weight-bold has-background-darker-grey
            "
            @click="sendIgnoreChoiceEvent"
            >NO
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as helper from "@/js/helper.js";

export default {
  name: "ConfirmStudyModal",
  props: {
    isConfirmStudyModalSeen: Boolean,
    existingStudies: Object,
  },
  methods: {
    formatDate: helper.formatDate,
    sendCloseEvent: function () {
      this.$emit("cancelConfirmStudy");
    },
    sendUploadChoiceEvent: function () {
      this.$emit("confirmChoice", 0);
    },
    sendIgnoreChoiceEvent: function () {
      this.$emit("confirmChoice", 1);
    },
  },
};
</script>
<style scoped>
.modal {
  overflow-y: initial !important;
}
</style>