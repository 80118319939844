<template>
  <div
    id="toolBox"
    class="
      margin-rl-8
      padding-5
      mt-2
      has-background-light-grey has-border-radius-5
    "
  >
    <div class="columns is-1 mx-1 is-multiline mb-0 has-max-width-320">
      <div class="column is-narrow px-0 mr-1 pb-1">
        <div
          class="tool-container is-relative has-border-radius-3"
          :class="
            this.interaction != TOOL.DEFAULT ? '' : 'has-background-active'
          "
          @click="enableSlicing()"
        >
          <div class="icon-has-background is-vcentered mt-1">
            <img
              src="/static/images/move_icon.png"
              class="has-text-white is-centered"
            />
          </div>
          <div class="has-text-grey label is-vcentered">move</div>
        </div>
      </div>
      <div class="column is-narrow px-0 mr-1 pb-1">
        <div
          class="tool-container is-relative has-border-radius-3"
          :class="this.interaction != TOOL.ZOOM ? '' : 'has-background-active'"
          @click="enableZoom()"
        >
          <div class="icon-has-background is-vcentered mt-1">
            <i class="fas fa-search-plus has-text-white is-centered"></i>
          </div>
          <div class="has-text-grey label is-vcentered">zoom</div>
        </div>
      </div>
      <div class="column is-narrow px-0 mr-1 pb-1">
        <div
          class="tool-container is-relative has-border-radius-3"
          :class="
            this.interaction != TOOL.CONTRAST ? '' : 'has-background-active'
          "
          @click="enableContrast()"
        >
          <div class="icon-has-background is-vcentered mt-1">
            <i class="fas fa-adjust has-text-white is-centered"></i>
          </div>
          <div class="has-text-grey label is-vcentered">window</div>
        </div>
      </div>
      <div class="column is-narrow px-0 mr-1 pb-1">
        <div class="tool-container is-relative has-border-radius-3">
          <div class="field">
            <input
              id="switchRoundedSuccess"
              type="checkbox"
              ref="slider"
              class="switch is-rounded is-success"
              v-model="segementationEnabled"
            />
            <label
              class="custom-label has-text-grey label is-vcentered"
              for="switchRoundedSuccess"
            >
            </label>
          </div>
          <div class="has-text-grey label" @click="clickSlider()">segment.</div>
        </div>
      </div>
      <div class="column is-narrow px-0 mr-1 pb-1">
        <div
          class="tool-container is-relative has-border-radius-3"
          :class="[
            isReadOnly ? 'tooltip is-tooltip-right' : '',
            this.interaction != TOOL.MEASURING ? '' : 'has-background-active',
          ]"
          :data-tooltip="isReadOnly ? readOnlyWarning : ''"
          @click="isReadOnly ? '' : enableMeasurements()"
        >
          <div class="icon-has-background is-vcentered mt-1">
            <i class="fas fa-ruler has-text-white is-centered"></i>
          </div>
          <div class="has-text-grey label is-vcentered">line</div>
        </div>
      </div>
    </div>

    <!-- Spline tool. Activate when in scope
      <div class="column is-narrow px-0">
        <div
          class="tool-container is-relative has-border-radius-3"
        >
          <div class="icon-has-background is-vcentered mt-1">
            <img
              src="/static/images/spline_icon.png"
              class="has-text-white is-centered"
            />
          </div>
          <div class="has-text-grey label is-vcentered">spline</div>
        </div>
      </div> -->
  </div>
</template>

<script>
import * as constants from "../constants.js";
import { TOOL } from "@/js/constants.js";

export default {
  name: "ToolsDashboard",
  props: {
    interaction: String,
    isOverlaysActive: Boolean,
  },
  data() {
    return {
      readOnlyWarning: constants.READ_ONLY_WARNING,
      TOOL: TOOL,
      segementationEnabled: this.isOverlaysActive,
    };
  },
  watch: {
    segementationEnabled: function () {
      this.toggleOverlays();
    },
  },
  computed: {
    isReadOnly() {
      return (
        this.$store.getters.currentAccessMode === constants.ACCESS_MODE_READONLY
      );
    },
  },
  methods: {
    clickSlider: function () {
      this.$refs.slider.click();
    },
    toggleOverlays: function () {
      this.$emit("toggleOverlays");
    },
    enableMeasurements: function () {
      this.$emit("enableMeasurements");
    },
    enableContrast: function () {
      this.$emit("enableContrast");
    },
    enableZoom: function () {
      this.$emit("enableZoom");
    },
    enableSlicing: function () {
      this.$emit("enableSlicing");
    },
  },
};
</script>

<style scoped>
.field {
  height: 27px;
  padding-top: 1px;
  margin-bottom: 0;
}

.custom-label {
  top: 0 !important;
  transform: translate(-35%, 0) !important;
  margin-top: 2px;
}

.switch[type="checkbox"].is-rounded + label::after {
  height: 24px;
  width: 24px;
  top: 0;
  left: 0;
  background-color: #757575;
  content: url("/static/images/segmentation_icon.png");
  padding-top: 4px;
  padding-left: 4px;
}

.switch[type="checkbox"]:checked + label::after {
  left: 1.625rem;
}

.is-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.is-vcentered {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.label {
  top: 27px;
  font-size: 14px;
  text-transform: uppercase;
  color: #757575;
  font-weight: bold;
}

.tool-container {
  height: 50px;
  width: 75px;
}

.tool-container:hover {
  cursor: pointer;
}

.has-background-active {
  background-color: #e5e5e5;
}
</style>