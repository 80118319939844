export const VIEW_BROWSE = 0;
export const VIEW_GATEWAY = 1;
export const VIEW_REPORT = 2;
export const VIEW_PLANNER = 3;
export const VIEW_ADMIN = 4;
export const VIEW_ACCOUNT = 5;
export const VIEW_ABOUT = 6;
export const ACCESS_MODE_READONLY = 7;
export const ACCESS_MODE_EDITOR = 8;
export const MODE_DEVELOPMENT = "development";
export const MODE_PRODUCTION = "production";
export const CERTIFICATION_ONGOING = "ongoing";
export const CERTIFICATION_CE = "ce";

export const READ_ONLY_WARNING = "Feature disabled in Read Only mode";

export const yamlExample =
  '{"name": "Protocol Name", "version": "0.0.1", "categories": [{"title": "example_title", "boards": [{"title": "Example Title", "views": [{"reference": "example_view.sax", "title": null, "phase": null}, {"reference": "example_view_3ch", "title": null, "phase": null}, {"reference": "example_view.lax_perp_3ch", "title": null, "phase": null}], "synchronized_views": null, "scalars": null, "errors": ""}]}]}';
