<template>
  <!-- Confirm Delete Modal -->
  <div
    id="confirmDeleteModal has-text-almost-black"
    class="modal"
    :class="isConfirmDeleteModalSeen ? 'is-active' : ''"
  >
    <div class="modal-background" @click="sendCloseEvent"></div>
    <div class="modal-content">
      <article class="message">
        <div class="message-header">
          <p class="has-text-mid-grey">Confirm Delete</p>
        </div>
        <div v-if="!hasError">
          <div class="message-body is-modal">
            <p
              id="deleteMessage"
              class="has-text-centered has-text-dark-primary"
            >
              <span>
                Are you sure you want to delete "{{ studyInfo.name }}"?
              </span>
            </p>

            <p
              class="
                has-text-centered has-text-dark-primary has-text-weight-bold
              "
            >
              This action cannot be undone.
            </p>
          </div>
          <div
            class="has-text-centered has-background-dark-primary padding-tb-5"
          >
            <button
              id="confirmButton"
              class="button is-danger margin-right-5"
              @click="deletePatient()"
            >
              Confirm
            </button>
            <button
              id="cancelButton"
              class="button margin-right-5"
              @click="sendCloseEvent"
            >
              Cancel
            </button>
          </div>
        </div>
        <div v-else>
          <div class="message-body">
            <p id="errorMessage" class="has-text-centered">
              Something went wrong. Please try again later.
            </p>
          </div>
          <div class="has-text-centered padding-tb-5">
            <button id="okButton" class="button" @click="sendCloseEvent">
              OK
            </button>
          </div>
        </div>
      </article>
    </div>
  </div>
  <!-- Confirm Delete modal end -->
</template>

<script>
import { sendErrors } from "@/js/errorHandler.js";
import axios from "axios";

export default {
  name: "ConfirmDeleteModal",
  props: {
    isConfirmDeleteModalSeen: Boolean,
    studyInfo: Object,
  },
  data() {
    return {
      hasError: false,
    };
  },
  mounted() {
    this.checkLoggedIn();
  },
  methods: {
    reset: function () {
      this.hasError = false;
    },
    sendCloseEvent: function () {
      this.reset();
      this.$emit("cancelDelete");
    },
    checkLoggedIn: function () {
      axios.get("/api/currentuser").then((response) => {
        if (!response.data.user) {
          window.location.href = "/";
        }
      });
    },
    deletePatient: function () {
      axios
        .post("/api/delete", {
          CaseID: this.studyInfo.caseId,
          StudyUID: this.studyInfo.studyUID,
          Name: this.studyInfo.name,
        })
        .then((res) => {
          this.reset();
          if (!res.data.error) {
            window.location.assign("/overview");
          }
        })
        .catch((error) => {
          sendErrors(error, "ConfirmDelete:deletePatient()");
          this.hasError = true;
        });
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: initial !important;
}

.modal-card {
  width: 90%;
  height: 75%;
  overflow-y: auto;
}
</style>