<template>
  <div id="resetForm">
    <section class="hero is-fullheight is-medium is-bold">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <article class="card is-rounded has-max-width-50">
              <div class="card-content" @keyup.enter="resetPassword">
                <article
                  v-show="errorMessage"
                  class="message is-danger is-small"
                >
                  <div class="message-body is-size-6">{{ errorMessage }}</div>
                </article>
                <article
                  v-show="message"
                  class="message is-success is-small has-max-width-250"
                >
                  <div class="message-body is-size-6">{{ message }}</div>
                </article>
                <div v-if="isInitial">
                  <div class="field">
                    <p
                      class="
                        control
                        has-icons-left has-icons-right
                        margin-bottom-15
                      "
                    >
                      <input
                        id="emailField"
                        class="input"
                        type="email"
                        name="email"
                        v-model="email"
                        placeholder="Email"
                      />
                      <span class="icon is-small is-left">
                        <i class="fas fa-envelope has-text-dark-secondary"></i>
                      </span>
                    </p>
                  </div>
                  <div class="field">
                    <p class="control">
                      <button
                        id="loginButton"
                        class="button is-success has-background-darker-grey"
                        @click="resetPassword()"
                      >
                        Reset Password
                      </button>
                    </p>
                  </div>
                </div>
                <div class="mt-3" @click="cancelResetPassword">
                  <a><i class="fas fa-chevron-left"></i> BACK TO LOGIN</a>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { sendErrors } from "@/js/errorHandler.js";

export default {
  name: "RequestResetPasswordPage",
  data() {
    return {
      email: "",
      message: "",
      errorMessage: "",
      isInitial: true,
    };
  },
  components: {},
  methods: {
    cancelResetPassword(msg = false) {
      this.$emit("cancelResetPassword", msg);
    },
    resetPassword() {
      if (!this.email) {
        this.errorMessage = "Please enter email!";
      } else {
        this.errorMessage = "";
        this.isInitial = false;
        axios
          .post("api/request_reset_password", { email: this.email })
          .then((res) => {
            console.log("Reset requested");
            this.message =
              "A reset link has been sent if the specified email exists";
          })
          .catch((err) => {
            this.errorMessage = "Something went wrong. Please try again later";
            sendErrors(err, "RequestResetPasswordPage:resetPassword()");
          });
      }
    },
  },
};
</script>