import axios from "axios";
import * as helper from "./helper.js";

// Global error handling for catching async errors inside Vue app
window.addEventListener("unhandledrejection", function(err) {
  let errorType;
  console.log(err);
  if (err.type) {
    errorType = err.type;
  } else {
    errorType = "Error in async function or promise";
  }
  let message;
  if (err.reason.message) {
    message = err.reason.message;
  } else if (err.reason) {
    message = err.reason;
  } else {
    message = "";
  }
  const error = `${message} : ${errorType}`;
  sendErrors(error);
  err.preventDefault();
});

function sendErrors(err, component = "") {
  console.log(err, component);
  const api = "/api/web_log_error";
  let error;
  if (err.stack) {
    error = err.stack;
  } else {
    error = err;
  }
  const payload = {
    error: error,
    component: component
  };
  return new Promise(resolve => {
    callApi(api, payload).then(() => {
      resolve();
    });
  });
}

function sendWarnings(warning, trace) {
  console.log(warning);
  const api = "/api/web_log_warning";
  const payload = {
    warning: warning,
    trace: trace
  };
  return new Promise(resolve => {
    callApi(api, payload).then(() => {
      resolve();
    });
  });
}

function sendInfos(infos) {
  console.log(infos);
  const api = "/api/web_log_info";
  if (infos) {
    const payload = {
      infos: infos
    };
    return new Promise(resolve => {
      callApi(api, payload).then(() => {
        resolve();
      });
    });
  }
}

function callApi(api, payload) {
  const cookie = helper.parseCookie(document.cookie);
  return axios
    .post(
      api,
      payload,
      {
        timeout: 120000, // 2 minutes
        headers: {"X-CSRF-TOKEN": cookie["csrf_access_token"]}
      }
    )
    .then(res => {
      if (res && res.data.includes("Please log in to access this page.")) {
        console.log("Login Required");
        return;
      }
      return "Done";
    })
    .catch(error => {
      console.log("Something went wrong", error);
    });
}

export { sendErrors, sendInfos, sendWarnings };
