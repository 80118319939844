var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isReady),expression:"isReady"}],staticClass:"no-print",class:_vm.isReady && _vm.theme === 'default' ? '' : 'dark-theme'},[_c('nav',{staticClass:"navbar has-shadow-bold is-fixed-top",attrs:{"id":"navbar","role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"navbar-brand"},[_c('a',{staticClass:"navbar-item margin-left-10",attrs:{"href":"/"}},[(_vm.isReady && _vm.theme != 'default')?_c('img',{staticClass:"margin-right-8 is-ci-logo",attrs:{"src":"/static/images/cardiac_logo_trans.png"}}):_vm._e(),(_vm.isReady && _vm.theme != 'default')?_c('span',{staticClass:"has-text-dark-font"},[_vm._v(" powered by ")]):_vm._e(),(_vm.isReady && _vm.theme != 'default')?_c('img',{staticClass:"padding-top-2",attrs:{"src":"/static/images/laralab_white.png","width":"70","height":"28"}}):_c('img',{attrs:{"src":"/static/images/laralab_trans_bg.png","width":"100","height":"40"}})]),(
          _vm.$store.getters.currentUser &&
          !_vm.isAdminView &&
          !_vm.isAccountView &&
          !_vm.isAboutView
        )?_c('span',{staticClass:"navbar-item margin-left-10"},[_c('span',{staticClass:"\n            is-size-6\n            has-text-weight-bold has-text-dark-primary\n            is-uppercase\n            pointer\n          ",class:_vm.isBrowseView ? 'has-border-bottom' : '',attrs:{"id":"navbarCaseOverviewButton"},on:{"click":_vm.switchToOverview}},[_vm._v("Case Overview ")])]):_vm._e(),(
          _vm.$store.getters.currentUser &&
          !_vm.isBrowseView &&
          !_vm.isAdminView &&
          !_vm.isAccountView &&
          !_vm.isAboutView
        )?_c('span',{staticClass:"navbar-item"},[_c('span',{staticClass:"\n            is-size-6\n            padding-10\n            has-border-radius-3 has-background-light-grey\n          "},[(!_vm.isCurrentCaseEmpty)?_c('span',{staticClass:"has-text-dark-primary"},[_vm._v(" CASE "+_vm._s(_vm.$store.getters.currentCaseId))]):_vm._e(),_c('span',{staticClass:"ml-2 has-text-dark-primary has-text-weight-bold pointer",class:_vm.isPlannerView ? 'has-border-bottom' : '',attrs:{"id":"navbarPlannerButton"},on:{"click":_vm.switchToAnalysis}},[_vm._v(" PLANNER ")]),_c('span',{staticClass:"ml-2 has-text-dark-primary has-text-weight-bold pointer",class:_vm.isReportBoardView ? 'has-border-bottom' : '',attrs:{"id":"navbarReportButton"},on:{"click":_vm.switchToReportingBoard}},[_vm._v(" REPORT ")])])]):_vm._e(),(_vm.isNotForClinicalUse)?_c('div',{staticClass:"\n          navbar-item\n          is-clinical-warning is-size-5\n          has-text-weight-bold has-text-danger\n          is-uppercase\n          has-text-right\n        "},[_vm._v(" NOT FOR CLINICAL USE ")]):_vm._e(),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.currentUser),expression:"$store.getters.currentUser"}],staticClass:"navbar-burger burger margin-top-3",attrs:{"id":"navbar-burger","aria-label":"menu","aria-expanded":"false","data-target":"navbarBasic"}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"navbar-menu padding-0",attrs:{"id":"navbarBasic"}},[_c('div',{staticClass:"navbar-end"},[(_vm.$store.getters.currentUser)?_c('div',{staticClass:"navbar-item has-dropdown is-hoverable",attrs:{"id":"userIcon"}},[_c('div',{staticClass:"margin-top-3 margin-right-10 padding-10"},[_c('figure',{staticClass:"image is-32x32"},[_c('img',{staticClass:"is-rounded",attrs:{"id":"userimage","src":_vm.$store.getters.currentUser.org === 'CI'
                    ? '/static/images/cardiac_implants.jpeg'
                    : '/static/images/default-logo.png'}})])]),_c('div',{staticClass:"navbar-dropdown is-right padding-0",attrs:{"id":"dropdown-menu"}},[_c('a',{staticClass:"dropdown-item padding-tb-10",attrs:{"href":"#"}},[_c('p',{staticClass:"has-text-weight-bold has-text-dark-primary",attrs:{"id":"email"}},[_vm._v(" "+_vm._s(this.$store.getters.currentUser.email)+" ")])]),_c('hr',{staticClass:"dropdown-divider margin-0"}),_vm._m(0),_c('hr',{staticClass:"dropdown-divider margin-0"}),(
                this.$store.getters.currentUser.roles.includes(_vm.LARA_SUPPORT)
              )?_c('a',{staticClass:"dropdown-item padding-tb-10",attrs:{"id":"adminToolButton","href":"/admin"}},[_c('p',{staticClass:"has-text-dark-primary",attrs:{"id":"support"}},[_vm._v("Admin Tools")])]):_vm._e(),_c('hr',{staticClass:"dropdown-divider margin-0"}),_c('a',{staticClass:"dropdown-item has-text-dark-primary padding-tb-10",attrs:{"id":"aboutButton","href":"/about"}},[_vm._v(" About ")]),_c('hr',{staticClass:"dropdown-divider margin-0"}),_c('a',{staticClass:"dropdown-item has-text-dark-primary padding-tb-10",attrs:{"id":"logoutButton"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout ")])])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"dropdown-item padding-tb-10",attrs:{"id":"accountSettingsButton","href":"/account"}},[_c('p',{staticClass:"has-text-dark-primary"},[_vm._v("Account Settings")])])}]

export { render, staticRenderFns }