<template>
  <div v-if="isLoggedIn" class="columns">
    <div v-if="waitingForResponse" class="modal-background has-zindex-15">
      <div class="loader loader-screen-centered"></div>
    </div>
    <div class="column is-one-fifth has-min-width-270">
      <div class="margin-top-20 margin-left-15 is-size-6">
        <span>{{ user.email }}</span>
      </div>
      <div class="margin-left-30 margin-top-20 is-size-5">
        <div>
          <a
            id="showChangePasswordButton"
            @click="waitingForResponse ? '' : showChangePassword()"
            class="button is-text has-no-decoration"
          >
            <span
              :class="
                changePassword ? 'has-text-weight-bold has-text-link' : ''
              "
              >Change Password</span
            >
          </a>
        </div>
      </div>
    </div>
    <div class="column margin-top-110">
      <div v-show="changePassword">
        <div class="is-relative">
          <input
            id="currentPassword"
            class="input password-input"
            :type="isPasswordVisible ? 'text' : 'password'"
            autocomplete="new-password"
            v-model="currentPassword"
            placeholder="Current password"
          />
          <i
            id="togglePwButton"
            class="eye-button fas"
            :class="isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
            @click="waitingForResponse ? '' : togglePasswordVisibility()"
          ></i>
          <i class="fas fa-check is-invisible"></i>
        </div>
        <div class="ml-1 mt-2 mb-1 has-text-grey is-size-7">
          <div>Password must contain at least 12 characters.</div>
          <div>Subsequent spaces will be contracted.</div>
        </div>
        <div class="is-relative mb-1">
          <input
            id="newPassword"
            class="input password-input"
            :type="isNewPasswordVisible ? 'text' : 'password'"
            autocomplete="new-password"
            v-model="newPassword"
            placeholder="New password"
          />
          <i
            class="eye-button fas"
            :class="isNewPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
            @click="waitingForResponse ? '' : toggleNewPasswordVisibility()"
          ></i>
          <i
            v-show="newPassword"
            class="fas is-size-4 password-check"
            :class="
              passwordMeetsRequirements
                ? 'fa-check has-text-success'
                : 'fa-times has-text-danger'
            "
          ></i>
        </div>
        <div class="is-relative">
          <input
            id="repeatNewPassword"
            class="input password-input"
            :type="isRepeatPasswordVisible ? 'text' : 'password'"
            autocomplete="new-password"
            v-model="repeatNewPassword"
            placeholder="Repeat new password"
          />
          <i
            class="eye-button fas"
            :class="isRepeatPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
            @click="waitingForResponse ? '' : toggleRepeatPasswordVisibility()"
          ></i>
          <i
            v-show="repeatNewPassword"
            class="fas is-size-4 password-check"
            :class="
              repeatPasswordMeetsRequirements
                ? 'fa-check has-text-success'
                : 'fa-times has-text-danger'
            "
          ></i>
          <div class="message is-danger is-small is-inline ml-2 mt-4 warn-box">
            <span v-show="repeatNewPassword && !passwordsMatch" class="mx-2"
              >Passwords don't match</span
            >
          </div>
        </div>

        <div
          v-if="errorMessage"
          class="message is-danger is-small mb-2 has-max-width-250"
          :class="isInitial ? 'margin-top-60' : ''"
        >
          <div class="message-body has-text-weight-bold mt-2">
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div v-else class="has-height-72"></div>
        <div class="mt-2">
          <a
            id="submitButton"
            @click="waitingForResponse ? '' : submitPassword()"
            class="
              button
              has-background-transparent
              is-outlined
              has-text-weight-bold has-text-darker-grey
              mr-4
            "
            >SUBMIT
          </a>
        </div>
      </div>
      <!-- Messages -->
      <div
        v-if="message"
        class="message is-success is-small has-max-width-250"
        :class="isInitial ? 'margin-top-60' : ''"
      >
        <div class="message-body has-text-weight-bold mt-2">
          <span>{{ message }}</span>
        </div>
      </div>
      <!-- End Messages -->
    </div>
  </div>
</template>
<script>
import * as constants from "../constants.js";
import axios from "axios";
import { sendErrors } from "@/js/errorHandler.js";

const INITIAL = 0;
const CHANGE_PASSWORD = 1;

export default {
  name: "AccountComponent",
  data() {
    return {
      isLoggedIn: false,
      user: "",
      currentActiveSession: INITIAL,
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      message: "",
      errorMessage: "",
      isPasswordVisible: false,
      isNewPasswordVisible: false,
      isRepeatPasswordVisible: false,
      waitingForResponse: false,
    };
  },
  beforeMount() {
    this.checkLogin();
  },
  computed: {
    changePassword() {
      return this.currentActiveSession === CHANGE_PASSWORD;
    },
    isInitial() {
      return this.currentActiveSession === INITIAL;
    },
    passwordMeetsRequirements() {
      // Replace subsequent spaces with one space
      const password = this.newPassword.replace(/  +/g, " ");
      return password.length > 11;
    },
    passwordsMatch() {
      return this.newPassword === this.repeatNewPassword;
    },
    repeatPasswordMeetsRequirements() {
      return this.passwordMeetsRequirements && this.passwordsMatch;
    },
  },
  methods: {
    reset: function () {
      this.currentActiveSession = INITIAL;
      this.currentPassword = "";
      this.newPassword = "";
      this.repeatNewPassword = "";
      this.isPasswordVisible = false;
      this.isNewPasswordVisible = false;
      this.isRepeatPasswordVisible = false;
      this.waitingForResponse = false;
      this.errorMessage = false;
    },
    togglePasswordVisibility: function () {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    toggleNewPasswordVisibility: function () {
      this.isNewPasswordVisible = !this.isNewPasswordVisible;
    },
    toggleRepeatPasswordVisibility: function () {
      this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
    },
    checkLogin: function () {
      axios
        .get("/api/currentuser")
        .then((response) => {
          if (!response.data.user) {
            window.location.href = "/";
          } else {
            this.isLoggedIn = true;
            this.$store.commit("setCurrentUser", response.data.user);
            this.switchToAccountView();
            this.user = this.$store.getters.currentUser;
          }
        })
        .catch((err) => {
          sendErrors(err, "Account.vue:checkLogin()");
        });
    },
    switchToAccountView: function () {
      this.$store.commit("setCurrentView", constants.VIEW_ACCOUNT);
    },
    showChangePassword: function () {
      this.reset();
      this.currentActiveSession = CHANGE_PASSWORD;
    },
    submitPassword: function () {
      this.waitingForResponse = true;
      this.isPasswordValid()
        .then(() => {
          axios
            .post("/api/change_password", {
              email: this.user.email,
              current_password: this.currentPassword,
              new_password: this.newPassword,
            })
            .then((res) => {
              this.reset();
              this.setMessage(res.data.message);
            })
            .catch((err) => {
              let msg = "";
              if (Array.isArray(err.response.data.message)) {
                err.response.data.message.forEach((m) => {
                  msg.concat(" ", m);
                });
              } else {
                msg = err.response.data.message;
              }
              this.waitingForResponse = false;
              this.setErrorMessage(msg);
              console.log(err);
            });
        })
        .catch(() => {
          this.waitingForResponse = false;
          console.log("Something went wrong");
        });
    },
    setErrorMessage: function (msg) {
      this.errorMessage = msg;
    },
    setMessage: function (msg) {
      this.message = msg;
      setTimeout(() => {
        this.message = "";
      }, 4000);
    },
    isPasswordValid: function () {
      return new Promise((resolve, reject) => {
        if (this.newPassword !== this.repeatNewPassword) {
          this.setErrorMessage("Passwords do not match");
          reject();
          return;
        }
        axios
          .post("/api/check_password", {
            password: this.newPassword,
          })
          .then((res) => {
            resolve();
          })
          .catch((err) => {
            this.setErrorMessage(err.response.data.message);
            reject();
          });
      });
    },
  },
};
</script>

<style scoped>
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #555;
  animation: spin 1.1s infinite linear;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
