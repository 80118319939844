import axios from "axios";
import { CtCache } from "./ctCache.js";
import { sendErrors } from "../errorHandler.js";
import { createSurfaceCache } from "./surface.js";
import DracoGLTFLoader from "./dracoGLTFLoader.js";
import { DoubleSide, FrontSide } from "three";
import { STATE_CHANGE_EVENT } from "../constants.js";
import { initializeGeometries } from "./geometries.js";
import * as helper from "../helper.js";

export default class PlannerCache {
  /* Store cached resources, including:
    - slices
    - rotatedSlices
    - measurements
    - surface models
    - measurements (phase dependent and independent)
    - heart planes
  */

  constructor(phases, caseProtocol, onStateChanged, materials) {
    this.phases = phases;
    this.caseProtocol = caseProtocol;
    this._materials = materials;
    this.modelsByPhase = createSurfaceCache(this.phases, caseProtocol);
    this.heartPlanesByPhase = {};
    this.onStateChanged = onStateChanged;
    this.ctCache = new CtCache(onStateChanged);
    this.measurementsByPhase = {};
    this.phaseIndependentMeasurements = undefined;
    this.initialized = false;
    this.measurementsIntitalized = false;
    this.geometries = undefined;
    this.cookie = helper.parseCookie(document.cookie);
  }

  loadHeartPlanes(apiURL) {
    /* Load heart planes for all phases */
    const promises = [];
    const self = this;
    this.phases.forEach(phase => {
      if (this.heartPlanesByPhase[phase] === undefined) {
        promises.push(
          new Promise(function(resolve, reject) {
            axios
              .get(
                apiURL + `/${phase}/heart_planes/`,
                { timeout: 30000 } // 30 seconds
              )
              .then(res => {
                if (res && res.data.heart_planes !== undefined) {
                  self.heartPlanesByPhase[phase] = res.data.heart_planes;
                  self.ctCache.setHeartPlanes(res.data.heart_planes, phase);
                  resolve();
                } else {
                  console.log("failed to load heartplanes");
                  reject();
                }
              })
              .catch(err => {
                sendErrors(err, "plannerCache:loadMeasurements()");
                console.log(
                  "Failed to load heart planes for phase:",
                  phase,
                  "Error:",
                  err
                );
              });
          })
        );
      }
    });
    Promise.all(promises).then(res => {
      this._trySetPoses();
      this.initialized = true;
      this.onStateChanged(STATE_CHANGE_EVENT.PLANES_LOADED);
      this.loadSurfaceModels(apiURL);
      this.loadMeasurements(apiURL);
    });
  }

  loadMeasurements(apiURL) {
    /* Load phase dependent and phase independent measurements
    (scalars and geometries) for all phases. */

    const promises = [];
    const self = this;
    this.phases.forEach(phase => {
      if (this.measurementsByPhase[phase] === undefined) {
        promises.push(
          new Promise(function(resolve, reject) {
            axios
              .get(
                apiURL + `/${phase}/measurements_pd/`,
                { timeout: 30000 } // 30 seconds
              )
              .then(res => {
                if (res && res.data.data !== undefined) {
                  self.measurementsByPhase[phase] = res.data.data;
                  resolve();
                } else {
                  console.log("failed to load phase dependent measurements");
                  reject();
                }
              })
              .catch(err => {
                sendErrors(err, "plannerCache:loadMeasurements()");
                console.log(
                  "Failed to load pd measurements for phase:",
                  phase,
                  "Error:",
                  err
                );
              });
          })
        );
      }
    });
    const firstPhase = this.phases[0];
    if (this.phaseIndependentMeasurements === undefined) {
      promises.push(
        new Promise(function(resolve, reject) {
          axios
            .get(
              apiURL + `/${firstPhase}/measurements_pi/`,
              { timeout: 30000 } // 30 seconds
            )
            .then(res => {
              if (res && res.data.data !== undefined) {
                self.phaseIndependentMeasurements = res.data.data;
                resolve();
              } else {
                console.log("failed to load phase independent measurements");
                reject();
              }
            })
            .catch(err => {
              sendErrors(err, "plannerCache:loadMeasurements()");
              console.log("Failed to load pi measurements. Error:", err);
            });
        })
      );
    }

    Promise.all(promises).then(res => {
      this.measurementsIntitalized = true;
      // notify CTPLanner.vue to fetch measurements for current board
      document.dispatchEvent(new CustomEvent("measurementsLoaded"));
      // initialize THREE js geometries
      // this.geometries = initializeGeometries(
      //   this.phaseIndependentMeasurements,
      //   this.measurementsByPhase,
      //   this.caseProtocol,
      //   this._materials
      // );
      // notify views that geometries are ready and can be added to the scenes
      this.onStateChanged(STATE_CHANGE_EVENT.MEASUREMENTS_LOADED);
    });
  }

  getScalarsForBoard(board) {
    /* Return the scalar measurments that shall be attached to the board. */

    if (this.measurementsIntitalized) {
      const scalarsByView = {};
      const views = board.views;
      for (let i = 0; i < 4; i++) {
        const scalars = [];
        const measurementBoxes = views[i].measurement_boxes;
        if (measurementBoxes !== null) {
          const measurementBox = measurementBoxes[0]; // TODO: currently only one box per view is supported by the client
          for (const row of measurementBox.rows) {
            const scalar = this._getScalar(
              row.master_id,
              row.sub_id,
              board.phase,
              row.name
            );
            if (scalar.value != null) {
              scalars.push(scalar);
            }
          }
        }
        scalarsByView[i] = scalars;
      }
      return scalarsByView;
    } else {
      return { 0: [], 1: [], 2: [], 3: [] };
    }
  }

  _getScalar(masterId, subId, phase, name) {
    /* Return the scalar (name, value, unit, decimals)
    to be attached to the specific view

    master id < 1000 - phase dependent
    1000 <= master id < 6000 - phase independent
    6000 <= master id - phase dependent cardiac implants */

    let master;
    if (masterId >= 1000 && masterId < 6000) {
      master = this.phaseIndependentMeasurements[masterId];
    } else {
      master = this.measurementsByPhase[phase][masterId];
    }

    const sub = master.scalars[subId];
    const scalar = {
      value: sub.value,
      decimal: sub.decimal,
      unit: sub.unit
    };
    scalar.name = name;
    return scalar;
  }

  _getGeometry(masterId, subId, phase) {
    /* Return the geometry (name, value, unit, decimals)
    to be attached to the specific view

    master id < 1000 - phase dependent
    1000 <= master id < 6000 - phase independent
    6000 <= master id - phase dependent cardiac implants */

    let master;
    if (masterId >= 1000 && masterId < 6000) {
      master = this.phaseIndependentMeasurements[masterId];
    } else {
      master = this.measurementsByPhase[phase][masterId];
    }
    return master.geometries[subId];
  }

  _trySetPoses() {
    /* Set poses transformations from the heartPlanes to the case protocol
    if available and unset. Also set contrast properties from the default
    Phase Set contrast if unset
    Necessary because poses transformations are calculated at datapipe and
    datapipe doesn't have access to the case protocol */
    for (const category of this.caseProtocol.categories) {
      for (const board of category.boards) {
        for (const view of board.views) {
          for (const pose of view.poses) {
            const heartPlanePose = this.heartPlanesByPhase[board.phase][
              board.heart_plane_group
            ][view.heart_plane].pose_by_id[pose.id_];
            if (
              pose.transformation === null &&
              heartPlanePose !== undefined &&
              heartPlanePose.transformation !== undefined
            ) {
              pose.transformation = heartPlanePose.transformation;
            }
            if (pose.window === undefined) {
              pose.window = this.caseProtocol.contrast_window;
              pose.window = this.caseProtocol.contrast_level;
            }
          }
        }
      }
    }
  }

  getHeartPlaneForView(phase, heartPlaneGroup, heartPlane) {
    return this.heartPlanesByPhase[phase][heartPlaneGroup][heartPlane];
  }

  addLine(line, phase) {
    /* Add new Line measurement.
    If line.masterId is not undefined, already existing Line Measurement will be overwritten. */
    if (line.masterId === undefined) {
      // masterIds from 10001 to 10999 are reserved for custom line measurements
      const masterIds = Object.keys(this.measurementsByPhase[phase]).filter(
        i => parseFloat(i) > 10000 && parseFloat(i) < 11000
      );
      if (masterIds.length === 0) {
        line.masterId = 10001;
      } else {
        line.masterId = Math.max(...masterIds) + 1;
      }
    }
    // Structure follows those of automatically calculated measurements
    // (see also lrlb_common.case_protocol.measurements.MeasurementsGroup)
    this.measurementsByPhase[phase][line.masterId] = {
      name: line.getPoseName(),
      type: "line",
      scalars: {
        1: { name: "distance", decimal: 1, unit: "mm", value: line.distance }
      },
      geometries: {
        51: {
          name: "points",
          type: "line",
          points: [line.start.worldCoordinates, line.end.worldCoordinates]
        }
      }
    };
  }

  deleteLine(line, phase) {
    /* Delete line from measurements */
    delete this.measurementsByPhase[phase][line.masterId];
  }

  loadSurfaceModels(apiURL) {
    this.phases.forEach(phase => {
      const gltfLoader = DracoGLTFLoader();
      gltfLoader.setRequestHeader({
        "X-CSRF-TOKEN": this.cookie["csrf_access_token"]
      });
      gltfLoader.load(apiURL + `/${phase}/model/`, gltf => {
        this._setSurfaceModels(phase, gltf);
      });
    });
  }

  _setSurfaceModels(phase, gltf) {
    /* Set the scene data for all boards in the given phase */
    const models = this.modelsByPhase[phase];
    const sceneMap = _createSceneMap(gltf.scenes);

    let sceneData;
    Object.keys(models.scenes).forEach(view => {
      // Map scenes to views
      if (sceneMap.hasOwnProperty(view)) {
        sceneData = gltf.scenes[sceneMap[view]];
      }
      // Remove redundant layer
      sceneData.children = sceneData.children[0].children;
      _setMaterialProperties(sceneData);

      models.scenes[view].data = sceneData;
    });
    this.onStateChanged(STATE_CHANGE_EVENT.SURFACE_LOADED);
  }
}

function _createSceneMap(gltfScenes) {
  /* Create Map to locate a scene by name in the scenes array */
  return Object.fromEntries(
    gltfScenes.map((scene, ix) => {
      return [scene.name, ix];
    })
  );
}

function _setMaterialProperties(sceneData) {
  /* Set THREE.js material properties of the surface meshes */
  sceneData.traverse(function(node) {
    if (node.isMesh) {
      node.material.side = DoubleSide;
      node.material.opacity = 1.0;
      node.material.transparent = false;

      if (
        typeof node.userData.opacity !== "undefined" &&
        node.userData.opacity < 1.0
      ) {
        // Clone material to have different opacities for each view
        node.material = node.material.clone();
        node.material.side = DoubleSide;
        node.material.transparent = true;
        node.material.opacity = node.userData.opacity;
      }

      if (
        typeof node.userData.color !== "undefined" &&
        node.userData.color === "gray"
      ) {
        node.material = node.material.clone();
        node.material.color = {
          r: 0.5,
          g: 0.5,
          b: 0.5
        };
        node.material.side = DoubleSide;
        node.material.transparent = true;
        node.material.opacity = 0.5;
      }
    }
  });
}
