import _ from "lodash";
import { STATE_CHANGE_EVENT, CT_QUEUE_MESSAGE } from "../constants.js";
import FetchWorker from "worker-loader!../ctQueue/ctFetchQueue.worker.js";
import * as helper from "../helper.js";

class Slice {
  /* Container of one MPR slice data.
  Store ct array, overlays and information required to properly
  show them. */
  constructor() {
    this.fetching = false;
    this.fetched = false;
    this.inWaitingList = false; // true if slice was requested out of turn
    this.ct = undefined; // ct image array
    this.overlay = undefined; // overlay as svg
    this.shape = undefined; // shape of the slice
    this.center = undefined; // coordinates of the point that shall be at the center of the view
    this.volumeToWorld; // transformation matrix from volume coordinate system to world coordinate system for given slice
  }
}

export class CtCache {
  /* Cache of the CT slices */

  constructor(onStateChanged) {
    this.slicesByPhase = {};
    this.fetchWorker = undefined;
    this.onStateChanged = onStateChanged;
  }

  setHeartPlanes(heartPlanes, phase) {
    this.slicesByPhase[phase] = this._createSlicesCache(heartPlanes);
  }

  startCtFetchWorker(apiURL, cache, currentBoard) {
    /* Start web worker retrieving ct slices */
    this.fetchWorker = new FetchWorker();
    const message = {
      aTopic: CT_QUEUE_MESSAGE.INITIAL,
      apiURL: apiURL,
      ctCache: cache.ctCache,
      heartPlanesByPhase: cache.heartPlanesByPhase,
      caseProtocol: cache.caseProtocol,
      currentBoard: currentBoard,
      cookie: helper.parseCookie(document.cookie)
    };
    this.fetchWorker.postMessage(JSON.parse(JSON.stringify(message)));

    // Hard binding of this necessary to access the data of cache
    this._setDataFromWorker = this._setDataFromWorker.bind(this);
    this.fetchWorker.addEventListener("message", message => {
      if (message.data.hasOwnProperty("info")) {
        this._setDataFromWorker(message);
      }
    });
  }

  updateCtFetchWorkerQueue(state) {
    /* Update Queue of the slices requested by web worker, e.g
    when board is changed. */
    const message = {
      aTopic: CT_QUEUE_MESSAGE.UPDATE,
      currentBoard: state.currentBoard,
      cookie: helper.parseCookie(document.cookie)
    };
    this.fetchWorker.postMessage(JSON.parse(JSON.stringify(message)));
  }

  requestOneSlice(sliceInfo) {
    /* Force ctFetchQueue.worker to fetch specific slice out of turn */
    const message = {
      aTopic: CT_QUEUE_MESSAGE.ONE_SLICE,
      sliceInfo: sliceInfo
    };
    this.fetchWorker.postMessage(JSON.parse(JSON.stringify(message)));
  }

  _setDataFromWorker(message) {
    /* Set the slice data from the CT fetch worker */

    // Read the message
    const {
      phase,
      heartPlaneGroup,
      heartPlane,
      sliceIndex
    } = message.data.info;
    const { fetching, fetched } = message.data.fetchingStatus;

    const slice = this.slicesByPhase[phase][heartPlaneGroup][heartPlane][
      sliceIndex
    ];

    slice.fetching = fetching;
    slice.fetched = fetched;
    const data = message.data.data;
    if (typeof data === "undefined") {
      return;
    }
    slice.ct = new Uint16Array(data[0]);
    slice.overlay = data[1];
    slice.shape = data[2];
    slice.center = data[3];
    slice.volumeToWorld = data[4];
    this.onStateChanged(STATE_CHANGE_EVENT.CT_SLICE_LOADED);
  }

  _createSlicesCache(heartPlanes) {
    const slices = {};
    Object.entries(heartPlanes).forEach(
      ([heartPlaneGroupGroupId, heartPlaneGroup]) => {
        slices[heartPlaneGroupGroupId] = {};

        Object.entries(heartPlaneGroup).forEach(
          ([heartPlaneId, heartPlane]) => {
            slices[heartPlaneGroupGroupId][heartPlaneId] = {};
            const minSlice = heartPlane.ct_planner_info.min_slice;
            const maxSlice = heartPlane.ct_planner_info.max_slice;

            _.range(minSlice, maxSlice + 1).forEach(sliceIndex => {
              slices[heartPlaneGroupGroupId][heartPlaneId][
                sliceIndex
              ] = new Slice();
            });
          }
        );
      }
    );
    return slices;
  }
}
