import PlannerCache from "./cache/plannerCache.js";
import axios from "axios";
import { sendErrors } from "./errorHandler.js";
import { preSelectPoints, pointsToMeasurementsGroup } from "./views/annulus.js";
import { store } from "@/store/store.js";

import {
  TOOL,
  VIEW_INTERACTION,
  VIEW_TYPE,
  STATE_CHANGE_EVENT,
  GEOMETRY_TYPE,
  CT_QUEUE_MESSAGE
} from "./constants.js";
import { ACCESS_MODE_READONLY } from "@/constants.js";

import Materials from "./cache/materials.js";
import * as THREE from "three";
import _ from "lodash";

import {
  clampFloat,
  clampInteger,
  degreesToRadians,
  cartesianToFluoro,
  fluoroToLabelsAngles
} from "./views/math.js";
import createActivityMonitor from "./activity/clientActivity.js";
import View from "./views/view.js";

import Cross from "./views/cross.js";
import * as helper from "@/js/helper.js";

class ViewController {
  /* Controller of the views */
  constructor(
    caseProtocol,
    startingCategory,
    onStateChanged,
    patchMeasurements,
    updatePoseInCR,
    takeScreenshot
  ) {
    this.caseProtocol = caseProtocol;
    this.onStateChanged = onStateChanged;
    this._patchMeasurements = patchMeasurements;
    this._updatePoseInCR = updatePoseInCR;
    this._takeScreenshot = takeScreenshot;
    this.currentCategory = this.caseProtocol.categories[startingCategory];
    this.currentBoard = this.currentCategory.boards[0];

    this.currentTool = TOOL.DEFAULT;
    this.isOverlaysActive = false;
    this.contrastLookUpTable = {};
    this._contrast = {};
    this.activityMonitor = undefined;

    this.materials = new Materials();
    this.cross = new Cross(this.materials);
    // Current Slice Indexes for each view are kept here in order to take care
    // of their synchronization (e.g cross update or two lax rotational views
    // which should be always orthogonal).
    this.sliceIndexByView = { 0: 0, 1: 0, 2: 0, 3: 0 };
    // Normal vectors for each view and cross center are also kept here for
    // orientation and postion of the cross tool.
    this.normalByView = {};
    this.upVectorByView = {};
    this.crossCenter;

    this._heartPlanesByPhase;
    this.resetBoardSettings();
  }

  setCache(cache) {
    this.cache = cache;
  }

  annulusPointsByBoard(boardId, viewIndex) {
    const board = this._getBoardById(boardId);
    const view = board.views[viewIndex];
    if (view.geometries !== null) {
      for (const geometryReference of view.geometries) {
        const masterId = geometryReference.master_id;
        if (masterId === 1) {
          const response = {
            modifiable:
              store.getters.currentAccessMode !== ACCESS_MODE_READONLY &&
              geometryReference.modifiable,
            diameters: [],
            points: preSelectPoints(
              this.cache.measurementsByPhase[board.phase][masterId]
                .geometries[51].points
            )
          };
          // Loading diameters if attached to view
          if (
            geometryReference.sub_ids.length != 1 &&
            geometryReference.sub_ids instanceof Object
          ) {
            for (const [subId, name] of Object.entries(
              geometryReference.sub_ids
            )) {
              if (subId != 51) {
                const geometry = this.cache.measurementsByPhase[board.phase][
                  masterId
                ].geometries[subId];
                if (geometry.type === GEOMETRY_TYPE.DIAMETER) {
                  response.diameters.push([geometry.points, name]);
                }
              }
            }
          }
          return response;
        }
      }
    }
    return undefined;
  }

  updateAnnulusPoints(points) {
    this.cache.measurementsByPhase[
      this.currentBoard.phase
    ][1].geometries[51].points = points;
    this._patchMeasurements(
      1,
      this.currentBoard.phase,
      "update",
      pointsToMeasurementsGroup(points)
    );
  }

  updateViewsFromAnnulusPoint(viewIndex, worldCoordinates) {
    this.onStateChanged(STATE_CHANGE_EVENT.ANNULUS_POINT_SELECTED, false, {
      worldCoordinates: worldCoordinates,
      viewIndex: viewIndex
    });
  }

  addLine(viewIndex, line) {
    /* Adding line implies
     - saving the geometry;
     - adding it to the list of attached geometries
     - adding new pose if there is no one for current slice index */

    // find if there is already a pose for the current slice index.
    let mode;
    if (line.masterId === undefined) {
      mode = "add";
    } else {
      mode = "update";
    }
    this.cache.addLine(line, this.currentBoard.phase);
    this._patchMeasurements(line.masterId, this.currentBoard.phase, mode);
    const name = line.getPoseName();
    const poseAddOptions = {
      addToView: true,
      addToReport: false
    };
    const pose = this._findOrAddPose(viewIndex, name, poseAddOptions);
    if (pose.lines === null) {
      pose.lines = [];
    }
    if (mode === "add") {
      // structure follows the structure of automatic geometries atatched
      // to the case protocol (see lrlb_common/case_protocol/protocol.py:180)
      pose.lines.push({
        master_id: line.masterId,
        sub_ids: [51],
        incl_master_name: false
      });
      const poseCopy = JSON.parse(JSON.stringify(pose));
      poseCopy.window = undefined;
      poseCopy.level = undefined;
      this._updatePoseInCR(
        poseCopy,
        this.currentCategory.id_,
        this.currentBoard.id_,
        this.currentBoard.views[viewIndex].id_,
        false
      );
    }
    // Update screenshot if was taken for the pose
    if (pose.added_to_report) {
      this._takeScreenshot(this.currentCategory.id_, viewIndex, true);
    }
  }

  deleteLine(viewIndex, line) {
    /* Delete line from cache and from the list of geometries attached to view */
    this._patchMeasurements(line.masterId, this.currentBoard.phase, "delete");
    this.cache.deleteLine(line, this.currentBoard.phase);
    const pose = this._findOrAddPose(viewIndex, undefined);
    if (pose !== undefined && pose.lines !== undefined) {
      const index = pose.lines.indexOf({
        master_id: line.masterId,
        sub_ids: [51],
        incl_master_name: false
      });
      pose.lines.splice(index, 1);

      if (pose.lines.length === 0 && !pose.predefined) {
        this.deletePose(viewIndex);
        this._updatePoseInCR(
          pose,
          this.currentCategory.id_,
          this.currentBoard.id_,
          this.currentBoard.views[viewIndex].id_,
          true
        );
      } else {
        const poseCopy = JSON.parse(JSON.stringify(pose));
        poseCopy.window = undefined;
        poseCopy.level = undefined;
        this._updatePoseInCR(
          poseCopy,
          this.currentCategory.id_,
          this.currentBoard.id_,
          this.currentBoard.views[viewIndex].id_,
          false
        );
      }
    }
    if (pose.added_to_report) {
      let self = this;
      setTimeout(function() {
        self._takeScreenshot(self.currentCategory.id_, viewIndex, true);
      }, 250);
      // this._takeScreenshot(this.currentCategory.id_, viewIndex, true);
    }
  }

  _findPose(viewIndex) {
    /* Return pose for the current sliceIndex/camera vectors of the viewIndex th view
    if such a pose exists */
    const poses = this.currentBoard.views[viewIndex].poses;
    const viewType = this.currentBoard.views[viewIndex].type;
    if (poses.length > 0) {
      for (const pose of poses) {
        if (viewType === VIEW_TYPE.MPR) {
          if (pose.transformation === this.sliceIndexByView[viewIndex]) {
            return pose;
          }
        } else if (viewType === VIEW_TYPE.SURFACE) {
          if (
            _.isEqual(
              pose.normal_vector,
              this.normalByView[viewIndex].toArray()
            ) &&
            _.isEqual(pose.up_vector, this.upVectorByView[viewIndex].toArray())
          ) {
            return pose;
          }
        }
      }
    }
  }

  _findOrAddPose(
    viewIndex,
    name,
    addOptions = {
      addToView: true,
      addToReport: false
    }
  ) {
    /* Return pose for the current sliceIndex/camera vectors of the viewIndex th view
    if such a pose exists. Create a new pose otherwise. */
    let pose;
    pose = this._findPose(viewIndex);
    if (pose !== undefined) {
      return pose;
    }

    const viewType = this.currentBoard.views[viewIndex].type;
    if (name !== undefined) {
      if (viewType === VIEW_TYPE.MPR) {
        pose = _makeMPRPose(
          name,
          this.mprZoom,
          this.sliceIndexByView[viewIndex],
          this._contrast.window,
          this._contrast.level,
          this.caseProtocol,
          this._getInitialMprPose().zoom,
          addOptions.addToReport
        );
      } else if (viewType === VIEW_TYPE.SURFACE) {
        pose = _makeSurfacePose(
          name,
          this.surfaceZoom,
          this.normalByView[viewIndex].toArray(),
          this.upVectorByView[viewIndex].toArray(),
          this._getInitialMprPose().zoom, // TODO: surface zoom should be independent
          addOptions.addToReport
        );
      }

      if (addOptions.addToView) {
        this.currentBoard.views[viewIndex].poses.push(pose);
      }

      return pose;
    }
  }

  _poseExists(viewIndex) {
    const pose = this._findPose(viewIndex);
    return pose !== undefined;
  }

  getOrCreatePoseAndBookmark(viewIndex) {
    /* Return pose, bookmark and pose-state for the current view */

    const self = this;
    // If current pose doesn't exist, return new pose and bookmarks and isNewPose: true
    let numberCustomBookmark = 1;
    if (!this._poseExists(viewIndex)) {
      // Create unique bookmark/pose name
      const allBookmarks = [];
      _.forEach(this.currentBoard.bookmarks, bookmark => {
        allBookmarks.push(bookmark.name);
        if (!bookmark.pose_predefined) {
          numberCustomBookmark++;
        }
      });
      let nameBookmark = "Shot " + numberCustomBookmark;
      while (allBookmarks.includes(nameBookmark)) {
        numberCustomBookmark++;
        nameBookmark = "Shot " + numberCustomBookmark;
      }

      // Create new pose and bookmark
      const poseAddOptions = {
        addToView: false,
        addToReport: true
      };
      const newPose = this._findOrAddPose(
        viewIndex,
        nameBookmark,
        poseAddOptions
      );
      const newBookmark = {
        id_: nameBookmark,
        name: nameBookmark,
        target_view: this.currentBoard.views[viewIndex].id_,
        target_board: this.currentBoard.id_,
        pose: newPose.id_,
        pose_predefined: false,
        editing: false
      };
      return [newPose, newBookmark, true];
    } else if (this._poseExists(viewIndex)) {
      // If pose exists find it
      const pose = this._findPose(viewIndex);
      // If it is not added to report return old pose, new bookmark and isNewPose: true
      if (!pose.added_to_report) {
        pose.added_to_report = true;
        const newBookmark = {
          id_: pose.id_,
          name: pose.name,
          target_view: this.currentBoard.views[viewIndex].id_,
          target_board: this.currentBoard.id_,
          pose: pose.id_,
          pose_predefined: true,
          editing: false
        };
        return [pose, newBookmark, true];
      } else {
        // If it is already added to report return old pose, old bookmark isNewPose: false
        const oldBookmark = _.find(this.currentBoard.bookmarks, o => {
          return (
            o.id_ === pose.id_ &&
            o.target_view === self.currentBoard.views[viewIndex].id_
          );
        });
        if (oldBookmark) {
          return [pose, oldBookmark, false];
        }
      }
    }
  }

  addPoseToReport(viewIndex, pose, bookmark) {
    if (!this._poseExists(viewIndex)) {
      const poseAddOptions = {
        addToView: true,
        addToReport: true
      };
      pose = this._findOrAddPose(viewIndex, pose.name, poseAddOptions);
    }
    pose.added_to_report = true;
    this._addBookmarkToReport(bookmark);
  }

  _addBookmarkToReport(bookmark) {
    this.currentBoard.bookmarks.push(bookmark);
  }

  deletePose(viewIndex) {
    const self = this;
    const pose = this._findPose(viewIndex);
    const poseIndex = this.currentBoard.views[viewIndex].poses.indexOf(pose);
    if (!pose.predefined && !pose.lines) {
      this.currentBoard.views[viewIndex].poses.splice(poseIndex, 1);
    }
    if (!pose.predefined && pose.lines && pose.lines.length === 0) {
      this.currentBoard.views[viewIndex].poses.splice(poseIndex, 1);
    }
    this.removeBookmark(viewIndex, pose);
  }

  removeBookmark(viewIndex, pose) {
    const self = this;
    const bookmarkIndex = _.findIndex(this.currentBoard.bookmarks, function(b) {
      return (
        b.id_ === pose.id_ &&
        b.target_view === self.currentBoard.views[viewIndex].id_
      );
    });
    pose.added_to_report = false;
    this.currentBoard.bookmarks.splice(bookmarkIndex, 1);
  }

  changeCurrentBoard(categoryIndex, boardIndex) {
    this.currentBoard = this.caseProtocol.categories[categoryIndex].boards[
      boardIndex
    ];
    this.currentCategory = this.caseProtocol.categories[categoryIndex];
    this.resetBoardSettings();
    this._resetCrossCenter();
  }

  updateContrast(deltaWindow, deltaLevel) {
    this._contrast.window += deltaWindow;
    this._contrast.level += deltaLevel;
    this._updateLookupTable();
    this.onStateChanged(STATE_CHANGE_EVENT.CONTRAST, true);
  }

  updateMPRZoom(delta) {
    let newZoom = this.mprZoom + delta;
    const defaultZoom = this._getInitialMprPose().zoom;

    newZoom = clampFloat(newZoom, defaultZoom.min, defaultZoom.max);
    this.mprZoom = newZoom;
    this.onStateChanged(STATE_CHANGE_EVENT.ZOOM, true);
  }

  updateSurfaceZoom(delta) {
    let newZoom = this.surfaceZoom + delta;
    newZoom = clampFloat(newZoom, 50, 500);
    this.surfaceZoom = newZoom;
  }

  setMprPose(viewIndex, pose) {
    /* Set pose for the MPR view.
    Since all MPR views are connected, this implies setting
    global contrast and zoom settings from this pose */
    const increment = pose.transformation - this.sliceIndexByView[viewIndex];
    this.updateSliceIndex(viewIndex, increment);
  }

  getPose(bookmark, categoryIndex, boardIndex) {
    const board = this.caseProtocol.categories[categoryIndex].boards[
      boardIndex
    ];
    let viewIndex = -1;
    _.forEach(board.views, (view, index) => {
      if (view.id_ === bookmark.target_view) {
        viewIndex = index;
      }
    });
    const self = this;
    const pose = _.find(
      self.caseProtocol.categories[categoryIndex].boards[boardIndex].views[
        viewIndex
      ].poses,
      function(p) {
        return p.name === bookmark.name;
      }
    );
    return [pose, viewIndex];
  }

  renamePose(bookmark, categoryIndex, boardIndex, newPoseName) {
    const board = this.caseProtocol.categories[categoryIndex].boards[
      boardIndex
    ];
    let viewIndex = -1;
    _.forEach(board.views, (view, index) => {
      if (view.id_ === bookmark.target_view) {
        viewIndex = index;
      }
    });

    const view = board.views[viewIndex];

    let poseIndex = -1;
    _.forEach(view.poses, (pose, index) => {
      if (pose.name === bookmark.name) {
        poseIndex = index;
      }
    });

    this.caseProtocol.categories[categoryIndex].boards[boardIndex].views[
      viewIndex
    ].poses[poseIndex].name = newPoseName;
    this._updatePoseInCR(
      this.caseProtocol.categories[categoryIndex].boards[boardIndex].views[
        viewIndex
      ].poses[poseIndex],
      this.currentCategory.id_,
      this.currentBoard.id_,
      this.currentBoard.views[viewIndex].id_,
      false
    );
    document.dispatchEvent(new CustomEvent("updatePose"));
  }

  _resetCrossCenter() {
    /* Reset the cross center tool */
    if (this._heartPlanesByPhase === undefined) {
      // return in case heart planes are not yet loaded
    } else {
      const view = this.currentBoard.views[0];
      const heartPlane = this._heartPlanesByPhase[this.currentBoard.phase][
        this.currentBoard.heart_plane_group
      ][view.heart_plane];
      this.crossCenter = [...heartPlane.master_plane.p];
    }
  }

  updateCross() {
    /* Return array of length 16 which is representation of the
    4x4 transformation matrix of the cross tool.
    First 3x3 block of this matrix contains the normals to the MPR views of the current board. These normals are ordered the same way as the views at the board (e.g first column is the normal view of the first view).
    Fourth Column (3x1) is the central point of the cross.

    TODO: This solution only supports exactly 3 MPR views on a board now.
     */

    if (this._heartPlanesByPhase === undefined) {
      // return in case heart planes are not yet loaded
    } else {
      // TODO: hack to resolve three.js cross bug
      let sign;
      if (
        this._heartPlanesByPhase[this.currentBoard.phase][
          this.currentBoard.heart_plane_group
        ][this.currentBoard.views[2].heart_plane].interaction ===
        VIEW_INTERACTION.SLICING
      ) {
        sign = 1;
      } else {
        sign = 1;
      }

      const normals = [];
      for (let i = 0; i < 4; i++) {
        if (this.currentBoard.views[i].type === VIEW_TYPE.MPR) {
          normals.push([
            this.normalByView[i].x,
            this.normalByView[i].y,
            this.normalByView[i].z
          ]);
        }
      }
      const matrix = [
        normals[0][0],
        sign * normals[1][0],
        -1 * normals[2][0],
        this.crossCenter[0], // end of 1st row
        normals[0][1],
        sign * normals[1][1],
        -1 * normals[2][1],
        this.crossCenter[1], // end of 2nd row
        normals[0][2],
        sign * normals[1][2],
        -1 * normals[2][2],
        this.crossCenter[2], // end of 3rd row
        0,
        0,
        0,
        1
      ];
      this.cross.setMatrix(matrix);
    }
  }

  updateFluoroAngles(viewIndex) {
    if (this._heartPlanesByPhase === undefined) {
      // return in case heart planes are not yet loaded
    } else {
      this._setFluoroAngles(viewIndex);
      const dependentViewIndex = this._findDependentView(viewIndex);
      if (dependentViewIndex !== undefined) {
        this._setFluoroAngles(dependentViewIndex);
      }
    }
  }

  _setFluoroAngles(viewIndex) {
    const normal = this.normalByView[viewIndex];
    let { alpha, beta } = cartesianToFluoro(-normal.y, -normal.x, normal.z);
    let { alphaLabel, betaLabel, finalAlpha, finalBeta } = fluoroToLabelsAngles(
      alpha,
      beta
    );

    const primaryText = document.getElementById(
      `primaryFluoroText_${viewIndex}`
    );
    const secondaryText = document.getElementById(
      `secondaryFluoroText_${viewIndex}`
    );
    primaryText.innerHTML = `${alphaLabel} ${finalAlpha}`;
    secondaryText.innerHTML = `${betaLabel} ${finalBeta}`;
  }

  updateSliceIndex(viewIndex, increment) {
    /* Update either slice index of the requested view
    and the view connected to it (if exists) */
    if (this._heartPlanesByPhase === undefined) {
      // return in case heart planes are not yet loaded
    } else {
      this._setSliceIndex(viewIndex, increment);
      const dependentViewIndex = this._findDependentView(viewIndex);
      if (dependentViewIndex !== undefined) {
        this._setSliceIndex(dependentViewIndex, increment);
      }
      this.onStateChanged(STATE_CHANGE_EVENT.SLICE_CHANGED, true);
      this.updateCross();
    }
  }

  _setSliceIndex(viewIndex, increment) {
    const sliceIndex = this.sliceIndexByView[viewIndex];
    const view = this.currentBoard.views[viewIndex];

    const heartPlane = this._heartPlanesByPhase[this.currentBoard.phase][
      this.currentBoard.heart_plane_group
    ][view.heart_plane];
    const ctPlannerInfo = heartPlane.ct_planner_info;

    if (heartPlane.interaction === VIEW_INTERACTION.SLICING) {
      const updatedSliceIndex = clampInteger(
        sliceIndex + increment,
        ctPlannerInfo.min_slice,
        ctPlannerInfo.max_slice
      );
      const clampedIncrement = updatedSliceIndex - sliceIndex;
      this.sliceIndexByView[viewIndex] = updatedSliceIndex;
      const normal = [
        this.normalByView[viewIndex].x,
        this.normalByView[viewIndex].y,
        this.normalByView[viewIndex].z
      ];

      // slicing update means translation of the cross
      for (let i = 0; i < 3; i++) {
        this.crossCenter[i] =
          this.crossCenter[i] +
          clampedIncrement * ctPlannerInfo.spacing[2] * normal[i];
      }
    } else {
      let updatedSliceIndex = sliceIndex + increment;
      // * we transfer images only for rotation for 180 degrees;
      // * the other rotations can be calculated by mirroring;
      // * mirroring logic is applied inside
      // `views/view.js:_ctCanvasTransformation()` function;
      // * slice index here can be in range [0, 360) in order
      // to keep track of actual rotation angle (e.g to correctly show measurements)
      const maxSlice = (ctPlannerInfo.max_slice + 1) * 2;

      updatedSliceIndex =
        ((updatedSliceIndex % maxSlice) + maxSlice) % maxSlice;
      let clippedIncrement =
        updatedSliceIndex - this.sliceIndexByView[viewIndex];
      this.sliceIndexByView[viewIndex] = updatedSliceIndex;
      // rotation update means rotating the normal of the respective view
      if (clippedIncrement * heartPlane.rotation_step < 0) {
        clippedIncrement = 360 / heartPlane.rotation_step + clippedIncrement;
      }
      const rotation = degreesToRadians(
        clippedIncrement * heartPlane.rotation_step
      );

      const normal = this.normalByView[viewIndex];
      const upVector = this.upVectorByView[viewIndex];

      // Find elevation axis
      const elevationAxis = new THREE.Vector3()
        .crossVectors(upVector, normal)
        .normalize();

      // Construct the rotation matrices using the offsets and the rotation axes
      const elevationRotationMatrix = new THREE.Matrix4();
      elevationRotationMatrix.makeRotationAxis(elevationAxis, rotation);

      const azimuthRotationMatrix = new THREE.Matrix4();
      azimuthRotationMatrix.makeRotationAxis(upVector, 0);
      // Apply the rotation matrices to find the new camera position (direction)

      normal
        .applyMatrix4(elevationRotationMatrix)
        .applyMatrix4(azimuthRotationMatrix);
      // Find new viewUp vector
      this.upVectorByView[viewIndex] = new THREE.Vector3()
        .crossVectors(normal, elevationAxis)
        .normalize();
    }
    this._setFluoroAngles(viewIndex);
    this.updateCross();
  }

  setHeartPlanes(heartPlanes) {
    this._heartPlanesByPhase = heartPlanes;

    this._resetCrossCenter();
    this._makeViewsOrthogonal();

    this.updateCross();
  }

  resetBoardSettings() {
    /* Reset the altered board setting to the default (which are
    defined by the settings of the first pose of the first MPR view of
    the board */
    const pose = this._getInitialMprPose();
    this._setContrastAndMPRZoomfromPose(pose);
    this.surfaceZoom = 200;
    this.sliceIndexByView = { 0: 0, 1: 0, 2: 0, 3: 0 };
    this._makeViewsOrthogonal();
    this.updateCross();
  }

  _setContrastAndMPRZoomfromPose(pose) {
    if (pose.window !== undefined && pose.window !== null) {
      this._contrast.window = pose.window.value;
    } else {
      this._contrast.window = this.caseProtocol.contrast_window.value;
    }
    if (pose.level !== undefined && pose.level !== null) {
      this._contrast.level = pose.level.value;
      this._contrast.maxLevel = pose.level.max;
    } else {
      this._contrast.level = this.caseProtocol.contrast_level.value;
      this._contrast.maxLevel = this.caseProtocol.contrast_level.max;
    }
    this._updateLookupTable();
    this.mprZoom = pose.zoom.value;
  }

  _findDependentView(viewIndex) {
    /* If there are two rotation MPR views in a board,
    one of them is dependent on the other (to ensure orthogonal system).
    This function returns the index of the dependent view. */
    const heartPlaneGroup = this._heartPlanesByPhase[this.currentBoard.phase][
      this.currentBoard.heart_plane_group
    ];
    const view = this.currentBoard.views[viewIndex];
    if (
      heartPlaneGroup[view.heart_plane].interaction ===
      VIEW_INTERACTION.ROTATION
    ) {
      for (let index = 0; index < 4; index++) {
        if (index !== viewIndex) {
          const dependentView = this.currentBoard.views[index];
          if (
            heartPlaneGroup[dependentView.heart_plane].interaction ===
              VIEW_INTERACTION.ROTATION &&
            dependentView.type === VIEW_TYPE.MPR
          ) {
            return index;
          }
        }
      }
    }
    return undefined;
  }

  findFirstRotationView() {
    const heartPlaneGroup = this._heartPlanesByPhase[this.currentBoard.phase][
      this.currentBoard.heart_plane_group
    ];
    for (let index = 0; index < 4; index++) {
      const view = this.currentBoard.views[index];
      if (
        heartPlaneGroup[view.heart_plane].interaction ===
          VIEW_INTERACTION.ROTATION &&
        view.type === VIEW_TYPE.MPR
      ) {
        return index;
      }
    }
    return undefined;
  }

  _getBoardById(boardId) {
    for (const category of this.caseProtocol.categories) {
      for (const board of category.boards) {
        if (boardId === board.id_) {
          return board;
        }
      }
    }
  }

  getInteractionByViewIndex(index, boardId = undefined) {
    let board;
    if (boardId === undefined) {
      board = this.currentBoard;
    } else {
      board = this._getBoardById(boardId);
    }
    const heartPlaneGroup = this._heartPlanesByPhase[board.phase][
      board.heart_plane_group
    ];
    const view = board.views[index];
    return heartPlaneGroup[view.heart_plane].interaction;
  }

  getViewTypeByViewIndex(index, boardId) {
    const board = this._getBoardById(boardId);
    return board.views[index].type;
  }

  _makeViewsOrthogonal() {
    /* In case there are 2 rotation views in a board. The second one needs to be orthogonal to the first.
    This function adjusts the sliceIndex of the second view to achieve this
    on the reset of the board state. */

    if (this._heartPlanesByPhase === undefined) {
      // return in case heart planes are not yet loaded.
    } else {
      // look if there are exactly two rotation views: more are not supported,
      // less means that there is no sync required.
      const heartPlaneGroup = this._heartPlanesByPhase[this.currentBoard.phase][
        this.currentBoard.heart_plane_group
      ];
      const rotationViews = [];
      _.range(0, 4).forEach(index => {
        const view = this.currentBoard.views[index];
        if (
          heartPlaneGroup[view.heart_plane].interaction ===
            VIEW_INTERACTION.ROTATION &&
          view.type === VIEW_TYPE.MPR
        ) {
          rotationViews.push([index, view]);
        }
      });
      if (rotationViews.length === 2) {
        // adjust the slice index of the second view
        const [viewIndex, view] = rotationViews[0];
        const view1sliceIndex = this.sliceIndexByView[viewIndex];
        const view2sliceIndex =
          view1sliceIndex +
          270 / heartPlaneGroup[view.heart_plane].rotation_step;
        this.sliceIndexByView[rotationViews[1][0]] = view1sliceIndex;
        this._setSliceIndex(
          rotationViews[1][0],
          270 / heartPlaneGroup[view.heart_plane].rotation_step
        );

        this.sliceIndexByView[rotationViews[1][0]] = view2sliceIndex;
      }
    }
  }

  _updateLookupTable() {
    /* Update the contrast lookup hashmap for all values from 0 to maxLevel.
    Output is an integer from 0 to 255 for the grey value assigned. */
    const window = this._contrast.window;
    const level = this._contrast.level;
    const maxLevel = this._contrast.maxLevel;
    const lowerBound = level - window / 2;
    const upperBound = level + window / 2;

    for (let value = 0; value < maxLevel; value++) {
      if (value <= lowerBound) {
        this.contrastLookUpTable[value] = 0;
      } else if (value >= upperBound) {
        this.contrastLookUpTable[value] = 255;
      } else {
        this.contrastLookUpTable[value] = (255 * (value - lowerBound)) / window;
      }
    }
  }

  _getInitialMprPose() {
    /* Find the pose of the first MPR view. Its settings (contrast and zoom)
    are used as the default global settings of all connected MPR views */
    for (const view of this.currentBoard.views) {
      if (view.type === VIEW_TYPE.MPR && view.poses.length > 0) {
        return view.poses[0];
      }
    }
  }
}

export default class Controller {
  /* Global controller */
  constructor(caseProtocol, startingCategory) {
    this.onStateChanged = this.onStateChanged.bind(this);
    this._patchMeasurements = this._patchMeasurements.bind(this);
    this._updatePoseInCR = this._updatePoseInCR.bind(this);
    this.takeScreenshot = this.takeScreenshot.bind(this);
    this.viewController = new ViewController(
      caseProtocol,
      startingCategory,
      this.onStateChanged,
      this._patchMeasurements,
      this._updatePoseInCR,
      this.takeScreenshot
    );
    this.caseProtocol = caseProtocol;
    this.categoryIndex = startingCategory;

    // When supporting full 4d, phases can be passed from MA server
    const phases = _findPhases(caseProtocol);
    this.cache = new PlannerCache(
      phases,
      caseProtocol,
      this.onStateChanged,
      this.viewController.materials
    );
    this.viewController.setCache(this.cache);
    this._crURL;
    this.views = [];
    // Currently we support 4 views for one board at most and will keep
    // 4 instances of view class.
    _.range(0, 4).forEach(index => {
      this.views.push(new View(index, this.cache, this.viewController));
    });
    this._registerKeyboardInteractions();
  }

  _registerKeyboardInteractions() {
    document.addEventListener("keydown", e => {
      if (e.key === "Delete" || e.key === "Backspace") {
        for (const view of this.views) {
          view.onDelete();
        }
      }
    });
  }

  onCrReady(crURL, maURL) {
    /* Start communication with CR containers:
        - start activity monitoring to manage CR containers lifecycle
        - retrieve initial cache data: heartPlanes and measurements
        - start retrieveing ct data
        - retrieve surface models
    */
    this._crURL = crURL;
    this.viewController.activityMonitor = createActivityMonitor(maURL);
    this.cache.loadHeartPlanes(crURL);

    this.refreshInterceptor = axios.interceptors.response.use(response => {
      const cookie = helper.parseCookie(document.cookie);
      if (response.config.url === "/api/refresh_token") {
        this.viewController.activityMonitor.postMessage({
          aTopic: "updateCookie",
          cookie: cookie
        });
        this.cache.ctCache.fetchWorker.postMessage({
          aTopic: CT_QUEUE_MESSAGE.UPDATE_COOKIE,
          cookie: cookie
        });
      }
      return response;
    });
  }

  destroy() {
    this.cache.ctCache.fetchWorker.terminate();
    this.viewController.activityMonitor.terminate();
    axios.interceptors.response.eject(this.refreshInterceptor);
  }

  onStateChanged(change, forceUpdate = false, kwargs = {}) {
    if (change === STATE_CHANGE_EVENT.ANNULUS_UPDATED) {
      _.range(0, 4).forEach(index => {
        if (index !== kwargs.viewIndex) {
          this.views[index].update(change, forceUpdate);
        }
      });
    }

    _.range(0, 4).forEach(index => {
      this.views[index].update(change, forceUpdate, kwargs);
    });

    if (change === STATE_CHANGE_EVENT.PLANES_LOADED) {
      this.cache.ctCache.startCtFetchWorker(
        this._crURL,
        this.cache,
        this.viewController.currentBoard
      );
      this.viewController.setHeartPlanes(this.cache.heartPlanesByPhase);
      this._setFirstPoses();
    }
  }

  _patchMeasurements(
    masterId,
    phase,
    mode,
    data = null,
    updateHeartPlanes = false
  ) {
    // propagate updates to CR so that they are saved
    const url = this._crURL + "/" + phase + "/measurements_pd/";
    if (data === null) {
      data = this.cache.measurementsByPhase[phase][masterId];
    }
    axios
      .patch(
        url,
        {
          master_id: masterId,
          measurements_group: data,
          mode: mode,
          update_heart_planes: updateHeartPlanes
        },
        {
          timeout: 30000, // 30 seconds
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(res => {
        if (masterId === 1) {
          this.cache.measurementsByPhase[phase][masterId] = res.data;
          this.onStateChanged(STATE_CHANGE_EVENT.ANNULUS_UPDATED, false);
          document.dispatchEvent(new CustomEvent("measurementsLoaded"));
        }
      })
      .catch(err => {
        sendErrors(err, "controller:_updateLineInCR()");
      });
  }

  _updatePoseInCR(pose, categoryId, boardId, viewId, remove) {
    /* Add DOCUMENTATION */
    const url =
      this._crURL +
      "/" +
      this.cache.phases[0] +
      "/categories/" +
      categoryId +
      "/boards/" +
      boardId +
      "/views/" +
      viewId +
      "/poses/" +
      pose.id_;

    if (remove) {
      axios
        .delete(
          url,
          {
            pose: pose
          },
          {
            timeout: 30000, // 30 seconds
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(res => {})
        .catch(err => {
          sendErrors(err, "controller:_updatePoseInCR()");
        });
    } else {
      axios
        .post(
          url,
          {
            pose: pose
          },
          {
            timeout: 30000, // 30 seconds
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(res => {})
        .catch(err => {
          sendErrors(err, "controller:_updatePoseInCR()");
        });
    }
  }

  changeCurrentBoard(categoryIndex, boardIndex) {
    if (this.cache.initialized) {
      this.categoryIndex = categoryIndex;
      this.viewController.changeCurrentBoard(categoryIndex, boardIndex);
      this.cache.ctCache.updateCtFetchWorkerQueue(this.viewController);
      this.onStateChanged(STATE_CHANGE_EVENT.BOARD_CHANGED, true);
      this.viewController.resetBoardSettings();
      this._setFirstPoses();
    }
    if (
      this.viewController.currentBoard.overlay !==
      this.viewController.isOverlaysActive
    ) {
      this.toggleOverlays();
    }
  }

  setPose(viewIndex, pose) {
    /* Change the pose of the view with index `viewIndex` */
    const viewType = this.viewController.currentBoard.views[viewIndex].type;
    if (viewType === VIEW_TYPE.MPR) {
      this.viewController.setMprPose(viewIndex, pose);
    } else if (viewType === VIEW_TYPE.SURFACE) {
      this.views[viewIndex].setSurfacePose(pose);
    }
  }

  setTool(tool) {
    /* change the interaction tool inside the MPR views
    (e.g zoom, contrast, default, etc) */
    this.viewController.currentTool = tool;
    // update the views, required in case of overlay tool to show overlays
    this.onStateChanged(STATE_CHANGE_EVENT.TOOL_CHANGED, true);
  }

  toggleOverlays() {
    /* change the overlay status for the MPR views */
    this.viewController.isOverlaysActive = !this.viewController
      .isOverlaysActive;
    // update the views
    if (this.cache.initialized) {
      this.onStateChanged(STATE_CHANGE_EVENT.OVL_STATUS_CHANGED, true);
    }
    document.dispatchEvent(new CustomEvent("overlayStateChanged"));
    console.log("Overlays active: " + this.viewController.isOverlaysActive);
  }

  toggleFullscreen() {
    this.onStateChanged(STATE_CHANGE_EVENT.FULLSCREEN_CHANGED, true);
  }

  getScalars() {
    /* Return scalar measurements to be shown at the views of the current
    board. */
    return this.cache.getScalarsForBoard(this.viewController.currentBoard);
  }

  getBoxTitles() {
    /* Return the titles of the measurements boxes for all views. */
    const boxTitles = {};
    for (let i = 0; i < 4; i++) {
      const measurementBoxes = this.viewController.currentBoard.views[i]
        .measurement_boxes;
      if (measurementBoxes !== null) {
        // Currently only one box per view is supported
        boxTitles[i] = measurementBoxes[0].title;
      } else {
        boxTitles[i] = "";
      }
    }
    return boxTitles;
  }

  _setFirstPoses() {
    /* "activate" the first pose by using its transformation as slice index */
    let viewIndex = 0;
    for (const view of this.viewController.currentBoard.views) {
      if (view.poses.length > 0 && view.poses[0].predefined) {
        this.setPose(viewIndex, view.poses[0]);
      }
      viewIndex++;
    }
  }

  _getPoseFromBookmark(bookmark) {
    let categoryIndex = 0;
    for (const category of this.caseProtocol.categories) {
      let boardIndex = 0;
      for (const board of category.boards) {
        if (board.id_ === bookmark.target_board) {
          let viewIndex = 0;
          for (const view of board.views) {
            if (view.id_ === bookmark.target_view) {
              let poseIndex = 0;
              for (const pose of view.poses) {
                if (pose.id_ === bookmark.pose) {
                  return {
                    pose: pose,
                    viewIndex: viewIndex,
                    boardIndex: boardIndex,
                    categoryIndex: categoryIndex,
                    poseIndex: poseIndex
                  };
                }
                poseIndex++;
              }
            }
            viewIndex++;
          }
        }
        boardIndex++;
      }
      categoryIndex++;
    }
    return undefined;
  }

  async deleteAllBoardScreenshots() {
    /* Delete all the screenshots for current board.
    Currently this should happen if the user changes annulus after approval
    category approval.
    TODO: later these screenshots should be updated automatically (also
    including the screenshots from other boards where the annulus is present).
    */
    const promises = [];
    const self = this;
    const board = this.viewController.currentBoard;
    for (const bookmark of board.bookmarks) {
      const poseAndIndexes = this._getPoseFromBookmark(bookmark);
      if (poseAndIndexes !== undefined) {
        promises.push(
          new Promise(function(resolve, reject) {
            axios
              .delete(
                self._crURL + "/" + self.cache.phases[0] + "/screenshot",
                {
                  headers: {
                    "Content-Type": "application/json"
                  },
                  data: {
                    category_id: self.viewController.currentCategory.id_,
                    pose: poseAndIndexes.pose,
                    bookmark: bookmark
                  },
                  timeout: 30000 // 30 seconds
                }
              )
              .then(() => {
                if (poseAndIndexes.pose.predefined) {
                  poseAndIndexes.pose.added_to_report = false;
                } else {
                  board.views[poseAndIndexes.viewIndex].poses.splice(
                    poseAndIndexes.poseIndex,
                    1
                  );
                }
                resolve();
              })
              .catch(err => {
                sendErrors(err, "controller:deleteAllBoardScreenshots()");
                reject();
              });
          })
        );
      }

      Promise.all(promises).then(res => {
        board.bookmarks = [];
        this.views[
          this.viewController.indexOfUpdatedAnnulus
        ].updateAnnulusForViews(this.viewController.annulusWorldCoordinantes);
        document.dispatchEvent(new CustomEvent("updateScreenshot"));
      });
    }
  }

  rejectAnnulusUpdate() {
    // TODO: this is rather a hack but we won't need this functionality
    // if implementing synchronization instead of deletion
    if (this.viewController.indexOfUpdatedAnnulus === 0) {
      this.views[1].updateAnnulusForViews();
    } else {
      this.views[0].updateAnnulusForViews();
    }
  }

  takeScreenshot(categoryId, viewIndex, forceUpdate = false) {
    const [
      pose,
      bookmark,
      isNewPose
    ] = this.viewController.getOrCreatePoseAndBookmark(viewIndex);
    const url = this._crURL + "/" + this.cache.phases[0] + "/screenshot";

    if (isNewPose || forceUpdate) {
      const primaryFlouroAngle = document.getElementById(
        `primaryFluoroText_${viewIndex}`
      ).innerHTML;
      const secondaryFlouroAngle = document.getElementById(
        `secondaryFluoroText_${viewIndex}`
      ).innerHTML;

      const { textBoxes, endpoints } = this.views[
        viewIndex
      ].getLinesForScreenshot();
      const image = this.views[viewIndex].screenshot();
      const scalars = this.getScalars()[viewIndex];
      axios
        .post(
          url,
          {
            category_id: categoryId,
            pose: pose,
            bookmark: bookmark,
            image: image,
            scalars: scalars,
            fluoro_angles: {
              primary: primaryFlouroAngle,
              secondary: secondaryFlouroAngle
            },
            text_boxes: textBoxes,
            endpoints: endpoints
          },
          {
            timeout: 30000, // 30 seconds
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(() => {
          if (isNewPose && !forceUpdate) {
            this.views[viewIndex].viewController.addPoseToReport(
              viewIndex,
              pose,
              bookmark
            );
          }
          document.dispatchEvent(new CustomEvent("updateScreenshot"));
        })
        .catch(err => {
          sendErrors(err, "controller:takeScreenshot()");
        });
    } else {
      axios
        .delete(url, {
          headers: {
            "Content-Type": "application/json"
          },
          data: {
            category_id: categoryId,
            pose: pose,
            bookmark: bookmark
          },
          timeout: 30000 // 30 seconds
        })
        .then(() => {
          this.views[viewIndex].viewController.deletePose(viewIndex);
          document.dispatchEvent(new CustomEvent("updateScreenshot"));
        })
        .catch(err => {
          sendErrors(err, "controller:takeScreenshot()");
        });
    }
  }
}

function _findPhases(caseProtocol) {
  /* Return list of unique phases used by the boards of the case protocol */
  const phases = [];
  for (const category of caseProtocol.categories) {
    for (const board of category.boards) {
      if (!phases.includes(board.phase)) {
        phases.push(board.phase);
      }
    }
  }
  return phases;
}

function _makeMPRPose(
  name,
  zoomValue,
  transformation,
  windowValue,
  levelValue,
  caseProtocol,
  zoom,
  addToReport
) {
  /* Creates an object with items of the Pose.
  `caseProtocol` argument is used to fill in min/max values for level and window;
  `zoom` - to fill in min/max values for zoom. */
  return {
    id_: name,
    name: name,
    zoom: { min: zoom.min, max: zoom.max, value: zoomValue },
    transformation: transformation,
    overlay: false,
    window: {
      min: caseProtocol.contrast_window.min,
      max: caseProtocol.contrast_window.max,
      value: windowValue
    },
    level: {
      min: caseProtocol.contrast_level.min,
      max: caseProtocol.contrast_level.max,
      value: levelValue
    },
    lines: [],
    added_to_report: addToReport,
    predefined: false
  };
}

function _makeSurfacePose(
  name,
  zoomValue,
  normalVector,
  upVector,
  zoom,
  addToReport
) {
  /* Creates an object with items of the Pose.
  `zoom` - to fill in min/max values for zoom. */
  return {
    id_: name,
    name: name,
    zoom: { min: zoom.min, max: zoom.max, value: zoomValue },
    normal_vector: normalVector,
    up_vector: upVector,
    added_to_report: addToReport,
    predefined: false
  };
}
