<template>
  <div
    v-if="isLoggedIn"
    class="has-background-light-grey hero is-fullheight-with-navbar admin"
    id="admin"
  >
    <div v-if="waitingForResponse" class="modal-background has-zindex-15">
      <div class="loader-fullscreen loader-screen-centered"></div>
    </div>
    <div class="columns">
      <div class="column is-one-fifth has-min-width-270">
        <div class="header margin-top-15 margin-left-15 is-size-4">
          <span>Admin Tools </span>
        </div>
        <div class="margin-left-30 margin-top-20 is-size-5">
          <div>
            <a
              @click="showAddOrganization()"
              class="button is-text has-no-decoration"
            >
              <span :class="addOrg ? 'has-text-weight-bold has-text-link' : ''"
                >Add Organization</span
              >
            </a>
          </div>
          <div>
            <a
              @click="showEditOrganization()"
              class="button is-text has-no-decoration"
            >
              <span :class="editOrg ? 'has-text-weight-bold has-text-link' : ''"
                >Edit Organization</span
              >
            </a>
          </div>
          <div>
            <a
              @click="showAssignProtocols()"
              class="button is-text has-no-decoration"
            >
              <span
                :class="
                  assignProtocols ? 'has-text-weight-bold has-text-link' : ''
                "
                >Assign Case Protocols</span
              >
            </a>
          </div>
          <div>
            <a
              @click="showManageProtocols()"
              class="button is-text has-no-decoration"
            >
              <span
                :class="
                  manageProtocols ? 'has-text-weight-bold has-text-link' : ''
                "
                >Manage Case Protocols</span
              >
            </a>
          </div>
          <div>
            <a @click="showAddUser()" class="button is-text has-no-decoration">
              <span
                :class="addUserForm ? 'has-text-weight-bold has-text-link' : ''"
                >Add User</span
              >
            </a>
          </div>
          <div>
            <a @click="showEditUser()" class="button is-text has-no-decoration">
              <span
                :class="
                  editUserForm ? 'has-text-weight-bold has-text-link' : ''
                "
                >Edit User</span
              >
            </a>
          </div>
        </div>
      </div>
      <div class="column is-two-fifths">
        <AdminModal
          :key="modalKey"
          :currentModal="currentModal"
          :orgName="orgName"
          :userEmail="userEmail"
          :selectedOrganization="selectedOrganization"
          :selectedUser="selectedUser"
          :selectedProtocol="selectedProtocol"
          :allProtocols="allProtocols"
          :protocolList="protocolList"
          v-on:submitOrganization="submitOrganization"
          v-on:deleteOrganization="deleteOrganization"
          v-on:deleteUser="deleteUser"
          v-on:closeModal="closeModal"
          v-on:addProtocols="addProtocols"
          v-on:createProtocol="createProtocol"
          v-on:sendUploadProtocolEvent="uploadProtocolEvent"
          v-on:editProtocolEvent="editProtocolEvent"
          v-on:deleteProtocol="deleteProtocol"
        ></AdminModal>
        <!-- Add Organization -->
        <div
          v-if="addOrg"
          :class="addOrg ? 'is-active' : ''"
          class="margin-top-40"
        >
          <div class="has-background-grey has-text-centered">
            <span class="is-size-3">Add Organization</span>
          </div>
          <div @keyup.enter="openAddOrganizationConfirmModal" class="my-1">
            <input
              class="input my-1"
              type="text"
              v-model="orgName"
              placeholder="Organization Name"
            />
            <input
              class="input"
              type="text"
              v-model="description"
              placeholder="Description"
            />
          </div>
          <hr />
          <div @keyup.enter="openAddOrganizationConfirmModal" class="mt-1">
            <div>
              <div>
                <span class="is-size-6">Admin-user for organization:</span>
                <div class="mt-1 mb-2">
                  <span
                    >The user will receive an email asking them to choose a
                    password.</span
                  >
                </div>
                <span class="is-size-7 has-text-grey is-block"
                  >Leave empty to create organization without admin.</span
                >
              </div>
              <div class="columns is-1 is-variable">
                <div class="column is-half mt-1">
                  <input
                    class="input"
                    type="email"
                    autocomplete="new-password"
                    v-model="userEmail"
                    placeholder="Email"
                  />
                </div>
              </div>
              <hr />
              <div class="mt-3 mb-1">
                <a
                  @click="openAddOrganizationConfirmModal()"
                  class="
                    button
                    has-background-transparent
                    is-outlined
                    has-text-weight-bold has-text-darker-grey
                    mb-2
                  "
                  >SUBMIT
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Add Organization -->
        <!-- Edit Organization -->
        <div v-if="editOrg && !addAdminUserForm && !editOrgManageProtocols">
          <div class="margin-top-40">
            <div class="has-background-grey has-text-centered">
              <span class="is-size-3">Edit Organization</span>
            </div>
            <div
              class="dropdown mt-2"
              :class="isOrgDropdownActive ? 'is-active' : ''"
            >
              <div class="dropdown-trigger">
                <button
                  class="button"
                  id="dropdownButton"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  @click="showDropdown('ORG')"
                >
                  <span
                    v-if="!selectedOrganization"
                    class="dropdown-custom-element"
                    >--Select Organization--</span
                  >
                  <span v-else class="dropdown-custom-element">{{
                    selectedOrganization
                  }}</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div
                    v-for="(value, index) in allOrganizationInfo"
                    :key="index"
                    class="dropdown-item has-hover-background-light"
                  >
                    <div
                      @click="selectOrganization(value.name, value.id)"
                      class="pointer"
                      :id="'organization_' + index"
                    >
                      <span class="is-size-6 ml-2"> {{ value.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div v-if="selectedOrganization">
                <hr />
                <div class="mt-2">
                  <label>Organization Name:</label>
                  <input class="input mb-2" type="text" v-model="orgName" />
                  <label>Description:</label>
                  <input class="input mb-2" type="text" v-model="description" />
                  <hr />
                  <div class="columns">
                    <div class="column is-half margin-bottom-10">
                      <div
                        class="
                          margin-bottom-10
                          has-text-weight-bold has-text-grey
                        "
                      >
                        <span class="has-text-grey">Admin Users:</span>
                      </div>
                      <div v-for="(user, index) in userList" :key="index">
                        <span>{{ user.email }}</span>
                        <i
                          class="
                            far
                            fa-trash-alt
                            delete-button
                            has-text-dark-secondary has-background-transparent
                            pointer
                          "
                          @click="
                            openDeleteUserConfirmModal(user.email, user.orgId)
                          "
                        ></i>
                      </div>
                      <a class="pointer is-block" @click="showAddAdminUser()">
                        <i class="fas fa-user-plus mt-2"></i>
                        ADD ADMIN-USER
                      </a>
                    </div>
                    <div class="column is-half">
                      <div class="margin-bottom-10 has-text-weight-bold">
                        <span class="has-text-grey">Case Protocols:</span>
                      </div>
                      <div
                        v-for="(protocol, index) in protocolList"
                        :key="index"
                        class="mt-2"
                      >
                        <div>
                          <div>
                            <span>{{ protocol }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <a @click="showAssignProtocols(selectedOrganization)"
                          ><i class="fas fa-tasks mr-2"></i>MANAGE PROTOCOLS</a
                        >
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div class="mb-1">
                      <a
                        @click="updateOrganization()"
                        class="
                          button
                          has-background-transparent
                          is-outlined
                          has-text-weight-bold has-text-darker-grey
                          mr-4
                        "
                        >SUBMIT
                      </a>
                      <a
                        @click="openDeleteOrganizationConfirmModal()"
                        class="
                          button
                          has-background-transparent
                          is-outlined
                          has-text-weight-bold has-text-darker-grey
                          mr-4
                        "
                        >DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Edit Organization -->
        <!-- Manage Protocols -->
        <div v-if="manageProtocols">
          <div class="margin-top-40">
            <div class="has-background-grey has-text-centered mb-2">
              <span class="is-size-3">Manage Case Protocols</span>
            </div>
            <hr />
            <div class="columns">
              <div class="column is-half">
                <span class="has-text-grey mb-2"
                  >Add Protocol (File Upload)</span
                >
                <span class="has-text-grey is-size-7 is-block mb-2"
                  >Supported Formats: .yaml .yml</span
                >
                <div>
                  <a
                    @click="onUploadClick()"
                    class="
                      button
                      has-background-transparent
                      is-outlined
                      has-text-weight-bold has-text-darker-grey
                      mr-4
                    "
                  >
                    <span>UPLOAD FILE</span>
                  </a>
                  <input
                    type="file"
                    class="is-display-none"
                    ref="fileInput"
                    @change="onFilePicked($event.target.files)"
                  />
                </div>
                <hr />
                <span class="has-text-grey mb-2"
                  >Add Protocol (Yaml input)</span
                >
                <span class="has-text-grey is-size-7 is-block mb-2"
                  >input must follow yaml conventions</span
                >
                <span class="has-text-grey is-size-7 is-block mb-2"
                  >*** See available protocols for input example</span
                >
                <div>
                  <a
                    @click="showEditProtocol({ name: '', protocol: '' })"
                    class="
                      button
                      has-background-transparent
                      is-outlined
                      has-text-weight-bold has-text-darker-grey
                      mr-4
                      mb-2
                    "
                  >
                    <span>ADD PROTOCOL</span>
                  </a>
                </div>
              </div>
              <div class="column is-half">
                <div class="mb-2">
                  <span class="has-text-grey">Available Protocols:</span>
                  <span class="has-text-grey is-size-7 is-block mb-2"
                    >Click to edit</span
                  >
                </div>
                <div
                  v-for="(protocol, index) in allProtocols"
                  :key="index"
                  class="mt-1"
                >
                  <div>
                    <span
                      @click="showEditProtocol(protocol)"
                      class="is-size-6 pointer"
                      >{{ protocol.name }}</span
                    >
                    <i
                      class="
                        far
                        fa-trash-alt
                        delete-button
                        has-text-dark-secondary has-background-transparent
                        pointer
                        is-size-7
                      "
                      @click="openDeleteProtocol(protocol)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Manage Protocols -->
        <!-- Assign Protocols -->
        <div v-if="assignProtocols || editOrgManageProtocols">
          <div class="margin-top-40">
            <div class="has-background-grey has-text-centered">
              <span class="is-size-3">Assign Case Protocols</span>
            </div>
            <a
              v-if="editOrgManageProtocols"
              @click="closeSubSession()"
              class="is-size-6 pointer is-block my-2"
              ><i class="fas fa-angle-left"></i> BACK</a
            >
            <div
              class="dropdown mt-2"
              :class="isOrgDropdownActive ? 'is-active' : ''"
            >
              <div class="dropdown-trigger">
                <button
                  class="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                  @click="showDropdown('ORG')"
                >
                  <span
                    v-if="!selectedOrganization"
                    class="dropdown-custom-element"
                    >--Select Organization--</span
                  >
                  <span v-else class="dropdown-custom-element">{{
                    selectedOrganization
                  }}</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <div
                    v-for="(value, index) in allOrganizationInfo"
                    :key="index"
                    class="dropdown-item has-hover-background-light"
                  >
                    <div
                      ref="selectOrganizationClick"
                      @click="selectOrganization(value.name, value.id)"
                      class="pointer"
                    >
                      <span class="is-size-6 ml-2"> {{ value.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="mt-2 mb-4" v-if="selectedOrganization">
              <span class="has-text-grey">Assigned Case Protocols:</span>
              <div
                v-for="(protocol, index) in protocolList"
                :key="index"
                class="mt-2"
              >
                <div>
                  <div class="is-size-6">
                    <span>{{ protocol }}</span>
                    <i
                      class="
                        far
                        fa-trash-alt
                        delete-button
                        has-text-dark-secondary has-background-transparent
                        pointer
                        is-size-7
                      "
                      @click="removeProtocol(index)"
                    ></i>
                  </div>
                </div>
              </div>
              <a class="mt-2 pointer is-block" @click="showAddProtocolModal()">
                <i class="fas fa-folder-plus"></i>
                ADD CASE PROTOCOL
              </a>
              <hr />
              <div class="my-2">
                <a
                  @click="updateOrganizationProtocols()"
                  class="
                    button
                    has-background-transparent
                    is-outlined
                    has-text-weight-bold has-text-darker-grey
                    mr-4
                  "
                  >SAVE
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Assign Protocols -->
        <!-- Add User -->
        <div v-if="addUserForm || addAdminUserForm">
          <div class="margin-top-40">
            <div class="has-background-grey has-text-centered">
              <span class="is-size-3">Add User</span>
            </div>
            <div v-if="addUserForm">
              <div
                class="dropdown mt-2"
                :class="isOrgDropdownActive ? 'is-active' : ''"
              >
                <div class="dropdown-trigger">
                  <button
                    class="button"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    @click="showDropdown('ORG')"
                  >
                    <span
                      v-if="!selectedOrganization"
                      class="dropdown-custom-element"
                      >--Select Organization--</span
                    >
                    <span v-else class="dropdown-custom-element">{{
                      selectedOrganization
                    }}</span>
                    <span class="icon is-small">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" role="menu">
                  <div class="dropdown-content">
                    <div
                      v-for="(value, index) in allOrganizationInfo"
                      :key="index"
                      class="dropdown-item has-hover-background-light"
                    >
                      <div
                        @click="selectOrganization(value.name, value.id)"
                        class="pointer"
                      >
                        <span class="is-size-6 ml-2"> {{ value.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="addAdminUserForm">
              <a
                @click="closeSubSession()"
                class="is-size-6 pointer is-block my-2"
                ><i class="fas fa-angle-left"></i> BACK</a
              >
              <hr />
            </div>
            <div>
              <div v-if="addUserForm">
                <div
                  class="dropdown mt-2"
                  :class="isRoleDropdownActive ? 'is-active' : ''"
                >
                  <div class="dropdown-trigger">
                    <button
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                      @click="showDropdown('ROLE')"
                    >
                      <span v-if="!selectedRole" class="dropdown-custom-element"
                        >--Select Role--</span
                      >
                      <span v-else class="dropdown-custom-element">{{
                        selectedRole
                      }}</span>
                      <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <div
                        v-for="(value, index) in roles"
                        :key="index"
                        class="dropdown-item has-hover-background-light"
                      >
                        <div @click="selectRole(value)" class="pointer">
                          <span class="is-size-6 ml-2"> {{ value }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div class="columns is-1 is-variable">
                <div class="column is-half mt-1">
                  <div class="mt-1 mb-2">
                    <span
                      >The user will receive an email asking them to choose a
                      password.</span
                    >
                  </div>
                  <input
                    class="input"
                    type="email"
                    autocomplete="new-password"
                    v-model="userEmail"
                    placeholder="Email"
                  />
                </div>
              </div>
              <hr />
              <div class="mt-2 mb-1">
                <a
                  @click="addUser()"
                  class="
                    button
                    has-background-transparent
                    is-outlined
                    has-text-weight-bold has-text-darker-grey
                  "
                  >ADD
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Add User -->
        <!-- Edit User -->
        <div v-if="editUserForm">
          <div class="margin-top-40">
            <div class="has-background-grey has-text-centered">
              <span class="is-size-3">Edit User</span>
            </div>
            <div class="field mt-3">
              <div class="control has-icons-right">
                <input
                  id="userInput"
                  class="input noborder"
                  type="text"
                  v-model="searchString"
                  placeholder="Search user..."
                  @keydown.down="keyDown()"
                  @keydown.up="keyUp($event)"
                  @keydown.enter="suggestionClick()"
                />
                <span class="icon is-right has-background-light-mid-grey">
                  <i class="fas fa-search has-text-white"></i>
                </span>
              </div>
            </div>
            <div
              v-if="searchString"
              class="is-absolute has-zindex-15 dropdown-content"
            >
              <span v-if="matches.length == 0" class="is-size-6 mx-2">{{
                "No matches found."
              }}</span>
              <div
                v-for="(suggestion, index) in matches"
                :key="index"
                class="is-block"
                @click="suggestionClick(index)"
              >
                <div
                  class="has-hover-background-light pointer my-2"
                  :class="
                    preselectedUser == suggestion ? 'has-background-light' : ''
                  "
                >
                  <span class="is-size-6 mx-2">{{ suggestion }}</span>
                </div>
              </div>
            </div>
            <hr />
            <div class="columns">
              <div class="column is-half">
                <div
                  class="dropdown mt-2"
                  :class="isOrgDropdownActive ? 'is-active' : ''"
                >
                  <div class="dropdown-trigger">
                    <button
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                      @click="showDropdown('ORG')"
                    >
                      <span
                        v-if="!selectedOrganization"
                        class="dropdown-custom-element"
                        >--Select Organization--</span
                      >
                      <span v-else class="dropdown-custom-element">{{
                        selectedOrganization
                      }}</span>
                      <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <div
                        v-for="(value, index) in allOrganizationInfo"
                        :key="index"
                        class="dropdown-item has-hover-background-light"
                      >
                        <div
                          @click="selectOrganization(value.name, value.id)"
                          class="pointer"
                        >
                          <span class="is-size-6 ml-2"> {{ value.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="dropdown mt-2"
                  :class="isUserDropdownActive ? 'is-active' : ''"
                >
                  <div class="dropdown-trigger mb-1">
                    <button
                      class="button"
                      aria-haspopup="true"
                      aria-controls="dropdown-menu"
                      @click="showDropdown('USER')"
                    >
                      <span v-if="!selectedUser" class="dropdown-custom-element"
                        >--Select User--</span
                      >
                      <span v-else class="dropdown-custom-element">{{
                        selectedUser
                      }}</span>
                      <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <div
                        v-for="(value, index) in userList"
                        :key="index"
                        class="dropdown-item has-hover-background-light"
                      >
                        <div
                          @click="selectUser(value.email, value.orgId)"
                          class="pointer"
                        >
                          <span class="is-size-6 mt-1 ml-2">
                            {{ value.email }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="selectedUser">
              <hr />
              <div class="columns is-1 is-variable">
                <div class="column is-half mt-1">
                  <input
                    class="input"
                    type="email"
                    autocomplete="new-password"
                    v-model="userEmail"
                    placeholder="Email"
                    disabled="true"
                  />
                </div>
              </div>
              <hr />
              <div class="mt-2">
                <span>Assigned Roles:</span>
                <div class="mt-1">
                  <input
                    type="checkbox"
                    id="admin"
                    value="admin"
                    v-model="checkedRoles"
                  />
                  <label for="admin"> Admin </label>
                </div>
                <div>
                  <input
                    type="checkbox"
                    id="User"
                    value="user"
                    v-model="checkedRoles"
                  />
                  <label for="User"> User </label>
                </div>
                <div v-if="selectedOrganization == 'Laralab'">
                  <input
                    type="checkbox"
                    id="laraSupport"
                    value="lara_support"
                    v-model="checkedRoles"
                  />
                  <label for="laraSupport"> Laralab Support </label>
                </div>
              </div>
              <hr />
              <div class="my-1">
                <a
                  @click="updateUser()"
                  class="
                    button
                    has-background-transparent
                    is-outlined
                    has-text-weight-bold has-text-darker-grey
                    mr-4
                  "
                  >SUBMIT
                </a>
                <a
                  @click="openDeleteUserConfirmModal()"
                  class="
                    button
                    has-background-transparent
                    is-outlined
                    has-text-weight-bold has-text-darker-grey
                    mr-4
                  "
                  >DELETE
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- End Edit User -->
        <!-- Messages -->
        <div
          v-if="errorMessage"
          class="message is-danger is-small has-max-width-50"
          :class="initial ? 'margin-top-60' : ''"
        >
          <div class="message-body has-text-weight-bold">
            <span>{{ errorMessage }}</span>
          </div>
        </div>
        <div
          v-if="message"
          class="message is-success is-small has-max-width-50"
          :class="initial ? 'margin-top-60' : ''"
        >
          <div class="message-body has-text-weight-bold">
            <span>{{ message }}</span>
          </div>
        </div>
        <!-- End Messages -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { sendErrors } from "@/js/errorHandler.js";
import AdminModal from "@/components/AdminModals.vue";
import * as constants from "../constants.js";

const INITIAL = 0;
const ADD_ORG = 1;
const EDIT_ORG = 2;
const ADD_USER = 3;
const EDIT_USER = 4;
const ADD_ORG_CONFIRM = 5;
const DELETE_ORG_CONFIRM = 6;
const DELETE_USER_CONFIRM = 7;
const ASSIGN_PROTOCOLS = 8;
const ADD_PROTOCOL = 9;
const MANAGE_PROTOCOLS = 10;
const EDIT_PROTOCOL = 11;
const DELETE_PROTOCOL_CONFIRM = 12;
const LARA_SUPPORT = 3;
const ADMIN = 1;
const USER = 2;

export default {
  name: "AdminComponent",
  data() {
    return {
      allOrganizationInfo: [],
      allUserInfo: [],
      allProtocols: [],
      checkedRoles: [],
      constants: constants,
      currentActiveSession: INITIAL,
      currentActiveSubSession: INITIAL,
      currentModal: INITIAL,
      description: "",
      errorMessage: "",
      isLoggedIn: false,
      isOrgDropdownActive: false,
      isUserDropdownActive: false,
      isRoleDropdownActive: false,
      message: "",
      modalKey: 0,
      orgId: 0,
      orgName: "",
      roles: ["admin", "user"],
      searchUserList: [],
      searchString: "",
      userEmail: "",
      userList: [],
      userRoles: [],
      selectedOrganization: "",
      selectedRole: "",
      selectedUser: "",
      selectedProtocol: {},
      preselectedUser: "",
      userIndexForSelection: -1,
      protocolList: [],
      waitingForResponse: false,
    };
  },
  watch: {
    searchString: function () {
      this.userIndexForSelection = -1;
      this.preselectedUser = "";
    },
  },
  components: {
    AdminModal,
  },
  computed: {
    matches() {
      return this.searchUserList.filter((str) => {
        return str.toLowerCase().indexOf(this.searchString.toLowerCase()) >= 0;
      });
    },
    initial() {
      return this.currentActiveSession === INITIAL;
    },
    addOrg() {
      return this.currentActiveSession === ADD_ORG;
    },
    editOrg() {
      return this.currentActiveSession === EDIT_ORG;
    },
    addUserForm() {
      return this.currentActiveSession === ADD_USER;
    },
    addAdminUserForm() {
      return this.currentActiveSubSession === ADD_USER;
    },
    editUserForm() {
      return this.currentActiveSession === EDIT_USER;
    },
    manageProtocols() {
      return this.currentActiveSession === MANAGE_PROTOCOLS;
    },
    assignProtocols() {
      return this.currentActiveSession === ASSIGN_PROTOCOLS;
    },
    editOrgManageProtocols() {
      return this.currentActiveSubSession === ASSIGN_PROTOCOLS;
    },
  },
  beforeMount() {
    this.checkAdminLoggedIn();
    this.getOrganizations();
    this.getUsers();
    this.getProtocols();
  },
  methods: {
    reset: function () {
      this.checkedRoles = [];
      this.currentActiveSession = INITIAL;
      this.currentActiveSubSession = INITIAL;
      this.description = "";
      this.errorMessage = "";
      this.isOrgDropdownActive = false;
      this.isUserDropdownActive = false;
      this.isRoleDropdownActive = false;
      this.message = "";
      this.orgName = "";
      this.selectedRole = "";
      this.selectedOrganization = "";
      this.selectedUser = "";
      this.userList = [];
      this.userRoles = [];
      this.userEmail = "";
      this.protocolList = [];
      this.waitingForResponse = false;
      this.getOrganizations();
      this.getUsers();
      this.getProtocols();
    },
    checkAdminLoggedIn: function () {
      axios
        .get("/api/currentuser")
        .then((response) => {
          if (
            !response.data.user ||
            !response.data.user.roles.includes(LARA_SUPPORT)
          ) {
            window.location.href = "/";
          } else {
            this.isLoggedIn = true;
            this.$store.commit("setCurrentUser", response.data.user);
            this.switchToAdminView();
            this.user = this.$store.getters.currentUser;
          }
        })
        .catch((err) => {
          sendErrors(err, "Admin.vue");
        });
    },
    switchToAdminView: function () {
      this.$store.commit("setCurrentView", constants.VIEW_ADMIN);
    },
    getOrganizations: function () {
      axios
        .get("api/get_organizations")
        .then((res) => {
          this.allOrganizationInfo = res.data.organizationInfo;
        })
        .catch((err) => {
          sendErrors(err, "Admin.vue");
        });
    },
    getUsers: function () {
      this.allUserInfo = [];
      this.searchUserList = [];
      axios
        .get("api/get_users")
        .then((res) => {
          for (const user of res.data.userInfo) {
            this.allUserInfo.push(user);
            this.searchUserList.push(user.email);
          }
        })
        .catch((err) => {
          sendErrors(err, "Admin.vue:getUsers()");
        });
    },
    getProtocols: function () {
      this.allProtocols = [];
      axios.get("api/get_protocols").then((res) => {
        res.data.protocols.forEach((protocol) => {
          this.allProtocols.push(protocol);
        });
      });
    },
    showAddOrganization: function () {
      this.reset();
      this.message = "";
      this.currentActiveSession = ADD_ORG;
    },
    showEditOrganization: function () {
      this.reset();
      this.message = "";
      this.currentActiveSession = EDIT_ORG;
    },
    showAssignProtocols: function (organization = null) {
      if (organization) {
        this.selectedOrganization = organization;
        this.currentActiveSubSession = ASSIGN_PROTOCOLS;
        return;
      }
      this.reset();
      this.currentActiveSession = ASSIGN_PROTOCOLS;
    },
    showEditProtocol: function (protocol) {
      this.selectedProtocol = protocol;
      this.currentModal = EDIT_PROTOCOL;
    },
    showManageProtocols: function () {
      this.reset();
      this.currentActiveSession = MANAGE_PROTOCOLS;
    },
    showAddUser: function () {
      this.reset();
      this.currentActiveSession = ADD_USER;
    },
    showAddAdminUser: function () {
      if (!this.selectedOrganization) {
        this.setErrorMessage("Select Organization");
        return;
      }
      this.selectedRole = "admin";
      this.userEmail = "";
      this.currentActiveSubSession = ADD_USER;
    },
    showEditUser: function () {
      this.reset();
      this.currentActiveSession = EDIT_USER;
    },
    expandEditOrgSection: function () {
      this.orgName = this.selectedOrganization;
      for (const org of this.allOrganizationInfo) {
        if (org.id === this.orgId) {
          this.description = org.description;
        }
      }
      this.createUserList(true);
    },
    expandEditUserSection: function () {
      this.createUserList();
    },
    getProtocolsForOrganization: function () {
      for (const org of this.allOrganizationInfo) {
        if (org.id === this.orgId) {
          this.protocolList = org.case_protocols;
        }
      }
    },
    showDropdown: function (dropdown) {
      if (dropdown === "ORG") {
        this.isUserDropdownActive = false;
        this.isRoleDropdownActive = false;
        this.isOrgDropdownActive = !this.isOrgDropdownActive;
      }
      if (dropdown === "USER") {
        this.isOrgDropdownActive = false;
        this.isRoleDropdownActive = false;
        this.isUserDropdownActive = !this.isUserDropdownActive;
      }
      if (dropdown === "ROLE") {
        this.isUserDropdownActive = false;
        this.isOrgDropdownActive = false;
        this.isRoleDropdownActive = !this.isRoleDropdownActive;
      }
    },
    selectOrganization: function (name, id) {
      this.roles = ["admin", "user"];
      if (name === "Laralab") {
        this.roles.push("lara_support");
      }
      this.selectedRole = "";
      this.selectedUser = "";
      this.selectedOrganization = name;
      this.orgId = id;
      if (this.currentActiveSession === EDIT_ORG) {
        this.expandEditOrgSection();
        this.getProtocolsForOrganization();
      } else if (this.currentActiveSession === EDIT_USER) {
        this.expandEditUserSection();
      } else if (this.currentActiveSession === ASSIGN_PROTOCOLS) {
        this.getProtocolsForOrganization();
      }
      this.isOrgDropdownActive = false;
    },
    selectRole: function (value) {
      this.selectedRole = value;
      this.isRoleDropdownActive = false;
    },
    selectUser: function (email, orgId) {
      if (email === this.$store.getters.currentUser.email) {
        this.setErrorMessage("Cannot edit logged in user");
        this.isUserDropdownActive = false;
        return;
      }
      this.userRoles = [];
      this.checkedRoles = [];
      this.selectedUser = this.userEmail = email;
      this.orgId = orgId;
      for (const user of this.allUserInfo) {
        if (user.email === email) {
          if (user.roles.includes(ADMIN) === true) {
            this.userRoles.push("admin");
            this.checkedRoles.push("admin");
          }
          if (user.roles.includes(USER) === true) {
            this.userRoles.push("user");
            this.checkedRoles.push("user");
          }
          if (user.roles.includes(LARA_SUPPORT) === true) {
            this.userRoles.push("laraSupport");
            this.checkedRoles.push("lara_support");
          }
        }
      }
      this.isUserDropdownActive = false;
    },
    closeSubSession: function () {
      this.currentActiveSubSession = INITIAL;
    },
    closeSession: function (res = null) {
      if (res != null) {
        if (res.status !== 200) {
          this.setErrorMessage(res.data.message);
        } else {
          this.setMessage(res.data.message);
        }
      }
      this.currentActiveSession = INITIAL;
    },
    openAddOrganizationConfirmModal: function () {
      let orgExists = false;
      for (const org of this.allOrganizationInfo) {
        if (org.name === this.orgName) {
          orgExists = true;
        }
      }
      if (!this.orgName) {
        this.setErrorMessage("Organization can't be empty");
        return;
      } else if (this.orgName.length > 80) {
        this.setErrorMessage("Organization Name can't exceed 80 characters");
        return;
      } else if (orgExists) {
        this.setErrorMessage("Organization already exists");
        return;
      }
      if (!this.userEmail) {
        this.modalKey++;
        this.currentModal = ADD_ORG_CONFIRM;
      } else {
        if (this.isMailFormatValid()) {
          this.currentModal = ADD_ORG_CONFIRM;
        }
      }
    },
    openDeleteOrganizationConfirmModal: function () {
      this.currentModal = DELETE_ORG_CONFIRM;
    },
    openDeleteUserConfirmModal: function (user = null, orgId = null) {
      if (user) {
        this.selectedUser = user;
        this.orgId = orgId;
      }
      this.currentModal = DELETE_USER_CONFIRM;
    },
    closeModal: function () {
      this.currentModal = INITIAL;
    },
    submitOrganization: function () {
      let apiResponse = "";
      if (this.userEmail) {
        this.isMailFormatValid();
      }
      axios
        .post("api/create_organization", {
          organization: this.orgName,
          description: this.description,
        })
        .then((res) => {
          apiResponse = res;
          if (this.userEmail) {
            this.selectedOrganization = this.orgName;
            this.selectedRole = "admin";
            this.addUser().then(() => {
              this.closeSession(apiResponse);
              this.closeModal();
            });
          }
          this.closeSession(apiResponse);
          this.closeModal();
        })
        .catch((err) => {
          this.closeModal();
          this.setErrorMessage(err.response.data.message);
          sendErrors(err, "Admin.vue:submitOrganization()");
        });
    },
    updateOrganization: function () {
      if (!this.orgName) {
        this.setErrorMessage("Organization name can't be empty");
        return;
      } else if (this.orgName.length > 80) {
        this.setErrorMessage("Organization name can't exceed 80 characters");
        return;
      }
      axios
        .post("api/update_organization", {
          org_id: this.orgId,
          organization: this.orgName,
          description: this.description,
        })
        .then((res) => {
          this.closeSession(res);
          this.getOrganizations();
        })
        .catch((err) => {
          sendErrors(err, "Admin.vue:updateOrganization()");
        });
    },
    // TODO: restrict deleting of organization, for example, only organizaitons without users can be deleted
    deleteOrganization: function () {
      axios
        .post("api/delete_organization", { org_id: this.orgId })
        .then((res) => {
          this.closeSession(res);
          this.closeModal();
          this.getOrganizations();
        })
        .catch((err) => {
          this.closeModal();
          this.setErrorMessage("Something went wrong");
          sendErrors(err, "Admin.vue:deleteOrganization()");
        });
    },
    addUser: function () {
      return new Promise((resolve, reject) => {
        if (this.currentActiveSession === ADD_USER) {
          this.orgName = this.selectedOrganization;
        }
        if (!this.selectedOrganization) {
          this.setErrorMessage("Choose Organization");
        } else if (!this.selectedRole) {
          this.setErrorMessage("Choose Role");
        } else {
          if (this.isMailFormatValid()) {
            const temp = this.userEmail;
            this.waitingForResponse = true;

            axios
              .post("api/create_user", {
                user: this.userEmail,
                role: this.selectedRole,
                org_name: this.orgName,
              })
              .then((res) => {
                if (res.status === 200) {
                  this.setMessage("User successfully created");
                  this.userList.push({ email: temp, orgId: this.orgId });
                } else {
                  this.setErrorMessage(res.data.message);
                }
                resolve("User created");
              })
              .catch((err) => {
                this.setErrorMessage(err.response.data.message);
                sendErrors(err, "Admin.vue:addUser()");
              })
              .finally(() => {
                this.waitingForResponse = false;
              });
            if (this.currentActiveSubSession === ADD_USER) {
              this.closeSubSession();
            } else {
              this.closeSession();
            }
          }
        }
      });
    },
    updateUser: function () {
      if (this.checkedRoles.length === 0) {
        this.setErrorMessage("User must have at least one role");
        return;
      }
      if (this.isMailFormatValid()) {
        axios
          .post("api/update_user", {
            old_email: this.selectedUser,
            new_email: this.userEmail,
            roles: this.checkedRoles,
          })
          .then((res) => {
            this.setMessage(res.data.message);
            this.closeSession();
          })
          .catch((err) => {
            this.setErrorMessage("Error updating user");
            sendErrors(err, "Admin.vue:updateUser()");
          });
      }
    },
    deleteUser: function () {
      axios
        .post("api/delete_user", {
          org_id: this.orgId,
          user: this.selectedUser,
        })
        .then((res) => {
          this.currentModal = INITIAL;
          const index = this.userList.findIndex(
            (element) => element.email === this.selectedUser
          );
          this.userList.splice(index, 1);
          if (res.status === 200) {
            this.setMessage(res.data.message);
          }
          if (this.currentActiveSession === EDIT_USER) {
            this.closeSession();
          }
        })
        .catch((err) => {
          this.setErrorMessage("Error while deleting user");
          sendErrors(err, "Admin.vue:deleteUser()");
        });
    },
    setErrorMessage: function (msg) {
      this.errorMessage = msg;
      setTimeout(() => {
        this.errorMessage = "";
      }, 4000);
    },
    setMessage: function (msg) {
      this.message = msg;
      setTimeout(() => {
        this.message = "";
      }, 4000);
    },
    isMailFormatValid: function () {
      const mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.userEmail.match(mailformat)) {
        this.setErrorMessage("Invalid mail-format");
        return false;
      } else {
        return true;
      }
    },
    createUserList: function (admin = false) {
      this.userList = [];
      if (admin) {
        for (const user of this.allUserInfo) {
          if (
            user.organization_id === this.orgId &&
            user.roles.includes(ADMIN)
          ) {
            this.userList.push({
              email: user.email,
              orgId: user.organization_id,
            });
          }
        }
      } else {
        for (const user of this.allUserInfo) {
          if (user.organization_id === this.orgId) {
            this.userList.push({
              email: user.email,
              orgId: user.organization_id,
            });
          }
        }
      }
    },
    suggestionClick(index = this.userIndexForSelection) {
      if (index === -1) {
        return;
      }
      this.selectUser(this.matches[index]);
      this.searchString = "";
      for (const user of this.allUserInfo) {
        if (user.email === this.selectedUser) {
          this.orgId = user.organization_id;
        }
      }
      this.createUserList();
      for (const org of this.allOrganizationInfo) {
        if (org.id === this.orgId) {
          this.selectedOrganization = org.name;
        }
      }
    },
    keyDown: function () {
      if (
        this.matches.length !== 0 &&
        this.userIndexForSelection < this.matches.length - 1
      ) {
        this.userIndexForSelection++;
        this.preselectedUser = this.matches[this.userIndexForSelection];
      }
    },
    keyUp: function (event) {
      event.preventDefault();
      if (this.matches.length !== 0 && this.userIndexForSelection > 0) {
        this.userIndexForSelection--;
        this.preselectedUser = this.matches[this.userIndexForSelection];
      }
    },
    removeProtocol: function (id) {
      this.protocolList.splice(id, 1);
    },
    showAddProtocolModal: function () {
      this.currentModal = ADD_PROTOCOL;
    },
    addProtocols: function (selectedProtocols) {
      selectedProtocols.forEach((protocol, index) => {
        this.protocolList[index] = protocol;
      });
    },
    uploadProtocolEvent: async function (file) {
      this.uploadProtocol(file);
      this.reset();
    },
    uploadProtocol: function (file) {
      return new Promise((resolve, reject) => {
        if (!this.selectedOrganization) {
          this.selectedOrganization = "";
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("organization", this.selectedOrganization);
        axios
          .post("manager_api/upload_protocol", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            this.setMessage(res.data.message);
            resolve();
          })
          .catch((err) => {
            reject();
            this.setErrorMessage(err.response.data.message);
            sendErrors(err, "Admin.Vue:uploadProtocol");
          });
      });
    },
    updateOrganizationProtocols: function () {
      axios
        .post("manager_api/update_organization_protocols", {
          organization_id: this.orgId,
          protocols: this.protocolList,
        })
        .then((res) => {
          this.setMessage(res.data.message);
        })
        .catch((err) => {
          sendErrors(err, "Admin.Vue:updateProtocol");
        });
      if (this.currentActiveSubSession !== INITIAL) {
        this.closeSubSession();
      } else {
        this.getProtocols();
      }
    },
    openDeleteProtocol(protocol) {
      this.selectedProtocol = protocol;
      this.currentModal = DELETE_PROTOCOL_CONFIRM;
    },
    deleteProtocol: function () {
      axios
        .post("manager_api/delete_protocol", {
          protocol: this.selectedProtocol.name,
        })
        .then((res) => {
          this.closeSession();
          this.showManageProtocols();
          this.setMessage(res.data.message);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            this.setErrorMessage(err.response.data.message);
          } else {
            sendErrors(err, "Admin.Vue:deleteProtocol");
          }
        });
      this.closeModal();
    },
    editProtocolEvent: function (protocol) {
      this.editProtocol(protocol)
        .then((res) => {
          this.closeModal();
          this.getProtocols();
        })
        .catch((err) => {
          this.setErrorMessage(err.response.data.message);
          sendErrors(err);
        });
    },
    editProtocol: function (protocol) {
      return new Promise((resolve) => {
        axios
          .post("manager_api/edit_protocol", {
            protocol: protocol,
          })
          .then((res) => {
            resolve();
            this.setMessage(res.data.message);
          })
          .catch((err) => {
            this.setErrorMessage(err.response.data.message);
            this.closeModal();
          });
      });
    },
    createProtocol: function (protocol) {
      axios
        .post("manager_api/add_case_protocol", {
          protocol: protocol,
        })
        .then((res) => {
          this.closeModal();
          this.getProtocols();
          this.setMessage(res.data.message);
        })
        .catch((err) => {
          this.setErrorMessage(err.response.data.message);
          this.closeModal();
        });
    },

    onUploadClick: function () {
      this.$refs.fileInput.click();
    },
    onFilePicked: async function (files) {
      const file = files[0];
      await this.uploadProtocol(file).then(() => {
        this.closeSession();
      });
    },
  },
};
</script>
<style scoped>
.admin {
  height: calc(100vh - 3.25rem);
  overflow-y: auto !important;
}
.dropdown-custom-element {
  min-width: 200px;
  text-align: left;
}
</style>
